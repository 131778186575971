import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import parse, { Element } from 'html-react-parser';
import { ReactComponent as LogoComcolors } from 'assets/svg/logo-comcolors.svg';
import { ReactComponent as ColorsLine } from 'assets/svg/colors-line.svg';
import { ISummary } from 'types/app';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPISummary from 'hooks/api/useAPISummary';
import {
  CopyRight,
  EditedBy,
  Line,
  LogoWrapper,
  MainContentWrapper,
  MySummaryContentWrapper,
  MySummaryWrapper,
  PopupContainer,
  PreparedBy,
} from 'pages/profile/has-profile/my-summary/MySummary.styled';
import MySummaryProfileSchemaExplanation from 'pages/profile/has-profile/my-summary/MySummaryProfileSchemaExplanation';
import MySummaryTOC from 'pages/profile/has-profile/my-summary/MySummaryTOC';
import MySummaryReadMore from 'pages/profile/has-profile/my-summary/MySummaryReadMore';
import MySummaryPopup from 'pages/profile/has-profile/my-summary/MySummaryPopup';
import MySummaryDownload from 'pages/profile/has-profile/my-summary/MySummaryDownload';
import MySummaryDiagramImage from 'pages/profile/has-profile/my-summary/MySummaryDiagramImage';

const MySummary: React.FC = () => {
  const { t, i18n } = useTranslation('translation');
  const contentEl = useRef<HTMLDivElement | null>(null);
  const summaryWrapperEl = useRef<HTMLDivElement | null>(null);
  const [summaryDetails, setSummaryDetails] = useState<ISummary>();
  const { getSummaryDetail } = useAPISummary();
  const { user } = useContext(UserContext) as UserContextProps;
  const [hasPopup, setPopup] = useState(false);
  const [isTOCOpen, setTOCOpening] = useState(false);
  const [isPopupOpen, setPopupOpening] = useState(false);
  const [popupContentHTML, setPopupContentHTML] = useState('');

  const loadDatas = useCallback(async () => {
    setSummaryDetails(await getSummaryDetail(parseInt(`${user?.id}`, 10)));
  }, []);

  const onTOCOpen = () => {
    const state = !isTOCOpen;

    setTOCOpening(state);
    setPopup(state);
  };

  const scrollIntoView = (id: string) => {
    if (summaryWrapperEl.current && contentEl.current) {
      const targetOffset = ((contentEl.current as HTMLElement).querySelector(id) as HTMLElement)
        .offsetTop;

      contentEl.current?.scrollTo({
        top: targetOffset - 16,
        behavior: 'smooth',
      });
    }
  };

  const onReadMore = (blockId: string) => {
    const toRememberBlock = contentEl.current?.querySelector(`#${blockId}`);
    if (toRememberBlock) {
      // Look if previous sibling is color-title-block
      const colorTitleBlock = toRememberBlock.previousElementSibling;
      const isColorBlockTitle = colorTitleBlock?.getAttribute('class') === 'color-title-block';

      setPopupContentHTML(
        `${isColorBlockTitle ? colorTitleBlock.outerHTML : ''}${toRememberBlock.innerHTML}`,
      );
      setPopupOpening(true);
      setPopup(true);
    }
  };

  useEffect(() => {
    loadDatas();
  }, []);

  return (
    <MySummaryWrapper className="app-page" ref={summaryWrapperEl}>
      <PopupContainer open={hasPopup}>
        {summaryDetails?.resultTOC && (
          <MySummaryTOC
            isOpen={isTOCOpen}
            toc={summaryDetails?.resultTOC[i18n.language]}
            onOpen={() => {
              onTOCOpen();
            }}
            onSelectEntry={scrollIntoView}
          />
        )}
        {
          <MySummaryPopup
            contentHTML={popupContentHTML}
            isOpen={isPopupOpen}
            onClose={() => {
              setPopupOpening(false);
              setPopup(false);
            }}
          />
        }
      </PopupContainer>
      <MySummaryContentWrapper className="my-summary-content-wrapper" ref={contentEl}>
        <Container padding={0}>
          <PageInnerTitle
            dangerouslySetInnerHTML={{
              __html: t('profile.mysummary.title', {
                name: `${user?.firstname} ${user?.lastname}`,
              }),
            }}
          />
          {summaryDetails && (
            <>
              {summaryDetails.trainerFullName && (
                <PreparedBy
                  dangerouslySetInnerHTML={{
                    __html: t('profile.mysummary.preparedBy', {
                      name: summaryDetails.trainerFullName || '',
                    }),
                  }}
                />
              )}
              <Line />
              <LogoWrapper>
                <LogoComcolors />
              </LogoWrapper>
              <ColorsLine style={{ width: '100%', marginBottom: '35px' }} />
              {summaryDetails.textEditedBy && (
                <EditedBy
                  dangerouslySetInnerHTML={{
                    __html: summaryDetails.textEditedBy[i18n.language],
                  }}
                />
              )}
              {summaryDetails.textCopyright && (
                <CopyRight
                  dangerouslySetInnerHTML={{
                    __html: summaryDetails.textCopyright[i18n.language],
                  }}
                />
              )}
              {summaryDetails.pdfURL && <MySummaryDownload linkPDF={summaryDetails.pdfURL} />}
              <Line />
              <Container>
                {summaryDetails.textHTML[i18n.language] && (
                  <MainContentWrapper>
                    {parse(summaryDetails.textHTML[i18n.language], {
                      replace(domNode) {
                        if (
                          domNode instanceof Element &&
                          domNode.attribs.class === 'schema-table'
                        ) {
                          return (
                            <>
                              <p className="schema-title">
                                <em>{t('profile.mysummary.schemaTitle')}</em>
                              </p>
                              <div className="schema-table">
                                {summaryDetails.schemaData && (
                                  <MySummaryProfileSchemaExplanation
                                    dataCells={summaryDetails.schemaData[i18n.language]}
                                  />
                                )}
                              </div>
                            </>
                          );
                        }

                        if (
                          domNode instanceof Element &&
                          domNode.attribs.class === 'line-separator'
                        ) {
                          return <Line className="inner-line-separator" />;
                        }

                        if (
                          domNode instanceof Element &&
                          domNode.attribs.class === 'read-more-placeholder'
                        ) {
                          return (
                            <MySummaryReadMore
                              blockId={domNode.attribs['data-block-id']}
                              onClick={(blockId) => onReadMore(blockId)}
                            />
                          );
                        }

                        // Diagrams
                        if (domNode instanceof Element && domNode.attribs['data-diagram-id']) {
                          const id = domNode.attribs['data-diagram-id'];
                          const texts =
                            (id === '#diagramme4#' || id === '#diagramme2#') ? summaryDetails.diagramColors : undefined;
                          const textsPercent =
                            id === '#diagramme1#' ? summaryDetails.diagramPercent : undefined;
                          const centeredText =
                            texts || textsPercent ? summaryDetails.diagramSecondaryText : undefined;

                          return (
                            <MySummaryDiagramImage
                              id={id}
                              imageURL={`${domNode.attribs['data-diagram-src']}`}
                              texts={texts}
                              textsPercent={textsPercent}
                              centeredText={centeredText}
                            />
                          );
                        }
                      },
                    })}
                  </MainContentWrapper>
                )}
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                {summaryDetails.pdfURL && <MySummaryDownload linkPDF={summaryDetails.pdfURL} />}
              </Container>
            </>
          )}
        </Container>
      </MySummaryContentWrapper>
    </MySummaryWrapper>
  );
};

export default MySummary;
