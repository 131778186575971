import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import {
  AppUpdateContainer,
  AppUpdaterWrapper,
  ButtonInner,
} from 'components/app-updater/AppUpdater.styled';
import { SvgWrapper } from 'styles/helpers/utils';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { AppProps } from 'App';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';

const AppUpdater: React.FC<Pick<AppProps, 'appUpdatePending' | 'updateAction'>> = ({
  // appUpdatePending,
  updateAction,
}) => {
  const { t } = useTranslation('translation');
  const { showRefresh, setShowRefresh } = useContext(SettingsContext) as SettingsContextProps;
  const {
    icons: [iconRefresh],
  } = useIcons([ICON_NAMES.REFRESH]);

  // console.log('AppUpdater:: showRefresh --> ', showRefresh, ', :: appUpdatePending: ', appUpdatePending);
  return (
    <AppUpdaterWrapper className={showRefresh ? 'open' : 'close'}>
      <AppUpdateContainer>
        <p>{t('message.newversion')}</p>
        <Button
          onClick={() => {
            setShowRefresh(false);
            updateAction && updateAction();
          }}
          fill="outline"
          color={'white'}
        >
          <ButtonInner>
            <SvgWrapper>{iconRefresh.icon}</SvgWrapper>
            <span>{t('message.newversionbuttonlabel')}</span>
          </ButtonInner>
        </Button>
      </AppUpdateContainer>
    </AppUpdaterWrapper>
  );
};

export default AppUpdater;
