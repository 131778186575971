import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import ForgottenpwdValidation from 'pages/forgottenpwd/Forgottenpwd-validation';
import { ForgottenpwdInstruction, ForgottenPwdNew } from 'pages/forgottenpwd/Forgottenpwd.styled';
import useFormHandler from 'hooks/useFormHandler';
import { isValidPassword } from 'helpers/utils';
import { ForgottenPwdProps } from 'pages/forgottenpwd/Forgottenpwd';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { ROUTES } from 'constants/routes';
import { Link } from 'react-router-dom';

const ForgottenpwdNewPwd: React.FC<ForgottenPwdProps> = ({ onBack, email = '' }) => {
  const { t } = useTranslation('translation');
  const { sendForgottenPwdNewPwd } = useAPIAuth();
  const [showSuccess, setSuccessState] = useState<boolean>(false);
  const { fields, isFormValid, getFieldByName, updateFieldValue, updateFieldErrorMessage } =
    useFormHandler([
      {
        name: 'code',
        value: '',
        required: true,
      },
      {
        name: 'password',
        value: '',
        required: true,
      },
    ]);
  const doRequest = async (password: string, code: string, email: string) => {
    updateFieldErrorMessage('code', '');
    const hasSubmitted = await sendForgottenPwdNewPwd(password, email, code);

    // Error
    hasSubmitted === false && updateFieldErrorMessage('code', t('message.error.forgottenpwdcode'));
    // Success
    hasSubmitted && setSuccessState(true);
  };

  const isValidPwd = (): boolean => {
    const pwdField = getFieldByName('password');
    let message = '';

    if (!isValidPassword(pwdField.value as string)) {
      message = t('message.error.password') || '';
    }
    updateFieldErrorMessage('password', message);
    return message === '';
  };
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid()) {
      if (isValidPwd()) {
        const password = getFieldByName('password').value as string;
        const code = getFieldByName('code').value as string;

        doRequest(password, code, email || '');
      }
    }
  };

  return (
    <ForgottenPwdNew>
      {showSuccess ? (
        <div className="pwd-changed">
          <p dangerouslySetInnerHTML={{ __html: t('message.newpwdsuccess') }} />
          <Link to={ROUTES.LOGIN}>{t('common.back')}</Link>
        </div>
      ) : (
        <>
          <ForgottenpwdInstruction
            className="newpwd"
            dangerouslySetInnerHTML={{ __html: t('forgottenpwd.newpwdpage.instruction') }}
          />
          <form noValidate onSubmit={onSubmit} autoComplete="off">
            <Textfield
              {...({
                ...getFieldByName('code', fields),
                label: t('forgottenpwd.codepage.form.code') || '',
                onChange: (value?) => updateFieldValue('code', value),
              } as TextfieldProps)}
            />
            <Textfield
              {...({
                ...getFieldByName('password', fields),
                label: t('forgottenpwd.newpwdpage.form.newpwd') || '',
                onChange: (value?) => updateFieldValue('password', value),
              } as TextfieldProps)}
            />
            {/*
              {newPwdResult && !newPwdResult.data.success && (
              <FormErrorWrapper>
                <span dangerouslySetInnerHTML={{ __html: t('message.error.forgottenpwdcode') }} />
              </FormErrorWrapper>
            )}
              * */}
            <ForgottenpwdValidation withBack={true} goBackTo={onBack} />
          </form>
        </>
      )}
    </ForgottenPwdNew>
  );
};

export default ForgottenpwdNewPwd;
