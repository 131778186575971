import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { IconChevronProps } from 'components/common/icons/icon-chevron/IconChevron';

const Chevron = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.3s;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconChevronWrapper = styled.div<IconChevronProps>`
  width: ${prem(17)};
  height: ${prem(17)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ mode, opened }) => {
    if (mode === 'rightdown' && opened) {
      return css`
        ${Chevron} {
          transform: rotate(90deg);
        }
      `;
    }

    if (mode === 'downup' && !opened) {
      return css`
        ${Chevron} {
          transform: rotate(90deg);
        }
      `;
    }

    if (mode === 'downup' && opened) {
      return css`
        ${Chevron} {
          transform: rotate(-90deg);
        }
      `;
    }
  }};
`;

export { IconChevronWrapper, Chevron };
