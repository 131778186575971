import React, { useContext } from 'react';
import { InstallAppTutoWrapper } from 'components/install-app-tuto/InstallAppTuto.styled';
import TutoComputer from 'components/install-app-tuto/tuto-computer/TutoComputer';
import TutoSmartphone from 'components/install-app-tuto/tuto-smartphone/TutoSmartphone';
import Button from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';
import { GenericDiv } from 'styles/helpers/utils';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { ROUTES } from 'constants/routes';

export interface InstallAppTutoProps {
  isDesktop?: boolean;
  isAndroid?: boolean;
  isIOS?: boolean;
}

const InstallAppTuto: React.FC<InstallAppTutoProps> = ({ isDesktop, isAndroid, isIOS }) => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;

  return (
    <InstallAppTutoWrapper>
      {isDesktop && <TutoComputer />}
      {(isAndroid || isIOS) && <TutoSmartphone isAndroid={isAndroid} isIOS={isIOS} />}
      <GenericDiv minWidth="100px" width="100%" maxWidth="250px" center>
        {user?.profileInfo?.tools.lms ? (
          <Button
            fill="outline"
            color="accent"
            onClick={() => {
              window.location.href = ROUTES.RESOURCES.MODULES;
            }}
          >
            {t('questionnaire.elearningbuttonlabel')}
          </Button>
        ) : (
          <Button
            color="accent"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('questionnaire.get-into-app')}
          </Button>
        )}
      </GenericDiv>
    </InstallAppTutoWrapper>
  );
};

export default InstallAppTuto;
