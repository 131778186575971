import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const SingleToolWrapper = styled.div<{ unavailable?: boolean; hasAccess?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: ${prem(10)};
  color: var(--ion-text-color-secondary);
  padding: ${prem(10)} ${prem(15)};

  .single-tool-left {
    display: flex;
    gap: ${prem(15)};
    align-items: center;
    width: 70%;

    .title-and-description {
      .title {
        font-weight: bolder;
      }
    }

    .icon {
      width: ${prem(30)};
      height: ${prem(30)};
      display: flex;
      align-items: center;
    }
  }

  .single-tool-right {
    display: flex;
    align-items: center;
    gap: ${prem(8)};
    width: 30%;
    max-width: ${prem(150)};

    .about-icon {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: ${prem(20)};
      }
    }

    .price {
      font-weight: bolder;
      text-align: center;
      flex: 1;
    }

    .not-available,
    .has-access {
      font-weight: normal;
      ${BODY.small}
    }

    .show-price {
      white-space: nowrap;
    }
  }

  ${({ unavailable }) =>
    unavailable &&
    css`
      .title,
      .icon {
        opacity: 0.6;
      }
    `}

  ${({ hasAccess }) =>
    hasAccess &&
    css`
      color: var(--ion-color-accent);

      .title, .icon {
        opacity: 1;
      }
    `}
`;

export { SingleToolWrapper };
