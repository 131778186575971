import { NOTIFICATIONS } from 'constants/global';
import { base64UrlToUint8Array } from 'helpers/utils';

function Notification() {
  let swRegistration: ServiceWorkerRegistration | undefined | null;

  // Save ServiceWorkerRegistration
  const setServiceWorkerRegistration = (reg: ServiceWorkerRegistration | null) => {
    swRegistration = reg;
  };

  // Get subscription from push manager
  const getSubscription = async (): Promise<PushSubscriptionJSON | undefined | null> => {
    console.log('getSubscription > swRegistration: ', swRegistration);
    if (!swRegistration || !('pushManager' in swRegistration)) {
      return null;
    }

    let subscription = await swRegistration.pushManager.getSubscription();
    if (!subscription) {
      subscription = await swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64UrlToUint8Array(NOTIFICATIONS.PUBLIC_KEY),
      });
    }

    if (subscription) {
      return subscription.toJSON();
    }

    return undefined;
  };

  // Check if we have notification push service
  const isNotificationPushAvailable = () => {
    console.log(' isNotificationPushAvailable - -----  ');
    console.log('navigator.serviceWorker: ', navigator.serviceWorker);
    console.log('window.PushManager : ', window.PushManager);
    console.log('window.Notification: ', window.Notification);
    console.log('swRegistration: ', swRegistration);
    console.log(' ------ end isNotificationPushAvailable - -----  ');

    return !!swRegistration && 'pushManager' in swRegistration;
  };

  return {
    setServiceWorkerRegistration,
    getSubscription,
    isNotificationPushAvailable,
  };
}

export default Notification();
