import React from 'react';
import { ITestimonial } from 'types/app';
import { TestimonialsListWrapper } from 'components/models/testimonials/testimonialslist/TestimonialsList.styled';
import SingleTestimonial from 'components/models/testimonials/singletestimonial/SingleTestimonial';

export interface TestimonialsListProps {
  testimonials: Array<ITestimonial>;
}

const TestimonialsList: React.FC<TestimonialsListProps> = ({ testimonials }) => {
  return (
    <TestimonialsListWrapper>
      {testimonials &&
        testimonials.map((testimonial, key) => {
          return (
            <SingleTestimonial className="single-testimonial" key={key} testimonial={testimonial} />
          );
        })}
    </TestimonialsListWrapper>
  );
};

export default TestimonialsList;
