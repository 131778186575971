import React from 'react';
import Button from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';
import { useIonRouter } from '@ionic/react';
import { IUser } from 'types/app';
import { USER_PROFILE_INFO_SOURCE } from 'hooks/api/useAPIAuth';

export interface QuestionnaireButtonProps {
  link?: string | null;
  internalLink?: boolean;
  label?: string | null;
  onClick?: () => void;
  isOutline?: boolean;
  user?: IUser;
}

const QuestionnaireButton: React.FC<QuestionnaireButtonProps> = ({
  link,
  internalLink,
  label,
  onClick,
  isOutline,
  user,
}) => {
  const { t } = useTranslation('translation');
  const ionRouter = useIonRouter();
  const gotoQuestionnaire = () => {
    let url = link;

    if (user) {
      let translationKey = 'defaulmessage';

      if (
        user.profileInfo?.isMemberTypeSiteMemberGroup &&
        user?.profileInfo?.source === USER_PROFILE_INFO_SOURCE.ACADEMY
      ) {
        translationKey = 'sitemembersmessage';
      }

      if (
        user.profileInfo?.isMemberTypeSiteMemberGroup &&
        user?.profileInfo?.source === USER_PROFILE_INFO_SOURCE.QUESTIONNAIRE
      ) {
        translationKey = 'boughtprofilefromquestionnairemessage';
      }

      if (user.profileInfo?.isMemberTypeIndividualGroup) {
        translationKey = 'particulargroupmessage';
      }

      url = t(`profile.questionnaire.${translationKey}.link`, {
        email: user?.email,
      });
    }

    if (url) {
      if (!internalLink) {
        window.open(url, '_blank');
      } else {
        ionRouter.push(url);
      }
    }

    onClick && onClick();
  };

  return (
    <Button
      color="accent"
      expand={true}
      onClick={gotoQuestionnaire}
      fill={isOutline ? 'outline' : 'solid'}
    >
      {label || t('profile.questionnaire.questionnairebuttonlabel')}
    </Button>
  );
};

export default QuestionnaireButton;
