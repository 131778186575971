import styled from 'styled-components';
import { CollapseButton } from 'components/common/showhide/Collapse.styled';
import { prem } from 'styles/helpers/spacing';
import { HEADLINE } from 'styles/helpers/typography';

const FaqItemWrapper = styled.div`
  position: relative;

  ${CollapseButton} {
    gap: ${prem(16)};
    text-align: left;
    padding: ${prem(25)} 0;
  }

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    background: var(--ion-color-accent);
    left: 50%;
    transform: translateX(-50%);
  }
`;
const FaqsTitle = styled.h3`
  ${HEADLINE.small};
`;
const FaqItemDesc = styled.div`
  padding: ${prem(16)} 0;
  white-space: pre-line;
`;
const FaqsWrapper = styled.div`
  &.full-width {
    ${FaqsTitle} {
      margin: 0 ${prem(25)};
    }

    .faq-desc-wrapper {
      padding: 0 ${prem(25)};
    }

    ${FaqItemWrapper} {
      &::after {
        width: calc(100% - ${prem(50)});
      }

      ${CollapseButton} {
        padding: ${prem(25)};
      }
    }
  }
`;

export { FaqsWrapper, FaqItemDesc, FaqItemWrapper, FaqsTitle };
