import { Container } from 'layout/page/Page.layout.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const ShopSummaryWrapper = styled.div`
  background: var(--ion-color-accent-contrast);
  color: white;
  font-weight: bolder;
  height: ${prem(205)};
  padding: ${prem(40)} ${prem(20)} ${prem(10)} ${prem(20)};
  width: 100%;
  max-width: ${prem(900)};
  border-radius: ${prem(30)} ${prem(30)} 0 0;
  position: fixed;
  bottom: ${prem(0)};
  left: 0;
  right: 0;
  margin: 0 auto;
  ${BODY.medium}

  ${Container} {
    max-width: ${prem(600)};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: ${prem(20)};

    .selected-tools,
    .total-to-pay {
      display: flex;
      gap: ${prem(40)};

      .label {
        width: 55%;
        text-align: end;
      }

      .value {
        flex: 1;
        text-align: start;
        white-space: nowrap;
      }
    }

    .proceed-to-payment-button {
      max-width: max-content;
      margin: 0 auto;
    }
  }
`;

export { ShopSummaryWrapper };
