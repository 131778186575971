import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const SingleTestimonialWrapper = styled.div`
  .testimonial-author-testimonial {
    p {
      display: inline;
      white-space: pre-wrap;
    }
  }

  .testimonial-logo {
    max-width: ${prem(138)};
    display: block;
    margin: ${prem(10)} auto ${prem(50)} auto;
  }
`;

export { SingleTestimonialWrapper };
