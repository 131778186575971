import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { IRelationInteractionSubscription } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { getOffsetTimeZone } from 'helpers/utils';
import { IHttpResponse } from 'helpers/api';

export const enum SubscriptionType {
  MOTIVATION = 1,
  RELATION = 6,
}

export interface IRelationPayload {
  colorId: number;
  relationId?: number;
}

export interface IMotivationPayload {
  colorId: number;
}

export interface IUseAPINotificationSubscription {
  subscribeToNotification: (
    type: SubscriptionType,
    deviceEndPoint: string,
    deviceAuth: string,
    deviceP256Dh: string,
    hour: number,
    day: string,
    relation?: IRelationPayload,
    motivation?: IMotivationPayload,
    subscriptionId?: number,
  ) => Promise<boolean>;
  loadSubscriptionDetail: (
    idSubscription: number,
    isInBackground?: boolean,
  ) => Promise<IRelationInteractionSubscription | undefined>;
  unsubscribeFromNotification: (idSubscription: number) => Promise<boolean>;
}

function useAPINotificationSubscription(): IUseAPINotificationSubscription {
  const { user } = useContext(UserContext) as UserContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  const parseSubscription = (raw: any): IRelationInteractionSubscription | undefined => {
    if (raw.id) {
      return {
        id: raw.id,
        themeId: raw.theme_id,
        relationId: raw.relation_id,
        colourId: raw.couleur_id,
        pushNotification: !!raw.active_notification_push,
        hour: raw.notification_hour,
        day: raw.notification_day_choice,
        timezoneOffset: raw.timezone_offset,
        deviceAuth: raw.device_auth || undefined,
        deviceEndPoint: raw.device_endpoint || undefined,
        deviceP256Dh: raw.device_p256dh || undefined,
      };
    }

    return undefined;
  };

  // Load subscription (interaction) detail
  const loadSubscriptionDetail = async (
    idSubscription: number,
    isInBackground?: boolean,
  ): Promise<IRelationInteractionSubscription | undefined> => {
    !isInBackground && showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.SUBSCRIPTION}/${idSubscription}`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const subscriptionState = result.data.data?.abonnement;

      if (subscriptionState) {
        // Parse detail & interaction
        return parseSubscription(subscriptionState);
      } else {
        if (result.data.message) {
          !isInBackground &&
            setToast({
              message: result.data.message,
              status: 'danger',
            });
        }
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while getting subscription : ', e);
      !isInBackground &&
        setToast({
          message: (e as Error).message || '',
          status: 'danger',
        });
    } finally {
      !isInBackground && hideLoading();
    }

    return undefined;
  };

  // Submit (POST or PATCH) a subscription
  const subscribeToNotification = async (
    type: SubscriptionType,
    deviceEndPoint: string,
    deviceAuth: string,
    deviceP256Dh: string,
    hour: number,
    day: string,
    relation?: IRelationPayload,
    motivation?: IMotivationPayload,
    subscriptionId?: number,
  ): Promise<boolean> => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: subscriptionId
          ? `${API.ROUTES.SUBSCRIPTION}/${subscriptionId}`
          : API.ROUTES.SUBSCRIPTION,
        method: subscriptionId ? 'patch' : 'post',
        data: {
          ...(!subscriptionId && {
            theme_id: type,
            user_id: user?.id,
            relation_id: relation?.relationId || null,
            couleur_id: relation?.colorId || motivation?.colorId,
          }),
          device_endpoint: deviceEndPoint,
          device_auth: deviceAuth,
          device_p256dh: deviceP256Dh,
          active_notification_push: deviceEndPoint ? 1 : 0,
          notification_hour: hour,
          notification_day_choice: day,
          timezone_offset: getOffsetTimeZone(),
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const submitState = result.data.data?.abonnement;

      if (!submitState) {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return !!submitState;
    } catch (e: unknown) {
      console.log('Error while submitting subscription : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return false;
  };

  const unsubscribeFromNotification = async (idSubscription: number): Promise<boolean> => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.SUBSCRIPTION}/${idSubscription}`,
        method: 'delete',
      };
      const result: IHttpResponse = await getRequest(options);
      const unsubscriptionState = result.data.data?.abonnement;

      if (!unsubscriptionState) {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return !!unsubscriptionState;
    } catch (e: unknown) {
      console.log('Error while unsubscribe from  : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return false;
  };

  return {
    subscribeToNotification,
    loadSubscriptionDetail,
    unsubscribeFromNotification,
  };
}

export default useAPINotificationSubscription;
