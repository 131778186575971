import { ButtonInnerWrapper } from 'components/common/button/Button.styled';
import styled from 'styled-components';

const TutoButtonPlayerWrapper = styled.div`
  width: max-content;
  margin: 0 auto;

  ${ButtonInnerWrapper} {
    justify-content: center;
    gap: 10px;

    img {
      width: 30px;
      height: 30px;
    }
  }
`;

export {
    TutoButtonPlayerWrapper
};
