import React, { useContext } from 'react';
import { AddTestimonialWrapper } from 'pages/models/testimonials/addtestimonial/AddTestimonial.styled';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import ButtonBack from 'components/common/button/ButtonBack';
import useFormHandler from 'hooks/useFormHandler';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import TextArea from 'components/common/form/textarea/TextArea';
import { FormFieldsGroup } from 'components/common/form/Form.styled';
import Button from 'components/common/button/Button';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { useSwiper } from 'swiper/react';
import useAPIModels from 'hooks/api/useAPIModels';

export type PostTestimonialPayload = {
  userId: number;
  firstname: string;
  company: string;
  content: string;
};

const AddTestimonial: React.FC = () => {
  const swiper = useSwiper();
  const { t } = useTranslation('translation');
  const { postTestimonial } = useAPIModels();
  const { user } = useContext(UserContext) as UserContextProps;
  const { fields, isFormValid, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'firstname',
      required: true,
      label: t('common.firstname') as string,
    },
    {
      name: 'company',
      required: false,
      label: t('common.company') as string,
    },
    {
      name: 'message',
      required: false,
      label: t('common.your_message') as string,
    },
  ]);

  const formatPayload = (objectToFormat: any): PostTestimonialPayload => {
    return {
      userId: user?.id as number,
      firstname: getFieldByName('firstname', objectToFormat).value as string,
      company: (getFieldByName('company', objectToFormat).value as string) || '',
      content: (getFieldByName('message', objectToFormat).value as string) || '',
    };
  };

  const clearForm = () => {
    updateFieldValue('firstname', '');
    updateFieldValue('company', '');
    updateFieldValue('message', '');
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid()) {
      const payload = formatPayload(fields);
      const posted = await postTestimonial(
        payload.userId,
        payload.firstname,
        payload.company,
        payload.content,
      );

      if (posted) {
        clearForm();
        setTimeout(() => {
          swiper.slidePrev();
        }, 250);
      }
    }
  };

  return (
    <AddTestimonialWrapper className="app-page">
      <Container className="send-message-container">
        <ButtonBack
          className="back-button-testimonial"
          onClick={() => {
            swiper.slidePrev();
          }}
        />
        <PageInnerTitle
          className="add-testimonial-title"
          dangerouslySetInnerHTML={{
            __html: t('model.testimonials.add.title'),
          }}
        />
        <form noValidate onSubmit={onSubmit} autoComplete="off">
          <FormFieldsGroup>
            <div className="testimonial-inputs-fields">
              <Textfield
                {...({
                  ...getFieldByName('firstname', fields),
                  onChange: (value?) => updateFieldValue('firstname', value),
                } as TextfieldProps)}
              />
              <Textfield
                {...({
                  ...getFieldByName('company', fields),
                  onChange: (value?) => updateFieldValue('company', value),
                } as TextfieldProps)}
              />
            </div>
            <TextArea
              {...({
                ...getFieldByName('message', fields),
                onChange: (value?) => updateFieldValue('message', value),
              } as TextfieldProps)}
            />
          </FormFieldsGroup>
          <Button className="testimonial-submit-button" color="accent" expand={false} type="submit">
            {t('model.testimonials.add.send_my_opinion')}
          </Button>
        </form>
      </Container>
    </AddTestimonialWrapper>
  );
};
export default AddTestimonial;
