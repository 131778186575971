import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { IonRouterOutlet } from '@ionic/react';
import { ROUTES } from 'constants/routes';
import InnerPageLayout, { RedirectorRoute } from 'layout/page/innerpage/InnerPage.layout';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import Books from 'pages/resources/books/Books';
import { Route } from 'react-router';
import Modules from 'pages/resources/modules/Modules';
import Training from 'pages/resources/training/Training';

const Resources: React.FC = () => {
  const { t } = useTranslation('translation');
  const {
    icons: [modules, books, training],
  } = useIcons([ICON_NAMES.MODULES, ICON_NAMES.BOOKS, ICON_NAMES.TRAINING]);

  return (
    <>
      <PageLayout headerProps={{ title: t('common.resources') }}>
        <InnerPageLayout tabs={[modules, books, training]} className="page-has-profile-wrapper">
          <IonRouterOutlet animated={false}>
            <RedirectorRoute exact path={ROUTES.RESOURCES.INDEX} tabs={[modules]}>
              <Modules />
            </RedirectorRoute>
            <Route exact path={ROUTES.RESOURCES.MODULES} render={() => <Modules />} />
            <Route exact path={ROUTES.RESOURCES.BOOKS} render={() => <Books />} />
            <Route exact path={ROUTES.RESOURCES.TRAINING} render={() => <Training />} />
          </IonRouterOutlet>
        </InnerPageLayout>
      </PageLayout>
    </>
  );
};

export default Resources;
