import React from 'react';
import { useTranslation } from 'react-i18next';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';

export interface ButtonDoIdentificationProps {
  onClick?: () => void;
}

const ButtonDoIdentification: React.FC<ButtonDoIdentificationProps> = ({ onClick }) => {
  const { t } = useTranslation('translation');

  return (
    <GenericDiv width="50%" minWidth={prem(200)} center={true}>
      <Button color="accent" fill="outline" expand={true} onClick={onClick}>
        {t('relations.identifycolor')}
      </Button>
    </GenericDiv>
  );
};

export default ButtonDoIdentification;
