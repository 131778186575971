import styled, { keyframes } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { ButtonCloseWrapper } from 'components/common/button/Button.styled';
import { SUBTITLE } from 'styles/helpers/typography';

const AppModalWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20000;

  &.open {
    pointer-events: auto;
  }

  &.close {
    pointer-events: none;
  }
`;

const AppModalTitle = styled.h4`
  ${SUBTITLE.medium};
  text-align: center;
  margin-bottom: ${prem(16)};
  color: white;
`;

const show = keyframes`
  from {
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
  }
  
  to {
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
`;

const hide = keyframes`
  from {
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
  
  to {
    left: 50%;
    bottom: ${prem(-1000)};
    transform: translate(-50%, 100%);
  }
`;

const AppModalContainer = styled.div`
  position: absolute;
  background: var(--comcolors-modal-background);
  border-radius: ${prem(20)};
  padding: ${prem(40)} ${prem(20)} ${prem(25)};
  min-width: ${prem(320)};
  width: auto;
  color: white;

  &.open {
    animation: ${show} 0.2s cubic-bezier(0.32, 0.72, 0, 1);
    animation-fill-mode: forwards;
  }

  &.close {
    animation: ${hide} 0.15s ease-in;
    animation-fill-mode: forwards;
  }

  ${ButtonCloseWrapper} {
    position: absolute;
    right: ${prem(10)};
    top: ${prem(10)};

    svg {
      .cross {
        stroke: white;
      }
    }
  }
`;

export { AppModalWrapper, AppModalContainer, AppModalTitle };
