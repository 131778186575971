import React, { useContext, useEffect, useState } from 'react';
import { PageInnerTitle } from 'layout/page/Page.layout.styled';
import { FindTrainerWrapper, SearchByPostalCode } from 'pages/find-trainer/FindTrainer.styled';
import Textfield from 'components/common/form/textfield/Textfield';
import { ReactComponent as IconSearch } from 'assets/svg/icon-search.svg';
import { ReactComponent as IconLocate } from 'assets/svg/icon-locate.svg';
import Button from 'components/common/button/Button';
import useAPIContact from 'hooks/api/useAPIContact';
import { Form, IPosition, ITrainers } from 'types/app';
import FoundTrainers from 'pages/find-trainer/found-trainers/FoundTrainers';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';

const FindTrainer: React.FC = () => {
  const { t } = useTranslation('translation');
  const [trainers, setTrainers] = useState<ITrainers>();
  const [location, setLocation] = useState<IPosition>();
  const { getTrainersWithUserPosition, getPositionFromAddress } = useAPIContact();
  const [postalAddress, setPostalCode] = useState<string>();
  const [authorizedGeolocation, setAuthorizedGeolocation] = useState(false);
  const { setToast } = useContext(ToastContext) as ToastContextProps;

  useEffect(() => {
    const checkGeolocationAuthorization = async () => {
      try {
        // Check if geolocation is supported by the browser
        if ('geolocation' in navigator) {
          // Attempt to get the current position, this will prompt the user for permission
          navigator.geolocation.getCurrentPosition(
            () => {
              // Geolocation is authorized
              setAuthorizedGeolocation(true);
            },
            () => {
              // Geolocation is not authorized or an error occurred
              setAuthorizedGeolocation(false);
            },
          );
        } else {
          // Geolocation is not supported
          setAuthorizedGeolocation(false);
        }
      } catch (error) {
        setAuthorizedGeolocation(false);
      }
    };

    checkGeolocationAuthorization();
  }, []);

  const showGeolocationError = () => {
    setToast({
      message:
        'Erreur de géolocalisation, vérifiez les paramètres de votre mobile ou bien utilisez la recherche par code postal',
      status: 'warning',
    });
  };

  const geolocateUser = async () => {
    if ('geolocation' in navigator) {
      // Get the user's location using the Geolocation API
      let tmpLocation = undefined;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Set the latitude and longitude in the state
          tmpLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          if (tmpLocation) {
            setLocation(tmpLocation);
          }
        },
        () => showGeolocationError(),
      );
      if (!tmpLocation) {
        if (!authorizedGeolocation) showGeolocationError();
      }
    } else {
      if (!authorizedGeolocation) showGeolocationError();
    }
  };

  useEffect(() => {
    if (location) {
      getTrainersWithUserPosition(location.longitude, location.latitude).then((trainers) => {
        if (trainers) {
          setTrainers([...trainers]);
        }
      });
    }
  }, [location]);

  const onSearchUsingPostalAddress = async () => {
    if (postalAddress) {
      const positions = await getPositionFromAddress(postalAddress);
      if (positions) {
        getTrainersWithUserPosition(positions.longitude, positions.latitude).then((trainers) => {
          if (trainers) {
            setTrainers([...trainers]);
          }
        });
      }
    }
  };

  return (
    <PageLayout headerProps={{ title: t('menu.findtrainer') }}>
      <FindTrainerWrapper className="app-page">
        <PageInnerTitle>{t('findtrainer.title')}</PageInnerTitle>
        <SearchByPostalCode>
          <div className="background-color" />
          <Textfield
            value={postalAddress}
            onChange={(e: Form.IValue) => {
              setPostalCode(e as string);
            }}
            name="postal-code"
            required={false}
            label={t('findtrainer.searchbypostalcode') as string}
            type="number"
          />
          <div
            className="icon-search"
            onClick={() => {
              onSearchUsingPostalAddress();
            }}
          >
            <IconSearch />
          </div>
        </SearchByPostalCode>
        <center>{t('findtrainer.franceonly')}</center>
        <PageInnerTitle>{t('common.or')}</PageInnerTitle>
        <br />
        <Button
          className="geolocate-me"
          fill="outline"
          color="highlightorange"
          onClick={() => {
            geolocateUser();
          }}
        >
          {t('findtrainer.geolocateme')} <IconLocate />
        </Button>
        {trainers && <FoundTrainers trainers={trainers} resultCount={trainers.length} />}
      </FindTrainerWrapper>
    </PageLayout>
  );
};

export default FindTrainer;
