import { IPayableTools, IUser } from 'types/app';
import { disableBuyModuleButton, hideBuyModuleButton } from 'helpers/modules';
import { PAYABLE_TOOLS_CODES } from 'constants/global';

export const checkDisableBuyAllAccessPack = (user: IUser, payableTools: IPayableTools) => {
  const otherToolsTotalPrice = payableTools.reduce(
    (prev, tool) =>
      !tool.hasAccess && tool.canBuy && !tool.hide && tool.code !== PAYABLE_TOOLS_CODES.FULL_PACKAGE
        ? prev + tool.price_ttc
        : prev,
    0,
  );

  const fullPackagePrice =
    payableTools.find((tool) => tool.code === PAYABLE_TOOLS_CODES.FULL_PACKAGE)?.price_ttc || 0;
  return (
    hideBuyModuleButton(user) ||
    disableBuyModuleButton(user) ||
    otherToolsTotalPrice < fullPackagePrice
  );
};
