import { useState } from 'react';
import { Form } from 'types/app';
import { useTranslation } from 'react-i18next';
import { isEmail, isValidPassword } from 'helpers/utils';
import { FORM } from 'constants/form';

export interface IUseFormHandler {
  isFormValid: (parameters? : { checkPassword?: boolean }) => boolean;
  fields: Array<Form.IField>;
  getFieldByName: (name: string, fields?: Array<Form.IField>) => Form.IField;
  updateFields: (fields: Array<Form.IField>) => void;
  updateFieldValue: (name: string, value: Form.IValue) => void;
  updateFieldErrorMessage: (name: string, message: string) => void;
  resetErrors: () => void;
}

function useFormHandler(fields: Array<Form.IField>): IUseFormHandler {
  const { t } = useTranslation('translation');
  const errorMessageRequired = t('message.error.required');
  const errorMessageEmail = t('message.error.email');
  const errorMessagePassword = t('message.error.password');
  const [fieldState, setFieldsState] = useState(fields);

  const isFormValid = (parameters? : { checkPassword?: boolean }) => {
    const fStates: Array<Form.IField> = [
      ...fieldState.map((f) => ({ ...f, errorMessage: undefined })),
    ];
    let fieldsValid = true;

    fStates.forEach((field) => {
      // Check required
      if (field.required) {
        if (field.value !== 0 && !field.value) {
          field.errorMessage = errorMessageRequired;
          fieldsValid = false;
        }
      }

      // Check validity
      if (!field.errorMessage && field.value) {
        switch (field.type) {
          case FORM.TYPES.EMAIL:
            if (!isEmail(field.value as string)) {
              field.errorMessage = errorMessageEmail;
              fieldsValid = false;
            }
            break;
          case FORM.TYPES.PASSWORD:
            if (parameters?.checkPassword) {
              if (!isValidPassword(field.value as string)) {
                field.errorMessage = errorMessagePassword;
                fieldsValid = false;
              }
            }
            break;
        }
      }
    });

    // Update current states
    setFieldsState(fStates);

    return fieldsValid;
  };

  // Find a field by his name among fields or the actual states
  const getFieldByName = (name: string, fields: Array<Form.IField> = []) => {
    return (fields.length ? fields : fieldState).find((f) => f.name === name) || { name };
  };

  // Update field value
  const updateFieldValue = (name: string, value: Form.IValue) => {
    const fStates: Array<Form.IField> = [...fieldState];
    let found = false;

    fStates.forEach((field) => {
      if (field.name === name) {
        field.value = value;
        found = true;
      }
    });

    found && setFieldsState(fStates);
  };

  const updateFieldErrorMessage = (name: string, message: string) => {
    const fStates: Array<Form.IField> = [...fieldState];
    let found = false;

    fStates.forEach((field) => {
      if (field.name === name) {
        field.errorMessage = message;
        found = true;
      }
    });

    found && setFieldsState(fStates);
  };

  const resetErrors = () => {
    const fStates: Array<Form.IField> = [...fieldState];
    fStates.forEach((field) => {
      field.errorMessage = '';
    });
  };

  const updateFields = (fs: Array<Form.IField>) => {
    resetErrors();
    setFieldsState(fs.map(entry => {
      const currentValue = fieldState.find(f => f.name === entry.name)?.value;
      return {
        ...entry,
        value: currentValue ? currentValue : undefined
      };
    }));
  };

  return {
    fields: fieldState,
    isFormValid,
    getFieldByName,
    updateFieldValue,
    updateFieldErrorMessage,
    resetErrors,
    updateFields
  };
}

export default useFormHandler;
