import React, { useEffect, useRef, useState } from 'react';
import { IComponentWithChildren } from 'types/app';
import { ShowHideInner, ShowHideWrapper } from 'components/common/showhide/ShowHide.styled';

export interface ShowHideCompProps extends IComponentWithChildren {
  opened?: boolean;
}

const ShowHideComp: React.FC<ShowHideCompProps> = ({ children, opened }) => {
  const showHideInnerEl = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<string>('0px');

  useEffect(() => {
    /*
    setTimeout(() => {
      if (opened) {
        if (showHideInnerEl?.current) {
          const children = showHideInnerEl?.current.children;
          let height = 0;

          for (let i = 0; i < children.length; i++) {
            const el = children.item(i);
            el && (height += getElHeight(el));
          }
          setHeight(height);
        }
      } else {
        setHeight(0);
      }
    }, 100);
    */
    setHeight(opened ? '5000px' : '0px');
  }, [opened]);

  return (
    <ShowHideWrapper height={height}>
      <ShowHideInner ref={showHideInnerEl}>{children}</ShowHideInner>
    </ShowHideWrapper>
  );
};

export default ShowHideComp;
