import { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';
import { useRollbar } from '@rollbar/react';

const useUpdateChecker = (watchLocation?: string) => {
  const rollbar = useRollbar();
  const { setShowRefresh } = useContext(SettingsContext) as SettingsContextProps;
  const location = useLocation();
  const pathName = location.pathname || location.location.pathname;
  const swChecker = useCallback(() => {
    // console.log('-- CHECK UPDATE!!! --- ');
    // console.log('serviceWorker in navigator --> ', 'serviceWorker' in navigator);

    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.ready
    //     .then((registration) => {
    //       // console.log('--- Trigger service worker update --- registration: ', registration);
    //       registration
    //         .update()
    //         .then((result: any) => {
    //           if (result?.waiting) {
    //             window.setTimeout(() => {
    //               setShowRefresh(true);
    //               // console.log('registration.update result: ', result);
    //             }, 250);
    //           }
    //         })
    //         .catch((error: any) => {
    //           console.log('registration.update error: ', error);
    //           rollbar.error('registration update error', error);
    //         });
    //     })
    //     .catch((error: any) => {
    //       console.log('navigator.serviceWorker.ready ::: error: ', error);
    //     });
    // }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          if (registration.waiting) {
            setShowRefresh(true);
          }

          registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;

            newWorker?.addEventListener('statechange', () => {
              if (newWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // A new service worker is waiting
                  setShowRefresh(true);
                }
              }
            });
          });
        })
        .catch((error: any) => {
          console.log('navigator.serviceWorker.ready ::: error:', error);
          rollbar.error('navigator.serviceWorker.ready error', error);
        });
    }
  }, []);

  const triggerCheck = () => {
    // let next = true;
    // next = true;

    // const currBuild = getAppBuild(process.env.REACT_APP_BUILD_TIMESTAMP);
    // const buildFromLocalstorage = getAppBuildInLocalstorage();
    // if (currBuild !== buildFromLocalstorage) {
    //   insertAppBuildInLocalstorage(currBuild);
    // }

    // const prevBuildQueryValue = query.get('prev_build') as string;
    // if (prevBuildQueryValue) {
    //   insertPreviousBuildInLocalstorage(prevBuildQueryValue);
    // }

    if (watchLocation) {
      const matchWatchLocation = matchPath(pathName, {
        path: watchLocation,
        exact: true,
      });

      matchWatchLocation && swChecker();
    } else {
      swChecker();
    }
  };
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      triggerCheck();
    }
  };
  // effect added from router location to check for a new service worker on every
  // page transition (change of route).
  useEffect(() => {
    triggerCheck();
  }, [location]);

  // Detect window visibility
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useUpdateChecker;
