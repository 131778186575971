import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonDownLoad,
  ButtonDownloadWrapper,
} from 'pages/profile/has-profile/my-summary/MySummary.styled';

interface IMySummaryDownload {
  linkPDF: string;
}

const MySummaryDownload: React.FC<IMySummaryDownload> = ({ linkPDF }: IMySummaryDownload) => {
  const { t } = useTranslation('translation');
  const openDownload = () => {
    window.open(linkPDF, '_blank');
  };

  return (
    <ButtonDownloadWrapper>
      <ButtonDownLoad onClick={() => openDownload()}>
        <span>{t('profile.mysummary.downloadPdf')}</span>
        <div className="icon">
          <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.24865 0.039032V5.27767H1.74955L6.9982 10.5163L12.2468 5.27767H8.74775V0.039032H5.24865ZM0 12.2625V14.0087H13.9964V12.2625H0Z"
              fill="white"
            />
          </svg>
        </div>
      </ButtonDownLoad>
    </ButtonDownloadWrapper>
  );
};

export default MySummaryDownload;
