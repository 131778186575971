import React from 'react';
import { BlogPostsWrapper, SingleBlogPost } from 'pages/home/blog-posts/BlogPosts.styled';
import { IHome } from 'types/app';
import { getLocaleValue, removeAttributes } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { Container } from 'layout/page/Page.layout.styled';
import { ReactComponent as IconArrowCircle } from 'assets/svg/icon-relation-arrow-circle.svg';
import ImageRenderer from 'components/common/image/ImageRenderer';

export interface BlogPostsProps {
  posts: IHome.IArticlesPost[];
}

const BlogPosts: React.FC<BlogPostsProps> = ({ posts }) => {
  const { i18n } = useTranslation('translation');

  return (
    <BlogPostsWrapper>
      <Container>
        {posts &&
          posts.map((post, key) => {
            return (
              <SingleBlogPost key={key}>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: removeAttributes(getLocaleValue(post.title, i18n.language)),
                  }}
                />
                <div className="image-container">
                  <ImageRenderer src={post.imageUrl as string} alt="" showLoader />
                </div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: removeAttributes(getLocaleValue(post.description, i18n.language)),
                  }}
                />
                <div className="read-next">
                  Lire la suite
                  <span
                    className="read-next-icon"
                    onClick={() => {
                      post.postUrl && window.open(post?.postUrl, '_blank');
                    }}
                  >
                    <IconArrowCircle />
                  </span>
                </div>
              </SingleBlogPost>
            );
          })}
      </Container>
    </BlogPostsWrapper>
  );
};

export default BlogPosts;
