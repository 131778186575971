import { useContext } from 'react';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import useFetch from 'hooks/api/useFetch';
import { API, LS_KEYS } from 'constants/global';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import {
  Appointment,
  IPerson,
  IRelation,
  IRelationGroup,
  IRelationGroupBase,
  IRelationInteraction,
  IUser,
} from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { IdentificationFactory, isMale } from 'helpers/relations';
import { tokensReplacer } from 'helpers/utils';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';
import { getFromStorage, saveToStorage } from 'helpers/storage';
import { IHttpResponse } from 'helpers/api';
import { useTranslation } from 'react-i18next';

export const enum IdentificationType {
  MANUAL = 'manual',
  QUIZ = 'quizz',
  AUTO = 'automatic',
}

export const enum InvitationType {
  NEW_INVITATION = 'invitation',
  ACCEPTED_INVITATION = 'invitation_accepte',
  REFUSED_INVITATION = 'invitation_refus',
  PROFILE_VISIBILITY = 'visibilite_profil',
}

export interface IUseAPIRelations {
  getRelations: () => void;
  requestGroups: (user: IUser) => Promise<void>;
  acceptRelationInvitation: (
    notificationId: number,
    groupId: number,
  ) => Promise<boolean | undefined>;
  refuseRelationInvitation: (notificationId: number) => Promise<boolean | undefined>;
  createGroup: (groupName: string) => Promise<boolean | undefined>;
  editGroup: (group: IRelationGroup) => Promise<boolean | undefined>;
  removeGroup: (groupId: number) => Promise<boolean | undefined>;
  addRelationToGroup: (relationId: number, groupId: number) => Promise<boolean | undefined>;
  createRelation: (
    name: string,
    firstName: string,
    pronoun: string,
    groupId: number,
    email: string,
  ) => Promise<boolean | undefined>;
  updateRelation: (
    relationId: number,
    identificationType: IdentificationType,
    colorUnspecified: number,
    primaryColorId: number,
    secondaryColorId?: number | null,
    isInBackground?: boolean,
  ) => Promise<boolean | undefined>;
  removeRelation: (relationId: number, groupId: number) => Promise<boolean | undefined>;
  loadColoursFinderQuestionnaire: () => Promise<boolean | undefined>;
  getRelationDetail: (relationId: number) => Promise<IRelation | undefined>;
  loadIdentificationCount: (userId: number, isInBackground?: boolean) => Promise<boolean>;
  incrementIdentificationCount: (userId: number, isInBackground?: boolean) => Promise<boolean>;
  getAppointment: (
    relation: IRelation,
    user: IUser,
  ) => Promise<Appointment.IAppointment | undefined>;
  updateAppointment: (
    relation: IRelation,
    relationSelectedColourId: number,
    user: IUser,
    userSelectedColourId: number,
  ) => Promise<Appointment.IAppointment | undefined>;
}

/*
const getFakeRelation = (input: IRelation): IRelation => {
  const primaryColor = getRandomColor()?.id;

  return {
    ...input,
    primaryColorId: primaryColor,
    secondaryColorId: primaryColor ? getRandomColor(true)?.id : null,
    profileShared: getBoolRand(),
    unspecifiedColor: getBoolRand(),
    hasDifferentColor: getBoolRand(),
    subscribed: getBoolRand(),
  };
};
*/

/*
const geFakeAdvices = (): Array<IAdvice> => {
  return [
    {
      id: 1,
      advice: {
        fr: 'FR _Barista brewed con black aroma half con panna chicory steamed.',
        en: 'EN _Milk foam irish rich in robusta whipped aroma as espresso extra a irish and aftertaste.',
      },
      liked: false,
      date: '2023-05-29T12:30:12',
      read: false
    },
    {
      id: 2,
      advice: {
        fr: 'FR _Viennese rich at french shop macchiato organic shot con cinnamon arabica fair affogato.',
        en: 'EN _Origin medium macchiato extra con as white sugar a lait sugar.',
      },
      liked: false,
      date: '2023-05-30T12:30:12',
      read: false
    },
    {
      id: 3,
      advice: {
        fr: 'FR _Sweet extraction espresso frappuccino espresso viennese lungo siphon galão qui cream so.',
        en: 'EN _Robusta instant whipped aroma cappuccino go robusta mazagran turkish.',
      },
      liked: true,
      date: '2023-05-29T16:00:12',
      read: false
    },
  ];
};
*/

function useAPIRelations(): IUseAPIRelations {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const {
    updateRelationsGroups,
    setRelationQuestionnaireFactory,
    updateIdentificationCount,
    updateGroupsList,
  } = useContext(RelationsContext) as RelationsContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { getAdvices } = useAPIAdvices();

  // Get all relations / per groups
  const getRelations = () => {
    requestRelations();
  };

  const parseGroups = (groups: Array<any>) => {
    const newGroups: Array<IRelationGroupBase> = groups.map((group) => {
      return {
        id: group.groupe_id,
        name: group.libelle,
      };
    });

    updateGroupsList(newGroups);
  };

  const requestGroups = async (user: IUser) => {
    try {
      const options: AxiosRequestConfig = {
        method: 'get',
        url: `${API.ROUTES.RELATIONS.GROUP.INDEX}/${user.id}`,
      };

      const result: IHttpResponse = await getRequest(options);
      const groups = result.data?.data?.groups;
      if (groups) {
        parseGroups(groups);
      }
    } catch (e) {
      console.log('Error while getting groups');
    }
  };

  const acceptRelationInvitation = async (notificationId: number, groupId: number) => {
    if (!user) return;
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        method: 'patch',
        url: `${API.ROUTES.NOTIFICATIONS.INVITATION}/${notificationId}/accepte`,
        headers: {
          Authorization: `${user.token?.type} ${user.token?.value}`,
        },
        data: {
          groupe_id: groupId,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      if (result.status === 200) {
        setToast({
          message: t('relations.invitations.invitationaccepted'),
          status: 'success',
        });
      } else {
        setToast({
          message: t('message.error.cannotacceptinvitation'),
          status: 'danger',
        });
      }
      return result.status === 200;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      hideLoading();
    }
  };

  // Refuse relation invitation
  const refuseRelationInvitation = async (notificationId: number) => {
    if (!user) return;
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        method: 'patch',
        url: `${API.ROUTES.NOTIFICATIONS.INVITATION}/${notificationId}/refus`,
        headers: {
          Authorization: `${user.token?.type} ${user.token?.value}`,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      if (result.status === 200) {
        setToast({
          message: t('relations.invitations.invitationrefused'),
          status: 'success',
        });
      } else {
        setToast({
          message: t('message.error.cannotrefuseinvitation'),
          status: 'danger',
        });
      }
      return result.status === 200;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      hideLoading();
    }
  };

  // Get all relations / per groups with request
  const requestRelations = async () => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        method: 'get',
        url: `${API.ROUTES.RELATIONS.RELATIONS}/${user?.id}`,
      };

      const result: IHttpResponse = await getRequest(options);
      if (result.data?.data.groups) {
        parseRelations(result.data?.data.groups);
      }
    } catch (e) {
      console.log('Error while getting relations');
    } finally {
      hideLoading();
    }

    return;
  };

  const getRelationOtherGroup = (
    groups: any,
    relationId: number,
  ): Array<IRelationGroupBase> | undefined => {
    const result = [] as Array<IRelationGroupBase>;
    groups.forEach((g: any) => {
      let add = true;

      if (g.relations?.length) {
        add = !g.relations.find((r: any) => r.id === relationId);
      }

      add && result.push({ id: g.id, name: g.libelle });
    });

    return result;
  };

  // Create a relation based on api response
  const parseRelation = (raw: any): IRelation => {
    const name = raw.nom || '';
    const fullName = `${raw.prenom} ${name}`;
    const relation: IRelation = {
      id: raw.id,
      name,
      firstName: raw.prenom,
      fullName,
      pronoun: raw.pronom,
      groupId: raw.groupe_id,
      primaryColorId: raw.dominante_id,
      secondaryColorId: raw.secondaire_id,
      accepted: raw.accepted,
      profileShared: !!raw.profil_partage_accepte,
      unspecifiedColor: !!raw.couleur_nondetermine,
      hasDifferentColor: !!raw.couleur_differente,
      subscribed: !!raw.has_abonne,
      identificationType: raw.identification_type,
      invitationSent: !!raw.invitation_sent,
      invitationAccepted: !!raw.invitation_sent_accepted,
      profileIconStatus: raw.statut_icone_profil,
      relationTruePrimaryColorId: raw.relation_dominante_id,
      relationTrueSecondaryColorId: raw.relation_secondaire_id,
    };

    if (relation.primaryColorId) {
      relation.hasUniqueColor = relation.primaryColorId === relation.secondaryColorId;
    }

    // Personality text
    if (raw.texte_personnalite && raw.texte_personnalite.translations?.length) {
      let indexDominant;
      const tmpPersonalityText: { [key: string]: string } = {};
      if (relation.primaryColorId === raw.texte_personnalite.couleur_id1) {
        indexDominant = 1;
      }
      if (relation.primaryColorId === raw.texte_personnalite.couleur_id2) {
        indexDominant = 2;
      }
      // Unspecified colors
      if (relation.unspecifiedColor) {
        for (const trans of raw.texte_personnalite.translations) {
          tmpPersonalityText[trans.locale] = tokensReplacer(
            trans.description_dominante_nondetermine || '',
            relation.firstName,
          );
        }
      } else {
        if (indexDominant) {
          for (const trans of raw.texte_personnalite.translations) {
            const str = trans[`description_dominante_couleur${indexDominant}`] || '';
            tmpPersonalityText[trans.locale] = tokensReplacer(str, relation.firstName);
          }
        }
      }

      !!tmpPersonalityText && (relation.personalityText = tmpPersonalityText);
    }

    if (raw.texte_generique) {
      relation.interactions = {
        generic: {
          fr: tokensReplacer(raw.texte_generique.nom_fr, relation.firstName),
          ...(raw.texte_generique.nom_en && {
            en: tokensReplacer(raw.texte_generique.nom_en, relation.firstName),
          }),
        },
      };
    }

    // Interactions at the manage relation section
    if (raw.interactions?.length) {
      relation.interactions = relation.interactions || {};
      relation.interactions.interactions = raw.interactions.map((i: any) => {
        const inter = {
          id: i.id,
          userPrimaryColorId: i.couleur_manager_id,
          relationPrimaryColorId: i.couleur_membre_id,
          subscribed: i.has_abonnement,
          ...(raw.libelle && { label: raw.libelle }),
          ...(i.id_abonnement && { subscriptionId: i.id_abonnement }),
        } as IRelationInteraction;

        if (isMale(relation.pronoun)) {
          inter.description = {
            ...(i.description_masculin?.fr && {
              fr: tokensReplacer(i.description_masculin.fr, relation.firstName),
            }),
            ...(i.description_masculin?.en && {
              en: tokensReplacer(i.description_masculin.en, relation.firstName),
            }),
          };
        } else {
          inter.description = {
            ...(i.description_feminin?.fr && {
              fr: tokensReplacer(i.description_feminin.fr, relation.firstName),
            }),
            ...(i.description_feminin?.en && {
              en: tokensReplacer(i.description_feminin.en, relation.firstName),
            }),
          };
        }

        // If at least one interaction is subscribed
        // We set relation subscription to true
        if (i.has_abonnement) {
          relation.subscribed = true;

          // Fake advices relation.advices = geFakeAdvices();
        }

        return inter;
      });
    }
    return relation;
  };

  // Create relations in the group
  const parseRelations = (groups: any) => {
    let result = [] as Array<IRelationGroup>;

    try {
      if (groups) {
        if (groups.length) {
          result = groups.map((g: any) => {
            const group = {
              id: g.id,
              name: g.libelle,
              relations: [],
            };

            if (g.relations && g.relations.length) {
              group.relations = g.relations.map((r: any) => {
                const relation = parseRelation(r);
                /*
                 * For each relation in this group look if we can add this relation into other group
                 * If it not in there yet
                 * */
                relation.proposedGroups = getRelationOtherGroup(groups, r.id);

                // Fake for test
                // return getFakeRelation(relation);
                return relation;
              });
            }

            return group;
          });
        }
      }
    } catch (e) {
      console.error('Error while parsing relations from API');
    }

    updateRelationsGroups(result);
  };

  // Create group
  const createGroup = async (groupName: string) => {
    if (!user?.id) {
      return undefined;
    }
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.RELATIONS.GROUP.INDEX,
        method: 'post',
        data: {
          user_id: user?.id,
          libelle: groupName,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const createdGroup = result.data.data?.groupe;

      if (createdGroup) {
        await requestRelations();
        await requestGroups(user);
      }

      return !!createdGroup;
    } catch (e) {
      console.log('Error while creating group : ', e);
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Rename group
  const editGroup = async (group: IRelationGroup) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.GROUP.INDEX}/${group.id}`,
        method: 'patch',
        data: {
          groupe: group.id,
          libelle: group.name,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const modifiedGroup = result.data.data?.groupe;

      if (modifiedGroup) {
        await requestRelations();
        await requestGroups(user as IUser);
      }

      return !!modifiedGroup;
    } catch (e) {
      console.log('Error while creating group : ', e);
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Create relation
  const createRelation = async (
    name: string,
    firstName: string,
    pronoun: string,
    groupId: number,
    email: string,
  ) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.RELATIONS.RELATIONS,
        method: 'post',
        data: {
          user_id: user?.id,
          nom: name,
          prenom: firstName,
          pronom: pronoun,
          groupe_id: groupId,
          email: email,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const createdRelation = result.data.data?.relation;

      if (createdRelation) {
        await requestRelations();
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'warning',
          });
        }
      }

      return !!createdRelation;
    } catch (e: unknown) {
      console.log('Error while creating relation : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Remove group
  const removeGroup = async (groupId: number) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.GROUP.INDEX}/${groupId}`,
        method: 'delete',
        data: {
          groupe: groupId,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const deletionState = result.data.data?.groupe;

      if (deletionState) {
        await requestRelations();
        await requestGroups(user as IUser);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return !!deletionState;
    } catch (e: unknown) {
      console.log('Error while deleting groupe : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Ad a relation into a group
  const addRelationToGroup = async (relationId: number, groupId: number) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.RELATIONS.GROUP.ADD_RELATION,
        method: 'post',
        data: {
          groupe_id: groupId,
          relation_id: relationId,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const addState = result.data.data?.groupe_relation;

      if (addState) {
        await requestRelations();
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return !!addState;
    } catch (e: unknown) {
      console.log('Error while adding relation into group : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Remove a relation from a group
  const removeRelation = async (relationId: number, groupId: number) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.RELATIONS}/${relationId}/groupe/${groupId}`,
        method: 'delete',
        data: {
          groupe: groupId,
          relation: relationId,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const removeState = result.data.data?.relation;

      if (removeState) {
        await requestRelations();
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return !!removeState;
    } catch (e: unknown) {
      console.log('Error while removing relation from group : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Update a relation's colors profile
  const updateRelation = async (
    relationId: number,
    identificationType: IdentificationType,
    colorUnspecified: number,
    primaryColorId: number,
    secondaryColorId?: number | null,
    isInBackground?: boolean,
  ) => {
    !isInBackground && showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.RELATIONS}/${relationId}`,
        method: 'patch',
        data: {
          identification_type: identificationType,
          couleur_nondetermine: colorUnspecified,
          dominante_id: primaryColorId,
          secondaire_id: secondaryColorId,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const updateState = result.data.data?.relation;

      if (updateState) {
        await requestRelations();
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return !!updateState;
    } catch (e: unknown) {
      console.log('Error while updating relation: ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Load questionnaire form local or online
  // and parse into relation context
  const getLocalRelationQuestionnaire = (): any => {
    const questionnaire = getFromStorage(LS_KEYS.RELATION_QUESTIONNAIRE);
    if (questionnaire) {
      return JSON.parse(questionnaire);
    }
    return null;
  };

  const loadColoursFinderQuestionnaire = async () => {
    let rawQuestionnaire: any;

    try {
      rawQuestionnaire = getLocalRelationQuestionnaire();
      if (rawQuestionnaire) {
        return true;
      }
      showLoading();
      const options: AxiosRequestConfig = {
        url: API.ROUTES.RELATIONS.QUESTIONNAIRE,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const updateState = result.data.enchainements;

      if (updateState) {
        rawQuestionnaire = result.data;
      } else {
        rawQuestionnaire = getLocalRelationQuestionnaire();
        if (!rawQuestionnaire) {
          if (result.data.message) {
            setToast({
              message: result.data.message,
              status: 'danger',
            });
          }
        }
      }

      return !!updateState;
    } catch (e: unknown) {
      console.log('Error while updating relation: ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      if (rawQuestionnaire) {
        console.log('rawQuestionnaire: ', rawQuestionnaire);
        try {
          setRelationQuestionnaireFactory(
            IdentificationFactory(
              rawQuestionnaire.questions,
              rawQuestionnaire.reponses,
              rawQuestionnaire.enchainements,
            ),
          );
          saveToStorage(LS_KEYS.RELATION_QUESTIONNAIRE, JSON.stringify(rawQuestionnaire));
        } catch (e) {
          setToast({
            message: 'Error while parsing questionnaire',
            status: 'danger',
          });
        }
      }

      hideLoading();
    }

    return undefined;
  };

  // Load relation detail by id
  const getRelationDetail = async (relationId: number): Promise<IRelation | undefined> => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.DETAIL}/${relationId}`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const detailState = result.data.data?.relation;

      if (detailState) {
        // Get
        // Parse detail & interaction
        const resultRelation: IRelation = parseRelation(detailState);

        resultRelation.advices = await getAdvices(
          AdviceType.RELATION,
          user?.id,
          resultRelation.id,
          { pronoun: user?.pronoun, firstName: user?.firstname } as IPerson,
        );
        return resultRelation;
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while getting relation : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Load user's identification count
  const loadIdentificationCount = async (
    userId: number,
    isInBackground?: boolean,
  ): Promise<boolean> => {
    !isInBackground && showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.IDENTIFICATION}/${userId}/stats`,
      };
      const result: IHttpResponse = await getRequest(options);
      const count = result.data.data.identification_count;

      if (count != null) {
        updateIdentificationCount(count as number);
        return true;
      } else {
        if (!isInBackground) {
          if (result.data.message) {
            setToast({
              message: result.data.message,
              status: 'danger',
            });
          }
        }
      }

      return false;
    } catch (e: unknown) {
      console.log('Error while updating relation: ', e);
      if (!isInBackground) {
        setToast({
          message: (e as Error).message || '',
          status: 'danger',
        });
      }
    } finally {
      hideLoading();
    }

    return false;
  };

  const incrementIdentificationCount = async (
    userId: number,
    isInBackground?: boolean,
  ): Promise<boolean> => {
    !isInBackground && showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.IDENTIFICATION}/${userId}/save`,
        method: 'post',
        data: {
          counter: 1,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const identification = result.data.data.identification;

      if (identification != null) {
        updateIdentificationCount(identification.identification_count as number);
        return true;
      } else {
        if (!isInBackground) {
          if (result.data.message) {
            setToast({
              message: result.data.message,
              status: 'danger',
            });
          }
        }
      }

      return false;
    } catch (e: unknown) {
      console.log('Error while incrementing IdentificationCount: ', e);
      if (!isInBackground) {
        setToast({
          message: (e as Error).message || '',
          status: 'danger',
        });
      }
    } finally {
      hideLoading();
    }

    return false;
  };

  // Parse appointment data
  const parseAppointment = (
    raw: any,
    personRelation: IPerson,
    personUser: IPerson,
  ): Appointment.IAppointment => {
    personUser;
    const getTextByPerson = (data: any, person: IPerson): { [key: string]: string } => {
      let texts = { fr: '', en: '' };

      if (data.description_masculin || data.description_feminin) {
        texts = data.description_feminin;
        isMale(person.pronoun) && (texts = data.description_masculin);
      }

      if (data.guide_entretien_masculin || data.guide_entretien_feminin) {
        texts = data.guide_entretien_feminin;
        isMale(person.pronoun) && (texts = data.guide_entretien_masculin);
      }

      return {
        fr: tokensReplacer(texts.fr, person.firstName),
        en: tokensReplacer(texts.en, person.firstName),
      };
    };

    const subscription: Appointment.ISubscription | undefined = raw.abonnement
      ? {
          id: raw.abonnement.id,
          relationId: raw.abonnement.relation_id,
          relationColourId: raw.abonnement.relation_couleur_id,
        }
      : undefined;
    const buyerStyle: Appointment.ISection | undefined = raw.styles
      ? {
          title: raw.styles.libelle,
          colourId: raw.styles.couleur_id,
          description: getTextByPerson(raw.styles, personRelation),
        }
      : undefined;
    const vendorInteraction: Appointment.ISection | undefined = raw.interactions
      ? {
          title: raw.interactions.libelle,
          colourId: raw.interactions.couleur_id,
          description: getTextByPerson(raw.interactions, personRelation),
        }
      : undefined;
    const interviewGuide: Appointment.IInterviewGuide | undefined = raw.guide_entretiens
      ? {
          title: raw.guide_entretiens.libelle,
          vendorColourId: raw.guide_entretiens.couleur_vendeur_id,
          buyerColourId: raw.guide_entretiens.couleur_acheteur_id,
          description: getTextByPerson(raw.guide_entretiens, personRelation),
        }
      : undefined;

    return {
      subscription,
      buyerStyle,
      vendorInteraction,
      interviewGuide,
    };
  };

  // Load appointment data
  const getAppointment = async (relation: IRelation, user: IUser) => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.APPOINTMENT.DETAIL}/${relation.id}`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const detailAppointment = result.data.data?.appointment;

      if (detailAppointment?.has_abonnement) {
        const personRelation: IPerson = {
          pronoun: relation.pronoun,
          firstName: relation.firstName,
        };
        const personUser: IPerson = {
          pronoun: user.pronoun || '',
          firstName: user.firstname || '',
        };

        return parseAppointment(detailAppointment, personRelation, personUser);
      } else {
        if (result.data.message && result.data.status_code !== 200) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while getting relation : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Update appointment
  const updateAppointment = async (
    relation: IRelation,
    relationSelectedColourId: number,
    user: IUser,
    userSelectedColourId: number,
  ) => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RELATIONS.APPOINTMENT.SUBSCRIPTION}/${relation.id}`,
        method: 'patch',
        data: {
          user_couleur_id: userSelectedColourId,
          relation_couleur_id: relationSelectedColourId,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const detailAppointment = result.data.data?.appointment;

      if (detailAppointment) {
        // Get fresh appointment
        return await getAppointment(relation, user);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while getting relation : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  return {
    getRelations,
    acceptRelationInvitation,
    refuseRelationInvitation,
    requestGroups,
    createGroup,
    editGroup,
    removeGroup,
    createRelation,
    updateRelation,
    addRelationToGroup,
    removeRelation,
    loadColoursFinderQuestionnaire,
    getRelationDetail,
    loadIdentificationCount,
    incrementIdentificationCount,
    getAppointment,
    updateAppointment,
  };
}

export default useAPIRelations;
