import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import {
  ToggleLabelOff,
  ToggleLabelOn,
  ToggleSlider,
} from 'components/common/form/toggle/Toggle.styled';
import {
  SelectBoxOptionsWrapper,
  SelectBoxWrapper,
} from 'components/common/form/selectbox/SelectBox.styled';
import { IonItemWrapper } from 'components/common/form/textfield/Textfield.styled';
import { IonButtonWrapper, RegularButtonFlexed } from 'components/common/button/Button.styled';
import { ShowHideWrapper } from 'components/common/showhide/ShowHide.styled';
import { BODY } from 'styles/helpers/typography';
import { FONT_WEIGHT } from 'styles/constants/fonts';

const SubscriptionNote = styled.p`
  color: white;
  text-align: center;
  margin-bottom: ${prem(16)};
`;
const NotificationToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: ${prem(16)};
  margin-bottom: ${prem(4)};
  --comcolors-toggle-background: rgba(255 255 255 / 0.15);
  --comcolors-toggle-thumb-background: white;

  ${ToggleLabelOn} {
    color: white;
  }

  ${ToggleLabelOff} {
    color: white;
  }

  ${ToggleSlider} {
    &::before {
      border-color: white;
    }
  }

  &.disabled-notification {
    span {
      opacity: 0.5;
    }
  }
`;

const SelectBoxesWrapper = styled.div`
  margin: 0 ${prem(16)} ${prem(30)};

  ${SelectBoxWrapper} {
    ${IonItemWrapper} {
      color: white;

      &::part(native) {
        background: transparent;
      }

      ${RegularButtonFlexed} {
        color: white;
      }

      ion-label {
        color: white !important;
      }

      input.native-input,
      select {
        border-bottom: 1px solid white;
        color: white;

        option {
          color: var(--ion-text-color);
        }
      }
    }

    ${ShowHideWrapper} {
      ${SelectBoxOptionsWrapper} {
        padding: ${prem(10)} 0;

        span {
          color: white;
        }
      }
    }
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: ${prem(6)};
  align-items: center;
  justify-content: center;

  ${IonButtonWrapper} {
    flex-basis: 50%;
  }
`;

const RelationInteractionSubscriptionWrapper = styled.div`
  max-width: ${prem(500)};

  p.notification-error {
    ${BODY.medium};
    font-weight: ${FONT_WEIGHT.BOLD};
    text-align: center;
  }

  .btn-form-submit-wrapper {
    margin-bottom: ${prem(24)};
  }
`;

export {
  RelationInteractionSubscriptionWrapper,
  SubscriptionNote,
  NotificationToggleWrapper,
  SelectBoxesWrapper,
  ActionWrapper,
};
