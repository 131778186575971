import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const ModuleTestAvailabilityWrapper = styled.div`
  text-align: center;
  /* stylelint-disable */
  background: rgba(var(--ion-color-accent-rgb), 0.1);
  /* stylelint-enable */
  padding: ${prem(50)} ${prem(20)};

  .buy-module {
    margin-bottom: ${prem(20)};
  }
`;

export { ModuleTestAvailabilityWrapper };
