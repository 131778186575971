import React, { useContext, useState } from 'react';
import {
  RelationsIdentificationSlideWrapper,
  SlideContentWrapper,
  SlideIntroWrapper,
} from 'pages/relations/identification/RelationIdentification.styled';
import { useTranslation } from 'react-i18next';
import { RelationManualIdentificationWrapper } from 'pages/relations/identification/slides/relation-manual-identification/RelationManualIdentification.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { IdentificationProps } from 'pages/relations/identification/RelationIdentification';
import { isMale } from 'helpers/relations';
import RelationColorSelector, {
  ColorType,
} from 'components/relations/relation/color-selector/RelationColorSelector';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { IonRouterLink } from '@ionic/react';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import { MAX_RELATION_IDENTIFICATION } from 'constants/global';

const RelationManualIdentification: React.FC<IdentificationProps> = ({
  relation,
  onSendColors,
  gotoTunnelingIdentification,
}) => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { identificationCount } = useContext(RelationsContext) as RelationsContextProps;
  const cannotAccessQuiz =
    !user?.profileInfo?.hasValidatedProfile && identificationCount >= MAX_RELATION_IDENTIFICATION;

  const [primaryColorId, setPrimaryColorId] = useState<number | undefined | null>(
    relation?.primaryColorId,
  );
  const [secondaryColorId, setSecondaryColorId] = useState<number | undefined | null>(
    relation?.secondaryColorId,
  );

  return (
    <RelationsIdentificationSlideWrapper>
      <RelationManualIdentificationWrapper>
        <Container>
          <SlideIntroWrapper>
            <p
              dangerouslySetInnerHTML={{
                __html: t('relations.identify.manually.title', {
                  relationFullName: relation?.fullName,
                  pronoun: t(isMale(relation?.pronoun) ? 'common.his_other' : 'common.her_other'),
                }),
              }}
            />
          </SlideIntroWrapper>
          <SlideContentWrapper>
            <div className="colors-selectors">
              <RelationColorSelector
                onSelect={(id) => setPrimaryColorId(id)}
                className="primary-selector"
                colorLabel={t('common.primarycolor')}
                type={ColorType.PRIMARY}
                defaultColorId={primaryColorId}
              />

              <RelationColorSelector
                onSelect={(id) => setSecondaryColorId(id)}
                className="secondary-selector"
                colorLabel={t('common.secondarycolor')}
                type={ColorType.SECONDARY}
                defaultColorId={secondaryColorId}
              />
            </div>
            <GenericDiv width="50%" minWidth={prem(230)} textCenter>
              <Button
                color="accent"
                fill="outline"
                expand={true}
                onClick={() => {
                  onSendColors && onSendColors(primaryColorId, secondaryColorId);
                }}
                disabled={primaryColorId === undefined || secondaryColorId === undefined}
              >
                {t('common.validatecolors')}
              </Button>
              {!cannotAccessQuiz && (
                <IonRouterLink
                  onClick={() => gotoTunnelingIdentification && gotoTunnelingIdentification()}
                >
                  {t('relations.identify.idontknow')}
                </IonRouterLink>
              )}
            </GenericDiv>
          </SlideContentWrapper>
        </Container>
      </RelationManualIdentificationWrapper>
    </RelationsIdentificationSlideWrapper>
  );
};

export default RelationManualIdentification;
