import React, { useContext } from 'react';
import {
  MotivationSlideWrapper,
  MotivationSubTitle,
} from 'pages/profile/has-profile/my-motivation/Motivation.styled';
import { useTranslation } from 'react-i18next';
import { Container } from 'layout/page/Page.layout.styled';
import {
  ColoursSelectorWrapper,
  DescColourMotivation,
  MotivationColorSelectionWrapper,
} from 'pages/profile/has-profile/my-motivation/slides/motivation-color-selection/MotivationColorSelection.styled';
import ColoursPicker from 'components/colours-picker/ColoursPicker';
import { IonRouterLink } from '@ionic/react';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { IColorDetail } from 'types/app';
import useAPIColours from 'hooks/api/useAPIColours';
import AppModalMessage from 'components/modal/AppModalMessage';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import useAPINotificationSubscription from 'hooks/api/useAPINotificationSubscription';

export interface MotivationColorSelectionProps {
  onBack: () => void;
}

const MotivationColorSelection: React.FC<MotivationColorSelectionProps> = ({ onBack }) => {
  const { t } = useTranslation('translation');
  const { getColourDetail } = useAPIColours();
  const { unsubscribeFromNotification } = useAPINotificationSubscription();
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { user, updateUser } = useContext(UserContext) as UserContextProps;
  const doUnsubscription = async () => {
    return await unsubscribeFromNotification(user?.motivation?.subscription?.id || 0);
  };
  // Ask
  const askForSubscriptionChange = (colourId: number) => {
    updateModalConf({
      component: (
        <AppModalMessage
          key={Math.random()}
          title={t('profile.motivation.colourchange')}
          className="custom-button-labels"
          validateLabel={t('profile.motivation.ichangecolour')}
          cancelLabel={t('profile.motivation.ikeepcolour')}
          message={t('profile.motivation.colourchangewarning')}
          onValidate={async () => {
            updateModalConf();
            await doUnsubscription();
            onChangeMotivationColour(colourId);
          }}
          onDismiss={() => {
            updateModalConf();
          }}
        />
      ),
    });
  };
  // Get colour desc and set it into the user motivation
  const onChangeMotivationColour = async (colourId: number) => {
    const colourDetail: IColorDetail | undefined = await getColourDetail(colourId);

    if (colourDetail && user) {
      updateUser({
        ...user,
        motivation: {
          colourId: colourDetail.id,
          motivationText: colourDetail.motivationLong,
          subscribed: false,
          subscriptionId: undefined,
          subscription: undefined,
        },
      });

      setTimeout(() => {
        onBack();
      }, 250);
    }
  };
  // When a colour is chosen
  // either user have subscription and we ask if he want to change colour subscription
  // or user don t have subscription so we get colour desc
  // and return to
  const onSelectColor = (colourId: number) => {
    if (user?.motivation?.subscription?.id) {
      askForSubscriptionChange(colourId);
    } else {
      onChangeMotivationColour(colourId);
    }
  };

  return (
    <MotivationSlideWrapper>
      <MotivationColorSelectionWrapper>
        <Container>
          <MotivationSubTitle
            dangerouslySetInnerHTML={{
              __html: t('profile.motivation.choosecolour'),
            }}
          />
          <ColoursSelectorWrapper>
            <ColoursPicker
              onSelectColor={onSelectColor}
              exceptedColour={user?.motivation?.colourId}
            />
          </ColoursSelectorWrapper>
          <DescColourMotivation
            dangerouslySetInnerHTML={{
              __html: t('profile.motivation.choosecolourdesc'),
            }}
          />
          <div className="link-back">
            <IonRouterLink
              onClick={() => {
                onBack && onBack();
              }}
            >
              {t('common.back')}
            </IonRouterLink>
          </div>
        </Container>
      </MotivationColorSelectionWrapper>
    </MotivationSlideWrapper>
  );
};

export default MotivationColorSelection;
