import React, { useContext, useEffect, useState } from 'react';
import {
  RelationsIdentificationSlideWrapper,
  SlideContentWrapper,
  SlideIntroWrapper,
} from 'pages/relations/identification/RelationIdentification.styled';
import { useTranslation } from 'react-i18next';
import {
  IdentificationLimitationMessage,
  RelationIdentificationHomeWrapper,
} from 'pages/relations/identification/slides/relation-identification-home/RelationIdentificationHome.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { IdentificationProps } from 'pages/relations/identification/RelationIdentification';
import { isMale } from 'helpers/relations';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPIRelations from 'hooks/api/useAPIRelations';
import useRelationIdentificationPermissionChecker from 'hooks/useRelationIdentificationPermissionChecker';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';

const RelationIdentificationHome: React.FC<IdentificationProps> = ({
  gotoManualIdentification,
  gotoTunnelingIdentification,
  relation,
}) => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { loadIdentificationCount } = useAPIRelations();
  const [isLoading, setLoadingState] = useState(true);
  const getIdentificationCount = async () => {
    user && (await loadIdentificationCount(user.id));
    setLoadingState(false);
  };
  const { hasValidatedProfile, isLimitedUser } = useRelationIdentificationPermissionChecker(user);
  const { identificationCount } = useContext(RelationsContext) as RelationsContextProps;

  // Load identification state
  // if user has an unvalidated profile
  useEffect(() => {
    if (user && isLimitedUser) {
      getIdentificationCount();
    } else {
      setLoadingState(false);
    }
  }, []);

  return (
    <RelationsIdentificationSlideWrapper>
      <RelationIdentificationHomeWrapper>
        <Container>
          {!isLoading && (
            <>
              <SlideIntroWrapper>
                {t('relations.identify.subtitle', {
                  pronounProfile: t(isMale(relation?.pronoun) ? 'common.his' : 'common.her'),
                  pronounColours: t(
                    isMale(relation?.pronoun) ? 'common.his_other' : 'common.her_other',
                  ),
                })}
              </SlideIntroWrapper>
              <SlideContentWrapper>
                <GenericDiv width="50%" minWidth={prem(230)}>
                  <Button color="accent" expand={true} onClick={gotoManualIdentification}>
                    {t('relations.identify.iknow')}
                  </Button>
                  <Button
                    color="accent"
                    fill="outline"
                    expand={true}
                    onClick={gotoTunnelingIdentification}
                    disabled={isLimitedUser && identificationCount > 0}
                  >
                    {t('relations.identify.idontknow')}
                  </Button>
                </GenericDiv>
                {isLimitedUser && identificationCount > 0 && (
                  <IdentificationLimitationMessage
                    dangerouslySetInnerHTML={{
                      __html: t(
                        hasValidatedProfile
                          ? 'relations.identify.identification-limitation-no-modules'
                          : 'relations.identify.identification-limitation-unvalidated-profile',
                      ),
                    }}
                  />
                )}
              </SlideContentWrapper>
            </>
          )}
        </Container>
      </RelationIdentificationHomeWrapper>
    </RelationsIdentificationSlideWrapper>
  );
};

export default RelationIdentificationHome;
