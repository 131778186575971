import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { FranckJulienWrapper } from 'pages/models/franckjulien/FranckJulien.styled';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { IFranckJullien } from 'types/app';
import useAPIModels from 'hooks/api/useAPIModels';
import { getLocaleValue } from 'helpers/utils';

const FranckJulien: React.FC = () => {
  const { t } = useTranslation('translation');
  const { getFranckJullien } = useAPIModels();
  const { i18n } = useTranslation('translation');
  const [franckInformationsState, setFranckInformationsState] = useState<IFranckJullien>();

  const loadDatas = useCallback(async () => {
    const franckInformations = await getFranckJullien();
    setFranckInformationsState(franckInformations);
  }, []);

  useEffect(() => {
    loadDatas();
  }, []);

  const {
    icons: [iconYoutube, iconTiktok, iconLinkedin],
  } = useIcons([ICON_NAMES.YOUTUBE, ICON_NAMES.TIKTOK, ICON_NAMES.LINKEDIN]);

  return (
    <FranckJulienWrapper className="app-page">
      <Container>
        {franckInformationsState && (
          <>
            <PageInnerTitle
              dangerouslySetInnerHTML={{
                __html:
                  getLocaleValue(franckInformationsState.title, i18n.language) ||
                  t('model.franck_julien.title'),
              }}
            />
            <img src={franckInformationsState.imageUrl} alt="" />
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: getLocaleValue(franckInformationsState.description, i18n.language),
              }}
            />
            <div className="socials">
              <p className="socials-title">{t('model.franck_julien.socials') || ''}</p>
              <p className="socials-icons">
                <a
                  className="social-icon"
                  href={franckInformationsState.socials.youtube}
                  target="_blank"
                  rel="noreferrer"
                >
                  {iconYoutube.icon}
                </a>
                <a
                  className="social-icon"
                  href={franckInformationsState.socials.tiktok}
                  target="_blank"
                  rel="noreferrer"
                >
                  {iconTiktok.icon}
                </a>
                <a
                  className="social-icon"
                  href={franckInformationsState.socials.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  {iconLinkedin.icon}
                </a>
              </p>
            </div>
          </>
        )}
      </Container>
    </FranckJulienWrapper>
  );
};

export default FranckJulien;
