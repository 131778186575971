import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';
import { GenericDiv } from 'styles/helpers/utils';

const MessageContainer = styled.div`
  margin: ${prem(16)} 0;
  ${BODY.large};
  text-align: center;
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${prem(28)};

  ${GenericDiv} {
    margin: unset;
  }
`;
const AppModalMessageWrapper = styled.div`
  &.custom-button-labels {
    ${ButtonsContainer} {
      gap: ${prem(16)};
    }
  }
`;

export { AppModalMessageWrapper, MessageContainer, ButtonsContainer };
