import styled from 'styled-components';

const MySummaryReadMoreWrapper = styled.div`
  text-align: center;
`;

const MySummaryReadMoreButton = styled.button`
  color: white;
  background: var(--remember-color);
  border-radius: 10px;
  padding: 10px 25px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 0.25);
`;

export {
  MySummaryReadMoreButton,
  MySummaryReadMoreWrapper
};
