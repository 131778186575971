import React from 'react';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import { GenericDiv } from 'styles/helpers/utils';
import Button from 'components/common/button/Button';
import {
  AppModalMessageWrapper,
  ButtonsContainer,
  MessageContainer,
} from 'components/modal/AppModalMesage.styled';
import { useTranslation } from 'react-i18next';

export interface AppModalMessageProps {
  message: string;
  cancelLabel?: string | null;
  validateLabel?: string | null;
  title?: string | null;
  onValidate?: () => void;
  onDismiss?: () => void;
  className?: string;
}

const AppModalMessage: React.FC<AppModalMessageProps> = ({
  message,
  title,
  onDismiss,
  onValidate,
  cancelLabel,
  validateLabel,
  className,
}) => {
  const { t } = useTranslation('translation');

  return (
    <AppModalMessageWrapper className={className}>
      {title && <AppModalTitle>{title}</AppModalTitle>}
      <MessageContainer dangerouslySetInnerHTML={{ __html: message }} />
      <ButtonsContainer>
        <GenericDiv minWidth={'7rem'} maxWidth={'15rem'} center>
          <Button color="white" type="submit" expand={true} onClick={onDismiss}>
            {cancelLabel || t('common.no')}
          </Button>
        </GenericDiv>
        <GenericDiv minWidth={'7rem'} maxWidth={'15rem'} center>
          <Button color="white" type="submit" fill="outline" expand={true} onClick={onValidate}>
            {validateLabel || t('common.yes')}
          </Button>
        </GenericDiv>
      </ButtonsContainer>
    </AppModalMessageWrapper>
  );
};

export default AppModalMessage;
