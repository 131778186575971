import React, { FC, useState } from 'react';
import { createRoot } from 'react-dom/client';
import App, { AppProps, defaultSettings } from 'App';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';
import { API, LS_KEYS } from 'constants/global';
import { ISettings, IUser } from 'types/app';
import NotificationService from 'helpers/notification';
import { getFromStorage, removeFromStorage, saveToStorage } from 'helpers/storage';
import { HttpClient } from 'helpers/api';
import { parseUserInfo } from 'helpers/parsers';
// eslint-disable-next-line no-restricted-imports
import dataPackage from '../package.json';
/* Core CSS required for Ionic components to work properly */
import Rollbar from 'rollbar';
import { getAppBuild } from 'helpers/utils';

// Build HOC for App to communicate with the service worker
const withSwRegistration = (WrappedComp: FC<AppProps>) => {
  const BUILD = process.env.REACT_APP_BUILD_TIMESTAMP
    ? `build ${getAppBuild(process.env.REACT_APP_BUILD_TIMESTAMP)}`
    : '';
  const ENV: string = `${process.env.REACT_APP_ENV}`.toLowerCase();
  const rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_POST_ITEM_ITEM_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: ENV === 'staging' || ENV === 'production',
    payload: {
      environment: `${process.env.REACT_APP_ENV}`,
      client: {
        javascript: {
          code_version: `${dataPackage.version} ${BUILD}`,
          source_map_enabled: true,
        },
      },
    },
  });
  const HOCApp = (props: AppProps) => {
    // holds all the SW registration setup
    const [appUpdatePending, setAppUpdatePending] = useState(false);
    // updates the state when a new update is pending.
    const onSWUpdate = (registration: ServiceWorkerRegistration | null) => {
      rollbar.info('Service Worker === onSWUpdate ===== registration ', {
        registration: registration,
      });
      console.log('=== onSWUpdate ===== registration ', registration);
      setAppUpdatePending(true);
      registration && NotificationService.setServiceWorkerRegistration(registration);
    };
    // action for updating the service worker.
    const updateAction = () => {
      if ('serviceWorker' in navigator) {
        console.log('BUILD', BUILD);
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.waiting) {
            // send the skip message to kick off the service worker install.
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // add an listener to reload page when the new service worker is ready.
            registration.waiting.addEventListener('statechange', (event: Event) => {
              const { state = '' } = (event.target as unknown as { state: string }) || {};
              if (state === 'activated') {
                rollbar.info(
                  ' -- Service Worker -- : on click update button => event.target.state == activated => reload window',
                  {
                    eventTarget: event.target,
                    registration: registration,
                    build: BUILD,
                  },
                );
                window.location.href +=
                  (window.location.href.indexOf('?') > -1 ? '&' : '?') +
                  'version_time=' +
                  new Date().getTime();
              }
            });
          } else {
            rollbar.info(
              '-- Service Worker -- : registration.waiting == false || undefined => reload window',
              {
                registration: registration,
                build: BUILD,
              },
            );
            window.location.href +=
              (window.location.href.indexOf('?') > -1 ? '&' : '?') +
              'version_time=' +
              new Date().getTime();
          }
        });
      }
    };

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
      onSuccess: (registration) => {
        console.log('onSuccess -> registration: ', registration);
        registration && NotificationService.setServiceWorkerRegistration(registration);
      },
    });

    return (
      <WrappedComp updateAction={updateAction} appUpdatePending={appUpdatePending} {...props} />
    );
  };

  return HOCApp;
};

const AppWithSwRegistration = withSwRegistration(App);
const initAll = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  const localStorageUserStr = getFromStorage(LS_KEYS.USER);
  const localStorageSettingsStr = getFromStorage(LS_KEYS.SETTINGS);
  const localStorageLang = getFromStorage(LS_KEYS.LANG);
  let user: IUser | undefined;

  // Init settings
  let settings: ISettings = { ...defaultSettings };

  if (localStorageLang) {
    if (localStorageLang.includes('fr')) {
      settings.lang = 'fr';
    }
    if (localStorageLang.includes('en')) {
      settings.lang = 'en';
    }
  }

  if (localStorageUserStr) {
    user = JSON.parse(localStorageUserStr) as IUser;
    // Check
    if (!user.token) {
      user = undefined;
    }
  } else {
    user = undefined;
  }

  // If we have a user in localstorage
  if (user && user.id) {
    // check if there a settings corresponding to the user
    if (localStorageSettingsStr) {
      try {
        const registeredSettings = JSON.parse(localStorageSettingsStr) as ISettings;
        if (registeredSettings.userId === user?.id) {
          settings = registeredSettings;
        }
      } catch (e) {
        console.error('settings error: ', e);
      }
    }

    // Check user validity and get user detail
    HttpClient.request({
      url: `${API.ROUTES.USER.DETAIL}/${user.id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.token?.type} ${user.token?.value}`,
      },
    })
      .then((response) => {
        // Update user & user in localstorage
        if (response.data.data) {
          user = parseUserInfo(user as IUser, response.data.data);
          saveToStorage(LS_KEYS.USER, JSON.stringify(user));
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          // delete localstorage user
          removeFromStorage(LS_KEYS.USER);
          user = undefined;
        }
      })
      .finally(() => {
        root.render(<AppWithSwRegistration user={user} settings={settings} />);
      });
  } else {
    root.render(<AppWithSwRegistration settings={settings} />);
  }
};

window.addEventListener('load', initAll);
reportWebVitals();
