import React from 'react';
import { StepWrapper } from 'pages/profile/has-profile/color-profile/step/Step.styled';
import { IComponentWithChildren } from 'types/app';

export interface StepProps extends IComponentWithChildren {
  stepNumber: number;
  totalStep: number;
  stepTitle: string;
  hide?: boolean;
  refValue?: React.Ref<any>;
}

const Step: React.FC<StepProps> = ({ children, stepNumber, totalStep, stepTitle, hide, refValue }) => {
  return (
    <StepWrapper ref={refValue} className={`${hide && 'hide'}`}>
      <div className="step-number">{`${stepNumber}/${totalStep}`}</div>
      <div className="step-title">{stepTitle}</div>
      <div className="children">{children}</div>
    </StepWrapper>
  );
};

export default Step;
