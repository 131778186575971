import React, { useEffect, useState } from 'react';
import { IonNote } from '@ionic/react';
import { Components } from '@ionic/core/components';
import { TextFieldTypes } from '@ionic/core';
import { Form } from 'types/app';
import {
  IonTextAreaStyled,
  IonInputWrapper,
  IonItemWrapper,
} from 'components/common/form/textarea/TextArea.styled';
import FormLabel from 'components/common/form/label/FormLabel';

export interface TextfieldProps
  extends Pick<Components.IonInput, 'inputmode' | 'required' | 'name' | 'color'> {
  label?: string;
  value?: Form.IValue;
  onChange?: (value?: Form.IValue) => void;
  errorMessage?: string | null;
  type?: TextFieldTypes;
  className?: string,
  rows?: number,
  cols?: number
}
const TextArea: React.FC<TextfieldProps> = ({
  label,
  required,
  value,
  inputmode,
  errorMessage,
  onChange,
  className,
  rows,
  cols
}: TextfieldProps) => {
  const [fieldValue, setFieldValue] = useState<Form.IValue>(value);
  const [isTouched, setIsTouched] = useState(false);
  const markTouched = () => {
    setIsTouched(true);
  };

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <IonInputWrapper className={className}>
      <IonItemWrapper
        lines="none"
        className={`${errorMessage && 'ion-invalid'} ${isTouched && 'ion-touched'}`}
      >
        {label && <FormLabel label={label} required={required} />}
        <IonTextAreaStyled
          required={required}
          inputmode={inputmode}
          onIonChange={(e) => {
            onChange && onChange(e.target.value);
            setFieldValue(e.target.value);
          }}
          onIonBlur={() => markTouched()}
          value={fieldValue as string}
          rows={rows || 9}
          cols={cols}
        />
        <IonNote slot="error">{errorMessage}</IonNote>
      </IonItemWrapper>
    </IonInputWrapper>
  );
};

export default TextArea;
