import { API, LANGUAGES, PRICE_UNITS } from 'constants/global';
import { IPriceUnit, ISectionBaseTexts } from 'types/app';
import slugify from 'slugify';

export function isEmail(email: string): boolean {
  return !!email.match(
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  );
}

export function isValidPassword(value: string): boolean {
  // return /\w{6,}/.test(value);
  return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(value);
}

export function getElHeight(el: Element): number {
  const style = window.getComputedStyle(el);
  const props = ['height', 'margin-top', 'margin-bottom'];

  try {
    return props
      .map((key) => parseInt(style.getPropertyValue(key), 10))
      .reduce((prev, cur) => prev + cur, 0);
  } catch (e) {
    console.log('getElHeight > error: ', e);
  }

  return 0;
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getId(chars = 10): string {
  const tokens = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';

  for (let j = 0; j < chars; j++) {
    const k = getRandomInt(0, 35);
    result += tokens[k];
  }

  return result;
}

export function getBoolRand() {
  return !!Math.floor(Math.random() * 2);
}

export function getLocaleValue<T, K = string>(obj: any, locale: string): T | K | string {
  try {
    for (const [key, value] of Object.entries(obj)) {
      const regExp = new RegExp(key);
      if (regExp.test(locale)) {
        return value as T;
      }
    }
  } catch (e) {
    console.log('error: ', e);
  }

  return '';
}

export function getLocaleHours(locale = 'fr'): Array<{ id: number; label: string }> {
  const basedIteration = new Array(24);
  const result = [];
  const diff = locale === 'fr' ? 0 : 12;

  for (let i = 0; i < basedIteration.length; i++) {
    const meridien = i < 12 ? ' AM' : ' PM';
    const hour = i - (i > 12 ? diff : 0);
    let label = `${hour < 10 ? '0' : ''}${hour}:00${locale === 'en' ? meridien : ''}`;

    locale === 'en' && i === 0 && (label = `12:00${meridien}`);

    result[i] = {
      id: i,
      label,
    };
  }

  return result;
}

export function base64UrlToUint8Array(base64UrlData: string): Uint8Array {
  const padding = '='.repeat((4 - (base64UrlData.length % 4)) % 4);
  const base64 = (base64UrlData + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const buffer = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }

  return buffer;
}

export function getOffsetTimeZone(): number {
  return (new Date().getTimezoneOffset() / 60) * -1;
}

export function getHourFromUTC(hour: number, isLocale = true): number {
  const diff = isLocale ? hour + getOffsetTimeZone() : hour - getOffsetTimeZone();
  let result = diff;

  // Clamp value between 0 - 24
  if (diff < 0) {
    result = 24 + diff;
  }

  if (diff > 24) {
    result = diff - 24;
  }
  // If 24 -> 0
  return result === 24 ? 0 : result;
}

export type TimeLabel = { unit: number; label: string };

export function getTimeLabel(dateStr: string): TimeLabel {
  const now = new Date();
  const error = {
    unit: 0,
    label: '',
  };

  try {
    const pastDate = new Date(dateStr);
    const diff = Math.floor(now.getTime() - pastDate.getTime());
    const milliseconds = 1000;

    const seconds = Math.floor(diff / milliseconds);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    /*
    console.log('now: ', now.toString());
    console.log('pastDate: ', pastDate.toString());
    console.log('diff: ', diff);
    console.log('seconds: ', seconds);
    */

    if (years > 0) {
      return {
        unit: years,
        label: 'year',
      };
    }

    if (months > 0) {
      return {
        unit: months,
        label: 'month',
      };
    }

    if (days > 0) {
      return {
        unit: days,
        label: 'day',
      };
    }

    if (hours > 0) {
      return {
        unit: hours,
        label: 'hour',
      };
    }

    if (minutes > 0) {
      return {
        unit: minutes,
        label: 'minute',
      };
    }

    return {
      unit: seconds > 0 ? seconds : 1,
      label: 'second',
    };

    throw new Error('Date label generation error');
  } catch (e) {
    console.log('getPastTimeLabel error: ', e);
  }

  return error;
}

export function convertDateStrToLocale(dateStr: string): string {
  try {
    const utcDateStr = `${dateStr.replace('Z', '')}Z`;
    const date = new Date(utcDateStr);

    return date.toString();
  } catch (e) {
    console.log('convertDateStrToLocale error: ', e);
  }

  return dateStr;
}

export function tokensReplacer(input: string, withStr = ''): string {
  const firstNameToken = /\[PRENOM\]|\[FIRST NAME\]/g;

  if (!withStr) {
    return input;
  }

  try {
    return input.replace(firstNameToken, withStr).split('[BR]').join('');
  } catch (e) {
    console.log('tokensReplacer error: ', e);
  }

  return '';
}

export function padNumber(digit: number, length: number): string {
  try {
    const diffLength = length - `${digit}`.length;

    if (diffLength >= 0) {
      return [...new Array(diffLength).fill(0), digit].join('');
    }
  } catch (e) {
    console.log('padNumber error: ', e);
  }

  return `${digit}`;
}

export function getAppBuild(unixTimestampStr?: string): string {
  if (unixTimestampStr) {
    const milliseconds = parseInt(unixTimestampStr, 10) * 1000;
    const buildLocaleDate = new Date(milliseconds);
    const year = buildLocaleDate.getUTCFullYear() - 2000;
    const month = padNumber(buildLocaleDate.getUTCMonth() + 1, 2);
    const date = padNumber(buildLocaleDate.getUTCDate(), 2);
    const hour = padNumber(buildLocaleDate.getUTCHours(), 2);
    const minute = padNumber(buildLocaleDate.getUTCMinutes(), 2);

    return `${year}${month}${date}${hour}${minute}`;
  }

  return '';
}

export class Stater {
  private state: boolean;

  constructor(initState = false) {
    this.state = initState;
  }

  setState(state: boolean) {
    this.state = state;
  }

  getState(): boolean {
    return this.state;
  }
}

// Check PWA installed
export function isAppInstalled() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return navigator['standalone'] || isStandalone;
}

export function removeAttributes(htmlString: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const removeAttributesRecursive = (element: HTMLElement) => {
    const attributes = element.getAttributeNames();
    for (const attr of attributes) {
      element.removeAttribute(attr);
    }
    const children = Array.from(element.children) as HTMLElement[];
    for (const child of children) {
      removeAttributesRecursive(child);
    }
  };

  // Add the removal of [BR] and BR] tags here
  const removeBrBrTags = (element: HTMLElement) => {
    const childNodes = Array.from(element.childNodes);
    for (const childNode of childNodes) {
      if (childNode.nodeType === Node.TEXT_NODE) {
        const text = childNode.textContent || '';
        const updatedText = text.replace(/\[BR\]|\[Br\]|BR\]|\[Br\]/g, '');
        childNode.textContent = updatedText;
      }
    }
  };

  removeAttributesRecursive(doc.body as HTMLElement);
  removeBrBrTags(doc.body as HTMLElement);

  const modifiedHTML = doc.body.innerHTML;
  return modifiedHTML;
}

export const POPUP_TUTO_STATE = new Stater();

const setLanguageFallback = (data: any, sourceLanguage: string, targetLanguage: string) => {
  if (data[sourceLanguage] && !data[targetLanguage]) {
    data[targetLanguage] = data[sourceLanguage];
  }
};

/**
 * Get translations in response API
 * @param lngCodes Array of lang codes ['fr', 'en']
 * @param input Array of translations, usually has a locale key which value is an language
 * @param mw middleware function to extract the value string
 * */
export function getTranslations<T>(
  lngCodes: Array<string>,
  input: Array<any>,
  mw: (input: any) => T,
): { [key: string]: T } {
  return lngCodes.reduce((accu, lang) => {
    const keys = Object.keys(accu);
    if (!keys.includes(lang)) {
      const raw = input.find((rawItem: any) => rawItem.locale === lang);
      if (raw) {
        return {
          ...accu,
          ...{ [lang]: mw(raw) as T },
        };
      }
    }

    return accu;
  }, {});
}

export function getSectionBaseTexts(
  translations: Array<any>,
  languages: Array<string>,
  image: string | undefined,
): ISectionBaseTexts {
  const res = {
    title: getTranslations<string>(languages, translations, (input) => input.libelle),
    description: getTranslations<string>(languages, translations, (input) => input.description),
    imageUrl: image,
  } as ISectionBaseTexts;
  setLanguageFallback(res.title, 'fr', 'en');
  setLanguageFallback(res.description, 'fr', 'en');
  return res;
}

export function formatProfileQualitiesString(inputString: string): string {
  const textWithoutPunctuation: string = inputString.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
  const textWithoutTags: string = textWithoutPunctuation.replace(/<[^>]*>/g, ' ');
  const words: string[] = textWithoutTags.split(' ').map((word) => word.trim());
  const nonEmptyWords: string[] = words.filter((word) => word !== '');
  const joinedWords: string = nonEmptyWords.join('<br />');
  return joinedWords;
}

export function getBase64User(email: string) {
  return btoa(`${email}////${API.BOA_KEY}`);
}

export function replaceStrings(input: string, replacements: { [key: string]: string }): string {
  const regex = /\[(.*?)\]/g;

  const replacedString = input.replace(regex, (match, placeholder) => {
    const replacement = replacements[placeholder];
    return replacement !== undefined ? replacement : match;
  });

  return replacedString;
}

// Only for fr and en
export function detectLanguage(input: string): string {
  const regex = /^(fr|en)(-[a-zA-Z]+)?$/i;
  if (regex.test(input)) {
    const match = input.match(regex);
    return match ? match[1].toLowerCase() : LANGUAGES.fr;
  } else {
    return LANGUAGES.en;
  }
}

export const formatUnit = (unit: IPriceUnit) => {
  return PRICE_UNITS[unit] || '';
};

export const formatNumberByLocale = (number: number, language: string): string => {
  const detectedLanguage = detectLanguage(language);
  const toFixedNumber = `${number.toFixed(2)}`;
  if (detectedLanguage === LANGUAGES.fr) {
    return toFixedNumber.replace('.', ',');
  } else if (detectedLanguage === LANGUAGES.en) {
    return toFixedNumber;
  }
  return `${number}`;
};

// Format price for english and french
export const formatPrice = (price: number, unit: IPriceUnit, language: string): string => {
  const unitFormated = formatUnit(unit);
  const detectedLanguage = detectLanguage(language);
  const formatedPrice = formatNumberByLocale(price, language);
  if (detectedLanguage === LANGUAGES.fr) {
    return `${formatedPrice} ${unitFormated}`;
  } else if (detectedLanguage === LANGUAGES.en) {
    return `${unitFormated} ${formatedPrice}`;
  }
  return `${formatedPrice}`;
};

export async function blobToBase64(blobUrl: any) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (reader) {
      reader.onloadend = () => resolve((reader?.result as string).split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }
  });
}

export async function getBase64Image(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

export function slugifyStr(input: string | null = ''): string {
  return slugify(`${input}`, { lower: true, strict: true, trim: true });
}
