import styled from 'styled-components';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import COLORS from 'styles/constants/colors';
import { MyProfileWrapper } from 'pages/profile/has-profile/my-profile/MyProfile.styled';

const MySummaryWrapper = styled(MyProfileWrapper)`
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 0;
`;

const MySummaryContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const PreparedBy = styled.p`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 35px;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  strong {
    color: var(--ion-text-color-secondary);
  }
`;

const Line = styled.div`
  height: 1px;
  background-color: #847C6E;
  width: 45%;
  margin: 0 auto 37px;
`;

const LogoWrapper = styled.div`
  max-width: 300px;
  width: 77%;
  margin: 0 auto 37px;
`;

const EditedBy = styled.p`
  padding: 0 20px;
  text-align: center;
  margin: 0 auto 37px;
  width: 60%;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: #847C6E;
`;

const CopyRight = styled.p`
  padding: 0 20px;
  text-align: center;
  margin: 0 auto 37px;
  color: #847C6E;
`;

const ButtonDownloadWrapper = styled.div`
  margin-bottom: 60px;
  text-align: center;
`;

const ButtonDownLoad = styled.button`
  background: ${COLORS.ACCENT.ORANGE};
  border: none;
  display: inline-flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 13px;
  gap: 8px;
  color: white;
  padding: 10px 12px;
  border-radius: 30px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 0.25);

  .icon {
    width: 14px;
    height: 14px;
  }
`;

const MainContentWrapper = styled.div`
  color: ${COLORS.BASE.BROWN.DEFAULT};
  font-size: 14px;

  h1, h2, h3 {
    font-weight: ${FONT_WEIGHT.SEMIBOLD};
    color: ${COLORS.BASE.GREEN.DEFAULT};
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 15px;
    margin: 16px 0;
  }

  h4 {
    font-size: 14px;
    margin-bottom: 14px;
    font-weight: ${FONT_WEIGHT.BOLD};
  }

  p {
    margin-bottom: 16px;
  }

  .inner-line-separator {
    margin: 65px auto;
  }

  .schema-table {
    text-align: center;
    padding: 50px 0;
    position: relative;

    svg {
      max-width: 475px;
      width: 75%;
      height: auto;
    }

    svg:first-of-type {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .primary-color-block {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    margin-top: 20px;

    .color-img {
      svg {
        width: 37px;
        height: 37px;

        circle.p-color {
          stroke: var(--pcolor);
        }
      }
    }

    &.pcb-0 {
      margin-top: 40px;
    }

    &.pcb-5 {
      margin-bottom: 40px;
    }
  }

  .color-title-block {
    display: flex;
    gap: 16px;
    color: var(--color-title);
    margin-bottom: 24px;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }

  .to-remember-block {
    margin-bottom: 40px;

    .to-remember-title-wrapper {
      display: flex;
      gap: 16px;
      align-items: flex-start;
      margin-left: -25px;

      strong.remember-label {
        display: none;
      }

      .to-remember-title-arrow {
        align-self: stretch;
        position: relative;
      }

      .arrow {
        position: relative;
        height: 41px;
      }

      .arrow.arrow-rouge {
        color: #E2001A;
      }

      .arrow.arrow-violette {
        color: #A664A1;
      }

      .arrow.arrow-orange {
        color: ${COLORS.BASE.ORANGE.DEFAULT};
      }

      .arrow.arrow-bleue {
        color: #006AB3;
      }

      .arrow.arrow-jaune {
        color: #FCC521;
      }

      .arrow.arrow-verte {
        color: #2D8F2E;
      }

      .arrow {
        .label {
          width: 100%;
          color: white;
          text-transform: uppercase;
          font-size: 10px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding-left: 6px;
        }
      }
    }
    /* stylelint-disable */
    .to-remember-content {
      p {
        display: none;

        &:first-of-type {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    /* stylelint-enable */
  }

  .role-color-block {
    display: flex;
    gap: 16px;

    .role-img {
      color: var(--role-color);
      flex-basis: 81px;
    }

    .role-text {
      flex: 1;
    }
  }

  div.step {
    font-size: 40px;
    width: 64px;
    height: 64px;
    background-color: white;
    border-radius: 100%;
    padding: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 5px 4px rgb(0 0 0 / 0.25);
    margin: 16px 16px 16px 0;
  }
`;


const PopupContainer = styled.div<{ open?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: ${({open}) => open ? 'rgba(255 255 255 / 0.75)' : 'rgba(255 255 255 / 0)'};
  transition: all;
  z-index: 99;
`;


export {
  MySummaryWrapper,
  MySummaryContentWrapper,
  PreparedBy,
  Line,
  LogoWrapper,
  EditedBy,
  CopyRight,
  ButtonDownloadWrapper,
  ButtonDownLoad,
  MainContentWrapper,
  PopupContainer
};
