import React, { useEffect } from 'react';
import { ColorsListWrapper } from 'components/models/sixcolors/colorslist/ColorsList.styled';
import { IModelColor } from 'types/app';
import useCollapse from 'hooks/useCollapse';
import SingleColor from 'components/models/sixcolors/singlecolor/SingleColor';

export interface ColorsListProps {
  colors: Array<IModelColor>;
  defaultOpenedId?: number | string;
}

const ColorsList: React.FC<ColorsListProps> = ({ colors, defaultOpenedId }) => {
  const { getOpenState, updateStates } = useCollapse();

  useEffect(() => {
    const collapsesIds = colors.map((c, i) => ({
      id: `${i}`,
      opened: defaultOpenedId !== undefined ? i === defaultOpenedId : false,
    }));
    updateStates('', false, collapsesIds);
  }, [colors]);

  return (
    <ColorsListWrapper>
      {colors &&
        colors.map((color, index) => {
          const id = `${index}`;
          return (
            <SingleColor
              opened={getOpenState(id)}
              onOpened={(o) => updateStates(id, o)}
              color={color}
              key={`color-${index}`}
              className='single-color-container'
            />
          );
        })}
    </ColorsListWrapper>
  );
};

export default ColorsList;
