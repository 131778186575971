import { useCallback, useContext } from 'react';
import { NavContext, RouterDirection } from '@ionic/react';

function useIonNavContext() {
  const { navigate } = useContext(NavContext);
  const gotoUrl = useCallback(
    (url: string, direction?: RouterDirection) => navigate(url, direction),
    [navigate],
  );

  return { gotoUrl };
}

export default useIonNavContext;
