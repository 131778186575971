import React, { useContext, useEffect, useState } from 'react';
import { IonToast, ToastOptions } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';

const AppToast: React.FC = () => {
  const { toast, setToast } = useContext(ToastContext) as ToastContextProps;
  const [opened, setOpen] = useState(false);
  const [conf, setConf] = useState<ToastOptions>();
  const onDidDismiss = () => {
    setToast();
  };

  useEffect(() => {
    if (toast) {
      const tmpConf: ToastOptions = {
        message: toast.message,
        duration: toast.duration || 3000,
      };

      if (toast.status) {
        tmpConf.color = toast.status;
        if (toast.status === 'warning') {
          tmpConf.icon = alertCircleOutline;
        }
      }

      setConf(tmpConf);
    }
    setOpen(!!toast);
  }, [toast]);

  return (
    <>
      <IonToast {...conf} isOpen={opened} onDidDismiss={onDidDismiss} />
    </>
  );
};

export default AppToast;
