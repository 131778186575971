import { useState } from 'react';

export interface ICollapseState {
  id: string;
  opened?: boolean;
}

export interface UseCollapseOutput {
  collapses: Array<ICollapseState>;
  getOpenState: (id: string) => boolean;
  updateStates: (id: string, state: boolean, allStates?: Array<ICollapseState>) => void;
}

function useCollapse(collapseStates: Array<ICollapseState> = []): UseCollapseOutput {
  const [collapses, setCollapses] = useState<Array<ICollapseState>>([...collapseStates]);
  const getOpenState = (id: string): boolean => {
    return !!collapses.find((c) => c.id === id)?.opened;
  };
  const updateStates = (id: string, state: boolean, allStates?: Array<ICollapseState>) => {
    const collapse = collapses.find((c) => c.id === id);
    if (collapse) {
      setCollapses(
        collapses.map((c) => {
          const _c = {
            id: c.id,
            opened: false,
          };

          if (c.id === id) {
            return {
              id: c.id,
              opened: state,
            };
          }

          return _c;
        }),
      );
    } else {
      if (allStates) {
        setCollapses([...allStates]);
      }
    }
  };

  return { collapses, getOpenState, updateStates };
}

export default useCollapse;
