import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { IContactDetails, IPosition, ITrainer, ITrainers } from 'types/app';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { AxiosRequestConfig } from 'axios';
import { API, EUROPE_COUNTRY_CODES, GEOLOC_KEY } from 'constants/global';
import { IHttpResponse } from 'helpers/api';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface IUseAPIContact {
  getContactDetails: () => Promise<IContactDetails | undefined>;
  getTrainersWithUserPosition: (
    longitude: number,
    latitude: number,
  ) => Promise<ITrainers | undefined>;
  getPositionFromAddress: (postalAddress: string) => Promise<IPosition | undefined>;
}

function useAPIContact(): IUseAPIContact {
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { user, isGuest } = useContext(UserContext) as UserContextProps;

  type IContactFromAPI = {
    contact: {
      email: string;
      is_contact_comcolors: boolean;
      phone_number: string;
    };
  };

  const formatContactDetails = (contact: IContactFromAPI): IContactDetails => {
    return {
      email: contact.contact.email,
      phoneNumber: contact.contact.phone_number,
      isComcolorsContact: contact.contact.is_contact_comcolors,
    };
  };

  // Load contact details
  const getContactDetails = async (): Promise<IContactDetails | undefined> => {
    try {
      if (isGuest) {
        return undefined;
      }

      showLoading();

      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.CONTACT.CONTACT}/${user?.id}`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const getState: IContactFromAPI = result.data.data;
      if (getState) {
        return formatContactDetails(getState);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting contact details : ', e);
      setToast({
        message: 'Error while getting colour detail : ',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }
    return undefined;
  };

  const formatTrainers = (trainers: Array<any>): ITrainers => {
    return trainers.map((trainer) => {
      return {
        firstname: trainer.prenom,
        lastname: trainer.nom,
        email: trainer.email,
        address: trainer.adresse,
        distance: trainer.distance,
        number: trainer?.telephone,
        photo: trainer?.avatar,
      } as ITrainer;
    });
  };

  const getTrainersWithUserPosition = async (longitude: number, latitude: number) => {
    try {
      showLoading();
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.CONTACT.FIND_TRAINER}`,
        method: 'post',
        data: {
          latitude: latitude,
          longitude: longitude,
          rayon: 1000,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const trainers = result?.data?.data?.trainers;
      const formatedTrainers = formatTrainers(trainers);
      return formatedTrainers;
    } catch (e: unknown) {
      console.log('Error while getting Trainers : ', e);
      // setToast({
      //   message: 'Error while getting Trainers',
      //   status: 'danger',
      // });
    } finally {
      hideLoading();
    }
    return [];
  };

  const formatPositionFromAddress = (result: any): IPosition => {
    return {
      latitude: result?.geometry?.location?.lat,
      longitude: result?.geometry?.location?.lng,
    };
  };

  const getFrenchPostalAddressFromResult = (results: Array<any>) => {
    return results.find((result) => {
      return result?.address_components.find((address_component: any) => {
        return EUROPE_COUNTRY_CODES.find((code) => code == address_component.short_name);
      });
    });
  };

  const getPositionFromAddress = async (postalAddress: string) => {
    // https://maps.googleapis.com/maps/api/geocode/json?address=France&sensor=true&key=AIzaSyDagvY-pYrA0mlooACpCBVp5OVBErFtdOs
    try {
      showLoading();
      const options: AxiosRequestConfig | any = {
        url: `${API.ROUTES.CONTACT.FIND_POSITIONS}?address=${postalAddress}&sensor=true&key=${GEOLOC_KEY}`,
        method: 'get',
        headers: null,
      };
      const result: IHttpResponse = await getRequest(options);
      const resultDatas = getFrenchPostalAddressFromResult(result?.data?.results);
      return formatPositionFromAddress(resultDatas) || undefined;
    } catch (e: unknown) {
      console.log('Error while getting positions : ', e);
      setToast({
        message: 'Error while getting positions',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }
    return undefined;
  };

  return {
    getContactDetails,
    getTrainersWithUserPosition,
    getPositionFromAddress,
  };
}

export default useAPIContact;
