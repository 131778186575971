import styled, { css } from 'styled-components';
import { RegularButtonFlexed } from 'components/common/button/Button.styled';
import { prem } from 'styles/helpers/spacing';
import { IonItemWrapper } from 'components/common/form/textfield/Textfield.styled';
import { BUTTON } from 'styles/helpers/typography';
import { ShowHideWrapper } from 'components/common/showhide/ShowHide.styled';

const SelectNative = styled.select`
  pointer-events: auto;
  width: 100%;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 0 ${prem(8)};
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  border-bottom: 1px solid var(--ion-color-accent);
  color: var(--ion-text-color-secondary);

  &:focus {
    outline: none;
  }
`;

const SelectBoxWrapper = styled.div`
  ion-input {
    --ripple-color: transparent;
    --box-shadow: unset;
    text-transform: unset;
    height: unset;
  }

  pointer-events: none;

  ${IonItemWrapper} {
    ${RegularButtonFlexed} {
      width: 100%;
      height: 50%;
      pointer-events: auto;
      position: absolute;
      bottom: 0;
      left: 0;
      justify-content: right;
      color: var(--ion-color-accent);

      &.native {
        pointer-events: none;
      }
    }

    ion-label {
      opacity: 1 !important;
    }

    input.native-input[disabled] {
      color: var(--ion-text-color-secondary);
      opacity: 1 !important;
    }
  }

  ${ShowHideWrapper} {
    pointer-events: auto;
  }

  ion-note {
    display: block;
    text-align: left;
    padding-top: 5px;
    font-size: ${prem(12)};
    color: var(--highlight-color-invalid);
  }
`;

const SelectBoxOptionsWrapper = styled.div<{ maxHeight?: string }>`
  padding: ${prem(20)} 0;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
      overflow-y: auto;
      padding: ${prem(20)} ${prem(20)} ${prem(20)} 0 !important;
    `}

  ${RegularButtonFlexed} {
    color: var(--ion-text-color-secondary);
    display: flex;
    justify-content: right;
    width: 100%;
    padding: ${prem(4)} 0;
    ${BUTTON.small};

    &:focus {
      opacity: 0.7;
    }
  }
`;

export { SelectBoxWrapper, SelectBoxOptionsWrapper, SelectNative };
