import React, { useMemo } from 'react';
import { IAdvice } from 'types/app';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { getLocaleValue, getTimeLabel, TimeLabel } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import {
  FavButton,
  AdviceDesc,
  AdviceTime,
  AdviceTimeWrapper,
  AdviceWrapper,
} from 'components/advice/Advice.styled';

export interface RelationAdviceProps {
  advice: IAdvice;
  onLike?: (state: boolean) => void;
}

const Advice: React.FC<RelationAdviceProps> = ({ advice, onLike }) => {
  const { t, i18n } = useTranslation('translation');
  const {
    icons: [iconFavorite],
  } = useIcons([ICON_NAMES.FAVORITE]);
  const timePast = useMemo<TimeLabel>(() => {
    return getTimeLabel(advice.date);
  }, [advice]);
  const timeLabelKey = timePast.unit ? t(`common.${timePast.label}`, { count: timePast.unit }) : '';

  return (
    <AdviceWrapper>
      <AdviceDesc
        dangerouslySetInnerHTML={{
          __html: getLocaleValue(advice.advice, i18n.language),
        }}
      />
      <AdviceTimeWrapper>
        {timePast.unit && (
          <AdviceTime>
            {t('common.timepast', { timeLabel: `${timePast.unit} ${timeLabelKey}` })}
          </AdviceTime>
        )}
        <FavButton
          onClick={() => onLike && onLike(!advice.liked)}
          className={advice.liked ? 'liked' : ''}
        >
          {iconFavorite.icon}
        </FavButton>
      </AdviceTimeWrapper>
    </AdviceWrapper>
  );
};

export default Advice;
