import React, { createContext, FC, useState } from 'react';
import { IComponentWithChildren } from 'types/app';
import { getId } from 'helpers/utils';

export interface ShowHideContextProps {
  refreshTrigger: string;
  refreshHeight: () => void;
}

const ShowHideContext = createContext<ShowHideContextProps | null>(null);
const ShowHideProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [refreshTrigger, setRefreshTrigger] = useState<string>(getId());
  const refreshHeight = () => {
    setRefreshTrigger(getId());
  };

  return (
    <ShowHideContext.Provider value={{ refreshHeight, refreshTrigger }}>
      {children}
    </ShowHideContext.Provider>
  );
};

export { ShowHideContext, ShowHideProvider };
