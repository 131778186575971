import styled from 'styled-components';
import { IntroContainer } from 'pages/intro/Intro.styled';
import { prem } from 'styles/helpers/spacing';
import { IonInputWrapper } from 'components/common/form/textfield/Textfield.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { FONT_SIZES } from 'styles/constants/fonts';
import { FormErrorWrapper } from 'components/common/form/Form.styled';

const ForgottenpwdTitle = styled.h2`
  margin-bottom: ${prem(50)};
  font-size: ${prem(FONT_SIZES.SUBTITLE.MOBILE_S)};
`;

const ForgottenpwdWrapper = styled(IntroContainer)`
  height: unset;
  width: 73%;
  max-width: ${prem(500)};
  min-width: ${prem(250)};
  text-align: center;
  margin: ${prem(25)} auto 0;

  ${IonInputWrapper} {
    margin-bottom: ${prem(25)};
  }

  ${IonButtonWrapper} {
    margin: ${prem(77)} auto ${prem(24)};
    width: 50%;
    min-width: ${prem(190)};
    max-width: ${prem(250)};
  }

  .pwd-changed {
    p {
      font-size: ${prem(18)};
      margin-bottom: ${prem(24)};
    }
  }

  &.step-2 {
    ${ForgottenpwdTitle} {
      margin-bottom: ${prem(30)};
    }
  }
`;

const ForgottenpwdInstruction = styled.div`
  margin-bottom: ${prem(34)};

  &.newpwd {
    margin-bottom: ${prem(16)};
  }

  p {
    margin-bottom: ${prem(17)};
  }

  ul {
    text-align: left;
  }
`;

const ForgottenPwdNew = styled.div`
  ${IonButtonWrapper} {
    margin: ${prem(50)} auto ${prem(24)};
  }

  ${FormErrorWrapper} {
    margin: ${prem(20)} 0;
  }
`;

export { ForgottenpwdWrapper, ForgottenpwdTitle, ForgottenpwdInstruction, ForgottenPwdNew };
