import Button from 'components/common/button/Button';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const TestimonialsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const AddTestimonialButtonWrapper = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${prem(10)};

  ion-button {
    &::part(native) {
      box-shadow: 0 ${prem(4)} ${prem(4)} #00000040;
    }
  }

  .inner-button {
    display: flex;
    align-items: center;
    gap: ${prem(10)};

    .icon {
      width: ${prem(30)};
      height: auto;
    }

    label {
      flex: 1;
      white-space: nowrap;
    }
  }
`;

export { TestimonialsWrapper, AddTestimonialButtonWrapper };
