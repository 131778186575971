import React from 'react';
import { PrivilegedCompanyModalWrapper } from 'components/profile/has-privileged-company-modal/PrivilegedCompanyModal.styled';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { useTranslation } from 'react-i18next';

export interface PrivilegedCompanyModalProps {
  email: string;
  companyName: string;
  onClose?: () => any;
}

const PrivilegedCompanyModal: React.FC<PrivilegedCompanyModalProps> = ({
  email,
  companyName,
  onClose,
}) => {
  const { t } = useTranslation('translation');

  return (
    <PrivilegedCompanyModalWrapper>
      <AppModalTitle>{t('profile.privilegedcompany.title')}</AppModalTitle>
      <div
        dangerouslySetInnerHTML={{
          __html: t('profile.privilegedcompany.desc1', {
            email: email,
            companyName: companyName,
          }),
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: t('profile.privilegedcompany.desc2'),
        }}
      />
      <GenericDiv minWidth="200px" maxWidth="200px">
        <Button fill="outline" color="white" onClick={onClose}>
          {t('profile.privilegedcompany.iunderstand')}
        </Button>
      </GenericDiv>
    </PrivilegedCompanyModalWrapper>
  );
};

export default PrivilegedCompanyModal;
