import React from 'react';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import {
  IconNotificationWrapper,
  NotificationDot,
} from 'components/common/icons/icon-notification/IconNotification.styled';

export interface IconNotificationProps {
  hasNotification?: boolean;
  isNotificationPage?: boolean;
  onClick: () => void;
}

const IconNotification: React.FC<IconNotificationProps> = ({
  hasNotification,
  isNotificationPage,
  onClick,
}) => {
  const {
    icons: [iconNotification],
  } = useIcons([ICON_NAMES.NOTIFICATION]);

  return (
    <IconNotificationWrapper onClick={onClick} isNotificationPage={!!isNotificationPage}>
      {iconNotification.icon}
      {hasNotification && <NotificationDot />}
    </IconNotificationWrapper>
  );
};

export default IconNotification;
