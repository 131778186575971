import styled from 'styled-components';
import {
  CheckboxContainer,
  CheckboxLabel,
  CheckWrapper,
} from 'components/common/form/checkbox/Checkbox.styled';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const QuestionnaireResponsesWrapper = styled.div`
  .limits-container {
    position: relative;
    margin: ${prem(12)} ${prem(10)};

    div {
      position: absolute;
      top: 0;
      text-align: center;
      ${BODY.small};

      &.min {
        left: 0;
        transform: translateX(-50%);
      }

      &.max {
        right: 0;
        transform: translateX(50%);
      }
    }
  }
`;

const ResponsesWrapper = styled.div`
  display: flex;
  gap: ${prem(24)};
  justify-content: center;

  ${CheckboxContainer} {
    flex-direction: column;
    gap: ${prem(10)};

    ${CheckWrapper} {
      top: 0;
      transform: translate(0, 0);
    }

    ${CheckboxLabel} {
      ${BODY.large};
      text-align: center;
    }
  }
`;

export { QuestionnaireResponsesWrapper, ResponsesWrapper };
