import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import ForgottenpwdValidation from 'pages/forgottenpwd/Forgottenpwd-validation';
import { ForgottenpwdInstruction } from 'pages/forgottenpwd/Forgottenpwd.styled';
import useFormHandler from 'hooks/useFormHandler';
import { FORM } from 'constants/form';
import { TextFieldTypes } from '@ionic/core';
import { ForgottenPwdProps } from 'pages/forgottenpwd/Forgottenpwd';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { FormErrorWrapper } from 'components/common/form/Form.styled';

const ForgottenpwdEmail: React.FC<ForgottenPwdProps> = ({ onSubmitted }) => {
  const { t } = useTranslation('translation');
  const { sendForgottenPwdEmail } = useAPIAuth();
  const [sendState, setSendState] = useState(true);
  const { fields, isFormValid, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'email',
      value: '',
      required: true,
      type: FORM.TYPES.EMAIL as TextFieldTypes,
    },
  ]);
  const doRequest = async (email: string) => {
    const hasSubmitted = await sendForgottenPwdEmail(email);

    hasSubmitted === false && setSendState(false);
    hasSubmitted && onSubmitted && onSubmitted(email);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormValid()) {
      const email = getFieldByName('email').value as string;
      setSendState(true);
      doRequest(email);
    }
  };

  return (
    <>
      <ForgottenpwdInstruction
        dangerouslySetInnerHTML={{ __html: t('forgottenpwd.emailpage.instruction') }}
      />
      <form noValidate onSubmit={onSubmit} autoComplete="off">
        <Textfield
          {...({
            ...getFieldByName('email', fields),
            inputmode: 'email',
            label: t('login.form.email') || '',
            onChange: (value?) => updateFieldValue('email', value),
          } as TextfieldProps)}
        />
        {!sendState && (
          <FormErrorWrapper>
            <span dangerouslySetInnerHTML={{ __html: t('message.error.emailnotfound') }} />
          </FormErrorWrapper>
        )}
        <ForgottenpwdValidation />
      </form>
    </>
  );
};

export default ForgottenpwdEmail;
