import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import InnerPageLayout from 'layout/page/innerpage/InnerPage.layout';
import { IonRouterOutlet } from '@ionic/react';
import useIcons, { ICON_NAMES, IIcon } from 'hooks/useIcons';
import { ROUTES } from 'constants/routes';
import SixColors from 'pages/models/sixcolors/SixColors';
import ComColors from 'pages/models/comcolors/ComColors';
import FranckJulien from 'pages/models/franckjulien/FranckJulien';
import Testimonials from 'pages/models/testimonials/Testimonials';

const Models: React.FC = () => {
  const { t } = useTranslation('translation');
  const {
    icons: [logo, sixColors, franckJulien, testimonials],
  } = useIcons([
    ICON_NAMES.LOGO,
    ICON_NAMES.SIX_COLORS,
    ICON_NAMES.FRANCK_JULIEN,
    ICON_NAMES.TESTIMONIALS
  ]);

  const tabs: Array<IIcon> = [logo, sixColors, testimonials, franckJulien];

  return (
    <PageLayout headerProps={{ title: t('menu.model') }}>
      <InnerPageLayout tabs={tabs} className="page-models-wrapper">
        <IonRouterOutlet animated={false}>
          <Route exact path={ROUTES.MODEL.INDEX} render={() => <ComColors />} />
          <Route exact path={ROUTES.MODEL.COMCOLORS} render={() => <ComColors />} />
          <Route exact path={ROUTES.MODEL.SIX_COLORS} render={() => <SixColors />} />
          <Route exact path={`${ROUTES.MODEL.TESTIMONIALS.INDEX}/:section?`} render={() => <Testimonials />} />
          <Route exact path={ROUTES.MODEL.FRANCK_JULIEN} render={() => <FranckJulien />} />
        </IonRouterOutlet>
      </InnerPageLayout>
    </PageLayout>
  );
};

export default Models;
