import axios from 'axios';
import { API } from 'constants/global';
import { Stater } from 'helpers/utils';

export interface IApiResponse {
  message: string;
  success: boolean;
  data?: any;
}

export interface IHttpResponse<T = any> {
  data: T;
  status: number;
  hasError?: boolean;
  statusText?: string;
  message?: string;
}

const defaultResponseError: IHttpResponse = {
  data: null,
  status: 404,
};

const HttpClient = axios.create({
  baseURL: API.BASEPATH,
  timeout: 15000,
});

// Interceptor
HttpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const result: IHttpResponse = {
      ...defaultResponseError,
      ...(error.status ? { status: error.status } : {}),
      hasError: true,
      message: error.message,
    };

    // Error
    if (error.response) {
      result.data = error.response.data;
      result.status = error.response.status || result.status;
      if (result.data) {
        result.data.message && (result.statusText = result.data.message);
        result.data.errors &&
          result.data.errors.length &&
          (result.statusText = result.data.errors.join('<br />'));
      }
    }

    return Promise.reject(result);
  },
);

// Loading throttler
const ION_LOADING_STATE = new Stater();

export { HttpClient, Stater, ION_LOADING_STATE };
