import React, { useEffect, useState } from 'react';
import { Provider } from '@rollbar/react';
import Rollbar from 'rollbar';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
// eslint-disable-next-line no-restricted-imports
import dataPackage from '../package.json';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Global */
import 'styles/global.scss';
/* Import i18n */
import 'i18n';
import Intro from 'pages/intro/Intro';
import Login from 'pages/login/Login';
import Forgottenpwd from 'pages/forgottenpwd/Forgottenpwd';
import { ROUTES } from 'constants/routes';
import MainLayout from 'layout/main/Main.layout';
import { IComponentWithChildren, ISettings, IUser } from 'types/app';
import { UserProvider } from 'contexts/UserContext';
import { LANGUAGES, LS_KEYS } from 'constants/global';
import { ModalProvider } from 'contexts/ModalContext';
import AppModal from 'components/modal/AppModal';
import { SettingsProvider } from 'contexts/SettingsContext';
import { RelationsProvider } from 'contexts/RelationsContext';
import { ToastProvider } from 'contexts/ToastContext';
import AppToast from 'components/toast/AppToast';
import { getFromStorage, saveToStorage } from 'helpers/storage';
import { NotificationsProvider } from 'contexts/NotificationsContext';
import AppVideoPlayer from 'components/videoplayer/AppVideoPlayer';
import { VideoPlayerProvider } from 'contexts/VideoPlayerContext';
import AppUpdater from 'components/app-updater/AppUpdater';
import { getAppBuild } from 'helpers/utils';
import Register from 'pages/register/Register';
import AutoLogin from 'pages/autologin/AutoLogin';
import SharedProfile from 'pages/profile/shared-profile/SharedProfile';
import Elearning from 'pages/elearning/Elearning';
import { ElearningProvider } from 'contexts/ElearningContext';
import { BuyToolProvider } from 'contexts/BuyToolContext';
import BuyToolModal from 'components/modal/buy-tool-modal/BuyToolModal';
import { PayableToolsProvider } from 'contexts/PayableToolsContext';
import { StripeProvider } from 'contexts/StripeContext';
import { ModuleTestProvider } from 'contexts/ModuleTestContext';
import { ModuleProvider } from 'contexts/ModuleContext';
import { useTranslation } from 'react-i18next';
/* React tooltips */
import 'react-tooltip/dist/react-tooltip.css';

/* Init Ionic: https://ionicframework.com/docs/react/config */
setupIonicReact({
  mode: 'ios',
});

interface PrivateRouteProps extends IComponentWithChildren {
  path?: string;
}

export const defaultSettings: ISettings = {
  lang: 'fr',
  profileVisibility: 0,
  notification: 0,
  darkMode: 0,
  themeColor: 'neutral',
};
const BUILD = process.env.REACT_APP_BUILD_TIMESTAMP
  ? `build ${getAppBuild(process.env.REACT_APP_BUILD_TIMESTAMP)}`
  : '';
const ENV: string = `${process.env.REACT_APP_ENV}`.toLowerCase();
const rollbarConfig: Rollbar.Configuration = {
  accessToken: `${process.env.REACT_APP_ROLLBAR_POST_ITEM_ITEM_TOKEN}`,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: ENV === 'staging' || ENV === 'production',
  payload: {
    environment: `${process.env.REACT_APP_ENV}`,
    client: {
      javascript: {
        code_version: `${dataPackage.version} ${BUILD}`,
        source_map_enabled: true,
      },
    },
  },
};

/* Private route */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const location = useLocation();
  const pathname = location ? location.pathname || location.location?.pathname : '';
  const isPathnameToLoginOrIntro = pathname.includes('login') || pathname.includes('intro');

  const resolveRedirectionPath = (): string => {
    return getFromStorage(LS_KEYS.SKIP_INTRO) ? ROUTES.INDEX : ROUTES.INTRO;
  };

  return !isPathnameToLoginOrIntro ? (
    <Route
      {...rest}
      render={({ location }) => {
        return LS_KEYS.SKIP_INTRO ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: resolveRedirectionPath(),
              state: { from: location },
            }}
          />
        );
      }}
    />
  ) : (
    <></>
  );
};

// TODO: Theme handler into context
// https://stackoverflow.com/questions/56300132/how-to-override-css-prefers-color-scheme-setting
// document.documentElement.setAttribute("data-theme", "dark")
export interface AppProps {
  appUpdatePending?: boolean;
  updateAction?: () => void;
  settings?: ISettings;
  user?: IUser;
}

const App: React.FC<AppProps> = ({
  user,
  appUpdatePending,
  updateAction,
  settings = defaultSettings,
}) => {
  const { i18n } = useTranslation();
  const [settingsState, setSettingsState] = useState<ISettings>(settings);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tmpLang = searchParams.get('lang');
    settings && i18n.changeLanguage(settings.lang);
    !localStorage.getItem(LS_KEYS.LANG) && i18n.changeLanguage(LANGUAGES.fr);
    if (tmpLang === LANGUAGES.fr || tmpLang === LANGUAGES.en) {
      i18n.changeLanguage(tmpLang);
      saveToStorage(LS_KEYS.LANG, settings.lang);
      setSettingsState({
        ...settings,
        lang: tmpLang,
      });
    }
  }, [settings]);

  useEffect(() => {
    console.log('New App version (2)');
  });
  return (
    <Provider config={rollbarConfig}>
      <IonApp>
        <UserProvider user={user}>
          <ModuleTestProvider>
            <StripeProvider>
              <SettingsProvider settings={settingsState}>
                <RelationsProvider>
                  <ToastProvider>
                    <ModalProvider>
                      <PayableToolsProvider payableTools={[]}>
                        <BuyToolProvider>
                          <ElearningProvider>
                            <ModuleProvider>
                              <VideoPlayerProvider>
                                <NotificationsProvider>
                                  <IonReactRouter>
                                    <IonRouterOutlet animated={false}>
                                      <Route exact={true} path={ROUTES.INTRO}>
                                        <Intro />
                                      </Route>
                                      <Route exact={true} path={ROUTES.LOGIN}>
                                        <Login />
                                      </Route>
                                      <Route exact={true} path={ROUTES.REGISTER}>
                                        <Register />
                                      </Route>
                                      <Route exact={true} path={ROUTES.FORGOTTEN_PWD.INDEX}>
                                        <Forgottenpwd />
                                      </Route>
                                      <Route exact={true} path={ROUTES.AUTOLOGIN}>
                                        <AutoLogin />
                                      </Route>
                                      <Route exact={true} path={ROUTES.SHARED_PROFILE}>
                                        <SharedProfile />
                                      </Route>
                                      <PrivateRoute>
                                        <MainLayout />
                                      </PrivateRoute>
                                    </IonRouterOutlet>
                                  </IonReactRouter>
                                  <AppModal />
                                  <AppToast />
                                  <AppVideoPlayer />
                                  <Elearning />
                                  <AppUpdater
                                    appUpdatePending={appUpdatePending}
                                    updateAction={updateAction}
                                  />
                                  <BuyToolModal />
                                </NotificationsProvider>
                              </VideoPlayerProvider>
                            </ModuleProvider>
                          </ElearningProvider>
                        </BuyToolProvider>
                      </PayableToolsProvider>
                    </ModalProvider>
                  </ToastProvider>
                </RelationsProvider>
              </SettingsProvider>
            </StripeProvider>
          </ModuleTestProvider>
        </UserProvider>
      </IonApp>
    </Provider>
  );
};
export default App;
