import styled from 'styled-components';
import { SUBTITLE } from 'styles/helpers/typography';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';

const BookItemTitle = styled.h3`
  ${SUBTITLE.medium};
  margin-bottom: ${prem(20)};
`;
const BookItemImage = styled.img`
  margin: 0 auto ${prem(20)};
  display: block;
  width: 75%;
  max-width: ${prem(500)};
`;
const BookItemDesc = styled.div`
  color: var(--ion-text-color-secondary);
  white-space: pre-wrap;
  margin-bottom: ${prem(20)};
`;
const BookItemWrapper = styled.div`
  ${GenericDiv} {
    margin-bottom: ${prem(20)};
  }
`;

export { BookItemTitle, BookItemImage, BookItemDesc, BookItemWrapper };
