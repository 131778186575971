import { ShopSummaryWrapper } from 'components/shop/shop-summary/ShopSummary.styled';
import { SingleToolWrapper } from 'components/shop/single-tool/SingleTool.styled';
import { Container } from 'layout/page/Page.layout.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const ShopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${prem(900)};
  margin: 0 auto;
  height: 80vh;

  ${Container} {
    display: flex;
    flex-direction: column;
  }

  .payable-tools-list {
    margin-top: ${prem(25)};
    padding-bottom: ${prem(200)};
    overflow-y: auto;
    overflow-x: hidden;
  }

  ${SingleToolWrapper} {
    min-height: ${prem(75)};
    position: relative;

    &::before {
      content: '';
      top: ${prem(0)};
      position: absolute;
      width: ${prem(700)};
      max-width: 65%;
      height: 1px;
      /* stylelint-disable */
      border-top: 1px solid rgb(var(--ion-color-medium-rgb), 0.5);
      /* stylelint-enable */
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${ShopSummaryWrapper} {
    flex: 1;
  }
`;

export { ShopWrapper };
