import styled from 'styled-components';
import { IonHeaderStyled } from 'layout/ionic/IonHeader.styled';
import { SUBTITLE } from 'styles/helpers/typography';
import { HEADER_HPADDING, prem } from 'styles/helpers/spacing';
import { IconNotificationWrapper } from 'components/common/icons/icon-notification/IconNotification.styled';
import { LanguageSelectorWrapper } from 'components/header/languageselector/Language.selector.styled';

const HeaderWrapper = styled(IonHeaderStyled)`
  &::after {
    display: none;
  }
`;

const HeaderPageWrapper = styled(HeaderWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${prem(25)} ${prem(HEADER_HPADDING)} ${prem(10)} ${prem(HEADER_HPADDING)};

  ${IconNotificationWrapper} {
    width: ${prem(25)};
  }

  ${LanguageSelectorWrapper} {
    margin-bottom: 0;
  }
`;

const HeaderRightSideWrapper = styled.div`
  display: flex;
  gap: ${prem(20)};
`;

const PageTitleWrapper = styled.h3`
  ${SUBTITLE.medium};
  color: var(--ion-text-color-secondary);
`;

const PageHeaderLogoWrapper = styled.div`
  width: ${prem(250)};

  svg {
    max-height: ${prem(40)};
  }
`;

export {
  HeaderWrapper,
  HeaderPageWrapper,
  PageTitleWrapper,
  PageHeaderLogoWrapper,
  HeaderRightSideWrapper,
};
