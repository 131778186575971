import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { AxiosRequestConfig } from 'axios';
import { API } from 'constants/global';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { IHttpResponse } from 'helpers/api';
import { IPaymentDatas, IPriceUnit } from 'types/app';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { BuyToolContext, BuyToolContextProps } from 'contexts/BuyToolContext';

export type ToolType = 'Module';

export interface IUseAPIPayment {
  initPayment: (
    amount: number,
    currency: IPriceUnit,
    description: string,
    type?: ToolType,
  ) => Promise<IPaymentDatas | undefined>;
  confirmPayment: (
    paymentId: string,
    paymentStatus: string,
    toolsCodes: string[],
  ) => Promise<any | undefined>;
}

function useAPIPayment(): IUseAPIPayment {
  const { getRequest } = useFetch<any>();
  const { user } = useContext(UserContext) as UserContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { shoppingList } = useContext(BuyToolContext) as BuyToolContextProps;

  const formatInitPaymentPayload = (datas: any): IPaymentDatas => {
    return {
      clientSecret: datas.client_secret,
      paymentAmount: datas.payment_amount,
      paymentDescription: datas.payment_description,
      paymentId: datas.paymentId,
    };
  };

  const initPayment = async (
    amount: number,
    currency: IPriceUnit,
    description: string,
    type: ToolType = 'Module',
  ) => {
    let typeSuffix = '';
    if (type === 'Module') {
      if (shoppingList.length === 1) {
        typeSuffix = 'Achat du module';
      } else if (shoppingList.length > 1) {
        typeSuffix = 'Achat des modules';
      }
    }

    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.PAYMENT.INIT_PAYMENT,
        method: 'post',
        headers: {
          Authorization: `${user?.token?.type} ${user?.token?.value}`,
        },
        data: {
          user_id: user?.id,
          amount: amount,
          currency: currency,
          description: `App : ${typeSuffix} ${description}`,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const paymentDatas = result.data.data?.payment_datas;
      return formatInitPaymentPayload(paymentDatas);
    } catch (e) {
      setToast({
        message: `${'Erreur'} lors de l'initialisation du paiement`,
      });
      console.log(e);
    }
  };

  const confirmPayment = async (paymentId: string, paymentStatus: string, toolsCodes: string[]) => {
    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.PAYMENT.CONFIRM_PAYMENT,
        method: 'post',
        headers: {
          Authorization: `${user?.token?.type} ${user?.token?.value}`,
        },
        data: {
          user_id: user?.id,
          payment_id: paymentId,
          payment_status: paymentStatus,
          tools_code: toolsCodes,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const datas = result.data.data;
      if (
        datas.payment_datas &&
        datas.payment_datas.payment_complete &&
        datas.payment_datas.status === 'succeeded'
      ) {
        return true;
      } else {
        console.log(datas);
        return false;
      }
    } catch (e) {
      setToast({
        message: `${'Erreur'} lors de la confirmation du paiement`,
      });
      console.log(e);
    }
    return;
  };

  return {
    initPayment,
    confirmPayment,
  };
}

export default useAPIPayment;
