import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { ButtonLabel, RegularButtonFlexed } from 'components/common/button/Button.styled';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { BUTTON } from 'styles/helpers/typography';

const RelationsWrapper = styled.div``;
const RelationMenuWrapper = styled.div<{ opened: boolean; yPos?: string }>`
  box-shadow: 0 4px 4px rgba(28 114 95 / 0.31);
  padding: ${prem(16)};
  background: var(--comcolors-contexual-menu-background);

  ${RegularButtonFlexed} {
    display: flex;
    color: var(--ion-color-accent);

    span {
      color: var(--ion-text-color-secondary);
    }

    ${ButtonLabel} {
      ${BUTTON.large};

      &.important {
        font-weight: ${FONT_WEIGHT.BOLD};
      }
    }
  }

  ${({ opened }) =>
    opened
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

export { RelationsWrapper, RelationMenuWrapper };
