import { MODULES_CODES, PAYABLE_TOOLS_CODES } from 'constants/global';
import React, { createContext, FC, useContext, useState } from 'react';
import {
  IComponentWithChildren,
  IModule,
  IPayableTool,
  IPayableTools,
  IPaymentConfs,
} from 'types/app';
import { PayableToolsContext, PayableToolsContextProps } from 'contexts/PayableToolsContext';

export interface BuyToolContextProps {
  paymentConfs: IPaymentConfs;
  updatePaymentConfs: ({ newTools }: { newTools: IPayableTools }) => void;
  shoppingList: IPayableTools;
  updateShoppingList: (tools: IPayableTools) => any;
  addToolToShoppingList: (tool: IPayableTool) => void;
  removeToolFromShoppingList: (tool: IPayableTool) => void;
  emptyShoppingList: () => void;
  formatModuleToPayableTool: (module: IModule) => IPayableTool | undefined;
}

const BuyToolContext = createContext<BuyToolContextProps | null>(null);
const BuyToolProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [selectedToolsToBuy, setSelectedToolsToBuy] = useState<IPayableTools>([]);
  const [paymentConfs, setPaymentConfs] = useState<IPaymentConfs>();
  const { getModulePrice } = useContext(PayableToolsContext) as PayableToolsContextProps;

  const updatePaymentConfs = ({ newTools }: { newTools: IPayableTools }) => {
    setPaymentConfs({
      tools: newTools as IPayableTools,
    });
  };

  const addToolToShoppingList = (tool: IPayableTool) => {
    if (
      !selectedToolsToBuy.find((item) => item.code === tool.code) &&
      !tool.hasAccess &&
      !tool.unavailable &&
      !tool.hide
    ) {
      setSelectedToolsToBuy([...selectedToolsToBuy, tool]);
    }
  };

  const removeToolFromShoppingList = (tool: IPayableTool) => {
    if (selectedToolsToBuy.find((item) => item.code === tool.code)) {
      setSelectedToolsToBuy(selectedToolsToBuy.filter((toolObj) => toolObj.code !== tool.code));
    }
  };

  const formatModuleToPayableTool = (module: IModule): IPayableTool | undefined => {
    const paymentType = 'card';
    if (module.code !== MODULES_CODES.ELEARNING) {
      return {
        code: module.paymentCode,
        currency: module.currency,
        title: {
          fr: module.title,
          en: module.title,
        },
        description: {
          fr: module.description,
          en: module.description,
        },
        payment_type: paymentType,
        price_ttc: getModulePrice(module.paymentCode) as number,
        canBuy: module.canBuy,
      };
    } else {
      if (module.paymentCode === PAYABLE_TOOLS_CODES.ELEARNING_BEHAVIOR_STRESS) {
        const title = {
          fr: `${module.title} - Comportements sous stress`,
          en: `${module.title} - Behaviors under stress`,
        };
        const description = {
          fr: module.description,
          en: module.description,
        };

        return {
          code: module.paymentCode,
          currency: module.currency,
          title: title,
          description: description,
          price_ttc: getModulePrice(module.paymentCode) as number,
          payment_type: paymentType,
        };
      } else if (module.paymentCode === PAYABLE_TOOLS_CODES.ELEARNING_WORKING_TOGETHER) {
        const title = {
          fr: `${module.title} - Travailler ensemble`,
          en: `${module.title} - Working together`,
        };
        const description = {
          fr: module.description,
          en: module.description,
        };

        return {
          code: module.paymentCode,
          currency: module.currency,
          title: title,
          description: description,
          price_ttc: getModulePrice(module.paymentCode) as number,
          payment_type: paymentType,
        };
      }
    }
  };

  const emptyShoppingList = () => {
    setSelectedToolsToBuy([]);
  };

  const updateShoppingList = (tools: IPayableTools) => {
    setSelectedToolsToBuy(tools);
  };
  
  return (
    <BuyToolContext.Provider
      value={{
        paymentConfs: paymentConfs as IPaymentConfs,
        shoppingList: selectedToolsToBuy,
        updatePaymentConfs: updatePaymentConfs,
        addToolToShoppingList: addToolToShoppingList,
        removeToolFromShoppingList: removeToolFromShoppingList,
        formatModuleToPayableTool: formatModuleToPayableTool,
        emptyShoppingList: emptyShoppingList,
        updateShoppingList: updateShoppingList,
      }}
    >
      {children}
    </BuyToolContext.Provider>
  );
};

export { BuyToolContext, BuyToolProvider };
