import React from 'react';
import {
  IntroHeading,
  IntroPageWrapper,
  IntroPreLoginButtonWrapper,
  IntroPreLoginWrapper,
} from 'pages/intro/Intro.styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import { ROUTES } from 'constants/routes';

const IntroPageLogin: React.FC = () => {
  const { t } = useTranslation('translation');
  return (
    <IntroPageWrapper>
      <IntroPreLoginWrapper>
        <div className="intro-texts-container">
          <IntroHeading>{t('intro.welcome')}</IntroHeading>
          <p dangerouslySetInnerHTML={{ __html: t('intro.page1') }} />
        </div>
        <IntroPreLoginButtonWrapper>
          <Button color="accent" expand={true} routerLink={ROUTES.LOGIN} routerDirection="forward">
            {t('intro.login.haveaccount')}
          </Button>
        </IntroPreLoginButtonWrapper>
        <IntroPreLoginButtonWrapper>
          <Button
            fill="outline"
            expand={true}
            routerLink={ROUTES.REGISTER}
            routerDirection="forward"
          >
            {t('intro.login.noaccount')}
          </Button>
        </IntroPreLoginButtonWrapper>
      </IntroPreLoginWrapper>
    </IntroPageWrapper>
  );
};

export default IntroPageLogin;
