import React, { useContext } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeaderPage, { HeaderPageProps } from 'components/header/Header.page';
import { IComponentWithChildren, IUser } from 'types/app';
import { showBuyElearningButton } from 'helpers/modules';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface PageLayoutProps extends IComponentWithChildren {
  headerProps?: HeaderPageProps;
  className?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  className,
  children,
  headerProps = {},
}): React.ReactElement => {
  const { user } = useContext(UserContext) as UserContextProps;

  return (
    <IonPage>
      <HeaderPage {...headerProps} hideShopCard={!showBuyElearningButton(user as IUser)} />
      <IonContent className={className} fullscreen>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default PageLayout;
