import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API, LS_KEYS } from 'constants/global';
import { IColorDetail } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { getFromStorage, saveToStorage } from 'helpers/storage';
import { IHttpResponse } from 'helpers/api';

export interface IUseAPIColours {
  getColourDetail: (
    colourId: number,
    isInBackground?: boolean,
  ) => Promise<IColorDetail | undefined>;
}

function useAPIColours(): IUseAPIColours {
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  // Parse advice
  const parseColourDetail = (raw: any): IColorDetail | undefined => {
    if (raw.id) {
      return {
        id: raw.id,
        motivationLong: raw.plus_motivation,
      } as IColorDetail;
    }

    return undefined;
  };

  // Load user motivation
  const getColourDetail = async (
    colourId: number,
    isInBackground?: boolean,
  ): Promise<IColorDetail | undefined> => {
    !isInBackground && showLoading();

    console.log('isInBackground : ', isInBackground);
    let colourDataStr = getFromStorage(`${LS_KEYS.COLOUR_DETAIL}${colourId}`);

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.COLOURS.COLOURS}/${colourId}`,
        method: 'get',
        headers: {
          Authorization: `${API.BASIC_TOKEN}`,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const getState = result.data.data;

      if (getState) {
        colourDataStr = JSON.stringify(getState);
      } else {
        if (result.data.message && !colourDataStr) {
          !isInBackground &&
            setToast({
              message: result.data.message,
              status: 'danger',
            });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting colour detail : ', e);
    } finally {
      !isInBackground && hideLoading();
    }

    if (colourDataStr) {
      // Save to storage this colour detail
      saveToStorage(`${LS_KEYS.COLOUR_DETAIL}${colourId}`, colourDataStr);
      return parseColourDetail(JSON.parse(colourDataStr));
    }

    return undefined;
  };

  return {
    getColourDetail,
  };
}

export default useAPIColours;
