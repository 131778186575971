import styled from 'styled-components';
import { BODY } from 'styles/helpers/typography';
import { prem } from 'styles/helpers/spacing';
import { BREAKPOINTS } from 'styles/constants/devices';

const InstallationTutoModalWrapper = styled.div`
  width: 100%;

  @media (${BREAKPOINTS.M}) {
    width: ${prem(500)};
  }

  p {
    margin: ${prem(20)} 0 0 ${prem(8)};
  }

  .msg-only {
    text-align: center;
    margin: 0 0 ${prem(20)} 0;
    ${BODY.large};
  }

  ul {
    margin-bottom: ${prem(16)};

    li {
      margin: 0 0 ${prem(4)} ${prem(25)};

      .icon-share {
        width: ${prem(20)};
      }
    }
  }
`;

export { InstallationTutoModalWrapper };
