import { IonButtonWrapper, RegularButtonFlexed } from 'components/common/button/Button.styled';
import {
  SelectBoxOptionsWrapper,
  SelectBoxWrapper,
} from 'components/common/form/selectbox/SelectBox.styled';
import { IonItemWrapper } from 'components/common/form/textfield/Textfield.styled';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { SUBTITLE } from 'styles/helpers/typography';

const AcceptInvitationModalWrapper = styled.div`
  ${AppModalTitle} {
    ${SUBTITLE.small}
  }

  .modal-description {
    margin-bottom: ${prem(30)};
  }

  .confirmation-buttons {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    min-width: ${prem(250)};
    max-width: ${prem(275)};
    position: relative;
    height: ${prem(75)};

    .confirmation-button {
      width: ${prem(120)};
      text-align: center;
      position: absolute;

      ${IonButtonWrapper} {
        ion-button {
          &::part(native) {
            height: ${prem(45)};
          }
        }
      }
    }

    .refuse-button {
      left: ${prem(0)};
    }

    .accept-button {
      top: ${prem(2)};
      right: ${prem(0)};
      border: ${prem(1)} solid white;
      background-color: var(--comcolors-modal-background);
      border-radius: ${prem(25)};
      padding: ${prem(0)} ${prem(10)};

      ${SelectBoxWrapper} {
        height: 100%;

        ${IonItemWrapper} {
          input {
            border: none;
            border-radius: ${prem(30)};
            text-align: center;
            height: ${prem(44)};
          }

          ion-label {
            text-align: center;
          }

          ::part(native) {
            background-color: transparent;
          }

          input.native-input[disabled] {
            color: white;
            opacity: 1 !important;
            font-weight: bolder;
            padding-top: ${prem(7)};
          }

          ${RegularButtonFlexed} {
            bottom: ${prem(10)};
          }
        }
      }

      ${SelectBoxOptionsWrapper} {
        padding-top: 0 !important;

        ${RegularButtonFlexed} {
          color: white;
          text-align: end;
        }
      }
    }
  }
`;

export { AcceptInvitationModalWrapper };
