import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TutoButtonPlayerWrapper } from 'components/tuto-button-player/TutoButtonPlayer.styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import { ButtonInnerWrapper } from 'components/common/button/Button.styled';
import { VideoPlayerContext, VideoPlayerContextProps } from 'contexts/VideoPlayerContext';
import { ITutoVideo, ITutoVideoKey } from 'types/app';
import useAPITutoVideos from 'hooks/api/useAPITutoVideos';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';

export interface TutoButtonPlayerProps {
    tutoKey?: ITutoVideoKey
}

const TutoButtonPlayer: React.FC<TutoButtonPlayerProps> = ({
    tutoKey
}) => {
    const [tuto, setTuto] = useState<ITutoVideo | undefined>();

    const { settings } = useContext(SettingsContext) as SettingsContextProps;
    const { t } = useTranslation('translation');

    const { setVideoUrl } = useContext(VideoPlayerContext) as VideoPlayerContextProps;

    const onPlayVideo = (videoUrl: string): void => {
        setVideoUrl(videoUrl);
    };

    const { getTutoInfos } = useAPITutoVideos();
    const loadTutoInfos = useCallback(async (tutoKey: ITutoVideoKey, lang: string) => {
        if(!lang) return;
        const tutoInfos = await getTutoInfos(lang);
        if (tutoInfos) {
            if (tutoInfos[tutoKey]) {
                setTuto(tutoInfos[tutoKey]);
            }
        }
    }, []);

    useEffect(() => {
        if (tutoKey && settings.lang) {
            loadTutoInfos(tutoKey, settings.lang);
        }
    }, [tutoKey, settings]);

    return (
        <>
            {tuto && tuto.url && <TutoButtonPlayerWrapper className="tuto-button-player">
                <Button fill='outline' color='highlightorange' type='button' opacity={1} small={true} onClick={() => {
                    if (tutoKey) {
                        onPlayVideo(tuto?.url);
                    }
                }}>
                    <ButtonInnerWrapper>
                        {t('common.tutorial')}
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClklEQVR4nO2cvW7UQBDH3fBRI4JESA89bSLvRAfiBajpIqR4JoQiV/IMVLwIvAKQCpB4CwqEjibcrCZaopMOpIv8cWuvvf+fNFKaS7w/j//r7K22KAAAAAAAAAAAAAAywl6Ut1XciYo7V3a/vZChyIILZfqslRPjZ7e6SeZyT9l9g1i6trlU3Nfgqn0nQ7I1k92is0NcoJOpUUwqE7cRfQ7R1Ey00KcWomkB0dRU9KKx6E2/rMtn/Ugqth+IFog2dDSiwxAdgoy2oSc8TIYC0Xi9WwOvdzKR6EBR55sE0dLP0wDRMnHRyu5CxZ3Z8WzXeH8n/KxMP8cYU6mLnv//94Nwz+6ditOh5U1GtL06uL/pOqwqH3l2H7ITHeMmFTVYVuVM2X1PRdZkRQfs6PENFTpSph8QHVH0Cjt9escLvVWmJTo6Zq5Fzu8i9+joK7+LGmQpetv5XdQgW9HbzO+iBtmLXmEn9NALvYdoidPR28jvJDo6RhWRucpvd6ZCf5Iby5RErwiykxvLFEXb6/JucmOZpGje30luLFMUrezmyY1lcpMhu3mstZEoort81g8gerSvd2MRbR0WnLqMMRvRhn/BKapoLCr10NFLLJPGjQ7Dwn/cjDZ8lRV3MjR8ORtf9DK37QYxyo5nu5uuI9sNNDFKsSWsN9EXQfbfTY4vn9zDJscEHk2feCUdHX5CBdEyMtE5PA1FZD8QLRBt6GhEhyE6BBltQ094mAwFovF6twZe72Rk0YHj2KjxDVJxv1qIxgGDvpcDBisnKUxMfkSlclg1Fh0ONA0Hmw598X4kpUxf7M3zm41FX8ku9yCb6kk+PXzQSvI/nc3EIX8wQdJaTNBC2X0McdG6kwEAAAAAAAAAAACKcXIJgl/PIbOP+PoAAAAASUVORK5CYII=" />
                    </ButtonInnerWrapper>
                </Button>
            </TutoButtonPlayerWrapper>}
        </>
    );
};

export default TutoButtonPlayer;
