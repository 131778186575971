import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const PrivilegedCompanyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${prem(18)};
`;

export { PrivilegedCompanyModalWrapper };
