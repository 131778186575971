import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';

const InstallAppTutoWrapper = styled.div`
  ${GenericDiv} {
    padding-bottom: ${prem(100)};
  }
`;

export { InstallAppTutoWrapper };
