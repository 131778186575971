import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { HEADLINE } from 'styles/helpers/typography';

const TutoComputerWrapper = styled.div`
  overflow: hidden;
  padding-bottom: ${prem(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .description {
    margin-bottom: ${prem(25)};

    p {
      ${HEADLINE.small}
      margin-bottom: 0;
      color: var(--ion-text-color);
      font-weight: normal;
      text-align: center;
    }
  }

  .qr-code-wrapper {
    max-width: ${prem(150)};
    width: 100%;
    height: auto;
    margin: 0 auto ${prem(5)} auto;
  }

  .qr-code {
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  img {
    max-width: ${prem(600)};
  }
`;

export { TutoComputerWrapper };
