import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const AddTestimonialWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  max-height: 100vh;

  .add-testimonial-title {
    margin: ${prem(20)} 0 ${prem(10)};
  }

  .back-button-testimonial {
    padding-top: ${prem(20)};
  }

  .testimonial-inputs-fields {
    max-width: 75%;
    display: block;
    margin: 0 auto ${prem(20)};
  }

  .send-message-container {
    display: block;
    max-width: ${prem(600)};
    margin: 0 auto;
  }

  .testimonial-submit-button {
    max-width: ${prem(196)};
    display: block;
    margin: 0 auto;

    ion-button {
      height: ${prem(50)};
    }
  }
`;

export { AddTestimonialWrapper };
