import React from 'react';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import { InstallationTutoModalWrapper } from 'components/modal/installation/InstallationTutoModal.styled';

export interface InstallationTutoModalProps {
  title?: string;
  steps?: Array<string>;
  message?: string;
}

const InstallationTutoModal: React.FC<InstallationTutoModalProps> = ({ title, steps, message }) => {
  return (
    <InstallationTutoModalWrapper>
      {title && <AppModalTitle>{title}</AppModalTitle>}
      {!!steps?.length && (
        <ul>
          {steps.map((step, index) => {
            return (
              <li
                key={`step-${index}`}
                dangerouslySetInnerHTML={{
                  __html: step,
                }}
              />
            );
          })}
        </ul>
      )}
      {message && (
        <p
          className={!title ? 'msg-only' : ''}
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      )}
    </InstallationTutoModalWrapper>
  );
};

export default InstallationTutoModal;
