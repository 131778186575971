import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { SUBTITLE } from 'styles/helpers/typography';

const GuestScreenWrapper = styled.div`
  height: 100%;
  max-width: ${prem(900)};
  margin: 0 auto;
  text-align: center;
  color: var(--ion-text-color-secondary);

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 11vh;
  }

  .title {
    ${SUBTITLE.medium}
    font-weight: bolder;
    margin-bottom: ${prem(15)};
  }

  .description {
    ${SUBTITLE.small}
    font-weight: normal;
  }

  .cta-label {
    ${SUBTITLE.small}
  }
`;

export { GuestScreenWrapper };
