import styled from 'styled-components';
import { IntroContainer } from 'pages/intro/Intro.styled';
import { prem } from 'styles/helpers/spacing';
import { IonInputWrapper } from 'components/common/form/textfield/Textfield.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { formWrapper } from 'components/common/form/Form.styled';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const LoginContainer = styled(IntroContainer)`
  ${formWrapper};

  ${IonInputWrapper} {
    margin-bottom: ${prem(25)};

    @media (${MQ_MAX_HEIGHT}) {
      margin-bottom: ${prem(8)};
    }
  }

  ${IonButtonWrapper} {
    margin: ${prem(77)} auto ${prem(24)};
    width: 50%;
    min-width: ${prem(190)};
    max-width: ${prem(250)};

    @media (${MQ_MAX_HEIGHT}) {
      margin: ${prem(24)} auto;
    }
  }
`;

export { LoginContainer };
