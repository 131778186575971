import React from 'react';
import { ChipWrapper } from 'components/common/chip/Chip.styled';

export interface ChipProps {
  color?: string;
}

const Chip: React.FC<ChipProps> = ({ color }: ChipProps) => {
  return <ChipWrapper color={color} className="chip-wrapper" />;
};

export default Chip;
