import styled from 'styled-components';
import { formWrapper } from 'components/common/form/Form.styled';
import { prem } from 'styles/helpers/spacing';
import { DefaultLink } from 'styles/helpers/utils';
import { IonInputStyled } from 'components/common/form/textfield/Textfield.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';

const AddRelationFormWrapper = styled.div`
  ${formWrapper};
  margin: 0 auto;
  color: var(--ion-text-color-secondary);
  padding-bottom: 100px;

  ${DefaultLink} {
    display: block;
    margin: ${prem(8)} 0;
  }

  ${IonInputStyled} {
    color: var(--ion-text-color-secondary);
  }

  form {
    ion-item {
      ion-label {
        span:first-child {
          color: var(--ion-text-color-secondary);
        }
      }
    }

    ${IonButtonWrapper} {
      margin-bottom: ${prem(16)};
    }
  }
`;

const QuestionWrapper = styled.div`
  .question-icon {
    position: relative;
    transition: all 0.1s linear;

    &:hover {
      cursor: pointer;
    }

    &:active {
      opacity: 0.5;
    }

    svg {
      position: absolute;
      width: ${prem(20)};
      bottom: 0;
      left: ${prem(10)};
      color: var(--ion-color-accent);
    }
  }

  .question-note {
    display: none;
    /* stylelint-disable */
    background-color: rgb(var(--ion-color-accent-rgb), 0.2);
    /* stylelint-enable */
    margin: ${prem(10)} auto 0;
    font-style: italic;
    max-width: ${prem(400)};
    padding: ${prem(15)};
    user-select: none;

    &.show {
      display: block;
    }
  }
`;

export { AddRelationFormWrapper, QuestionWrapper };
