import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const ImageCropperWrapper = styled.div`
  padding: ${prem(20)};

  .react-crop {
    display: block;
    max-width: ${prem(300)};
    margin: 0 auto ${prem(20)} auto;
  }
`;

export default ImageCropperWrapper;
