import React, { useContext, useEffect, useState } from 'react';
import { StripeCardNumberElement, StripeCardNumberElementOptions } from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Button from 'components/common/button/Button';
import { CheckoutFormWrapper } from 'components/payment/form/checkout-form/CheckoutFormWrapper';
import useAPIPayment from 'hooks/api/useAPIPayment';
import { IPayableTool, IPayableTools, IPaymentDatas, IPriceUnit } from 'types/app';
import useFetch from 'hooks/api/useFetch';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { PageTitle } from 'layout/page/Page.layout.styled';
import { GenericDiv } from 'styles/helpers/utils';
import { formatPrice, getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconSuccess } from 'assets/svg/icon-sucess.svg';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';

export interface CheckoutFormProps {
  tools: IPayableTools;
  currency?: IPriceUnit;
  onSuccess?: () => void;
}

export interface ICardField {
  code: string;
  complete: boolean;
  empty: boolean;
  error: string | undefined;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ tools, currency = 'eur', onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useContext(UserContext) as UserContextProps;
  const { i18n, t } = useTranslation('translation');
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const [errorMessage, setErrorMessage] = useState(null);
  const { initPayment, confirmPayment } = useAPIPayment();
  const { showLoading, hideLoading } = useFetch<any>();
  const [totalPriceTTC, setTotalPriceTTC] = useState(0);
  const [cardNumberState, setCardNumberState] = useState<ICardField>();
  const [cvcState, setCvcState] = useState<ICardField>();
  const [expirationState, setExpirationState] = useState<ICardField>();
  const [disabledPaymentButton, setDisabledPaymentButton] = useState(true);

  const getTotalPriceTTC = () => {
    if (tools) {
      return tools.reduce((total, tools) => {
        return (total += tools.price_ttc);
      }, 0);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (tools) {
      setTotalPriceTTC(getTotalPriceTTC());
    }
  }, [tools]);

  const checkIfCanPay = (): boolean => {
    const canPay = (cardNumberState?.complete &&
      cvcState?.complete &&
      expirationState?.complete) as boolean;

    if (canPay) setDisabledPaymentButton(false);
    else setDisabledPaymentButton(true);
    return canPay;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const canPay = checkIfCanPay();
    if (!canPay) return;
    setDisabledPaymentButton(true);
    showLoading();

    try {
      if (!stripe) {
        console.error('stripe is not defined');
        hideLoading();
        return setToast({
          message: t('message.error.paymenterror'),
          status: 'danger',
        });
      }

      if (!elements) {
        console.error('elements is not defined');
        hideLoading();
        return setToast({
          message: t('message.error.paymenterror'),
          status: 'danger',
        });
      }

      const concatenatedTitles: string = tools.reduce(
        (accumulator: string, currentTool: IPayableTool) => {
          return `${accumulator} + ${getLocaleValue(currentTool.title, i18n.language)}`;
        },
        '',
      );

      const { clientSecret } = (await initPayment(
        totalPriceTTC,
        currency,
        concatenatedTitles,
        // `${getLocaleValue(tools[0].title, i18n.language)}`,
      )) as IPaymentDatas;

      if (clientSecret == null) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement) as StripeCardNumberElement;

      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message as any);
        hideLoading();
        return;
      }

      const additionalData = {
        name: `${user?.firstname} ${user?.lastname}`,
        email: user?.email,
      };

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: additionalData,
        },
      });

      if (error) {
        hideLoading();
        console.log(error);
        if (error.type === 'card_error') {
          setCardNumberState({
            code: error.code as string,
            complete: false,
            empty: false,
            error: error.message,
          });
        }
        return setToast({
          message: error.message || t('message.error.paymenterror'),
          status: 'danger',
        });
      } else {
        if (paymentIntent?.status === 'succeeded') {
          setToast({
            message: t('payment.paymentinitsuccess'),
            status: 'success',
          });
        } else if (paymentIntent?.status === 'canceled') {
          setToast({
            message: t('message.error.paymentinitfailed'),
            status: 'danger',
          });
        }
        const paymentConfirmed = await confirmPayment(
          `${paymentIntent?.id}`,
          `${paymentIntent?.status}`,
          tools.map((tool) => tool.code),
        );
        if (paymentConfirmed) {
          // console.log('paiement confirmed');
          // updateModalConf({
          //   component: <>Paiement effectué avec succès</>,
          // });
          onSuccess && onSuccess();
        } else {
          updateModalConf();
          return setToast({
            message: t('message.error.paymenterror'),
            status: 'danger',
          });
        }
      }
      hideLoading();
    } catch (err) {
      console.log(err);
      hideLoading();
      return setToast({
        message: t('message.error.paymenterror'),
        status: 'danger',
      });
    } finally {
      hideLoading();
      checkIfCanPay();
    }
  };

  const getCardElementOptions = (
    placeholder: string,
    className: string,
    options?: StripeCardNumberElementOptions,
  ) => {
    return {
      className: className,
      options: {
        style: {
          base: {
            color: 'white',
            '::placeholder': {
              color: '#FFFFFF70',
              fontSize: '18px',
            },
            fontSize: '18px',
          },
        },
        placeholder: placeholder,
        ...options,
      } as StripeCardNumberElementOptions,
    };
  };

  const getFieldInfosByEvent = (e: any): ICardField => {
    return {
      code: e.elementType,
      complete: e.complete,
      empty: e.empty,
      error: e.error?.message || undefined,
    };
  };

  useEffect(() => {
    checkIfCanPay();
  }, [cardNumberState, cvcState, expirationState]);

  return (
    <CheckoutFormWrapper onSubmit={handleSubmit}>
      <div className="orders checkout-section">
        <PageTitle>
          {t('payment.yourorder')}
          <span className="status success">
            <IconSuccess />
          </span>
        </PageTitle>
        <div className="orders-content">
          {tools &&
            tools.map((tool, key) => {
              return (
                <div className="order space-between" key={key}>
                  <div
                    className="order-title"
                    dangerouslySetInnerHTML={{
                      __html: getLocaleValue(tool.title, i18n.language),
                    }}
                  />
                  <div className="order-price">
                    {formatPrice(tool.price_ttc, tool.currency, i18n.language)}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="orders-total space-between">
          <div className="total-label">{t('payment.totaltopay')}</div>
          <div className="total-price"> {formatPrice(totalPriceTTC, currency, i18n.language)}</div>
        </div>
      </div>
      <div className="card-details checkout-section">
        <PageTitle>
          {t('payment.yourpayment')}
          {false && (
            <span className="status success">
              <IconSuccess />
            </span>
          )}
        </PageTitle>
        {stripe && elements ? (
          <>
            <div className="paiement-elements">
              <div className="element">
                <div className="label-n-status">
                  <label>{t('payment.bankcardnumber')}</label>
                  {cardNumberState && cardNumberState.complete && (
                    <span className="status success">
                      <IconSuccess />
                    </span>
                  )}
                </div>
                <CardNumberElement
                  onChange={(e) => {
                    setCardNumberState(getFieldInfosByEvent(e));
                  }}
                  {...getCardElementOptions('xxxx xxxx xxxx xxxx', 'card-number-element')}
                />
                {cardNumberState && (
                  <>
                    {cardNumberState.error && <div className="error">{cardNumberState.error}</div>}
                    {cardNumberState.empty && (
                      <div className="error">{t('message.error.required')}</div>
                    )}
                  </>
                )}
              </div>
              <div className="expiration-and-cvc">
                <div className="element">
                  <div className="label-n-status">
                    <label>{t('payment.cvccvv')}</label>
                    {cvcState && cvcState.complete && (
                      <span className="status success">
                        <IconSuccess />
                      </span>
                    )}
                  </div>
                  <CardCvcElement
                    onChange={(e) => setCvcState(getFieldInfosByEvent(e))}
                    {...getCardElementOptions('xxx', 'card-cvc-element')}
                  />
                  {cvcState && (
                    <>
                      {cvcState.error && <div className="error">{cvcState.error}</div>}
                      {cvcState.empty && <div className="error">{t('message.error.required')}</div>}
                    </>
                  )}
                </div>
                <div className="element">
                  <div className="label-n-status">
                    <label>{t('payment.expiration')}</label>
                    {expirationState && expirationState.complete && (
                      <span className="status success">
                        <IconSuccess />
                      </span>
                    )}
                  </div>
                  <CardExpiryElement
                    onChange={(e) => setExpirationState(getFieldInfosByEvent(e))}
                    {...getCardElementOptions('MM/YY', 'card-expiry-element')}
                  />
                  {expirationState && (
                    <>
                      {expirationState.error && (
                        <div className="error">{expirationState.error}</div>
                      )}
                      {expirationState.empty && (
                        <div className="error">{t('message.error.required')}</div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <GenericDiv minWidth="" maxWidth="500px" center>
                <Button type="submit" fill="solid" color="dark" disabled={disabledPaymentButton}>
                  {t('payment.pay')} {formatPrice(totalPriceTTC, currency, i18n.language)}
                </Button>
              </GenericDiv>
            </div>
          </>
        ) : (
          <div className="error">{t('message.error.cannotdisplaypaymentform')}</div>
        )}
      </div>
      {errorMessage && <div className="error">{errorMessage}</div>}
    </CheckoutFormWrapper>
  );
};

export default CheckoutForm;
