import React from 'react';
import { IComponentWithChildren } from 'types/app';
import { ShowHideProvider } from 'contexts/ShowHideContext';
import ShowHideComp from 'components/common/showhide/ShowHideComp';

export interface ShowHideProps extends IComponentWithChildren {
  opened?: boolean;
}

const ShowHide: React.FC<ShowHideProps> = ({ children, opened }) => (
  <ShowHideProvider>
    <ShowHideComp opened={opened}>{children}</ShowHideComp>
  </ShowHideProvider>
);

export default ShowHide;
