import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const MyProfileWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: ${prem(150)};

  .share-profile {
    text-align: center;
    color: var(--ion-text-color-secondary);
    margin-bottom: ${prem(25)};
  }

  .share-profile-title {
    margin-bottom: ${prem(25)};
    text-align: center;
  }
`;

export { MyProfileWrapper };
