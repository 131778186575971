import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';
import { SvgWrapper } from 'styles/helpers/utils';

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  color: white;
  gap: ${prem(16)};

  ${SvgWrapper} {
    width: ${prem(40)};
    height: ${prem(40)};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: auto;
    }
  }
`;

const AppUpdateContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: var(--comcolors-modal-background);
  border-radius: ${prem(20)};
  padding: ${prem(25)};
  min-width: ${prem(300)};

  p {
    ${BODY.medium};
    margin-bottom: ${prem(16)};
    color: white;
  }
`;

const AppUpdaterWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 30000;
  background: rgba(0 0 0 / 0.5);

  &.open {
    pointer-events: auto;
    display: block;
  }

  &.close {
    display: none;
  }
`;

export { ButtonInner, AppUpdateContainer, AppUpdaterWrapper };
