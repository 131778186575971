import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'layout/page/Page.layout.styled';
import {
  NotificationsListSelectFilterWrapper,
  NotificationsListWrapper,
} from 'pages/notifications/Notifications.styled';
import { useTranslation } from 'react-i18next';
import { Form, IAdvice } from 'types/app';
import NotificationAdvice from 'components/advice/NotificationAdvice';
import SelectBox, { SelectBoxProps } from 'components/common/form/selectbox/SelectBox';
import useFormHandler from 'hooks/useFormHandler';
import { AdviceType } from 'hooks/api/useAPIAdvices';
import { InvitationType } from 'hooks/api/useAPIRelations';
import NotificationRelationInvitation from 'components/relations/relation/notifications/invitation/NotificationRelationInvitation';

export interface NotificationsListProps {
  notifications: Array<IAdvice>;
  onLikeAdvice?: (advice: IAdvice, state: boolean) => void;
}

const filtersType = [
  [
    AdviceType.MOTIVATION,
    AdviceType.RELATION,
    InvitationType.NEW_INVITATION,
    InvitationType.ACCEPTED_INVITATION,
    InvitationType.REFUSED_INVITATION,
  ],
  [AdviceType.RELATION],
  [AdviceType.MOTIVATION],
  [
    InvitationType.NEW_INVITATION,
    InvitationType.ACCEPTED_INVITATION,
    InvitationType.REFUSED_INVITATION,
  ],
];

const NotificationsList: React.FC<NotificationsListProps> = ({ notifications, onLikeAdvice }) => {
  const { t } = useTranslation('translation');
  const [notifsState, setNotifsState] = useState(notifications);
  const [filterId, setFilterId] = useState(0);

  const filterOptions = useMemo(() => {
    return [
      {
        id: 0,
        label: t('common.filterby'),
      },
      {
        id: 1,
        label: 'Relation',
      },
      {
        id: 2,
        label: 'Motivation',
      },
      {
        id: 3,
        label: 'Invitation',
      },
    ];
  }, []);
  const { fields, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'filter',
      options: filterOptions,
    },
  ]);
  const onFilter = (value?: Form.IOption) => {
    value && setFilterId(value.id as number);
    updateFieldValue('filter', value);
  };
  const updateNotifsByFilter = (filters: Array<AdviceType | InvitationType>) => {
    setNotifsState(
      notifications.filter((n) => {
        return filters.includes(n.type as AdviceType);
      }),
    );
  };

  useEffect(() => {
    updateNotifsByFilter(filtersType[filterId]);
  }, [filterId, notifications]);

  const notifIsMotivationOrRelationType = (notificationType: string): boolean => {
    return notificationType === AdviceType.MOTIVATION || notificationType === AdviceType.RELATION;
  };

  const notifIsInvitationType = (notificationType: string, actionCompleted: boolean): boolean => {
    return notificationType === InvitationType.NEW_INVITATION && !actionCompleted;
  };

  const notifIsInvitationAcceptedType = (notificationType: string): boolean => {
    return notificationType === InvitationType.ACCEPTED_INVITATION;
  };

  const notifIsInvitationRefusedType = (notificationType: string): boolean => {
    return notificationType === InvitationType.REFUSED_INVITATION;
  };

  return (
    <NotificationsListWrapper>
      <Container>
        <NotificationsListSelectFilterWrapper className="select-box-wrapper">
          <div className="inner">
            <SelectBox
              {...({
                ...getFieldByName('filter', fields),
                label: '',
                onChange: (value?) => onFilter(value),
                isNative: true,
                required: true,
              } as SelectBoxProps)}
            />
          </div>
        </NotificationsListSelectFilterWrapper>
        <div className="advices-list">
          {notifsState.map((notif, index) => {
            return (
              <React.Fragment key={index}>
                {notifIsMotivationOrRelationType(notif.type as string) && (
                  <NotificationAdvice
                    key={`advice-index-${index}`}
                    advice={notif}
                    onLikeAdvice={(state: boolean) => {
                      onLikeAdvice && onLikeAdvice(notif, state);
                    }}
                  />
                )}
                {notifIsInvitationType(notif.type as string, notif.actionCompleted as boolean) && (
                  <NotificationRelationInvitation notif={notif} />
                )}
                {notifIsInvitationAcceptedType(notif.type as string) && (
                  <NotificationRelationInvitation notif={notif} />
                )}

                {notifIsInvitationRefusedType(notif.type as string) && (
                  <NotificationRelationInvitation notif={notif} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </NotificationsListWrapper>
  );
};

export default NotificationsList;
