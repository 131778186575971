import React from 'react';
import { RegisterFormWrapper } from 'components/forms/register-form/RegisterForm.styled';
import { FORM } from 'constants/form';
import { TextFieldTypes } from '@ionic/core';
import { FormButtonSubmitter, FormFieldsGroup } from 'components/common/form/Form.styled';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import SelectBox, { SelectBoxProps } from 'components/common/form/selectbox/SelectBox';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import useFormHandler from 'hooks/useFormHandler';
import Checkbox, { CheckboxProps } from 'components/common/form/checkbox/Checkbox';
import { Form } from 'types/app';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import { useSwiper } from 'swiper/react';

const RegisterForm: React.FC = () => {
  const { t } = useTranslation('translation');
  const { register } = useAPIAuth();

  const {
    fields,
    isFormValid,
    getFieldByName,
    updateFieldValue,
    updateFieldErrorMessage,
    resetErrors,
  } = useFormHandler([
    {
      name: 'name',
      value: '',
      required: true,
    },
    {
      name: 'firstname',
      value: '',
      required: true,
    },
    {
      name: 'pronoun',
      required: true,
      options: [
        {
          id: 1,
          label: t('common.form.pronoun.him'),
          value: 'Monsieur',
        },
        {
          id: 2,
          label: t('common.form.pronoun.her'),
          value: 'Madame',
        },
      ],
    },
    {
      name: 'email',
      value: '',
      type: FORM.TYPES.EMAIL as TextFieldTypes,
      required: true,
    },
    {
      name: 'password',
      value: '',
      type: FORM.TYPES.PASSWORD as TextFieldTypes,
      required: true,
    },
    {
      name: 'acceptCGU',
      value: false,
      type: FORM.TYPES.CHECKBOX as Form.CheckboxType,
      required: true,
    },
  ]);

  type RegisterPayload = {
    name: string;
    firstname: string;
    pronoun: string;
    email: string;
    password: string;
    acceptCgu: number;
  };

  const formatPayload = (objectToFormat: any): RegisterPayload => {
    return {
      name: getFieldByName('name', objectToFormat).value as string,
      firstname: getFieldByName('firstname', objectToFormat).value as string,
      pronoun: (getFieldByName('pronoun', objectToFormat).value as Form.IOption).value as string,
      email: getFieldByName('email', objectToFormat).value as string,
      password: getFieldByName('password', objectToFormat).value as string,
      acceptCgu: getFieldByName('acceptCGU', objectToFormat).value === true ? 1 : 0,
    };
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    resetErrors();
    if (
      isFormValid({
        checkPassword: true,
      })
    ) {
      const payload = formatPayload(fields);
      const result = await register(
        payload.name,
        payload.firstname,
        payload.pronoun,
        payload.email,
        payload.password,
        payload.acceptCgu,
      );

      if (result && result == 400) {
        updateFieldErrorMessage('email', t('message.error.email-exist') || '');
      }
    }
  };
  const swiper = useSwiper();

  return (
    <RegisterFormWrapper>
      <AppModalTitle>{t('register.registertocomcolors')}</AppModalTitle>
      <form noValidate onSubmit={onSubmit} autoComplete="off">
        <FormFieldsGroup>
          <Textfield
            {...({
              ...getFieldByName('firstname', fields),
              label: t('common.firstname') || '',
              onChange: (value?) => updateFieldValue('firstname', value),
            } as TextfieldProps)}
          />
          <Textfield
            {...({
              ...getFieldByName('name', fields),
              label: t('common.name') || '',
              onChange: (value?) => updateFieldValue('name', value),
            } as TextfieldProps)}
          />
          <SelectBox
            {...({
              ...getFieldByName('pronoun', fields),
              label: t('common.pronoun') || '',
              onChange: (value?) => updateFieldValue('pronoun', value),
            } as SelectBoxProps)}
          />
          <Textfield
            {...({
              ...getFieldByName('email', fields),
              label: t('common.email') || '',
              onChange: (value?) => updateFieldValue('email', value),
            } as TextfieldProps)}
          />
          <Textfield
            {...({
              ...getFieldByName('password', fields),
              label: t('common.password') || '',
              onChange: (value?) => updateFieldValue('password', value),
            } as TextfieldProps)}
          />
          <Checkbox
            className="accept-cgu"
            {...({
              ...getFieldByName('acceptCGU', fields),
              label: `${t('register.iacceptthe')}`,
              onChange: (value) => {
                updateFieldValue('acceptCGU', value);
              },
            } as CheckboxProps)}
          />
        </FormFieldsGroup>
        <Button color="white" fill="outline" expand={false} type="submit">
          {t('register.iregister')}
        </Button>
        <small
          className="have-account"
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          <u>{t('intro.login.haveaccount')}</u>
        </small>
        <FormButtonSubmitter type="submit" />
      </form>
    </RegisterFormWrapper>
  );
};

export default RegisterForm;
