import { IonFab, IonFabButton } from '@ionic/react';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const FabButtonIconWrapper = styled.div`
  width: ${prem(40)};
  height: ${prem(40)};
`;

const FabButton = styled(IonFab)`
  bottom: ${prem(10)};
  right: calc(${prem(20)} + var(--ion-safe-area-right, 0px));
  position: fixed;

  svg {
    width: 100%;
    height: auto;
    transform: translate(${prem(2)}, ${prem(-2)});
  }
`;

const FabInnerButton = styled(IonFabButton)`
  width: ${prem(73)};
  height: ${prem(73)};
`;

export { FabButtonIconWrapper, FabButton, FabInnerButton };
