import {
  LanguageSelectorButton,
  LanguageSelectorInner,
  LanguageSelectorWrapper,
} from 'components/header/languageselector/Language.selector.styled';
import React, { useContext } from 'react';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';

const LanguageSelector: React.FC = (): React.ReactElement => {
  const { settings, changeLanguage } = useContext(SettingsContext) as SettingsContextProps;
  const switchLang = () => {
    changeLanguage(settings.lang.includes('fr') ? 'en' : 'fr');
  };

  return (
    <LanguageSelectorWrapper>
      <LanguageSelectorButton onClick={switchLang}>
        <LanguageSelectorInner lng={settings.lang} />
      </LanguageSelectorButton>
    </LanguageSelectorWrapper>
  );
};

export default LanguageSelector;
