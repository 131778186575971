import React, { useContext } from 'react';
import { IModule } from 'types/app';
import { SingleModuleWrapper } from 'components/resources/module/single-module/SingleModule.styled';
import { removeAttributes } from 'helpers/utils';
import BuyModuleButton from 'components/resources/module/single-module/buy-module/buy-module-button/BuyModuleButton';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { GenericDiv } from 'styles/helpers/utils';
import { MODULES_CODES } from 'constants/global';
import AccessModule from 'components/resources/module/single-module/access-module/AccessModule';
import { BuyToolContext, BuyToolContextProps } from 'contexts/BuyToolContext';
import useIonNavContext from 'hooks/useIonNavContext';
import { ROUTES } from 'constants/routes';
import {
  disableBuyModuleButton,
  hideBuyModuleButton,
  showBuyElearningButton,
} from 'helpers/modules';
import Button from 'components/common/button/Button';
import { VideoPlayerContext, VideoPlayerContextProps } from 'contexts/VideoPlayerContext';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';

export interface SingleModuleProps {
  module: IModule;
}
const SingleModule: React.FC<SingleModuleProps> = ({ module }) => {
  const { user } = useContext(UserContext) as UserContextProps;
  const { addToolToShoppingList, formatModuleToPayableTool } = useContext(
    BuyToolContext,
  ) as BuyToolContextProps;
  const { gotoUrl } = useIonNavContext();
  const { setVideoUrl } = useContext(VideoPlayerContext) as VideoPlayerContextProps;
  const onPlayVideo = (videoUrl: string) => {
    setVideoUrl(videoUrl);
  };
  const {
    icons: [video],
  } = useIcons([ICON_NAMES.VIDEO]);

  return (
    <SingleModuleWrapper>
      <div
        className="single-module-description"
        dangerouslySetInnerHTML={{ __html: removeAttributes(module.description) }}
      />
      {user ? (
        <>
          {!module.hasAccess && (
            <GenericDiv minWidth={'7rem'} maxWidth={'21rem'} center>
              <>
                {module.code !== MODULES_CODES.ELEARNING && (
                  <>
                    {!hideBuyModuleButton(user) && module.price_ttc > 0 && (
                      <BuyModuleButton
                        module={module}
                        hasAccessToModule={module.hasAccess}
                        disable={disableBuyModuleButton(user)}
                        onClick={() => {
                          const formatedModule = formatModuleToPayableTool(module);
                          if (formatedModule) {
                            addToolToShoppingList(formatedModule);
                            gotoUrl(ROUTES.SHOP);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {module.code === MODULES_CODES.ELEARNING && (
                  <>
                    {showBuyElearningButton(user) && (
                      <BuyModuleButton
                        module={module}
                        hasAccessToModule={module.hasAccess}
                        onClick={() => {
                          const formatedModule = formatModuleToPayableTool(module);
                          if (formatedModule) {
                            addToolToShoppingList(formatedModule);
                            gotoUrl(ROUTES.SHOP);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </>
            </GenericDiv>
          )}
          {module.hasAccess && (
            <>
              <GenericDiv minWidth={'7rem'} maxWidth={'21rem'} center>
                <AccessModule module={module} />
              </GenericDiv>
            </>
          )}
          {module.videoCta && module.videoUrl && (
            <>
              <GenericDiv minWidth={'7rem'} maxWidth={'21rem'} center>
                <Button
                  onClick={() => onPlayVideo(module.videoUrl as string)}
                  fill="solid"
                  color="accent"
                >
                  <div className="video">{video.icon}</div> {module.videoCta}
                </Button>
              </GenericDiv>
            </>
          )}
        </>
      ) : (
        <>{/** No user */}</>
      )}
    </SingleModuleWrapper>
  );
};

export default SingleModule;

{
  /* {user?.profileInfo?.hasValidatedProfile && (
    <Button
      color="accent"
      fill="solid"
      type="submit"
      expand={true}
      disabled={!modulePrice || modulePrice <= 0}
      onClick={() => {
        if (!modulePrice || modulePrice <= 0) return;
        updatePaymentConfs({
          newTools: [
            {
              code: module.paymentCode,
              currency: module.currency,
              payment_type: 'card',
              price_ttc: modulePrice,
              title: {
                fr: `${module.title}`,
                en: `${module.title}`,
              },
            },
          ],
        });
      }}
    >
      {`💳 ${t('resources.modules.buythemodule')}`}
      {module.price_ttc > 0 && <>{` ${t('common.for')} ${formatedModulePrice}`}</>}
    </Button>
  )} */
}
