import React, { useEffect, useState } from 'react';
import { IconProfileWrapper } from 'components/common/icons/icon-profile/IconProfile.styled';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { getColorCodeById } from 'helpers/colors';
import { IBadge, IRelation } from 'types/app';
import { BADGE } from 'constants/global';

export interface IconProfileProps
  extends Pick<IRelation, 'primaryColorId' | 'secondaryColorId' | 'unspecifiedColor'> {
  id?: any;
  primaryColor?: string;
  secondaryColor?: string;
  iconProfile?: string;
  relationId?: number;
}

const IconProfile: React.FC<IconProfileProps> = ({
  primaryColor,
  secondaryColor,
  primaryColorId,
  secondaryColorId,
  unspecifiedColor,
  iconProfile,
}) => {
  const {
    icons: [
      profileSvg,
      unknownProfile,
      iconBadgeChecked,
      iconBadgeWarning,
      iconBadgeQuestion,
      iconProfileUnspecified,
    ],
  } = useIcons([
    ICON_NAMES.PROFILE_SVG,
    ICON_NAMES.UNKNOWN_PROFILE,
    ICON_NAMES.PROFILE_BADGE_CHECKED,
    ICON_NAMES.PROFILE_BADGE_WARNING,
    ICON_NAMES.PROFILE_BADGE_QUESTION,
    ICON_NAMES.PROFILE_UNSPECIFIED,
  ]);

  // const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const [badgeState, setBadgeState] = useState<string>();

  const badgeIcons: IBadge = {
    valid: {
      icon: iconBadgeChecked.icon,
      name: BADGE.VALID,
    },
    exclamation: {
      icon: iconBadgeWarning.icon,
      name: BADGE.WARNING,
    },
    interrogation: {
      icon: iconBadgeQuestion.icon,
      name: BADGE.QUESTION,
    },
    none: {
      icon: '',
      name: BADGE.NONE,
    },
  };

  useEffect(() => {
    setBadgeState(iconProfile);
  }, [iconProfile]);

  return (
    <IconProfileWrapper
      primaryColor={primaryColor || getColorCodeById(primaryColorId)}
      secondaryColor={secondaryColor || getColorCodeById(secondaryColorId)}
    >
      {unspecifiedColor && (
        <div className="unspecified profile-icon">{iconProfileUnspecified.icon}</div>
      )}
      {!unspecifiedColor && <div className="profiled profile-icon">{profileSvg.icon}</div>}
      <div className="unknown profile-icon">{unknownProfile.icon}</div>
      {badgeState && <div className="badge">{badgeIcons[badgeState]?.icon}</div>}
    </IconProfileWrapper>
  );
};

export default IconProfile;
