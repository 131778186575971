import { css } from 'styled-components';
import { FONT_SIZES, FONT_WEIGHT } from 'styles/constants/fonts';
import { prem } from 'styles/helpers/spacing';
import { BREAKPOINTS, MQ_MAX_HEIGHT } from 'styles/constants/devices';

// BODY
const bodyBase = css`
  line-height: 1.187;
`;

const bodyExtraSmall = css`
  ${bodyBase};
  line-height: 1.3;
  font-size: ${prem(FONT_SIZES.BODY.MOBILE_XXS)};

  @media (${BREAKPOINTS.SM}) {
    font-size: ${prem(FONT_SIZES.BODY.MOBILE_XS)};
  }

  @media (${BREAKPOINTS.M}) {
    font-size: ${prem(FONT_SIZES.BODY.DESKTOP_XS)};
  }
`;

const bodySmall = css`
  ${bodyBase};
  line-height: 1.3;
  font-size: ${prem(FONT_SIZES.BODY.MOBILE_S)};

  @media (${BREAKPOINTS.M}) {
    font-size: ${prem(FONT_SIZES.BODY.DESKTOP_S)};
  }
`;

const bodyMedium = css`
  ${bodyBase};
  font-size: ${prem(FONT_SIZES.BODY.MOBILE_M)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BODY.DESKTOP_M)};
  }
`;

const bodyMM = css`
  ${bodyBase};
  font-size: ${prem(FONT_SIZES.BODY.MOBILE_MM)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BODY.DESKTOP_M)};
  }
`;

const bodyLarge = css`
  ${bodyBase};
  font-size: ${prem(FONT_SIZES.BODY.MOBILE_L)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BODY.DESKTOP_L)};
  }
`;

// HEADLINE
const headlineBase = css`
  font-weight: ${FONT_WEIGHT.BOLD};
  line-height: 1.187;
`;

const headlineSmall = css`
  ${headlineBase};
  font-size: ${prem(FONT_SIZES.HEADLINE.MOBILE_S)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.HEADLINE.DESKTOP_S)};
  }

  @media (${MQ_MAX_HEIGHT}) {
    font-size: ${prem(FONT_SIZES.HEADLINE.DESKTOP_XS)};
  }
`;

const headlineMedium = css`
  ${headlineBase};
  font-size: ${prem(FONT_SIZES.HEADLINE.MOBILE_M)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.HEADLINE.DESKTOP_S)};
  }
`;

const headlineLarge = css`
  ${headlineBase};
  font-size: ${prem(FONT_SIZES.HEADLINE.MOBILE_L)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.HEADLINE.DESKTOP_L)};
  }
`;

const headlineExtraLarge = css`
  ${headlineBase};
  line-height: 1;
  font-size: ${prem(FONT_SIZES.HEADLINE.DESKTOP_XL)};
`;

// SUBTITLES
const subtitleBase = css`
  font-weight: ${FONT_WEIGHT.BOLD};
  line-height: 1.187;
`;

const subtitleSmall = css`
  ${subtitleBase};
  font-size: ${prem(FONT_SIZES.SUBTITLE.MOBILE_S)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.SUBTITLE.DESKTOP_S)};
  }
`;

const subtitleMedium = css`
  ${subtitleBase};
  font-size: ${prem(FONT_SIZES.SUBTITLE.MOBILE_M)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.SUBTITLE.DESKTOP_M)};
  }
`;

//  BUTTON
const buttonBase = css`
  font-weight: ${FONT_WEIGHT.REGULAR};
  line-height: 1.171;
`;

const buttonSmall = css`
  ${buttonBase};
  font-size: ${prem(FONT_SIZES.BUTTON.DESKTOP_SMALL)};
`;

const buttonMedium = css`
  ${buttonBase};
  font-size: ${prem(FONT_SIZES.BUTTON.MOBILE_MEDIUM)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BUTTON.DESKTOP_BIG)};
  }
`;

const buttonLarge = css`
  ${buttonBase};
  font-size: ${prem(FONT_SIZES.BUTTON.MOBILE_L)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BUTTON.DESKTOP_BIG)};
  }
`;

const buttonMediumSecondary = css`
  ${buttonMedium};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BUTTON.DESKTOP_MEDIUM)};
  }
`;

const buttonTertiary = css`
  ${buttonBase};
  font-size: ${prem(FONT_SIZES.BUTTON.MOBILE_MEDIUM)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BUTTON.DESKTOP_MEDIUM)};
  }
`;

const buttonBig = css`
  ${buttonBase};
  font-size: ${prem(FONT_SIZES.BUTTON.MOBILE_BIG)};

  @media (${BREAKPOINTS.L}) {
    font-size: ${prem(FONT_SIZES.BUTTON.DESKTOP_BIG)};
  }
`;

const BODY = {
  extraSmall: bodyExtraSmall,
  small: bodySmall,
  medium: bodyMedium,
  mediumM: bodyMM,
  large: bodyLarge,
};

const HEADLINE = {
  small: headlineSmall,
  medium: headlineMedium,
  large: headlineLarge,
  extraLarge: headlineExtraLarge,
};

const SUBTITLE = {
  small: subtitleSmall,
  medium: subtitleMedium,
};

const BUTTON = {
  small: buttonSmall,
  medium: buttonMedium,
  mediumSecondary: buttonMediumSecondary,
  tertiary: buttonTertiary,
  big: buttonBig,
  large: buttonLarge,
};

export { BODY, HEADLINE, SUBTITLE, BUTTON };
