import styled, { css } from 'styled-components';
import { ButtonProps } from 'components/common/button/Button';
import { prem } from 'styles/helpers/spacing';
import * as TYPOGRAPHY from 'styles/helpers/typography';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { transparentEl } from 'styles/helpers/utils';

const getVerticalPadding = (isSmall?: boolean): number => {
  if (isSmall) {
    return 7;
  }

  return 10;
};

const IonButtonWrapper = styled.div<Pick<ButtonProps, 'small' | 'opacity'>>`
  ion-button {
    --ripple-color: transparent;
    --box-shadow: unset;
    text-transform: unset;
    height: unset;
    display: flex;
    align-items: center;

    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: ${prem(17)};
      gap: ${prem(8)};
    }

    &::part(native) {
      white-space: normal;
      border-radius: ${prem(30)};
      letter-spacing: normal;
      ${TYPOGRAPHY.BUTTON.medium};
    }

    &.button-strong {
      &::part(native) {
        font-weight: ${FONT_WEIGHT.BOLD};
      }
    }

    &.button-outline {
      &::part(native) {
        color: var(--ion-color-contrast);
      }
    }

    ${({ small, opacity }) => css`
      --background-hover-opacity: ${opacity};

      &::part(native) {
        padding: ${prem(getVerticalPadding(small))} ${prem(16)};
        background: rgba(var(--ion-color-base-rgb), ${opacity});
      }
    `};

    &.ion-activated {
      --ion-color-base: none !important;
    }
  }
`;

const ButtonCloseWrapper = styled.div``;
const ButtonFullScreenWrapper = styled.div``;
const ButtonBackWrapper = styled.div`
  ion-button {
    color: var(--ion-color-accent);
    height: unset;
    --background: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --border-radius: 0;
    --border-color: transparent;
    --border-style: solid;
    --border-width: 0;
    --box-shadow: none;
    --ripple-color: transparent;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;

    .icon {
      color: var(--ion-color-accent);
      margin-right: ${prem(8)};
    }

    span.label {
      font-weight: ${FONT_WEIGHT.REGULAR};
    }
  }
`;

const RegularButton = styled.button<Pick<ButtonProps, 'color'>>`
  ${transparentEl};
  cursor: pointer;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

const ButtonIconWrapper = styled.div`
  width: ${prem(22)};
  height: ${prem(22)};
  flex-shrink: 0;
`;

const ButtonLabel = styled.span`
  &.important {
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`;

const RegularButtonFlexed = styled(RegularButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${prem(15)};
  padding: ${prem(5)} 0;

  ${ButtonIconWrapper} {
    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &:active {
    opacity: 0.5;
  }
`;

const ButtonInnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonTOCWrapper = styled.button`
  border: none;
  background: transparent;
  display: block;
`;

export {
  IonButtonWrapper,
  RegularButton,
  ButtonInnerWrapper,
  ButtonCloseWrapper,
  ButtonFullScreenWrapper,
  RegularButtonFlexed,
  ButtonIconWrapper,
  ButtonLabel,
  ButtonBackWrapper,
  ButtonTOCWrapper
};
