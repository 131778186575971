import { AxiosRequestConfig } from 'axios';
import { API, LANGUAGES } from 'constants/global';
import { IHttpResponse } from 'helpers/api';
import {
  detectLanguage,
  getSectionBaseTexts,
  getTranslations,
  replaceStrings,
} from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/api/useFetch';
import { useContext } from 'react';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { IAudios, IHome, IVideo, IVideos } from 'types/app';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { useRollbar } from '@rollbar/react';

export interface IUseApiHome {
  getHomeDatas: () => Promise<IHome.IDatas | undefined>;
  getSpotifyRssFluxDatas: () => Promise<IAudios | undefined>;
}

function useAPIHome(): IUseApiHome {
  const { t, i18n } = useTranslation('translation');
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { user } = useContext(UserContext) as UserContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { languages } = useContext(SettingsContext) as SettingsContextProps;
  const rollbar = useRollbar();

  const {
    icons: [iconApplePodcast, iconSpotifyPodcast, iconDeezerPodcast],
  } = useIcons([ICON_NAMES.APPLE_PODCAST, ICON_NAMES.SPOTIFY_PODCAST, ICON_NAMES.DEEZER_PODCAST]);

  const formatVideos = (videos: any[]): IVideos => {
    if (!videos || !Array.isArray(videos)) return [];
    return videos.map((video) => {
      const tmpVideo = getTranslations(languages, video.translations, (input) => input.url_video);

      const formatedVideo = {
        ...getSectionBaseTexts(video.translations, languages, video.thumbnail_url),
        videoUrl:
          tmpVideo && (tmpVideo[LANGUAGES.fr] || tmpVideo[LANGUAGES.en])
            ? tmpVideo
            : {
                [LANGUAGES.fr]: video.url_video,
                [LANGUAGES.en]: video.url_video,
              },
      } as IVideo;
      return formatedVideo;
    }) as IVideos;
  };

  const formatArticles = (articles: any[]): IHome.IArticlesPost[] | IHome.ILinkedinPost[] => {
    return articles.map((article) => {
      const formatedArticle = {
        ...getSectionBaseTexts(article.translations, languages, article.image_url),
        postUrl: article.url_site,
      } as IHome.IArticlesPost;
      return formatedArticle;
    });
  };

  const getHomeDatas = async () => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: replaceStrings(API.ROUTES.HOME.INDEX, {
          locale: detectLanguage(i18n.language),
        }),
        method: 'get',
        headers: {
          Authorization: user ? `${user.token?.type} ${user.token?.value}` : API.BASIC_TOKEN,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const data = result?.data?.data;
      if (!data) {
        console.log(result);
        rollbar.error('Cannot charge home datas');
        setToast({
          message: t('message.error.cannotchargehomedatas'),
          status: 'danger',
        });
        return undefined;
      }
      const DATA_NAMES = {
        PODCASTS: 'podcasts',
        ARCTICLE_POSTS: 'post_articles',
        LINKEDIN_POSTS: 'post_linkedins',
        TIKTOK: 'tiktoks',
        VIMEO: 'vimeos',
        YOUTUBE: 'youtubes',
      };

      return {
        youtubeVideos: formatVideos(data[DATA_NAMES.YOUTUBE]) as IVideos,
        articlePosts: formatArticles(data[DATA_NAMES.ARCTICLE_POSTS]) as IHome.IArticlesPost[],
        linkedinPosts: formatArticles(data[DATA_NAMES.LINKEDIN_POSTS]) as IHome.ILinkedinPost[],
        tiktokVideos: formatVideos(data[DATA_NAMES.TIKTOK]) as IVideos,
        vimeoVideos: formatVideos(data[DATA_NAMES.VIMEO]) as IVideos,
        podcastsChannels: {
          apple: {
            icon: iconApplePodcast.icon,
            url: data[DATA_NAMES.PODCASTS].apple || '',
          },
          spotify: {
            icon: iconSpotifyPodcast.icon,
            url: data[DATA_NAMES.PODCASTS].spotify || '',
          },
          deezer: {
            icon: iconDeezerPodcast.icon,
            url: data[DATA_NAMES.PODCASTS].deezer || '',
          },
        },
      } as IHome.IDatas;
    } catch (err) {
      rollbar.error(err as any);
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const formatSpotifyRssFluxDatas = (datas: any): IAudios => {
    return datas.lists.map((podcast: any) => {
      return {
        title: {
          fr: podcast.title,
          en: podcast.title,
        },
        description: {
          fr: podcast.description || podcast.content,
          en: podcast.description || podcast.content,
        },
        imageUrl: podcast.thumbnail,
        audioUrl: podcast.audio_url,
        audioPermalink: podcast.permalink,
      };
    });
  };

  const getSpotifyRssFluxDatas = async () => {
    try {
      const options: AxiosRequestConfig = {
        url: replaceStrings(API.ROUTES.HOME.SPOTIFY_RSS, {
          locale: detectLanguage(i18n.language),
        }),
        method: 'get',
        headers: {
          Authorization: user ? `${user.token?.type} ${user.token?.value}` : API.BASIC_TOKEN,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const podcastDatas = result?.data?.data.podcast_rss;
      if (!podcastDatas) return undefined;
      return formatSpotifyRssFluxDatas(podcastDatas);
    } catch (err) {
      return undefined;
    }
  };

  return {
    getHomeDatas,
    getSpotifyRssFluxDatas,
  };
}

export default useAPIHome;
