import styled from 'styled-components';
import { ResourcesWrapper } from 'pages/resources/Resources.styled';
import { prem } from 'styles/helpers/spacing';

const TrainingWrapper = styled.div`
  ${ResourcesWrapper};

  .trainings-description {
    color: var(--ion-text-color-secondary);
    margin-bottom: ${prem(27)};
  }
`;

export { TrainingWrapper };
