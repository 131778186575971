import Button from 'components/common/button/Button';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericDiv } from 'styles/helpers/utils';
import { ShareProfileModalWrapper } from 'components/profile/has-profile/my-profile/share-profile-modal/ShareProfileModal.styled';

export interface ShareProfileModalProps {
  urlToCopy: string;
}

const ShareProfileModal: React.FC<ShareProfileModalProps> = ({ urlToCopy }) => {
  const { t } = useTranslation('translation');
  const { setToast } = useContext(ToastContext) as ToastContextProps;

  const copyProfileUrl = (urlToCopy: string) => {
    navigator.clipboard.writeText(urlToCopy).then(() => {
      setToast({
        message: t('profile.myprofile.shareyourcolors.copiedtoclipboard'),
        status: 'success',
      });
    });
  };
  return (
    <ShareProfileModalWrapper>
      <input className="link-url" type="text" readOnly value={urlToCopy} />
      <GenericDiv center minWidth="100px" maxWidth="250px">
        <Button fill="solid" color="white" onClick={() => copyProfileUrl(urlToCopy)}>
          {t('profile.myprofile.shareyourcolors.copylink')}
        </Button>
      </GenericDiv>
    </ShareProfileModalWrapper>
  );
};

export default ShareProfileModal;
