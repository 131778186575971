import React, { createContext, FC, useState } from 'react';
import { IComponentWithChildren } from 'types/app';

export type MessageType = 'success' | 'warning' | 'danger';

export interface IToastConf {
  message: string;
  duration?: number;
  status?: MessageType;
}

export interface ToastContextProps {
  toast?: IToastConf;
  setToast: (conf?: IToastConf) => void;
}

const ToastContext = createContext<ToastContextProps | null>(null);
const ToastProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [currentConf, setConf] = useState<IToastConf | undefined>();
  const setToast = (conf?: IToastConf) => {
    setConf(conf);
  };

  return (
    <ToastContext.Provider value={{ setToast, toast: currentConf }}>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
