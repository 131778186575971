import React from 'react';
import { IProduct } from 'types/app';
import { SingleTrainingWrapper } from 'components/resources/training/singletraining/SingleTraining.styled';
import Collapse from 'components/common/showhide/Collapse';
import { useTranslation } from 'react-i18next';
import { Container } from 'layout/page/Page.layout.styled';
import { getLocaleValue, removeAttributes } from 'helpers/utils';
import Button from 'components/common/button/Button';
import ImageRenderer from 'components/common/image/ImageRenderer';

export interface ISingleTraining {
  training: IProduct;
  opened?: boolean;
  onOpened?: (open: boolean) => void;
  className?: string;
}

const SingleTraining: React.FC<ISingleTraining> = ({ training, opened, onOpened, className }) => {
  const { i18n } = useTranslation('translation');
  return (
    <SingleTrainingWrapper className={className}>
      <Collapse
        label={removeAttributes(getLocaleValue(training.title, i18n.language))}
        opened={opened}
        onOpened={onOpened}
      >
        <Container className="single-resource-training-collapse-content">
          {/* <img src={training.imageUrl} className="single-resource-training-image" alt="" /> */}
          <ImageRenderer
            src={training.imageUrl as string}
            alt=""
            showLoader
            className="single-resource-training-image"
          />
          <div
            className="single-resource-training-description"
            dangerouslySetInnerHTML={{
              __html: removeAttributes(getLocaleValue(training.description, i18n.language)),
            }}
          />
          {Boolean(getLocaleValue(training.purchaseTitle, i18n.language)) &&
            training.purchaseUrl && (
              <Button
                href={training.purchaseUrl}
                className="single-resource-training-button"
                fill="outline"
              >
                {removeAttributes(getLocaleValue(training.purchaseTitle, i18n.language))}
              </Button>
            )}
        </Container>
      </Collapse>
    </SingleTrainingWrapper>
  );
};

export default SingleTraining;
