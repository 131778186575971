import styled from 'styled-components';

const InnerPageLayoutWrapper = styled.div`
  &.page-models-wrapper,
  &.page-has-profile-wrapper {
    height: 100%;
    position: relative;

    .ion-page-invisible {
      opacity: 1;
    }
  }
`;

export { InnerPageLayoutWrapper };
