import React, { useContext, useEffect } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { isAppInstalled, POPUP_TUTO_STATE } from 'helpers/utils';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import InstallationTutoModal, {
  InstallationTutoModalProps,
} from 'components/modal/installation/InstallationTutoModal';
import { useTranslation } from 'react-i18next';
import IconShare from 'assets/svg/icon-share.svg';

const MIN_IOS_VERSION = 16.4;

function useCheckInstallation() {
  const { t } = useTranslation('translation');
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);

  const { isIOS, isMobile } = selectors;
  // eslint-disable-next-line no-prototype-builtins
  const canInstallAuto = window.hasOwnProperty('BeforeInstallPromptEvent');
  const openPopupTuto = () => {
    let modalProps: InstallationTutoModalProps = {};
    if (isMobile && isIOS) {
      try {
        const {
          browser: { version },
        } = data;
        const [majorVer, minorVer] = version.split('.');
        const iIOSVersion = parseFloat(`${majorVer}.${minorVer ? minorVer : 0}`);

        console.log('UA info: ', data);
        modalProps = {
          title:
            t('settings.installation.plateform_ios.title', {
              iOSDevice: data.device?.model || '',
            }) || '',
          steps: [
            t('settings.installation.plateform_ios.steps.step0', {
              iconShare: `<img class="icon-share" src="${IconShare}" />`,
            }) || '',
            t('settings.installation.plateform_ios.steps.step1') || '',
          ],
        };

        if (iIOSVersion < MIN_IOS_VERSION) {
          modalProps.message = t('settings.installation.plateform_ios.additionnal_note') || '';
        }
      } catch (e) {
        console.log('error getting version: ');
      }
    } else {
      modalProps = {
        message: t('settings.installation.plateform_others') || '',
      };
    }

    updateModalConf({
      component: <InstallationTutoModal key={Math.random()} {...modalProps} />,
    });
    POPUP_TUTO_STATE.setState(true);
  };

  const initCheck = () => {
    // If app not already installed
    if (!isAppInstalled()) {
      // If tuto is not already shown
      if (!POPUP_TUTO_STATE.getState()) {
        // Browser can't show prompt to install the app
        if (!canInstallAuto) {
          openPopupTuto();
        }
      }
    }
  };

  useEffect(() => {
    initCheck();
  }, []);
}

export default useCheckInstallation;
