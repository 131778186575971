/**
 * Font weights
 */
const FONT_WEIGHT = {
  THIN: 100,
  EXTRALIGHT: 200,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMIBOLD: 600,
  BOLD: 700,
  BLACK: 900,
};
/**
 * Font sizes
 */
const FONT_BASE_SIZE = 16;
const HEADLINE = {
  DESKTOP_XS: 20,
  DESKTOP_S: 32,
  DESKTOP_M: 40,
  DESKTOP_L: 50,
  DESKTOP_XL: 50,
  MOBILE_L: 42,
  MOBILE_M: 32,
  MOBILE_S: 24,
};

const BODY = {
  DESKTOP_XS: 12,
  DESKTOP_S: 14,
  DESKTOP_M: 16,
  DESKTOP_L: 18,
  MOBILE_S: 12,
  MOBILE_XXS: 8,
  MOBILE_XS: 10,
  MOBILE_MM: 14,
  MOBILE_M: 16,
  MOBILE_L: 18,
};

const SUBTITLE = {
  DESKTOP_S: 20,
  DESKTOP_M: 30,
  MOBILE_S: 18,
  MOBILE_M: 20,
};

const BUTTON = {
  DESKTOP_BIG: 18,
  DESKTOP_MEDIUM: 16,
  DESKTOP_SMALL: 16,
  MOBILE_BIG: 16,
  MOBILE_MEDIUM: 16,
  MOBILE_L: 18,
};

export { HEADLINE, SUBTITLE, BODY, BUTTON };

/**
 * Font families
 */
const FONT_FAMILY = { ROBOTO: 'Roboto, sans-serif' };
const FONT_SIZES = {
  HEADLINE,
  SUBTITLE,
  BODY,
  BUTTON,
};

export { FONT_BASE_SIZE, FONT_WEIGHT, FONT_FAMILY, FONT_SIZES };
