import React, { useState } from 'react';
import { LoginFormWrapper } from 'components/forms/login-form/LoginForm.styled';
import { useTranslation } from 'react-i18next';
import { IntroPageWrapper } from 'pages/intro/Intro.styled';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import Button from 'components/common/button/Button';
import { FormButtonSubmitter, FormErrorWrapper } from 'components/common/form/Form.styled';
import useFormHandler from 'hooks/useFormHandler';
import { FORM } from 'constants/form';
import { TextFieldTypes } from '@ionic/core';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import { useSwiper } from 'swiper/react';
import { ROUTES } from 'constants/routes';

const LoginForm: React.FC = () => {
  const { fields, isFormValid, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'email',
      value: '',
      required: true,
      type: FORM.TYPES.EMAIL as TextFieldTypes,
    },
    {
      name: 'password',
      value: '',
      required: true,
      type: FORM.TYPES.PASSWORD as TextFieldTypes,
    },
  ]);

  const rollbar = useRollbar();
  const { t } = useTranslation('translation');
  const { doLogin } = useAPIAuth();
  const [authFailed, setAuthFailed] = useState<boolean>(false);
  const swiper = useSwiper();
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormValid()) {
      const email = getFieldByName('email').value as string;
      const password = getFieldByName('password').value as string;

      try {
        const isLoggedIn = await doLogin(email.trim(), password.trim());
        if (isLoggedIn) {
          setAuthFailed(false);
          window.location.reload();
        } else {
          setAuthFailed(true);
        }
      } catch (e) {
        setAuthFailed(true);
        console.log('Login > onSubmit: error : ', e);
        rollbar.error(e as LogArgument);
      }
    }
  };

  return (
    <IntroPageWrapper className="app-page">
      <LoginFormWrapper>
        <AppModalTitle>{t('login.logintocomcolors')}</AppModalTitle>
        <form noValidate onSubmit={onSubmit} autoComplete="off">
          <Textfield
            {...({
              ...getFieldByName('email', fields),
              label: t('login.form.email') || '',
              inputmode: 'email',
              onChange: (value?) => updateFieldValue('email', value),
            } as TextfieldProps)}
          />
          <Textfield
            {...({
              ...getFieldByName('password', fields),
              label: t('login.form.password') || '',
              onChange: (value?) => updateFieldValue('password', value),
            } as TextfieldProps)}
          />
          {authFailed && (
            <FormErrorWrapper>
              <span dangerouslySetInnerHTML={{ __html: t('message.error.login') }} />
            </FormErrorWrapper>
          )}
          {/* <Link to={`${ROUTES.FORGOTTEN_PWD.INDEX}`}>{t('login.forgottenpwd')}</Link> */}
          <small
            className="forgot-password"
            onClick={() => {
              window.location.href = ROUTES.FORGOTTEN_PWD.INDEX;
              // swiper.slideNext();
            }}
          >
            <u>{t('login.forgottenpwd')}</u>
          </small>
          <Button color="white" fill="outline" expand={true} type="submit">
            {t('login.iconnect')}
          </Button>
          <small
            className="no-account"
            onClick={() => {
              swiper.slideNext();
            }}
          >
            <u>{t('intro.login.noaccount')}</u>
          </small>
          <FormButtonSubmitter type="submit" />
        </form>
      </LoginFormWrapper>
    </IntroPageWrapper>
  );
};

export default LoginForm;
