import { IPayableTool, IPayableTools, IUser } from 'types/app';
import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API, NOTIFICATIONS, PAYABLE_TOOLS_CODES } from 'constants/global';
import { AxiosRequestConfig } from 'axios';
import { IHttpResponse } from 'helpers/api';
import { getSectionBaseTexts } from 'helpers/utils';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import {
  disableBuyModuleButton,
  hideBuyModuleButton,
  showBuyElearningButton,
} from 'helpers/modules';

export interface IUseAPIPayableTools {
  getPayableTools: () => Promise<IPayableTools | undefined>;
}

function useAPIPayableTools(): IUseAPIPayableTools {
  const { getRequest, hideLoading } = useFetch<any>();
  const { languages } = useContext(SettingsContext) as SettingsContextProps;
  const { user } = useContext(UserContext) as UserContextProps;

  const {
    icons: [myMotivation, manageRelation, prepareAppointment, mapDraw, elearning, profile, talent],
  } = useIcons([
    ICON_NAMES.MY_MOTIVATION,
    ICON_NAMES.RELATION_MNGT,
    ICON_NAMES.RELATION_APPOINTMENT,
    ICON_NAMES.MAP_DRAW,
    ICON_NAMES.ELEARNING,
    ICON_NAMES.PROFILE,
    ICON_NAMES.TALENT,
  ]);

  const PAYABLE_TOOLS_STATUS: {
    [x: string]: {
      hide?: boolean;
      hasAccess?: boolean;
      unavailable?: boolean;
      canBuy?: boolean;
    };
  } = {
    [PAYABLE_TOOLS_CODES.FULL_PACKAGE]: {
      hasAccess:
        !!user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MOTIVATION) &&
        !!user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MANAGEMENT) &&
        !!user?.profileInfo?.tools.lmsStress &&
        !!user?.profileInfo?.tools.lmsWorkTogether &&
        !!user?.profileInfo?.notifications?.includes(NOTIFICATIONS.SALE),
      canBuy: !hideBuyModuleButton(user as IUser) && !disableBuyModuleButton(user as IUser),
    },
    [PAYABLE_TOOLS_CODES.COLOR_PROFILE]: {
      hasAccess: !!user?.profileInfo?.tools.profile,
      // unavailable: true,
      hide: true,
      canBuy: !hideBuyModuleButton(user as IUser) && !disableBuyModuleButton(user as IUser),
    },
    [PAYABLE_TOOLS_CODES.MOTIVATION]: {
      hasAccess: !!user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MOTIVATION),
      canBuy: !hideBuyModuleButton(user as IUser) && !disableBuyModuleButton(user as IUser),
    },
    [PAYABLE_TOOLS_CODES.MANAGEMENT]: {
      hasAccess: !!user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MANAGEMENT),
      canBuy: !hideBuyModuleButton(user as IUser) && !disableBuyModuleButton(user as IUser),
    },
    [PAYABLE_TOOLS_CODES.ELEARNING_BEHAVIOR_STRESS]: {
      hasAccess: !!user?.profileInfo?.tools.lmsStress,
      canBuy: false,
      hide: true,
    },
    [PAYABLE_TOOLS_CODES.ELEARNING_WORKING_TOGETHER]: {
      hasAccess: !!user?.profileInfo?.tools.lms,
      canBuy: showBuyElearningButton(user as IUser),
    },
    [PAYABLE_TOOLS_CODES.APPOINTMENT]: {
      hasAccess: user?.profileInfo?.notifications?.includes(NOTIFICATIONS.SALE),
      canBuy: !hideBuyModuleButton(user as IUser) && !disableBuyModuleButton(user as IUser),
    },
    [PAYABLE_TOOLS_CODES.GROUP_CARTOGRAPHY]: {
      hasAccess: false,
      unavailable: true,
      canBuy: !hideBuyModuleButton(user as IUser) && !disableBuyModuleButton(user as IUser),
    },
    [PAYABLE_TOOLS_CODES.TALENT_QUESTIONNAIRE]: {
      hasAccess: user?.profileInfo?.tools.talent,
      canBuy: false,
      unavailable: true,
      hide: false,
    },
  };

  const getToolIcon = (tool: IPayableTool): any => {
    switch (tool.code) {
      case PAYABLE_TOOLS_CODES.COLOR_PROFILE:
        return profile.icon;
      case PAYABLE_TOOLS_CODES.MOTIVATION:
        return myMotivation.icon;
      case PAYABLE_TOOLS_CODES.MANAGEMENT:
        return manageRelation.icon;
      case PAYABLE_TOOLS_CODES.ELEARNING_BEHAVIOR_STRESS:
        return elearning.icon;
      case PAYABLE_TOOLS_CODES.ELEARNING_WORKING_TOGETHER:
        return elearning.icon;
      case PAYABLE_TOOLS_CODES.APPOINTMENT:
        return prepareAppointment.icon;
      case PAYABLE_TOOLS_CODES.GROUP_CARTOGRAPHY:
        return mapDraw.icon;
      case PAYABLE_TOOLS_CODES.TALENT_QUESTIONNAIRE:
        return talent.icon;
      default:
        return '';
    }
  };

  const checkHideTool = (tool: IPayableTool): boolean => {
    return !!PAYABLE_TOOLS_STATUS[tool.code]?.hide;
  };

  const checkHasAccessTool = (toolToCheck: IPayableTool): boolean => {
    return !!PAYABLE_TOOLS_STATUS[toolToCheck.code]?.hasAccess;
  };

  const checkToolIsUnavailable = (tool: IPayableTool): boolean => {
    return !!PAYABLE_TOOLS_STATUS[tool.code]?.unavailable;
  };

  const checkCanBuyTool = (tool: IPayableTool): boolean => {
    return !!PAYABLE_TOOLS_STATUS[tool.code]?.canBuy;
  };

  const formatPayableTools = (payableTools: Array<any>): IPayableTools => {
    const sortedPayableTools = payableTools.sort((a, b) => {
      if (a.code === PAYABLE_TOOLS_CODES.FULL_PACKAGE) {
        return -1;
      } else if (b.code === PAYABLE_TOOLS_CODES.FULL_PACKAGE) {
        return 1;
      } else {
        return a.code - b.code;
      }
    });

    const res: IPayableTools = sortedPayableTools.map((tool) => {
      const toolIcon = getToolIcon(tool);
      return {
        ...getSectionBaseTexts(tool.translations, languages, ''),
        code: tool.code,
        payment_type: tool.payment_type,
        price_ttc: Number(tool.tarif_ttc),
        currency: tool.unite,
        hide: checkHideTool(tool),
        hasAccess: checkHasAccessTool(tool),
        unavailable: checkToolIsUnavailable(tool),
        icon: toolIcon,
        canBuy: checkCanBuyTool(tool),
      } as IPayableTool;
    });

    return res;
  };

  const getPayableTools = async () => {
    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.PAYMENT.TOOLS,
        method: 'get',
        headers: {
          Authorization: `${user?.token?.type} ${user?.token?.value}`,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const datas = result.data.data;
      const tools = datas.outils;
      if (!tools) {
        console.error('Error while loading payable tools');
      }

      const formatedPayableTools = formatPayableTools(tools);
      return formatedPayableTools;
    } catch (err) {
      // console.error('Error while loading payable tools');
      // console.error(err);
    } finally {
      hideLoading();
    }
  };

  return {
    getPayableTools,
  };
}

export default useAPIPayableTools;
