import { Chevron } from 'components/common/icons/icon-chevron/IconChevron.styled';
import { CollapseButton, CollapseLabel } from 'components/common/showhide/Collapse.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const SingleColorWrapper = styled.div<{
  backgroundColor?: string;
}>`
  .single-color-collapse-content {
    padding: ${prem(30)} ${prem(30)};
    background-color: ${({ backgroundColor }) => (backgroundColor ? `${backgroundColor}30` : '')};
  }

  ${CollapseButton} {
    max-width: ${prem(750)};
    margin: 0 auto;

    &.opened {
      ${CollapseLabel} {
        color: unset;
      }

      ${Chevron} {
        color: unset;
      }
    }
  }

  .single-color-qualities {
    text-align: center;
    margin-bottom: ${prem(20)};
    font-weight: bolder;
    color: var(--ion-text-color-secondary);
    font-size: ${prem(19)};
    text-transform: capitalize;
  }

  .single-color-description {
    color: var(--ion-text-color-secondary);
    max-width: ${prem(675)};
    display: block;
    margin: 0 auto ${prem(30)} auto;
  }

  .single-video-button {
    width: ${prem(120)};
    display: block;
    margin: 0 auto;
    border-radius: ${prem(30)};
    border: ${prem(1.5)} solid ${({ backgroundColor }) => backgroundColor};
    background-color: var(--ion-background-color);

    &:active {
      border-color: ${({ backgroundColor }) => `${backgroundColor}80`};
      background-clip: padding-box;
    }
  }

  .single-video-icon {
    color: ${({ backgroundColor }) => backgroundColor};
  }

  ion-button {
    margin: ${prem(3)} 0 0 0;

    &::part(native) {
      padding: 0;
    }
  }
`;

const SingleColorLabelWrapper = styled.div<{ color?: string }>`
  .icon {
    width: 50%;
  }
  
  .label {
    width: 50%;
    color: ${({ color }) => color};
  }

  display: flex;
  align-items: center;
`;

export { SingleColorWrapper, SingleColorLabelWrapper };
// border: 1px solid ${({backgroundColor}) => backgroundColor};
