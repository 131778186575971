import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import {
  QuestionnaireContent,
  QuestionnaireSlideWrapper,
} from 'pages/questionnaire/Questionnaire.styled';
import { ISectionBaseTexts } from 'types/app';
import { useSwiper } from 'swiper/react';
import QuestionnaireInstructionsFr from 'assets/images/questionnaire-instructions.png';
import QuestionnaireInstructionsEn from 'assets/images/questionnaire-instructions-en.png';
import { getLocaleValue } from 'helpers/utils';

export interface QuestionnaireHomeProps {
  textIntro: ISectionBaseTexts;
}

const QuestionnaireHome: React.FC<QuestionnaireHomeProps> = ({ textIntro }) => {
  const parentSwiper = useSwiper();
  const { t, i18n } = useTranslation('translation');
  const instructionsImages = {
    fr: QuestionnaireInstructionsFr,
    en: QuestionnaireInstructionsEn,
  };

  return (
    <QuestionnaireSlideWrapper>
      <QuestionnaireContent className="content">
        {textIntro && (
          <>
            <div className="questionnaire-description">
              <img
                src={
                  getLocaleValue(instructionsImages, i18n.language) || QuestionnaireInstructionsFr
                }
                alt="questionnaire-instructions"
              />
            </div>
            <GenericDiv minWidth={prem(200)} maxWidth={prem(200)} center>
              <Button fill="outline" onClick={() => parentSwiper.slideNext()}>
                <span>{t('questionnaire.launchquestionnaire')}</span>
              </Button>
            </GenericDiv>
          </>
        )}
      </QuestionnaireContent>
    </QuestionnaireSlideWrapper>
  );
};

export default QuestionnaireHome;
