import React, { createContext, FC, useState } from 'react';
import { IComponentWithChildren } from 'types/app';

export interface VideoPlayerContextProps {
  videoUrl?: string;
  setVideoUrl: (url?: string) => void;
}

const VideoPlayerContext = createContext<VideoPlayerContextProps | null>(null);
const VideoPlayerProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [currentVideoUrl, setVideoUrl] = useState<string>();
  const updateVideoUrl = (url?: string) => {
    setVideoUrl(url);
  };

  return (
    <VideoPlayerContext.Provider value={{ setVideoUrl: updateVideoUrl, videoUrl: currentVideoUrl }}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

export { VideoPlayerContext, VideoPlayerProvider };
