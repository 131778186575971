import { loadStripe, Stripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from 'constants/global';
import React, { createContext, FC, useEffect, useState } from 'react';
import { IComponentWithChildren } from 'types/app';

export interface StripeContextProps {
  stripePromise: Promise<Stripe | null> | undefined;
}

// export interface StripeProviderProps extends IComponentWithChildren {}

const StripeContext = createContext<StripeContextProps | null>(null);
const StripeProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  useEffect(() => {
    setStripePromise(loadStripe(STRIPE_PUBLISHABLE_KEY));
  }, []);

  return (
    <StripeContext.Provider value={{ stripePromise: stripePromise as Promise<Stripe | null> }}>
      {children}
    </StripeContext.Provider>
  );
};

export { StripeContext, StripeProvider };
