import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { SUBTITLE } from 'styles/helpers/typography';
import { IconProfileWrapper } from 'components/common/icons/icon-profile/IconProfile.styled';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const RelationColorSelectorWrapper = styled.div``;
const SelectedColorContainer = styled.div`
  display: flex;
  gap: ${prem(15)};
  align-items: center;

  h3 {
    color: var(--ion-text-color-secondary);
    ${SUBTITLE.medium};

    @media (${MQ_MAX_HEIGHT}) {
      ${SUBTITLE.small};
    }
  }

  ${IconProfileWrapper} {
    width: ${prem(54)};
    height: ${prem(54)};

    @media (${MQ_MAX_HEIGHT}) {
      width: ${prem(46)};
      height: ${prem(46)};
    }
  }
`;

export { RelationColorSelectorWrapper, SelectedColorContainer };
