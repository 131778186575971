import { useContext, useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { HttpClient, IHttpResponse, ION_LOADING_STATE } from 'helpers/api';
import { useIonLoading } from '@ionic/react';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { API } from 'constants/global';
import { ROUTES } from 'constants/routes';
import useIonNavContext from 'hooks/useIonNavContext';

export interface IUseFetch<T> {
  doRequest: (options?: AxiosRequestConfig) => void;
  state?: IHttpResponse<T>;
  getRequest: (options?: AxiosRequestConfig) => any;
  showLoading: () => void;
  hideLoading: (arg?: string) => void;
  loadingState?: LoadingState;
}

export type LoadingState = undefined | 'loading' | 'done';

function useFetch<T = any>(url = ''): IUseFetch<T> {
  const { gotoUrl } = useIonNavContext();
  const { user } = useContext(UserContext) as UserContextProps;
  const [present, dismiss] = useIonLoading();
  const [state, setState] = useState<IHttpResponse>();
  const [loadingState, setLoadingState] = useState<LoadingState>();
  const [controller, setController] = useState<any>();
  const getDefaultRequestOptions = (): {
    controller: AbortController;
    options: AxiosRequestConfig;
  } => {
    const c = new AbortController();
    return {
      controller: c,
      options: {
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.token?.value
            ? `${user.token?.type} ${user.token?.value}`
            : `${API.BASIC_TOKEN}`,
        },
        signal: c.signal,
      },
    };
  };

  const showLoading = async () => {
    if (!ION_LOADING_STATE.getState()) {
      ION_LOADING_STATE.setState(true);
      await present({
        spinner: 'circles',
      });
    }
  };

  const hideLoading = async (arg?: string) => {
    arg && console.log('hideLoading: arg: ', arg);
    await dismiss();
    ION_LOADING_STATE.setState(false);
  };

  // Redirect to login if 401
  const redirectToLogin = (statusCode: number) => {
    if (statusCode === 401) {
      gotoUrl(ROUTES.LOGIN);
    }
  };

  const doRequest = (options?: AxiosRequestConfig) => {
    const defaultOptions = getDefaultRequestOptions();

    setController(defaultOptions.controller);

    // Clear data
    setState(undefined);
    setLoadingState('loading');

    showLoading();

    // Call api
    HttpClient.request({
      url,
      ...defaultOptions.options,
      ...options,
    })
      .then((response) => {
        const tmpState = {
          data: response.data,
          status: response.data.status_code || response.status,
          statusText: response.data.message || response.statusText,
        };

        redirectToLogin(tmpState.status);
        setState(tmpState);
      })
      .catch((error) => {
        redirectToLogin(error.status);
        setState(error);
      })
      .finally(() => {
        // Hide loading
        hideLoading();
        setLoadingState('done');
      });
  };

  const getRequest = (options?: AxiosRequestConfig) => {
    const defaultOptions = getDefaultRequestOptions();

    setController(defaultOptions.controller);
    return HttpClient.request({
      ...defaultOptions.options,
      ...options,
    });
  };

  useEffect(() => {
    return () => {
      controller && controller.abort();
    };
  }, [controller]);

  return { doRequest, state, getRequest, showLoading, hideLoading, loadingState };
}

export default useFetch;
