import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const formWrapper = css`
  height: unset;
  width: 70%;
  max-width: ${prem(500)};
  min-width: ${prem(250)};
  text-align: center;
`;

const FormButtonSubmitter = styled.input`
  position: absolute;
  left: -9999px;
`;

const FormFieldsGroup = styled.div`
  margin-bottom: ${prem(40)};
`;

const FormErrorWrapper = styled.div`
  text-align: left;
  color: var(--highlight-color-invalid);
  font-size: ${prem(12)};
  margin-bottom: ${prem(20)};
`;

export { FormButtonSubmitter, FormErrorWrapper, formWrapper, FormFieldsGroup };
