import { ROUTES } from 'constants/routes';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { useQuery } from 'hooks/useQuery';
import React, { useContext, useEffect } from 'react';

const AutoLogin: React.FC = () => {
  const query = useQuery();
  const { user } = useContext(UserContext) as UserContextProps;
  const { doLogin } = useAPIAuth();
  const token = query.get('_token') as string;

  useEffect(() => {
    const userToken = user?.token;
    if (userToken && userToken.type && userToken.value) {
      window.location.href = ROUTES.HOME;
    } else {
      doLogin(undefined, undefined, token).then(() => {
        window.location.href = ROUTES.INDEX;
      });
    }
  }, []);
  return <></>;
};

export default AutoLogin;
