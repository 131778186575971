import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ChooseColorWrapper,
  ChooseImageWrapper,
  ColorProfileWrapper,
  DownloadPictureWrapper,
} from 'pages/profile/has-profile/color-profile/ColorProfile.styled';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import ProfilePicture from 'assets/images/profile-picture-example.png';
import Step from 'pages/profile/has-profile/color-profile/step/Step';
import Button from 'components/common/button/Button';
import ColoursPicker from 'components/colours-picker/ColoursPicker';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { IColor } from 'types/app';
import useAPIProfile from 'hooks/api/useAPIProfile';
import 'react-image-crop/src/ReactCrop.scss';
import ImageCropper from 'pages/profile/has-profile/color-profile/image-cropper/ImageCropper';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import Slider from 'components/slider/Slider';
import { SwiperSlide, useSwiper } from 'swiper/react';

// import PictoBlog from 'assets/images/picto-blog.png';
// import FrankJulien from 'assets/images/franck-julien.png';

const ColorProfile: React.FC = () => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { uploadProfilePicture, chooseProfilePictureColor } = useAPIProfile();
  const [initialImage, setInitialImage] = useState<any>();
  const [croppedImageFile, setCroppedImageFile] = useState<File>();
  const [croppedImageB64, setCroppedImageB64] = useState<string>();
  const [choosedColor, setChoosedColor] = useState<number>();
  const [finalProfilePicture, setFinalProfilePicture] = useState<string>();

  const [activatedStep2, setActivatedStep2] = useState(false);
  const [activatedStep3, setActivatedStep3] = useState(false);

  const userColors = useMemo<Array<IColor>>(() => {
    if (user && user.profileInfo?.colors) {
      const colors = [user.profileInfo.colors.primary as IColor];

      if (user.profileInfo.colors.primary?.id !== user.profileInfo.colors.secondary?.id) {
        colors.push(user.profileInfo.colors.secondary as IColor);
      }

      return colors;
    }
    return [];
  }, [user]);

  const totalStep = 3;

  const hiddenProfilePictureInput = useRef<any>(null);
  const handleChooseProfilePicture = () => {
    hiddenProfilePictureInput.current.click();
  };

  const handleInputChange = async (event: any) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      if (fileUploaded.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const image = new Image();

          image.onload = () => {
            const { width, height } = image;

            if (width >= 235 && height >= 235) {
              // Image dimensions are valid, proceed with setting the initial image
              setInitialImage(reader.result as string);
            } else {
              // Invalid image dimensions
              setToast({
                message: 'Image dimensions must be at least 235x235 pixels.',
                status: 'danger',
              });
            }
          };

          image.src = reader.result as string;
        });

        reader.readAsDataURL(fileUploaded);
      } else {
        // Handle the case where the uploaded file is not an image
        setToast({
          message: 'Invalid image',
          status: 'danger',
        });
      }
    }
  };

  useEffect(() => {
    if (initialImage) {
      updateModalConf({
        component: (
          <ImageCropper
            imageSrc={initialImage}
            onCrop={(e) => {
              if (e.base64Value && e.fileValue) {
                setCroppedImageFile(e.fileValue);
                setCroppedImageB64(e.base64Value);
              }
              updateModalConf({
                hide: true,
              });
            }}
          />
        ),
        hide: false,
        onDismiss: () =>
          updateModalConf({
            hide: true,
          }),
      });
    }
  }, [initialImage]);

  useEffect(() => {
    if (croppedImageFile && croppedImageB64) {
      uploadProfilePicture(croppedImageFile).then((e) => {
        if (e) {
          if (!activatedStep2) setActivatedStep2(true);
          else {
            if (choosedColor) {
              chooseProfilePictureColor(choosedColor).then((e) => {
                if (e) {
                  setFinalProfilePicture(`${e}?_v=${new Date().getTime()}`);
                }
              });
            }
          }
        }
      });
    }
  }, [croppedImageFile, croppedImageB64]);

  useEffect(() => {
    if (choosedColor) {
      chooseProfilePictureColor(choosedColor).then((e) => {
        if (e) {
          setActivatedStep3(true);
          setFinalProfilePicture(`${e}?_v=${new Date().getTime()}`);
        }
      });
    }
  }, [choosedColor]);

  const ChooseImage: React.FC = () => {
    const swiper = useSwiper();

    useEffect(() => {
      if (activatedStep2) {
        swiper.slideNext();
      }
    }, [activatedStep2]);

    return (
      <ChooseImageWrapper>
        <PageInnerTitle>{t('profile.colorprofile.title')}</PageInnerTitle>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: t('profile.colorprofile.description'),
          }}
        />

        {finalProfilePicture ? (
          <img
            className="final-profile-picture"
            src={finalProfilePicture}
            alt="final-profile-picture"
          />
        ) : (
          <img className="profile-picture" src={ProfilePicture} alt="" />
        )}
        <Step
          stepNumber={1}
          totalStep={totalStep}
          stepTitle={t('profile.colorprofile.uploadprofilepicture')}
        >
          <Button fill="outline" onClick={() => handleChooseProfilePicture()}>
            Choisir un fichier
          </Button>
          <input
            type="file"
            onChange={handleInputChange}
            ref={hiddenProfilePictureInput}
            style={{ display: 'none' }}
          />
        </Step>
      </ChooseImageWrapper>
    );
  };

  const ChooseColor: React.FC = () => {
    const swiper = useSwiper();

    useEffect(() => {
      if (activatedStep3) {
        swiper.slideNext();
      }
    }, [activatedStep3]);

    return (
      <ChooseColorWrapper className="choose-color-wrapper">
        {croppedImageB64 && <img className="cropped-profile-picture" src={croppedImageB64} />}
        <Step
          stepNumber={2}
          totalStep={totalStep}
          stepTitle={t('profile.colorprofile.choosecolor')}
          hide={!initialImage}
        >
          <ColoursPicker
            onSelectColor={(colourId: number) => {
              setChoosedColor(colourId);
            }}
            colors={userColors}
            selectedColorId={choosedColor}
          />
        </Step>
      </ChooseColorWrapper>
    );
  };

  const DownloadPicture = () => {
    const swiper = useSwiper();

    const downloadImage = async () => {
      const imageUrl = finalProfilePicture as string;

      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'profile-picture.png';

        // Append the anchor element to the body
        document.body.appendChild(a);

        // Trigger a click on the anchor element
        a.click();

        // Remove the anchor element from the body
        document.body.removeChild(a);
      } catch (error) {
        // setToast({
        //   message: 'Error downloading image',
        //   status: 'danger',
        // });
        console.error('Error downloading image:', error);
      }
    };

    return (
      <DownloadPictureWrapper>
        {finalProfilePicture && (
          <img
            className="final-profile-picture"
            id="final-profile-picture"
            src={finalProfilePicture}
            alt="final-profile-picture"
          />
        )}
        <Step
          stepNumber={3}
          totalStep={totalStep}
          stepTitle={t('profile.colorprofile.downloadprofilepicture.description')}
          hide={!activatedStep3}
        >
          {finalProfilePicture && (
            <Button
              fill="outline"
              onClick={() => {
                downloadImage();
              }}
            >
              {t('profile.colorprofile.downloadprofilepicture.cta')}
            </Button>
          )}
          <br />
          <PageInnerTitle>{t('common.or')}</PageInnerTitle>
          <br />
          <div className="buttons">
            <Button
              fill="solid"
              color="accent"
              onClick={() => {
                swiper.slideTo(0);
              }}
            >
              Utiliser une autre photo
            </Button>
            <Button
              fill="solid"
              color="accent"
              onClick={() => {
                swiper.slidePrev();
              }}
            >
              Utiliser une autre couleur
            </Button>
          </div>
        </Step>
      </DownloadPictureWrapper>
    );
  };

  return (
    <ColorProfileWrapper>
      <Container>
        <div className="steps">
          <Slider allowTouchMove={true}>
            <SwiperSlide>
              <ChooseImage />
            </SwiperSlide>
            {activatedStep2 && (
              <SwiperSlide>
                <ChooseColor />
              </SwiperSlide>
            )}
            {activatedStep3 && (
              <SwiperSlide>
                <DownloadPicture />
              </SwiperSlide>
            )}
          </Slider>
          {/* <Step
            stepNumber={1}
            totalStep={totalStep}
            stepTitle={t('profile.colorprofile.uploadprofilepicture')}
          >
            <Button fill="outline" onClick={() => handleChooseProfilePicture()}>
              Choisir un fichier
            </Button>
            <input
              type="file"
              onChange={handleInputChange}
              ref={hiddenProfilePictureInput}
              style={{ display: 'none' }}
            />
          </Step>
          {croppedImageB64 && (
            <img
              className="cropped-profile-picture"
              onLoad={() => {
                scrollToStep2();
              }}
              src={croppedImageB64}
            />
          )} */}
        </div>
      </Container>
    </ColorProfileWrapper>
  );
};

export default ColorProfile;
