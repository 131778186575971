import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { IAdvice, IPerson } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { isMale } from 'helpers/relations';
import { convertDateStrToLocale, tokensReplacer } from 'helpers/utils';
import { getColorById } from 'helpers/colors';
import { NotificationsContext, NotificationsContextProps } from 'contexts/NotificationsContext';
import { IHttpResponse } from 'helpers/api';
import { InvitationType } from 'hooks/api/useAPIRelations';

export const enum AdviceType {
  RELATION = 'relation',
  MOTIVATION = 'motivation',
  ALL = 'alls',
}

export interface IUseAPIAdvices {
  getAdvices: (
    type: AdviceType,
    userId?: number,
    relationId?: number,
    person?: IPerson,
  ) => Promise<Array<IAdvice>>;
  loadNotifications: (
    type: AdviceType,
    userId: number,
    isInBackground?: boolean,
  ) => Promise<boolean>;
  updateAdvice: (advice: IAdvice, value: number) => Promise<IAdvice | undefined>;
  setAdvicesRead: (
    type: AdviceType,
    userId: number,
    relationId?: number,
    isInBackground?: boolean,
  ) => Promise<boolean>;
}

function useAPIAdvices(): IUseAPIAdvices {
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { notifications, updateNotifications } = useContext(
    NotificationsContext,
  ) as NotificationsContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  // Parse advice
  const parseAdvice = (conseil: any, person?: IPerson, type?: AdviceType): IAdvice => {
    const adviceDesc = isMale(person?.pronoun)
      ? conseil.libelle_masculin
      : conseil.libelle_feminin || conseil.libelle_masculin;
    /*
    conseil.updated_at && (mappedAdvice.date = convertDateStrToLocale(conseil.updated_at));
    conseil.date_like && (mappedAdvice.date = convertDateStrToLocale(conseil.date_like));
    */

    return {
      id: conseil.id,
      advice: {
        fr: tokensReplacer(adviceDesc.fr, person?.firstName),
        en: tokensReplacer(adviceDesc.en, person?.firstName),
      },
      liked: !!conseil.conseil_like,
      date: convertDateStrToLocale(conseil.created_at) || '',
      read: false,
      ...(conseil.couleur_id && { colour: getColorById(conseil.couleur_id) }),
      type,
    };
  };

  const parseNotification = (notif: any) => {
    return {
      id: notif.conseil_envoye_id,
      notificationId: notif.id,
      advice: {
        fr: notif.content || '',
        en: notif.content || '',
      },
      liked: !!notif.notification_like,
      date: convertDateStrToLocale(notif.created_at) || '',
      read: !!notif.notification_lu,
      ...(notif.couleur_id && { colour: getColorById(notif.couleur_id) }),
      type: notif.category,
      actionCompleted: !!notif.action_completed,
      title: notif?.notification_title,
      firstname: notif?.prenom,
      name: notif?.nom,
    };
  };

  // Load relation/motivation advices
  const getAdvices = async (
    type: AdviceType,
    userId?: number,
    relationId?: number,
    person?: IPerson,
  ): Promise<Array<IAdvice>> => {
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.ADVICE.ADVICE}/${userId}`,
        method: 'get',
      };
      if (type === AdviceType.RELATION) {
        options.url = `${options.url}/relation/${relationId}`;
      }
      if (type === AdviceType.MOTIVATION) {
        options.url = `${options.url}/motivation`;
      }

      const result: IHttpResponse = await getRequest(options);
      const getState = result.data.data?.conseils;
      const arr: Array<IAdvice> = [];

      if (getState && Array.isArray(getState)) {
        // Get
        // Parse advices
        return getState.map((conseil: any) => {
          return parseAdvice(conseil, person, relationId ? AdviceType.RELATION : undefined);
        });
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return arr;
    } catch (e: unknown) {
      console.log('Error while getting advices : ', e);
    }
    return [];
  };

  // Get notifications
  const loadNotifications = async (
    type: AdviceType,
    userId: number,
    isInBackground?: boolean,
  ): Promise<boolean> => {
    !isInBackground && showLoading();

    let allNotifications: Array<IAdvice> = [];

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.NOTIFICATIONS.NOTIFICATIONS}/${userId}/category/${type}`,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const getState = result.data.data?.notifications;

      if (Array.isArray(getState)) {
        allNotifications = getState
          .map((notif: any) => parseNotification(notif))
          .filter(
            (notification) =>
              !(
                (notification.type === InvitationType.NEW_INVITATION &&
                  notification.actionCompleted === true) ||
                notification.type === InvitationType.PROFILE_VISIBILITY
              ),
          );
      } else {
        if (result.data.message) {
          !isInBackground &&
            setToast({
              message: result.data.message,
              status: 'danger',
            });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting notifications : ', e);
    } finally {
      !isInBackground && hideLoading();
    }

    updateNotifications(allNotifications);
    return true;
  };

  // Update advice state
  const updateAdvice = async (advice: IAdvice, value: number): Promise<IAdvice | undefined> => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.ADVICE.ADVICE_LIKE}/${advice.id}`,
        method: 'patch',
        data: {
          conseil_like: value,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const patchState = result.data.data?.conseil;

      if (patchState && patchState.id) {
        const resultAdvice: IAdvice = {
          ...advice,
          liked: !!patchState.conseil_like,
        };
        patchState.updated_at &&
          (resultAdvice.date = convertDateStrToLocale(patchState.updated_at));
        patchState.date_like && (resultAdvice.date = convertDateStrToLocale(patchState.date_like));

        return resultAdvice;
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while submitting subscsription : ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Update read statet of locales notification
  const updateNotificationReadState = (ids: string) => {
    if (ids) {
      const adviceIds: Array<number> = ids.split(',').map((id) => parseInt(id, 10));
      const notifs: Array<IAdvice> = notifications.map((n) => {
        const notif = { ...n };

        if (adviceIds.includes(notif.id)) {
          notif.read = true;
        }
        return notif;
      });

      console.log('updateNotificationReadState: ', notifs);
      updateNotifications(notifs);
    }
  };

  // Sett advice read true
  const setAdvicesRead = async (
    type: AdviceType,
    userId: number,
    relationId?: number,
    isInBackground?: boolean,
  ): Promise<boolean> => {
    !isInBackground && showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.NOTIFICATIONS.NOTIFICATIONS}/${userId}/lu`,
        method: 'patch',
        data: {
          type: type,
          relation_id: relationId || null,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const patchState = result.data.data?.notification_ids;

      if (patchState) {
        updateNotificationReadState(`${patchState}`);
      } else {
        if (!result.data.success && result.data.message) {
          !isInBackground &&
            setToast({
              message: result.data.message,
              status: 'danger',
            });
        }
      }
      return !!patchState;
    } catch (e: unknown) {
      console.log('Error while getting notifications : ', e);
    } finally {
      !isInBackground && hideLoading();
    }

    return true;
  };

  return {
    getAdvices,
    updateAdvice,
    loadNotifications,
    setAdvicesRead,
  };
}

export default useAPIAdvices;
