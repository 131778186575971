import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { ISettings } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { useContext } from 'react';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { IHttpResponse } from 'helpers/api';

export interface IUseAPISettings {
  getSettings: (userId: number, token?: string) => Promise<ISettings | undefined>;
  registerSettings: (settings: ISettings) => Promise<IHttpResponse | undefined>;
}

function useAPISettings(): IUseAPISettings {
  const { user } = useContext(UserContext) as UserContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  const getSettingsData = (response: IHttpResponse<any>, userId: number): ISettings | undefined => {
    const apiData = response.data?.data as any;
    let settings: ISettings | undefined;

    if (apiData && apiData.reglage) {
      settings = {
        userId,
        id: apiData.reglage['id'],
        lang: apiData.reglage['lang'],
        profileVisibility: apiData.reglage['profile_is_visible'],
        notification: apiData.reglage['active_push_notification'],
        darkMode: apiData.reglage['active_dark_mode'],
        themeColor: apiData.reglage['interface_theme'],
      };
    }

    return settings;
  };

  // Handle settings request
  const getSettings = async (userId: number, token?: string) => {
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.SETTINGS.SETTINGS}/${userId}`,
      };
      if (token) {
        options.headers = {
          Authorization: token,
        };
      }
      const result = await getRequest(options);

      return getSettingsData(result, userId);
    } catch (e) {
      console.log('Error while getting settings: ', e);
    }

    return undefined;
  };

  // Send settings
  const registerSettings = async (settings: ISettings) => {
    if (!user?.id) {
      return undefined;
    }
    try {
      showLoading();
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.SETTINGS.SETTINGS}/${settings.id}`,
        method: 'patch',
        data: {
          reglage: settings.id,
          lang: settings.lang,
          profile_is_visible: settings.profileVisibility,
          active_dark_mode: settings.darkMode,
          interface_theme: settings.themeColor,
        },
      };

      return await getRequest(options);
    } catch (e) {
      console.log('Error while getting settings: ', e);
    } finally {
      hideLoading();
    }

    return undefined;
  };

  return {
    getSettings,
    registerSettings,
  };
}

export default useAPISettings;
