import { Chevron } from 'components/common/icons/icon-chevron/IconChevron.styled';
import { CollapseButton, CollapseLabel } from 'components/common/showhide/Collapse.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const SingleTrainingWrapper = styled.div`
  .single-resource-training-collapse-content {
    padding: ${prem(30)} ${prem(30)};
    /* stylelint-disable */
    background-color: rgb(var(--ion-color-accent-rgb), 0.2);
    /* stylelint-enable */
    display: block;
    margin: 0 auto;
  }
  
  ${CollapseButton} {
    max-width: ${prem(990)};
    margin: 0 auto;
  }

  ${CollapseLabel} {
    color: var(--ion-text-color-secondary);
  }

  ${Chevron} {
    color: var(--ion-text-color-secondary);
  }

  .single-resource-training-image {
    width: 100%;
    max-width: ${prem(400)};
    display: block;
    margin: 0 auto ${prem(20)};
  }

  .single-resource-training-description {
    display: block;
    margin: 0 auto ${prem(50)};
    max-width: ${prem(940)};
  }

  .single-resource-training-button {
    max-width: ${prem(210)};
    display: block;
    margin: 0 auto;

    ion-button {
      &::part(native) {
        padding: ${prem(15)} 0;
      }
    }
  }
`;

export { SingleTrainingWrapper };
