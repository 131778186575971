import React, { useContext } from 'react';
import { SingleToolWrapper } from 'components/shop/single-tool/SingleTool.styled';
import { IPayableTool } from 'types/app';
import { useTranslation } from 'react-i18next';
import { formatPrice, getLocaleValue } from 'helpers/utils';
import Checkbox from 'components/common/form/checkbox/Checkbox';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import ToolDescription from 'components/shop/single-tool/tool-description/ToolDescription';

export interface SingleToolProps {
  tool: IPayableTool;
  selected?: boolean;
  onToggle?: () => void;
  disabled?: boolean;
}

const SingleTool: React.FC<SingleToolProps> = ({ tool, selected, onToggle, disabled }) => {
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { i18n, t } = useTranslation('translation');
  const {
    icons: [info],
  } = useIcons([ICON_NAMES.INFO]);

  return (
    <SingleToolWrapper
      hasAccess={tool.hasAccess}
      unavailable={tool.unavailable || !tool.canBuy || disabled}
    >
      <div className="single-tool-left">
        <Checkbox
          onClick={() => {
            onToggle && onToggle();
          }}
          value={!!selected}
          disabled={tool.unavailable || tool.hasAccess || !tool.canBuy || disabled}
        />
        {tool.icon && <div className="icon">{tool.icon}</div>}
        <div className="title-and-description">
          <div className="title">{getLocaleValue(tool.title, i18n.language)}</div>
        </div>
      </div>
      <div className="single-tool-right">
        <div
          className={`about-icon ${disabled && 'disabled'}`}
          onClick={() =>
            updateModalConf({
              component: (
                <ToolDescription
                  title={getLocaleValue(tool.title, i18n.language)}
                  description={getLocaleValue(tool.description, i18n.language)}
                />
              ),
            })
          }
        >
          {info.icon}
        </div>
        <div className="price">
          {tool.hasAccess ? (
            <>
              <div className="has-access">{t('common.alreadyavailable')}</div>
            </>
          ) : (
            <>
              {tool.unavailable || !tool.canBuy ? (
                <>
                  {tool.unavailable && (
                    <div className="not-available">
                      {tool.canBuy && (
                        <center>{formatPrice(tool.price_ttc, tool.currency, i18n.language)}</center>
                      )}
                      <center>{t('common.notavailable')}</center>
                    </div>
                  )}
                  {!tool.canBuy && !tool.unavailable && (
                    <div className="not-available">{t('common.notavailable')}</div>
                  )}
                </>
              ) : (
                <>
                  <div className={`show-price ${disabled && 'disabled'}`}>
                    {formatPrice(tool.price_ttc, tool.currency, i18n.language)}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </SingleToolWrapper>
  );
};

export default SingleTool;
