import React from 'react';
import {
  RelationColorIcon,
  RelationItemContextualMenuWrapper,
  RelationItemHeaderLeft,
  RelationItemHeaderWrapper,
  RelationItemMenuGroupWrapper,
  RelationItemName,
  RelationItemWrapper,
  RelationMessageIcon,
  RelationSubscribedIcon,
} from 'components/relations/relation/RelationItem.styled';
import { Form, IRelation, IRelationGroup } from 'types/app';
import IconProfile from 'components/common/icons/icon-profile/IconProfile';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import {
  ButtonIconWrapper,
  ButtonLabel,
  RegularButtonFlexed,
} from 'components/common/button/Button.styled';
import { ReactComponent as IconEmpty } from 'assets/svg/icon-noicon.svg';
import { useTranslation } from 'react-i18next';
import ButtonClose from 'components/common/button/ButtonClose';
import Dropdown from 'components/common/dropdown/Dropdown';
import RelationIconExplications from 'components/relations/relation/icon-explications/RelationIconExplications';
import { BADGE } from 'constants/global';

export interface RelationItemProps {
  relationId: number;
  person: IRelation;
  isOpened: boolean;
  onIdentifyColor?: (relation: IRelation) => void;
  onAddToGroup?: (relation: IRelation, group: IRelationGroup) => void;
  onRemove?: (relation: IRelation) => void;
  onOpenRelation?: (relation: IRelation) => void;
  onManageRelation?: (relation: IRelation) => void;
  onMakeAppointmentToRelation?: (relation: IRelation) => void;
}

const RelationItem: React.FC<RelationItemProps> = ({
  relationId,
  person,
  isOpened,
  onAddToGroup,
  onRemove,
  onIdentifyColor,
  onOpenRelation,
  onManageRelation,
  onMakeAppointmentToRelation,
}) => {
  const { t } = useTranslation('translation');
  const {
    icons: [
      iconMsgSent,
      iconMsgInProgress,
      iconRelationAppointment,
      iconRelationMngt,
      iconRelationSubscription,
    ],
  } = useIcons([
    ICON_NAMES.MESSAGE_SENT,
    ICON_NAMES.MESSAGE_IN_PROGRESS,
    ICON_NAMES.RELATION_APPOINTMENT,
    ICON_NAMES.RELATION_MNGT,
    ICON_NAMES.RELATION_SUBSCRIPTION_MARKER,
  ]);
  const onSelectGroup = (value: Form.IOption) => {
    onAddToGroup && onAddToGroup(person, { id: value.id as number, name: value.label });
  };
  const openRelation = () => {
    onOpenRelation && onOpenRelation(person);
  };

  // useEffect(() => {
  //   console.log(person);
  // }, [person]);

  return (
    <RelationItemWrapper>
      <RelationItemHeaderWrapper>
        <RelationItemHeaderLeft className="relation-icon-and-name-wrapper" gap={20}>
          <RelationColorIcon>
            <IconProfile
              primaryColorId={person.primaryColorId}
              secondaryColorId={person.secondaryColorId || person.primaryColorId}
              unspecifiedColor={person.unspecifiedColor}
              iconProfile={person.profileIconStatus}
              relationId={relationId}
            />
          </RelationColorIcon>
          <RelationItemHeaderLeft className="relation-name" gap={10} onClick={() => openRelation()}>
            <RelationItemName>{`${person.firstName} ${person.name}`}</RelationItemName>
            {!!person.subscribed && (
              <RelationSubscribedIcon>{iconRelationSubscription.icon}</RelationSubscribedIcon>
            )}
          </RelationItemHeaderLeft>
        </RelationItemHeaderLeft>
        {!!person.invitationAccepted && (
          <RelationMessageIcon>{iconMsgSent.icon}</RelationMessageIcon>
        )}
        {!!person.invitationSent && (
          <RelationMessageIcon>{iconMsgInProgress.icon}</RelationMessageIcon>
        )}
      </RelationItemHeaderWrapper>
      <RelationItemContextualMenuWrapper opened={isOpened}>
        <ButtonClose onClick={() => openRelation()} />
        <RegularButtonFlexed
          onClick={() => {
            onManageRelation && onManageRelation(person);
          }}
        >
          <ButtonIconWrapper>{iconRelationMngt.icon}</ButtonIconWrapper>
          <ButtonLabel className="important">{t('relations.manage')}</ButtonLabel>
        </RegularButtonFlexed>
        {!!onMakeAppointmentToRelation && (
          <RegularButtonFlexed
            onClick={() => {
              onMakeAppointmentToRelation(person);
            }}
          >
            <ButtonIconWrapper>{iconRelationAppointment.icon}</ButtonIconWrapper>
            <ButtonLabel>
              <strong>{t('relations.prepareappointment')}</strong>
            </ButtonLabel>
          </RegularButtonFlexed>
        )}
        <RegularButtonFlexed
          onClick={() => {
            onIdentifyColor && onIdentifyColor(person);
          }}
        >
          <ButtonIconWrapper>
            <IconEmpty />
          </ButtonIconWrapper>
          <ButtonLabel>{t('relations.identifycolor')}</ButtonLabel>
        </RegularButtonFlexed>
        {!!person.proposedGroups?.length && (
          <RelationItemMenuGroupWrapper>
            <ButtonIconWrapper>
              <IconEmpty />
            </ButtonIconWrapper>
            <Dropdown
              label={t('relations.addrelation.addtoagroup')}
              menuItems={person.proposedGroups.map((g) => ({ id: g.id, label: g.name }))}
              onSelect={onSelectGroup}
            />
          </RelationItemMenuGroupWrapper>
        )}
        <RegularButtonFlexed onClick={() => onRemove && onRemove(person)}>
          <ButtonIconWrapper>
            <IconEmpty />
          </ButtonIconWrapper>
          <ButtonLabel>{t('relations.removerelation')}</ButtonLabel>
        </RegularButtonFlexed>
        <RelationIconExplications
          badge={person.profileIconStatus as BADGE}
          invitationAccepted={!!person.invitationAccepted}
          invitationSent={!!person.invitationSent}
          relationId={relationId}
          trueRelationPrimaryColorId={person.relationTruePrimaryColorId as number}
          trueRelationSecondaryColorId={person.relationTrueSecondaryColorId as number}
          firstName={person.firstName}
          name={person.name}
          unknownProfile={!person.primaryColorId}
          subscribed={person.subscribed}
        />
      </RelationItemContextualMenuWrapper>
    </RelationItemWrapper>
  );
};

export default RelationItem;
