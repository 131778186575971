import React, { useRef, useState } from 'react';
import {
  RelationGroupItemHeader,
  RelationGroupItemHeaderContextMenuButton,
  RelationGroupItemWrapper,
  RelationGroupNameContainer,
  RelationGroupRemoveEntry,
  RelationItemsWrapper,
  RelationMenuGroupWrapper,
} from 'components/relations/group/RelationGroup.styled';
import IconChevron from 'components/common/icons/icon-chevron/IconChevron';
import { ReactComponent as IconThreeDots } from 'assets/svg/icon-three-dots.svg';
// import { ReactComponent as IconMapDraw } from 'assets/svg/icon-map-draw.svg';
import { ReactComponent as IconEmpty } from 'assets/svg/icon-noicon.svg';
import ShowHide from 'components/common/showhide/ShowHide';
import {
  ButtonIconWrapper,
  ButtonLabel,
  RegularButtonFlexed,
} from 'components/common/button/Button.styled';
import { useTranslation } from 'react-i18next';
import useOutsideClick from 'hooks/useOutsideClick';
import { IRelation, IRelationGroup } from 'types/app';
import RelationItem from 'components/relations/relation/RelationItem';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';

export interface RelationGroupItemProps extends IRelationGroup {
  onEdit?: () => void;
  onRemove?: () => void;
  onOpened?: () => void;
  onAddRelationIntoGroup?: (relation: IRelation, group: IRelationGroup) => void;
  onRemoveRelation?: (relation: IRelation) => void;
  onIdentifyRelation?: (relation: IRelation) => void;
  onManageRelation?: (relation: IRelation) => void;
  onMakeAppointmentToRelation?: (relation: IRelation) => void;
  isOpened?: boolean;
}

const MENU_GROUP_HEIGHT = 138;

const RelationGroupItem: React.FC<RelationGroupItemProps> = ({
  name,
  relations,
  onEdit,
  onRemove,
  onOpened,
  isOpened,
  onAddRelationIntoGroup,
  onRemoveRelation,
  onIdentifyRelation,
  onManageRelation,
  onMakeAppointmentToRelation,
}) => {
  const { t } = useTranslation('translation');
  const headerEl = useRef<HTMLDivElement | null>(null);
  const [showRemoveNote, setRemoveNote] = useState(false);
  const [menuGroupOpened, setMenuGroupOpened] = useState(false);
  const [menuGroupPosY, setMenuGroupPosY] = useState('100%');
  const [currentOpenedRelation, setCurrentOpenedRelation] = useState<IRelation | undefined>();
  const {
    icons: [iconBadgeWarning],
  } = useIcons([ICON_NAMES.PROFILE_BADGE_WARNING]);
  const nameClickHandler = () => {
    onOpened && onOpened();
  };
  // Replace opened menu group to top
  // if it il will b under the main tab navigation
  const resolveMenuGroupPosY = () => {
    try {
      const headerBounds = (headerEl.current as HTMLDivElement).getBoundingClientRect();
      const headerBottomPosY = headerBounds.y + headerBounds.height;
      const mainTabBarBounds = (
        document.querySelector('#main-tab-bar') as HTMLElement
      )?.getBoundingClientRect();
      const posYOpen = headerBottomPosY + MENU_GROUP_HEIGHT;

      if (posYOpen > mainTabBarBounds.y) {
        setMenuGroupPosY(`-${headerBounds.height}px`);
      }
    } catch (e) {
      console.log('resolveMenuGroupPosY: error ', e);
    }
  };
  const openContextualMenuGroup = () => {
    const opened = !menuGroupOpened;
    opened && resolveMenuGroupPosY();
    setRemoveNote(false);
    setMenuGroupOpened(opened);
  };
  const { ref: groupMenuEl, exceptRef } = useOutsideClick(() => {
    setMenuGroupOpened(false);
  });
  const renameGroup = () => {
    onEdit && onEdit();
    setMenuGroupOpened(false);
  };
  const removeGroup = () => {
    onRemove && onRemove();
    setMenuGroupOpened(false);
  };
  const addRelationToGroup = (relation: IRelation, group: IRelationGroup) => {
    onAddRelationIntoGroup && onAddRelationIntoGroup(relation, group);
  };
  const removeRelation = (relation: IRelation) => {
    onRemoveRelation && onRemoveRelation(relation);
  };
  const identifyRelation = (relation: IRelation) => {
    onIdentifyRelation && onIdentifyRelation(relation);
  };
  const onOpenRelation = (relation: IRelation) => {
    setCurrentOpenedRelation(currentOpenedRelation?.id === relation.id ? undefined : relation);
  };
  const manageRelation = (relation: IRelation) => {
    onManageRelation && onManageRelation(relation);
  };
  const nbRelations = relations?.length ? ` (${relations.length})` : '';

  return (
    <RelationGroupItemWrapper>
      <RelationGroupItemHeader ref={headerEl}>
        <RelationGroupNameContainer onClick={nameClickHandler}>
          <IconChevron opened={isOpened} />
          <span>{`${name}${nbRelations}`}</span>
        </RelationGroupNameContainer>
        <RelationGroupItemHeaderContextMenuButton ref={exceptRef} onClick={openContextualMenuGroup}>
          <IconThreeDots />
        </RelationGroupItemHeaderContextMenuButton>
        <RelationMenuGroupWrapper ref={groupMenuEl} opened={menuGroupOpened} yPos={menuGroupPosY}>
          {/*
            <RegularButtonFlexed>
              <ButtonIconWrapper>
                <IconMapDraw />
              </ButtonIconWrapper>
              <ButtonLabel className='important'>{t('relations.groupmapping')}</ButtonLabel>
            </RegularButtonFlexed>
            * */}
          <RegularButtonFlexed onClick={renameGroup}>
            <ButtonIconWrapper>
              <IconEmpty />
            </ButtonIconWrapper>
            <ButtonLabel>{t('relations.renamegroup')}</ButtonLabel>
          </RegularButtonFlexed>
          {relations?.length ? (
            <RelationGroupRemoveEntry>
              <ButtonIconWrapper>
                <IconEmpty />
              </ButtonIconWrapper>
              <div className="block-text">
                <div className="entry">
                  <ButtonLabel>{t('relations.removegroup')}</ButtonLabel>
                  <RegularButtonFlexed
                    onClick={() => {
                      setRemoveNote(!showRemoveNote);
                    }}
                  >
                    <ButtonIconWrapper>{iconBadgeWarning.icon}</ButtonIconWrapper>
                  </RegularButtonFlexed>
                </div>
                {showRemoveNote && <div className="note">{t('relations.inforemove')}</div>}
              </div>
            </RelationGroupRemoveEntry>
          ) : (
            <RegularButtonFlexed
              onClick={removeGroup}
              className={relations?.length ? 'disabled' : ''}
            >
              <ButtonIconWrapper>
                <IconEmpty />
              </ButtonIconWrapper>
              <ButtonLabel>{t('relations.removegroup')}</ButtonLabel>
            </RegularButtonFlexed>
          )}
        </RelationMenuGroupWrapper>
      </RelationGroupItemHeader>
      {relations && !!relations.length && (
        <ShowHide opened={isOpened}>
          <RelationItemsWrapper>
            {relations.map((r, index) => {
              return (
                <RelationItem
                  relationId={r.id || (r.relationId as number)}
                  key={`${r.name}${index}`}
                  person={r}
                  isOpened={!!currentOpenedRelation && currentOpenedRelation.id === r.id}
                  onAddToGroup={addRelationToGroup}
                  onRemove={removeRelation}
                  onIdentifyColor={identifyRelation}
                  onOpenRelation={onOpenRelation}
                  onManageRelation={manageRelation}
                  onMakeAppointmentToRelation={onMakeAppointmentToRelation}
                />
              );
            })}
          </RelationItemsWrapper>
        </ShowHide>
      )}
    </RelationGroupItemWrapper>
  );
};

export default RelationGroupItem;
