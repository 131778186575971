import styled from 'styled-components';
import { slideCommonWrapper, SlideContentWrapper } from 'pages/relations/identification/RelationIdentification.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { prem } from 'styles/helpers/spacing';
import { firstLetterUpper } from 'styles/helpers/utils';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const RelationQuizIdentificationWrapper = styled.div`
  ${slideCommonWrapper};

  height: 100%;

  .result {
    .colors-names {
      margin-bottom: ${prem(50)};

      p {
        text-align: center;
        color: var(--ion-text-color-secondary);
      }
    }

    .icon-profile-wrapper {
      margin: 0 auto ${prem(30)};
      width: ${prem(90)};
      height: ${prem(90)};
      color: transparent;
    }
  }

  ${IonButtonWrapper} {
    margin-bottom: ${prem(24)};

    ion-button::part(native) {
      padding: 1rem;
    }

    @media (${MQ_MAX_HEIGHT}) {
      margin-bottom: ${prem(16)};
    }
  }

  ${SlideContentWrapper} {
    @media (${MQ_MAX_HEIGHT}) {
      justify-content: unset;
    }
  }

  @media (${MQ_MAX_HEIGHT}) {
    padding-bottom: ${prem(100)};
  }
`;

const QuestionLabel = styled.p`
  ${firstLetterUpper};
  text-align: center;
  margin-top: ${prem(24)};

  &.result {
    font-weight: bold;
  }
`;

const ResponseLabel = styled.span`
  ${firstLetterUpper};
`;

export { RelationQuizIdentificationWrapper, ResponseLabel, QuestionLabel };
