import React, { useEffect, useState } from 'react';
import {
  RelationColorSelectorWrapper,
  SelectedColorContainer,
} from 'components/relations/relation/color-selector/RelationColorSelector.styled';
import IconProfile, { IconProfileProps } from 'components/common/icons/icon-profile/IconProfile';
import COLORS from 'styles/constants/colors';
import { getColorCodeById, getColorNameById } from 'helpers/colors';
import { useTranslation } from 'react-i18next';
import { ColorLabel } from 'components/colours-picker/ColoursPicker.styled';
import ColoursPicker from 'components/colours-picker/ColoursPicker';
import { IColor } from 'types/app';

export const enum ColorType {
  PRIMARY,
  SECONDARY,
}

export interface RelationColorSelectorProps {
  type: ColorType;
  colorLabel: string;
  onSelect?: (colorId: number) => void;
  className?: string;
  defaultColorId?: number | null;
  colors?: Array<IColor>
}

const RelationColorSelector: React.FC<RelationColorSelectorProps> = ({
  type,
  onSelect,
  colorLabel,
  className,
  defaultColorId,
  colors
}) => {
  const { i18n } = useTranslation('translation');
  const [selectedColorId, setColorId] = useState<number | undefined | null>(defaultColorId);
  const [iconProfileProps, updateProfileProps] = useState<IconProfileProps>({});
  const [selectedColorLabel, setSelectedColorLabel] = useState<string | null | undefined>(
    defaultColorId ? getColorNameById(defaultColorId, i18n.language) : null,
  );
  const [selectedColorCode, setSelectedColorCode] = useState<string | null | undefined>(
    defaultColorId ? getColorCodeById(defaultColorId) : undefined,
  );
  const onSelectColor = (colorId: number) => {
    const label = getColorNameById(colorId, i18n.language);
    const code = getColorCodeById(colorId);

    setColorId(colorId);
    label && setSelectedColorLabel(label);
    code && setSelectedColorCode(code);
    onSelect && onSelect(colorId);
  };

  useEffect(() => {
    const props: IconProfileProps = {};

    if (type === ColorType.PRIMARY) {
      props.secondaryColor = 'white';
      if (selectedColorId) {
        props.primaryColorId = selectedColorId;
      } else {
        props.primaryColor = COLORS.ACCENT.GREEN;
      }
    } else {
      props.primaryColor = 'white';
      if (selectedColorId) {
        props.secondaryColorId = selectedColorId;
      } else {
        props.secondaryColor = COLORS.ACCENT.GREEN;
      }
    }

    updateProfileProps(props);
  }, [type, selectedColorId]);

  return (
    <RelationColorSelectorWrapper className={className}>
      <SelectedColorContainer>
        <IconProfile {...iconProfileProps} />
        <div>
          <h3>{colorLabel}</h3>
          {selectedColorLabel && (
            <ColorLabel color={`${selectedColorCode}`}>{selectedColorLabel}</ColorLabel>
          )}
        </div>
      </SelectedColorContainer>
      <ColoursPicker onSelectColor={onSelectColor} colors={colors} selectedColorId={selectedColorId} />
    </RelationColorSelectorWrapper>
  );
};

export default RelationColorSelector;
