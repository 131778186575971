import { IonButtonWrapper } from 'components/common/button/Button.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { StepWrapper } from 'pages/profile/has-profile/color-profile/step/Step.styled';
import { PageInnerTitle } from 'layout/page/Page.layout.styled';

const ColorProfileWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .description {
    color: var(--ion-text-color-secondary);
    text-align: center;
  }

  .profile-picture {
    width: 100%;
    max-width: ${prem(200)};
    margin: 0 auto;
    display: block;
  }

  .final-profile-picture {
    border-radius: 50%;
    max-width: ${prem(200)};
    margin: 0 auto;
    box-shadow: #979292 ${prem(-10)} ${prem(3)} ${prem(14)} ${prem(2)},
      #97979b ${prem(0)} ${prem(-2)} ${prem(7)} ${prem(1)},
      #bcbcc3 ${prem(1)} ${prem(4)} ${prem(17)} ${prem(0)};
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: ${prem(25)};

    ${IonButtonWrapper} {
      max-width: ${prem(200)};
      margin: 0 auto;
    }

    ${StepWrapper} {
      width: ${prem(900)};
      max-width: 100%;
      margin: 0 auto;
      align-items: center;
    }
  }
`;

const ChooseImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${prem(25)};
`;

const ChooseColorWrapper = styled.div`
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${prem(25)};

  .cropped-profile-picture {
    border-radius: 50%;
    max-width: ${prem(200)};
    margin: 0 auto;
    box-shadow: #979292 ${prem(-10)} ${prem(3)} ${prem(14)} ${prem(2)},
      #97979b ${prem(0)} ${prem(-2)} ${prem(7)} ${prem(1)},
      #bcbcc3 ${prem(1)} ${prem(4)} ${prem(17)} ${prem(0)};
  }
`;

const DownloadPictureWrapper = styled.div`
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${prem(25)};

  ${PageInnerTitle} {
    margin: 0;
  }
  
  .buttons {
    display: flex;
    gap: ${prem(20)};
  }
`;

export { ColorProfileWrapper, ChooseImageWrapper, ChooseColorWrapper, DownloadPictureWrapper };
