import styled from 'styled-components';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import COLORS from 'styles/constants/colors';

const TOCContentWrapper = styled.div<{ open?: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  width: 94%;
  max-height: 95%;
  transform: ${({ open }) => open ? 'translateX(0) translateY(-50%)' : 'translateX(-102%) translateY(-50%)'};
  background-color: white;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 0.25);
  padding: 20px 14px 14px 26px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 0.3s ease-out;

  .btn-toc {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 50%;
    right: ${({ open }) => open ? '-19px' : '-34px'};
    transform: translateY(-50%);
    pointer-events: auto;
    box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 0.25);
    border-radius: 100%;
    transition: all 0.3s ease-out;

    @media (min-width: 960px) {
      right: ${({ open }) => !open && '-47px'};
    }
  }
`;

const TOCContainer = styled.div<{ open?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: ${({open}) => open ? 'auto' : 'none'};
`;

const TOCTitle = styled.h1`
  text-align: center;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  font-size: 24px;
`;

const TOCContent = styled.div`
  font-size: 14px;
  overflow-y: auto;
  flex: 1;
  padding-right: 20px;

  & * {
    color: ${COLORS.BASE.BROWN.DEFAULT};
  }

  ul {
    list-style-type: disc;
    margin: 6px 0 0;
    padding: 0;
  }

  li {
    margin-bottom: 4px;

    &.first-level {
      margin-bottom: 14px;
    }
  }

  a {
    text-decoration: none;
  }

  li > ul {
    padding-left: 26px;
  }
`;

export {
  TOCContainer,
  TOCContentWrapper,
  TOCTitle,
  TOCContent
};
