import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const CheckWrapper = styled.div<{ disabled?: boolean }>`
  width: ${prem(20)};
  height: ${prem(20)};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  pointer-events: none;
  border: ${prem(2)} solid var(--ion-color-accent);
  border-radius: ${prem(3)};

  &::before {
    content: '';
    position: absolute;
    display: none;
    width: ${prem(13)};
    height: ${prem(13)};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--ion-color-accent);
    z-index: 2;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: none;
      opacity: 0.3;

      &::before {
        display: block;
        width: 100%;
        height: 100%;
        background: var(--ion-color-medium);
      }
    `}
`;

const CheckboxContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: ${prem(10)};

  input[type='checkbox'] {
    cursor: pointer;
    opacity: 0;
    width: ${prem(20)};
    height: ${prem(20)};
  }

  input:checked {
    & ~ ${CheckWrapper} {
      &::before {
        display: block;
      }
    }
  }
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
  text-align: left;
`;

const CheckboxWrapper = styled.div`
  text-align: left;
`;

export { CheckWrapper, CheckboxContainer, CheckboxWrapper, CheckboxLabel };
