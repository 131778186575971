import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MyProfileWrapper } from 'pages/profile/has-profile/my-profile/MyProfile.styled';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import useAPIProfile from 'hooks/api/useAPIProfile';
import { IProfile } from 'types/app';
import ProfileDetails from 'components/profile/has-profile/profile-details/ProfileDetails';
import { GenericDiv } from 'styles/helpers/utils';
import Button from 'components/common/button/Button';
import { ROUTES } from 'constants/routes';
import { Share } from '@capacitor/share';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import ShareProfileModal from 'components/profile/has-profile/my-profile/share-profile-modal/ShareProfileModal';

const MyProfile: React.FC = () => {
  const { t } = useTranslation('translation');
  const [profileDetails, setProfileDetails] = useState<IProfile>();
  const { getProfileDetails } = useAPIProfile();
  const { user } = useContext(UserContext) as UserContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const [canShare, setCanShare] = useState<boolean>();

  const loadDatas = useCallback(async () => {
    setProfileDetails(await getProfileDetails());
  }, []);

  useEffect(() => {
    loadDatas();
  }, []);

  const canShareNative = useCallback(async () => {
    setCanShare((await Share.canShare()).value);
  }, []);

  useEffect(() => {
    canShareNative();
  }, []);

  const shareProfile = async () => {
    try {
      if (canShare === undefined) return;
      if (canShare) {
        await Share.share({
          title: t('profile.myprofile.shareyourcolors.shareapi.title', {
            name: `${user?.firstname} ${user?.lastname}`,
          }) as string,
          text: t('profile.myprofile.shareyourcolors.shareapi.text', {
            name: `${user?.firstname} ${user?.lastname}`,
          }) as string,
          url: `${ROUTES.SHARED_PROFILE}?_token=${profileDetails?.token?.value}`,
        });
      } else {
        const urlToCopy = `${window.location.origin}${ROUTES.SHARED_PROFILE}?_token=${profileDetails?.token?.value}`;

        updateModalConf({
          component: <ShareProfileModal urlToCopy={urlToCopy} />,
        });
      }
    } catch (e) {
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    }
  };

  return (
    <MyProfileWrapper className="app-page">
      <PageInnerTitle>{t('profile.myprofile.title')}</PageInnerTitle>
      {profileDetails && (
        <>
          <ProfileDetails profileDetails={profileDetails} pronoun="your" />
          <Container className="share-profile">
            <div className="share-profile-title">
              <strong>{t('profile.myprofile.shareyourcolors.title')}</strong>
            </div>
            <GenericDiv center minWidth="100px" maxWidth="250px">
              <Button fill="solid" color="accent" onClick={async () => await shareProfile()}>
                {t('profile.myprofile.shareyourcolors.ctalabel')}
              </Button>
            </GenericDiv>
          </Container>
        </>
      )}
    </MyProfileWrapper>
  );
};

export default MyProfile;
