import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { ITestimonial } from 'types/app';
import {
  AddTestimonialButtonWrapper,
  TestimonialsWrapper,
} from 'pages/models/testimonials/Testimonials.styled';
import TestimonialsList from 'components/models/testimonials/testimonialslist/TestimonialsList';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import AddTestimonial from 'pages/models/testimonials/addtestimonial/AddTestimonial';
import { SwiperSlide, useSwiper } from 'swiper/react';
import useAPIModels from 'hooks/api/useAPIModels';
import Slider from 'components/slider/Slider';
import { UserContext, UserContextProps } from 'contexts/UserContext';

const Testimonials: React.FC = () => {
  const { t } = useTranslation('translation');
  const { getTestimonials } = useAPIModels();
  const { user } = useContext(UserContext) as UserContextProps;

  const {
    icons: [iconAdd],
  } = useIcons([ICON_NAMES.ADD]);

  const [testimonials, setTestimonials] = useState<Array<ITestimonial>>();

  const loadDatas = useCallback(async () => {
    setTestimonials(await getTestimonials());
  }, []);

  useEffect(() => {
    loadDatas();
  }, []);

  const AddTestimonialButtonContainer = useMemo(() => {
    const Component: React.FC<any> = () => {
      const swiper = useSwiper();

      return (
        <AddTestimonialButtonWrapper
          className="button-add-testimonial"
          opacity={1}
          fill="solid"
          color="orangebutton"
          onClick={() => {
            swiper.slideNext();
          }}
          expand
          small
        >
          <div className="inner-button">
            <label>{t('model.testimonials.add_my_testimonial')}</label>
            <div className="icon">{iconAdd.icon}</div>
          </div>
        </AddTestimonialButtonWrapper>
      );
    };
    return Component;
  }, []);

  const TestimonialsComponent = useMemo(() => {
    const Component: React.FC = () => {
      return (
        <TestimonialsWrapper className="app-page">
          <Container className="testimonial-container">
            <PageInnerTitle
              dangerouslySetInnerHTML={{
                __html: t('model.testimonials.title'),
              }}
            />
            {testimonials && <TestimonialsList testimonials={testimonials} />}
          </Container>
        </TestimonialsWrapper>
      );
    };
    return Component;
  }, [testimonials]);

  return (
    <Slider allowTouchMove={false}>
      <SwiperSlide className="testimonials-swipper-slide">
        <TestimonialsComponent />
        {user && <AddTestimonialButtonContainer />}
      </SwiperSlide>
      {user && (
        <SwiperSlide>
          <AddTestimonial />
        </SwiperSlide>
      )}
    </Slider>
  );
};

export default Testimonials;
