export function saveToStorage(key: string, value: string, isSession = false) {
  isSession ? window.sessionStorage.setItem(key, value) : window.localStorage.setItem(key, value);
}

export function getFromStorage(key: string, isSession = false): string | null {
  return isSession ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);
}

export function removeFromStorage(key: string, isSession = false) {
  isSession ? window.sessionStorage.removeItem(key) : window.localStorage.removeItem(key);
}
