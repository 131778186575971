import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const TestimonialsListWrapper = styled.div`
  color: var(--ion-text-color-secondary);
  min-height: 20vh;
  height: 100%;

  .single-testimonial {
    margin-bottom: ${prem(35)};
  }
`;

export { TestimonialsListWrapper };
