import React, { useContext, useEffect, useState } from 'react';
import { NotificationRelationInvitationWrapper } from 'components/relations/relation/notifications/invitation/NotificationRelationInvitation.styled';
import { ReactComponent as IconArrowCircle } from 'assets/svg/icon-relation-arrow-circle.svg';
import { NotificationAdviceTitle } from 'components/advice/NotificationAdvice.styled';
import { TimeLabel, getLocaleValue, getTimeLabel } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import { AdviceTime } from 'components/advice/Advice.styled';
import { IAdvice } from 'types/app';
import useAPIRelations, { InvitationType } from 'hooks/api/useAPIRelations';
import AcceptInvitationModal from 'components/relations/invitations/single-relation-invitation/accept-invitation-modal/AcceptInvitationModal';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';

export interface NotificationRelationInvitationProps {
  notif: IAdvice;
}

const NotificationRelationInvitation: React.FC<NotificationRelationInvitationProps> = ({
  notif,
}) => {
  const { t, i18n } = useTranslation('translation');
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const [timePast, setTimePast] = useState<TimeLabel>();
  const [timeLabelKey, setTimeLabelKey] = useState<string>();
  const { user } = useContext(UserContext) as UserContextProps;
  const { refuseRelationInvitation } = useAPIRelations();
  const { loadNotifications } = useAPIAdvices();

  useEffect(() => {
    setTimePast(getTimeLabel(notif.date));
  }, [notif]);

  useEffect(() => {
    if (timePast) {
      setTimeLabelKey(
        timePast.unit ? (t(`common.${timePast.label}`, { count: timePast.unit }) as string) : '',
      );
    }
  }, [timePast]);

  const onRefuseInvitation = async (notificationId: number) => {
    await refuseRelationInvitation(notificationId);
    updateModalConf();
    await loadNotifications(AdviceType.ALL, user?.id as number);
  };

  const onClickInvitationConfirmation = () => {
    updateModalConf({
      component: (
        <AcceptInvitationModal
          notificationId={notif.notificationId as number}
          title={notif.advice}
          onRefuseInvitation={async () => {
            onRefuseInvitation(notif.notificationId as number);
          }}
        />
      ),
    });
  };

  return (
    <NotificationRelationInvitationWrapper>
      <NotificationAdviceTitle>{notif.title}</NotificationAdviceTitle>
      <div className="relation-invitation-description-wrapper">
        <div className="description-text">{getLocaleValue(notif.advice, i18n.language)}</div>
        {notif.type === InvitationType.NEW_INVITATION && (
          <div
            className="icon"
            onClick={() => {
              onClickInvitationConfirmation();
            }}
          >
            <IconArrowCircle />
          </div>
        )}
      </div>
      <div className="date">
        {timePast && timePast.unit && (
          <AdviceTime>
            {t('common.timepast', { timeLabel: `${timePast.unit} ${timeLabelKey}` })}
          </AdviceTime>
        )}
      </div>
    </NotificationRelationInvitationWrapper>
  );
};

export default NotificationRelationInvitation;
