import React from 'react';
import { IonTabBar, IonTabButton } from '@ionic/react';
import { IComponentWithChildren } from 'types/app';
import { Route, useLocation } from 'react-router';
import { Components } from '@ionic/core';
import { IIcon } from 'hooks/useIcons';
import IconTab from 'components/common/icons/icon-tab/IconTab';
import { IonTabButtonLabel, IonTabsInnerStyled } from 'layout/ionic/IonTabBar.styled';
import { InnerPageLayoutWrapper } from 'layout/page/innerpage/InnerPage.layout.styled';
import { matchPath, Redirect } from 'react-router-dom';

export interface RedirectorRouteProps extends IComponentWithChildren {
  tabs: Array<IIcon>;
  path?: string;
  exact?: boolean;
}

// Check if route is in tabs
// if not redirect to first tab
export const RedirectorRoute: React.FC<RedirectorRouteProps> = ({ tabs, children, ...rest }) => {
  return tabs.length ? (
    <Route
      {...rest}
      render={({ location }) => {
        const pathname = location.pathname || location.location?.pathname;
        const isLocationInTabs = tabs.some((icon) => icon.route === pathname);

        console.log('isLocationInTabs: ', isLocationInTabs);
        if (isLocationInTabs) {
          return children;
        } else {
          // get the first tab and redirect to its route
          return (
            <Redirect
              to={{
                pathname: tabs[0].route,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  ) : (
    <></>
  );
};

export interface InnerPageLayoutProps extends IComponentWithChildren {
  tabs: Array<IIcon>;
  activeTab?: string;
  onSelectTab?: (tabName: string) => void;
  className?: string;
}

const InnerPageLayout: React.FC<InnerPageLayoutProps> = ({
  tabs,
  onSelectTab,
  className,
  children,
}): React.ReactElement => {
  const location = useLocation();
  const pathname = location.pathname || location.location.pathname;

  return (
    <InnerPageLayoutWrapper className={className}>
      <IonTabsInnerStyled>
        {children}
        <IonTabBar slot="top">
          {!!tabs?.length &&
            tabs.map((item, index) => {
              const buttonProps: Partial<Components.IonTabButton> = {
                tab: item.name,
              };
              // If have route
              if (item.route) {
                buttonProps.href = item.route;
              }

              if (item.hrefs) {
                buttonProps.selected = item.hrefs.some((href) => {
                  const match = matchPath(pathname, {
                    path: href,
                    exact: true,
                  });

                  return !!match;
                });
              }

              return (
                <IonTabButton
                  {...buttonProps}
                  key={`profile-${item.name.toLowerCase()}-${index}`}
                  onClick={() => {
                    onSelectTab && onSelectTab(item.name);
                  }}
                  disabled={item.disabled}
                >
                  <IconTab>{item.icon}</IconTab>
                  <IonTabButtonLabel>{item.label}</IonTabButtonLabel>
                </IonTabButton>
              );
            })}
        </IonTabBar>
      </IonTabsInnerStyled>
    </InnerPageLayoutWrapper>
  );
};

export default InnerPageLayout;
