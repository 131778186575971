import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { centerize } from 'styles/helpers/utils';
import { SUBTITLE } from 'styles/helpers/typography';
import { RegularButtonFlexed } from 'components/common/button/Button.styled';

const NotificationsWrapper = styled.div`
  padding-top: ${prem(20)} 0;
`;
const NoNotificationsWrapper = styled.div`
  height: 100%;
  ${centerize};

  .subscribe-text {
    text-align: center;
    margin: ${prem(24)} 0;
  }
`;
const NoNotificationsPix = styled.div`
  min-width: ${prem(190)};
  max-width: ${prem(400)};
  width: 63%;
  margin: ${prem(30)} auto;
`;

const NoNotificationsNote = styled.p`
  ${SUBTITLE.medium};
  text-align: center;
  width: 60%;
  max-width: ${prem(250)};
  min-width: ${prem(150)};
  margin: 0 auto;
`;

const NotificationsListSelectFilterWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: ${prem(20)};

  .inner {
    width: 30%;
    min-width: ${prem(110)};

    ${RegularButtonFlexed} {
      height: 100%;
    }
  }
`;

const NotificationsListWrapper = styled.div``;

export {
  NotificationsListSelectFilterWrapper,
  NoNotificationsNote,
  NoNotificationsPix,
  NotificationsWrapper,
  NoNotificationsWrapper,
  NotificationsListWrapper,
};
