const BASE = {
  GREEN: {
    DEFAULT: '#437D76',
    LIGHTER: 'rgba(0 210 163 / 0.15)',
    LIGHTEST: 'rgba(32 204 153 / 0.15)',
  },
  ORANGE: {
    DEFAULT: '#FD6E41',
  },
  YELLOW: {
    DEFAULT: '#FCC521',
  },
  RED: {
    DEFAULT: '#E2001A',
  },
  BROWN: {
    DEFAULT: '#989184',
  },
  BLUE: {
    DEFAULT: '#006496',
  },
  PURPLE: {
    DEFAULT: '#704A97',
  }
};

const ACCENT = {
  GREEN: '#54C5A3',
  ORANGE: '#FD6E41',
};

const FORM = {
  ERROR: {
    DEFAULT: '#FD6E41',
  },
  REQUIRED: {
    DEFAULT: '#EB5757',
  },
};

const COLORS = { FORM, BASE, ACCENT };

export default COLORS;
