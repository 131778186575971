import React from 'react';
import { IMySummaryProfileSchemaExplanation } from 'types/app';
import { Tooltip } from 'react-tooltip';
import COLORS from 'styles/constants/colors';

interface IMySummaryProfileSchema {
  dataCells?: IMySummaryProfileSchemaExplanation;
}

const TOOLTIP_MAX_WIDTH = '80%';
const TOOLTIP_BORDER = '1px solid white';
const TOOLTIP_OFFSET = 2;
const TOOLTIP_DELAY_SHOW = 500;

const MySummaryProfileSchemaExplanation: React.FC<IMySummaryProfileSchema> = ({
  dataCells,
}: IMySummaryProfileSchema) => {

  return (
    <>
      <Tooltip
        id="primary-color-zone" events={['click']}
        className="schema-tooltip"
        opacity={1}
        delayHide={0}
        delayShow={TOOLTIP_DELAY_SHOW}
        border={TOOLTIP_BORDER}
        offset={TOOLTIP_OFFSET}
        style={{
          backgroundColor: COLORS.BASE.BLUE.DEFAULT,
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell1}</p>${dataCells?.cell3}`
        }} />
      </Tooltip>
      <Tooltip
        id="secondary-color-zone" events={['click']}
        className="schema-tooltip"
        border={TOOLTIP_BORDER}
        offset={TOOLTIP_OFFSET}
        delayShow={TOOLTIP_DELAY_SHOW}
        opacity={1}
        style={{
          backgroundColor: COLORS.BASE.ORANGE.DEFAULT,
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell2}</p>${dataCells?.cell4}<p>${dataCells?.cell6}</p>`
        }} />
      </Tooltip>
      <Tooltip
        id="purple-progression-zone" events={['click']}
        className="schema-tooltip"
        border={TOOLTIP_BORDER}
        delayShow={TOOLTIP_DELAY_SHOW}
        offset={-25}
        opacity={1}
        style={{
          backgroundColor: COLORS.BASE.PURPLE.DEFAULT,
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell5}</p>`
        }} />
      </Tooltip>
      <Tooltip
        id="red-progression-zone" events={['click']}
        className="schema-tooltip"
        border={TOOLTIP_BORDER}
        offset={TOOLTIP_OFFSET}
        delayShow={TOOLTIP_DELAY_SHOW}
        opacity={1}
        style={{
          backgroundColor: '#CC0001',
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell5}</p>`
        }} />
      </Tooltip>
      <Tooltip
        id="blue-progression-zone" events={['click']}
        className="schema-tooltip"
        border={TOOLTIP_BORDER}
        offset={TOOLTIP_OFFSET}
        delayShow={TOOLTIP_DELAY_SHOW}
        opacity={1}
        style={{
          backgroundColor: COLORS.BASE.BLUE.DEFAULT,
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell5}</p>`
        }} />
      </Tooltip>
      <Tooltip
        id="green-progression-zone" events={['click']}
        className="schema-tooltip"
        border={TOOLTIP_BORDER}
        offset={TOOLTIP_OFFSET}
        delayShow={TOOLTIP_DELAY_SHOW}
        opacity={1}
        style={{
          backgroundColor: '#669934',
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell5}</p>`
        }} />
      </Tooltip>
      <Tooltip
        id="yellow-progression-zone" events={['click']}
        className="schema-tooltip"
        border={TOOLTIP_BORDER}
        offset={TOOLTIP_OFFSET}
        delayShow={TOOLTIP_DELAY_SHOW}
        opacity={1}
        style={{
          backgroundColor: '#F1A81E',
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell5}</p>`
        }} />
      </Tooltip>
      <Tooltip
        id="orange-progression-zone" events={['click']}
        className="schema-tooltip"
        border={TOOLTIP_BORDER}
        offset={TOOLTIP_OFFSET}
        delayShow={TOOLTIP_DELAY_SHOW}
        opacity={1}
        style={{
          backgroundColor: '#F0611B',
          color: 'white',
          maxWidth: TOOLTIP_MAX_WIDTH
        }}
      >
        <div className="tooltip-wrapper" dangerouslySetInnerHTML={{
          __html: `<p>${dataCells?.cell5}</p>`
        }} />
      </Tooltip>
      <svg height="0" width="0">
        <filter id='color-glow' colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="5" floodOpacity="1" floodColor="white" />
        </filter>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        preserveAspectRatio="none"
        x="0px"
        y="0px"
        width="360px"
        height="360px"
        viewBox="0 0 360 360"
      >
        <defs>
          <g id="Layer12_0_FILL" data-tooltip-id="primary-color-zone">
            <path
              fill="#006496"
              stroke="none"
              d="
M 299.85 58.75
Q 250.1 9 179.65 9 109.25 9 59.4 58.75 9.65 108.6 9.65 179 9.65 249.45 59.4 299.2 109.25 349 179.65 349 250.1 349 299.85 299.2 349.65 249.45 349.65 179 349.65 108.6 299.85 58.75
M 261.7 98.25
Q 295.6 132.1 295.6 180 295.6 227.9 261.7 261.7 227.9 295.6 180 295.6 132.1 295.6 98.25 261.7 64.45 227.9 64.45 180 64.45 132.1 98.25 98.25 132.1 64.45 180 64.45 227.9 64.45 261.7 98.25 Z"
            />
          </g>

          <g id="Layer10_0_FILL" data-tooltip-id="purple-progression-zone">
            <path
              fill="#704A97"
              stroke="none"
              d="
M 82.8 179
Q 82.8 179.05 82.8 179.1 82.8 219.35 111.2 247.85 120.437890625 257.087890625 131 263.3
L 163.85 206.4
Q 160.4 204.35 157.35 201.3 148.15 192.05 148.15 179
L 82.8 179 Z"
            />

            <path
              fill="#D4C8E0"
              stroke="none"
              d="
M 82.8 179.1
Q 82.8 179.05 82.8 179
L 54.15 179
Q 54.15 230.95 90.9 267.7 103 279.85 116.8 288
L 131 263.3
Q 120.437890625 257.087890625 111.2 247.85 82.8 219.35 82.8 179.1 Z"
            />

            <path
              fill="#0169B2"
              stroke="none"
              d="
M 201.9 156.75
Q 198.85 153.7 195.45 151.7 188.4 147.5 179.65 147.5 170.9 147.5 163.9 151.65 160.4 153.7 157.35 156.75 148.15 166 148.15 179 148.15 192.05 157.35 201.3 160.4 204.35 163.85 206.4 170.9 210.5 179.65 210.5 188.4 210.5 195.45 206.35 198.85 204.3 201.9 201.3 211.15 192.05 211.15 179 211.15 166 201.9 156.75 Z"
            />
          </g>

          <g id="Layer8_0_FILL" data-tooltip-id="red-progression-zone">
            <path
              fill="#CC0001"
              stroke="none"
              d="
M 195.45 206.35
Q 188.4 210.5 179.65 210.5 170.9 210.5 163.85 206.4
L 141.45 245.25
Q 158.4802734375 255.3 179.7 255.3 200.8640625 255.3 217.9 245.25
L 195.45 206.35 Z"
            />

            <path
              fill="#EFB3B4"
              stroke="none"
              d="
M 116.8 288
Q 117.7 288.5 118.65 289.05 145.95 304.5 179.65 304.5 214.5 304.5 242.55 287.95
L 217.9 245.25
Q 200.8640625 255.3 179.7 255.3 158.4802734375 255.3 141.45 245.25
L 116.8 288 Z"
            />
          </g>

          <g id="Layer7_0_FILL" data-tooltip-id="orange-progression-zone">
            <path
              fill="#F0611B"
              stroke="none"
              d="
M 305.15 179
L 211.15 179
Q 211.15 192.05 201.9 201.3 198.85 204.3 195.45 206.35
L 242.55 287.95
Q 244.9 286.5 247.25 285 258.35 277.7 268.35 267.7 305.15 230.95 305.15 179 Z"
            />
          </g>

          <g id="Layer5_0_FILL" data-tooltip-id="yellow-progression-zone">
            <path
              fill="#F1A81E"
              stroke="none"
              d="
M 226 132.8
Q 219.65 126.5 212.55 122.25
L 212.5 122.2 195.45 151.7
Q 198.85 153.7 201.9 156.75 211.15 166 211.15 179
L 245.2 179
Q 245.1822265625 152.03203125 226 132.8 Z"
            />

            <path
              fill="#FBE5BC"
              stroke="none"
              d="
M 268.35 90.25
Q 257.6 79.5 245.6 71.95 244.1 70.95 242.6 70.1
L 212.5 122.2 212.55 122.25
Q 219.65 126.5 226 132.8 245.1822265625 152.03203125 245.2 179
L 305.15 179
Q 305.15 177.65 305.15 176.35 304.2 126.05 268.35 90.25 Z"
            />
          </g>

          <g id="Layer3_0_FILL" data-tooltip-id="green-progression-zone">
            <path
              fill="#669934"
              stroke="none"
              d="
M 163.9 151.65
Q 170.9 147.5 179.65 147.5 188.4 147.5 195.45 151.7
L 219.1 110.75
Q 201.5279296875 100.3 179.7 100.3 157.848046875 100.3 140.25 110.7
L 163.9 151.65 Z"
            />

            <path
              fill="#D1E1C2"
              stroke="none"
              d="
M 242.6 70.1
Q 214.55 53.5 179.65 53.5 148.45 53.5 122.75 66.75 119.7 68.3 116.8 70.05
L 140.25 110.7
Q 157.848046875 100.3 179.7 100.3 201.5279296875 100.3 219.1 110.75
L 242.6 70.1 Z"
            />
          </g>

          <g id="Layer2_0_FILL" data-tooltip-id="blue-progression-zone">
            <path
              fill="#006496"
              stroke="none"
              d="
M 116.8 70.05
L 116.75 70.05
Q 103 78.15 90.9 90.25 55.6 125.55 54.2 174.95 54.15 176.95 54.15 179
L 148.15 179
Q 148.15 166 157.35 156.75 160.4 153.7 163.9 151.65
L 116.8 70.05 Z"
            />
          </g>

          <g id="Layer1_0_FILL" data-tooltip-id="secondary-color-zone">
            <path
              filter="url(#color-glow)"
              fill="#EF601A"
              stroke="none"
              d="
M 211.15 179
Q 211.15 166 201.9 156.75 192.65 147.5 179.65 147.5 166.6 147.5 157.35 156.75 148.15 166 148.15 179 148.15 192.05 157.35 201.3 166.6 210.5 179.65 210.5 192.65 210.5 201.9 201.3 211.15 192.05 211.15 179 Z"
            />
          </g>

          <path
            id="Layer0_0_1_STROKES"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
            d="
M 116.8 70.05
L 163.9 151.65
Q 170.9216796875 147.5 179.65 147.5 192.65 147.5 201.9 156.75 211.15 166 211.15 179
M 54.15 179
L 148.15 179
Q 148.15 166 157.35 156.75 160.41328125 153.68671875 163.9 151.65
M 196.725 205.525
Q 189.2048828125 210.5 179.65 210.5 170.905859375 210.5 163.875 206.375 160.4021484375 204.335546875 157.35 201.3 148.15 192.05 148.15 179"
          />
        </defs>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer12_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer10_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer8_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer7_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer5_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer3_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer2_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer1_0_FILL" />
        </g>

        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <use xlinkHref="#Layer0_0_1_STROKES" />
        </g>
      </svg>
    </>
  );
};

export default MySummaryProfileSchemaExplanation;
