import React, { useContext } from 'react';
import { IModule } from 'types/app';
import { AccessModuleWrapper } from 'components/resources/module/single-module/access-module/AccessModule.styled';
import Button from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';
import { MODULES_CODES } from 'constants/global';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface AccessModuleProps {
  module: IModule;
}

const AccessModule: React.FC<AccessModuleProps> = ({ module }) => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;

  const checkUserCanAccessBothElearning = () => {
    return user?.profileInfo?.tools.lmsWorkTogether && user.profileInfo.tools.lmsStress;
  };

  const checkUserCanOnlyAccessElearningWorkTogether = () => {
    return user?.profileInfo?.tools.lmsWorkTogether && !user.profileInfo.tools.lmsStress;
  };

  const checkUserCanOnlyAccessElearningBehaviourUnderStress = () => {
    return user?.profileInfo?.tools.lmsStress && !user?.profileInfo?.tools.lmsWorkTogether;
  };

  return (
    <AccessModuleWrapper>
      {module.hasAccess && (
        <>
          {module.code !== MODULES_CODES.ELEARNING && (
            <Button
              color="accent"
              fill="outline"
              type="submit"
              expand={true}
              routerLink={module.route}
              onClick={() => module.hasAccess && module.onClick && module.onClick()}
            >
              {module.hasAccess && t('resources.modules.iaccessthemodule')}
            </Button>
          )}
          {module.code === MODULES_CODES.ELEARNING && (
            <>
              {checkUserCanAccessBothElearning() && (
                <Button
                  color="accent"
                  fill="outline"
                  type="submit"
                  expand={true}
                  routerLink={module.route}
                  onClick={() => module.hasAccess && module.onClick && module.onClick()}
                >
                  {module.hasAccess && `${t('resources.modules.iaccessthemodule')}`}
                </Button>
              )}
              {checkUserCanOnlyAccessElearningWorkTogether() && (
                <>
                  <Button
                    color="accent"
                    fill="outline"
                    type="submit"
                    expand={true}
                    routerLink={module.route}
                    onClick={() => module.hasAccess && module.onClick && module.onClick()}
                  >
                    {module.hasAccess &&
                      `${t('resources.modules.iaccessthemodule')} ${module.title} - ${t(
                        'resources.modules.lmsworktogether',
                      )}`}
                  </Button>
                </>
              )}
              {checkUserCanOnlyAccessElearningBehaviourUnderStress() && (
                <>
                  <Button
                    color="accent"
                    fill="outline"
                    type="submit"
                    expand={true}
                    routerLink={module.route}
                    onClick={() => module.hasAccess && module.onClick && module.onClick()}
                  >
                    {module.hasAccess &&
                      `${t('resources.modules.iaccessthemodule')} ${module.title} - ${t(
                        'resources.modules.lmsstress',
                      )}`}
                  </Button>
                </>
              )}
            </>
          )}
        </>
      )}
    </AccessModuleWrapper>
  );
};

export default AccessModule;
