import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'layout/page/Page.layout.styled';
import { RelationAppointmentProps } from 'pages/relations/appointment/RelationAppointment';
import { SlideWrapper } from 'pages/relations/appointment/RelationAppointment.styled';
import { RelationAppointmentHomeWrapper } from 'pages/relations/appointment/slides/relation-appointment-home/RelationAppointmentHome.styled';
import RelationInteractionIconProfiles from 'components/relations/relation/interaction/interaction-icon-profiles/RelationInteractionIconProfiles';
import { IModule, IRelationInteraction, ITest, IUser } from 'types/app';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import QuestionnaireButton from 'components/profile/QuestionnaireButton';
import { getLocaleValue, removeAttributes } from 'helpers/utils';
import { isMale } from 'helpers/relations';
import useCollapse from 'hooks/useCollapse';
import Collapse from 'components/common/showhide/Collapse';
import { RelationInteractionDesc } from 'components/relations/relation/interaction/RelationInteraction.styled';
import { ROUTES } from 'constants/routes';
import { useIonRouter } from '@ionic/react';
import ButtonDoIdentification from 'components/relations/relation/interaction/ButtonDoIdentification';
import { NOTIFICATIONS } from 'constants/global';
import { disableBuyModuleButton, hideBuyModuleButton } from 'helpers/modules';
import ModuleTestAvailability from 'components/module-test/test-availability/ModuleTestAvailability';

export interface RelationAppointmentHomeProps extends RelationAppointmentProps {
  isTestRelationAppointmentValid?: boolean;
  onInitRelationAppointmentTest?: (relationId: number) => Promise<void>;
  onBuyRelationAppointmentModule?: (module: IModule) => any;
  canLaunchRelationAppointmentTest?: boolean;
  appointmentModule?: IModule;
  relationAppointmentTest?: ITest;
  hasRelationAppointmentTest?: boolean;
  hasAlreadyUsedTestOnRelation?: boolean;
}

const RelationAppointmentHome: React.FC<RelationAppointmentHomeProps> = ({
  appointment,
  gotoColorSelection,
  relation,
  isTestRelationAppointmentValid,
  onInitRelationAppointmentTest,
  canLaunchRelationAppointmentTest,
  relationAppointmentTest,
  hasRelationAppointmentTest,
  appointmentModule,
  onBuyRelationAppointmentModule,
  hasAlreadyUsedTestOnRelation,
}) => {
  const { t, i18n } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const ionRouter = useIonRouter();

  const hasModule = user ? user.profileInfo?.notifications?.includes(NOTIFICATIONS.SALE) : false;
  const interaction: IRelationInteraction = {
    id: 0,
    userPrimaryColorId:
      appointment?.vendorInteraction?.colourId || user?.profileInfo?.colors?.primary?.id || 0,
    relationPrimaryColorId: appointment?.buyerStyle?.colourId || relation?.primaryColorId || 0,
  };
  const { getOpenState, updateStates } = useCollapse([
    { id: 'style', opened: true },
    { id: 'interaction' },
    { id: 'guide' },
  ]);

  const styleLabel = useMemo((): string => {
    if (relation) {
      const pronoun = isMale(relation.pronoun) ? `${t('common.his')}` : `${t('common.her')}`;

      return `${pronoun.toLowerCase()} style`;
    }
    return '';
  }, [relation]);
  const onIdentifyingRelation = () => {
    ionRouter.push(`${ROUTES.RELATIONS.IDENTIFY}/${relation?.id}`);
  };

  return (
    <SlideWrapper>
      <RelationAppointmentHomeWrapper className="appointment-home-wrapper">
        <Container padding={0}>
          <RelationInteractionIconProfiles interaction={interaction} />
          {!!interaction.userPrimaryColorId && !!interaction.relationPrimaryColorId && (
            <div className="to-other-colours">
              {hasModule && (
                <>
                  <p>{t('relations.appointment.colours-note')}</p>
                  {(user?.profileInfo?.hasUniqueColor === false ||
                    relation?.hasUniqueColor === false) && (
                    <>
                      <GenericDiv
                        width="60%"
                        minWidth={prem(180)}
                        maxWidth={prem(250)}
                        center={true}
                      >
                        <Button
                          color="accent"
                          expand={true}
                          onClick={() => {
                            gotoColorSelection && gotoColorSelection();
                          }}
                        >
                          {t('relations.appointment.use-other-colours')}
                        </Button>
                      </GenericDiv>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {(hasModule || isTestRelationAppointmentValid) && (
            <>
              {/* User has no colors */}
              {!user?.profileInfo?.colors?.primary ? (
                <>
                  {/* User has no profile tool */}
                  {!user?.profileInfo?.tools.profile ? (
                    <div className="no-profile">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t('relations.appointment.no-profile-tool'),
                        }}
                      />
                      <GenericDiv width="50%" minWidth={prem(200)} center={true}>
                        <QuestionnaireButton
                          label={t('profile.discover.title')}
                          link={ROUTES.PROFILE.INDEX}
                          internalLink={true}
                        />
                      </GenericDiv>
                    </div>
                  ) : (
                    // User has profile tool but has no colors
                    <div className="no-profile">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `relations.appointment.${
                              !user?.profileInfo?.hasValidatedProfile
                                ? 'no-validated-profile'
                                : 'profile-in-progress'
                            }`,
                          ),
                        }}
                      />
                      {/* User has profile , no colors and has no validatedProfile */}
                      {!user?.profileInfo?.hasValidatedProfile && (
                        <GenericDiv
                          className="btn-quiz"
                          width="25%"
                          minWidth={prem(175)}
                          center={true}
                        >
                          <QuestionnaireButton
                            isOutline
                            user={user}
                            label={t('profile.questionnaire.title')}
                          />
                        </GenericDiv>
                      )}
                    </div>
                  )}
                </>
              ) : (
                // User has colors and has module or has test
                <>
                  {
                    /* 2° user profile but relation not identified */
                    !relation?.primaryColorId && (
                      <div className="no-profile">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t('relations.appointment.relation-no-colors', {
                              relationFullName: relation?.fullName,
                            }),
                          }}
                        />
                        <GenericDiv minWidth={prem(175)} center={true}>
                          <ButtonDoIdentification onClick={onIdentifyingRelation} />
                        </GenericDiv>
                      </div>
                    )
                  }
                </>
              )}
            </>
          )}
          {relationAppointmentTest &&
            hasRelationAppointmentTest &&
            isTestRelationAppointmentValid && (
              <ModuleTestAvailability
                module={appointmentModule as IModule}
                moduleTestInfos={relationAppointmentTest}
                onBuyModule={() => {
                  onBuyRelationAppointmentModule &&
                    onBuyRelationAppointmentModule(appointmentModule as IModule);
                }}
              />
            )}
          {appointment && (hasModule || isTestRelationAppointmentValid) && (
            <div className="appointment-collapses-wrapper">
              <Collapse
                label={styleLabel}
                opened={getOpenState('style')}
                onOpened={(o) => updateStates('style', o)}
                disabled={!appointment.buyerStyle}
                className={!appointment.buyerStyle ? 'disabled' : ''}
              >
                {appointment.buyerStyle?.description && (
                  <Container className="desc-wrapper">
                    <RelationInteractionDesc
                      dangerouslySetInnerHTML={{
                        __html: `${removeAttributes(
                          getLocaleValue(appointment.buyerStyle.description, i18n.language),
                        )}`,
                      }}
                    />
                  </Container>
                )}
              </Collapse>
              <Collapse
                label={`${t('relations.appointment.our-interaction')}`}
                opened={getOpenState('interaction')}
                onOpened={(o) => updateStates('interaction', o)}
                disabled={!appointment.vendorInteraction}
                className={!appointment.vendorInteraction ? 'disabled' : ''}
              >
                {appointment.vendorInteraction?.description && (
                  <Container className="desc-wrapper">
                    <RelationInteractionDesc
                      dangerouslySetInnerHTML={{
                        __html: `${removeAttributes(
                          getLocaleValue(appointment.vendorInteraction.description, i18n.language),
                        )}`,
                      }}
                    />
                  </Container>
                )}
              </Collapse>
              <Collapse
                label={`${t('relations.appointment.interview-guide')}`}
                opened={getOpenState('guide')}
                onOpened={(o) => updateStates('guide', o)}
                disabled={!appointment.interviewGuide}
                className={!appointment.interviewGuide ? 'disabled' : ''}
              >
                {appointment.interviewGuide?.description && (
                  <Container className="desc-wrapper">
                    <RelationInteractionDesc
                      dangerouslySetInnerHTML={{
                        __html: `${removeAttributes(
                          getLocaleValue(appointment.interviewGuide.description, i18n.language),
                        )}`,
                      }}
                    />
                  </Container>
                )}
              </Collapse>
            </div>
          )}
          {/* User doesn't have the appointment module */}
          {!hasModule && !isTestRelationAppointmentValid && (
            <Container className="no-appointment-module">
              <div className="desc-wrapper">{t('relations.appointment.no-appointment-module')}</div>
              <div className="no-appointment-module-cta">
                <div className="ctas">
                  {/* Relation doesn't have a color */}
                  {!relation?.primaryColorId ? (
                    <>
                      <div className="no-profile">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t('relations.appointment.relation-no-colors', {
                              relationFullName: relation?.fullName,
                            }),
                          }}
                        />
                        <GenericDiv minWidth={prem(175)} center={true}>
                          <ButtonDoIdentification onClick={onIdentifyingRelation} />
                        </GenericDiv>
                      </div>
                    </>
                  ) : (
                    // Relation has a color
                    <div className="buy-test-ctas">
                      {user &&
                        !isTestRelationAppointmentValid &&
                        canLaunchRelationAppointmentTest &&
                        !hideBuyModuleButton(user) && (
                          <Button
                            fill="outline"
                            color="accent"
                            onClick={() =>
                              onInitRelationAppointmentTest &&
                              relation &&
                              onInitRelationAppointmentTest(relation.id)
                            }
                            disabled={disableBuyModuleButton(user)}
                          >
                            {t('common.testmodule')}
                          </Button>
                        )}
                      {user &&
                        !isTestRelationAppointmentValid &&
                        !hideBuyModuleButton(user as IUser) &&
                        !disableBuyModuleButton(user as IUser) && (
                          <div className="buy-module">
                            {!canLaunchRelationAppointmentTest && (
                              <>
                                {hasAlreadyUsedTestOnRelation ? (
                                  <div className="cannot-test-anymore">
                                    {t('relations.management.buyaftertestexpiration')}
                                  </div>
                                ) : (
                                  <div className="cannot-test-anymore">
                                    {t('relations.management.buyafterusingtest')}
                                  </div>
                                )}
                              </>
                            )}
                            <Button
                              onClick={
                                () => {
                                  onBuyRelationAppointmentModule &&
                                    onBuyRelationAppointmentModule(appointmentModule as IModule);
                                }
                                // () => console.log(appointmentModule)
                              }
                              color="accent"
                              fill="outline"
                              expand={true}
                            >
                              {t('common.buymodule')}
                            </Button>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </Container>
          )}
        </Container>
      </RelationAppointmentHomeWrapper>
    </SlideWrapper>
  );
};

export default RelationAppointmentHome;
