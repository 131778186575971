import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const RelationsAppointmentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SlideWrapper = styled.div`
  height: 100%;
  margin-bottom: ${prem(100)};
`;

export { RelationsAppointmentWrapper, SlideWrapper };
