import React from 'react';
import { GuestScreenWrapper } from 'components/guest-screen/GuestScreen.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { GenericDiv } from 'styles/helpers/utils';
import Button from 'components/common/button/Button';

export interface GuestScreenProps {
  title?: string;
  description?: string;
  cta?: {
    label: string;
    onClick?: () => void;
  };
}

const GuestScreen: React.FC<GuestScreenProps> = ({ title, description, cta }) => {
  return (
    <GuestScreenWrapper>
      <Container flexCentered className="wrapper">
        <Container className="texts">
          <div className="title">{title}</div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: description as string,
            }}
          />
        </Container>
        <Container className="cta">
          <GenericDiv minWidth="100px" width="100%" maxWidth="300px" center>
            <Button color="accent" onClick={cta?.onClick && cta.onClick}>
              <span className="cta-label">{cta?.label}</span>
            </Button>
          </GenericDiv>
        </Container>
      </Container>
    </GuestScreenWrapper>
  );
};

export default GuestScreen;
