import React from 'react';
import { IIcon } from 'hooks/useIcons';
import InnerPageLayout from 'layout/page/innerpage/InnerPage.layout';
import { Route } from 'react-router';
import { ROUTES } from 'constants/routes';
import { IonRouterOutlet } from '@ionic/react';
import Motivation from 'pages/profile/has-profile/my-motivation/Motivation';
import MyProfile from 'pages/profile/has-profile/my-profile/MyProfile';
import ColorProfile from 'pages/profile/has-profile/color-profile/ColorProfile';
import MySummary from 'pages/profile/has-profile/my-summary/MySummary';

const HasProfile: React.FC<{ tabs: Array<IIcon> }> = ({ tabs }) => {
  return (
    <InnerPageLayout tabs={tabs} className="page-has-profile-wrapper">
      <IonRouterOutlet animated={false}>
        <Route exact path={ROUTES.PROFILE.INDEX} render={() => <MyProfile />} />
        <Route exact path={ROUTES.PROFILE.MY_PROFILE} render={() => <MyProfile />} />
        <Route exact path={ROUTES.PROFILE.MY_SUMMARY} render={() => <MySummary />} />
        <Route exact path={ROUTES.PROFILE.MY_MOTIVATION} render={() => <Motivation />} />
        <Route exact path={ROUTES.PROFILE.COLOUR_PROFILE} render={() => <ColorProfile />} />
      </IonRouterOutlet>
    </InnerPageLayout>
  );
};

export default HasProfile;
