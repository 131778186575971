import React, { useEffect, useRef } from 'react';
import {
  ProgressBarContainer,
  ProgressBarWrapper,
  ProgressionLabel,
  ProgressionLabelPath,
} from 'components/common/progress-bar/ProgressBar.styled';
import { ReactComponent as PastilleBg } from 'assets/svg/slider-level-bg.svg';

export interface ProgressBarProps {
  percent: number;
  title?: string;
  percentLabel?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, title, percentLabel }) => {
  const barEl = useRef<HTMLDivElement | null>(null);
  const labelEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (barEl.current && labelEl.current) {
      const pctValue = percent > 100 ? 100 : (percent < 0 ? 0 : percent);

      barEl.current.style.width = `${pctValue}%`;
      labelEl.current.style.left = `${pctValue}%`;
    }
  }, [percent]);

  return (
    <ProgressBarWrapper>
      {title && <p className="title">{title}</p>}
      <ProgressBarContainer>
        <div className="bar" ref={barEl} />
      </ProgressBarContainer>
      <ProgressionLabelPath>
        <ProgressionLabel ref={labelEl}>
          <span className="label-text">{percentLabel || `${percent}%`}</span>
          <PastilleBg />
        </ProgressionLabel>
      </ProgressionLabelPath>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
