import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY, SUBTITLE } from 'styles/helpers/typography';

const AppVersionWrapper = styled.div`
  color: var(--ion-text-color-secondary);
  text-align: center;
  ${BODY.small};
  margin: ${prem(40)} 0;
`;

const SettingLine = styled.div`
  margin-bottom: ${prem(24)};
`;

const SettingLineTile = styled.h3`
  ${SUBTITLE.small};
  margin-bottom: ${prem(20)};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${prem(20)};
  align-items: center;
  justify-content: center;
  padding: 0 ${prem(20)};
`;

const ButtonInnerWrapper = styled.div`
  width: 50%;
  max-width: ${prem(250)};
`;

const LinkTCU = styled.div`
  margin: ${prem(30)} 0 ${prem(16)};
  text-align: center;

  a {
    color: var(--ion-text-color-secondary);
  }
`;

export {
  SettingLine,
  SettingLineTile,
  ButtonsContainer,
  ButtonInnerWrapper,
  LinkTCU,
  AppVersionWrapper,
};
