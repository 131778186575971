import React from 'react';
import { NoInvitationWrapper } from 'components/relations/invitations/no-invitation/NoInvitation.styled';
import { useTranslation } from 'react-i18next';

const NoInvitation: React.FC = () => {
  const { t } = useTranslation('translation');

  return <NoInvitationWrapper>{t('relations.invitations.noinvitation')} ...</NoInvitationWrapper>;
};

export default NoInvitation;
