import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const ComColorWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: ${prem(16)};

  img {
    width: 100%;
    max-width: ${prem(600)};
    margin: 0 auto ${prem(25)};
    display: block;
  }

  p {
    margin-bottom: ${prem(20)};
  }

  .description {
    color: var(--ion-text-color-secondary);
  }
`;

export { ComColorWrapper };
