import React from 'react';
import { IRelationInteraction } from 'types/app';
import IconProfile, { IconProfileProps } from 'components/common/icons/icon-profile/IconProfile';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { IconProfilesContainer } from 'components/relations/relation/interaction/interaction-icon-profiles/RelationInteractionIconProfiles.styled';

export interface RelationInteractionIconProfilesProps {
  interaction: IRelationInteraction;
}

const RelationInteractionIconProfiles: React.FC<RelationInteractionIconProfilesProps> = ({
  interaction,
}) => {
  const {
    icons: [iconRelationInteraction],
  } = useIcons([ICON_NAMES.RELATION_INTERACTION]);
  const leftProfile = {
    primaryColorId: interaction.userPrimaryColorId,
    ...(interaction.userSecondaryColorId
      ? { secondaryColorId: interaction.userSecondaryColorId }
      : { secondaryColor: 'var(--ion-white-to-black)' }),
  } as IconProfileProps;
  const rightProfile = {
    primaryColorId: interaction.relationPrimaryColorId,
    ...(interaction.relationSecondaryColorId
      ? { secondaryColorId: interaction.relationSecondaryColorId }
      : { secondaryColor: 'var(--ion-white-to-black)' }),
  } as IconProfileProps;

  return (
    <IconProfilesContainer>
      <IconProfile {...leftProfile} />
      <div className="icon-interaction">{iconRelationInteraction.icon}</div>
      <IconProfile {...rightProfile} />
    </IconProfilesContainer>
  );
};

export default RelationInteractionIconProfiles;
