/**
 * Screen sizes
 */
const S = 0;
const SM = 350;
const M = 600;
const L = 960;
const XL = 1280;
const XXL = 1440;

const MQ_MAX_HEIGHT = 'max-height: 674px';

/**
 * Devices width
 */
const DEVICES = {
  S,
  SM,
  M,
  L,
  XL,
  XXL,
};

/**
 * Mobile first media query breakpoints
 */
const BREAKPOINTS = {
  S: `min-width: ${S}px`,
  SM: `min-width: ${SM}px`,
  M: `min-width: ${M}px`,
  L: `min-width: ${L}px`,
  XL: `min-width: ${XL}px`,
  XXL: `min-width: ${XXL}px`,
};

/**
 * Orientations
 */
enum ORIENTATIONS {
  PORTRAIT = 'orientation: portrait',
  LANDSCAPE = 'orientation: landscape',
}

export { DEVICES, BREAKPOINTS, ORIENTATIONS, MQ_MAX_HEIGHT };
