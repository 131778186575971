import React, { createContext, FC, useEffect, useState } from 'react';
import { IComponentWithChildren, IUser } from 'types/app';
import { LS_KEYS } from 'constants/global';
import { removeFromStorage, saveToStorage } from 'helpers/storage';

export interface UserContextProps {
  user?: IUser;
  updateUser: (user?: IUser) => void;
  isGuest: boolean;
}

export interface UserProviderProps extends IComponentWithChildren {
  user?: IUser;
}

const UserContext = createContext<UserContextProps | null>(null);
const UserProvider: FC<UserProviderProps> = ({ user, children }) => {
  const [currentUser, setUser] = useState(user);
  const [isGuest, setIsGuest] = useState(true);

  const updateUser = (user?: IUser) => {
    setUser(user);
    if (user) {
      saveToStorage(LS_KEYS.USER, JSON.stringify(user));
    } else {
      removeFromStorage(LS_KEYS.USER);
      removeFromStorage(LS_KEYS.SETTINGS);
    }
  };

  useEffect(() => {
    setIsGuest(!user);
  }, [user]);

  return (
    <UserContext.Provider value={{ user: currentUser, updateUser, isGuest }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
