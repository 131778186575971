import styled from 'styled-components';
import { RegularButtonFlexed } from 'components/common/button/Button.styled';

const DropDownLabel = styled.span``;

const DropdownWrapper = styled.div`
  ${RegularButtonFlexed} {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

const DropdownItemsWrapper = styled.div`
  .dropdown-item {
    display: flex;
    justify-content: right;
  }
`;

export { DropdownWrapper, DropDownLabel, DropdownItemsWrapper };
