import { IUser } from 'types/app';
import { NOTIFICATIONS } from 'constants/global';

export interface useRelationIdentificationPermissionCheckerOut {
  hasRequiredModules: boolean;
  hasValidatedProfile: boolean;
  cannotAccessQuiz: boolean;
  isLimitedUser: boolean;
}

function useRelationIdentificationPermissionChecker(
  user?: IUser,
): useRelationIdentificationPermissionCheckerOut {
  const hasValidatedProfile = Boolean(user?.profileInfo?.hasValidatedProfile);
  const hasSaleTool = Boolean(user?.profileInfo?.notifications?.includes(NOTIFICATIONS.SALE));
  const hasRequiredModules = Boolean(
    user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MANAGEMENT),
  );

  // const isLimitedUser =
  //   !hasValidatedProfile ||
  //   (hasValidatedProfile && !hasRequiredModules) ||
  //   (hasValidatedProfile && !hasSaleTool);

  const hasRequiredModuleOrHasSaleTool = hasRequiredModules || hasSaleTool;

  const checkUserIsNotLimited = () => {
    return !!user?.profileInfo?.colors?.primary && hasRequiredModuleOrHasSaleTool;
  };

  const isLimitedUser = !checkUserIsNotLimited();
  const cannotAccessQuiz = isLimitedUser;

  return {
    hasRequiredModules,
    hasValidatedProfile,
    cannotAccessQuiz,
    isLimitedUser,
  };
}

export default useRelationIdentificationPermissionChecker;
