import { CheckoutFormWrapper } from 'components/payment/form/checkout-form/CheckoutFormWrapper';
import styled from 'styled-components';

const BuyToolsWrapper = styled.div`
  width: 90vw;
  height: 90vh;
  max-width: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .payment-form {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;

    ${CheckoutFormWrapper} {
      max-height: 95%;
    }
  }
`;

export { BuyToolsWrapper };
