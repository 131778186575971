import React, { useEffect, useState } from 'react';
import { IGenericObjectString } from 'types/app';
import { useTranslation } from 'react-i18next';
import {
  CenteredTextWrapper,
  DiagramImageWrapper,
  TextItem,
  TextsWrapper,
} from 'pages/profile/has-profile/my-summary/MySummaryDiagramImage.styled';

interface IMySummaryDiagramImage {
  id: string;
  imageURL?: string;
  texts?: { [key: string]: IGenericObjectString };
  textsPercent?: { [key: string]: number };
  centeredText?: IGenericObjectString;
}

const imageSize = {
  w: 1500,
  h: 1590,
};
const positions: { [key: string]: { posX: number; posY: number } } = {
  texte_pdf_bleu: {
    posX: 500,
    posY: 650,
  },
  texte_pdf_jaune: {
    posX: 1020,
    posY: 640,
  },
  texte_pdf_orange: {
    posX: 1020,
    posY: 960,
  },
  texte_pdf_rouge: {
    posX: 750,
    posY: 1090,
  },
  texte_pdf_vert: {
    posX: 750,
    posY: 500,
  },
  texte_pdf_violet: {
    posX: 500,
    posY: 950,
  },
  valeur_bleu: {
    posX: 500,
    posY: 650,
  },
  valeur_jaune: {
    posX: 1020,
    posY: 640,
  },
  valeur_orange: {
    posX: 1020,
    posY: 960,
  },
  valeur_rouge: {
    posX: 750,
    posY: 1090,
  },
  valeur_vert: {
    posX: 750,
    posY: 500,
  },
  valeur_violet: {
    posX: 500,
    posY: 950,
  },
};
const getPercentPosition = (key: string) => {
  return {
    left: `${((positions[key]?.posX || 0) / imageSize.w) * 100}%`,
    top: `${((positions[key]?.posY || 0) / imageSize.h) * 100}%`,
  };
};

const MySummaryDiagramImage: React.FC<IMySummaryDiagramImage> = ({
  id,
  imageURL,
  texts,
  textsPercent,
  centeredText,
}: IMySummaryDiagramImage) => {
  const { i18n } = useTranslation('translation');
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const imgLoader = document.createElement('img');
    imgLoader.onload = () => setReady(true);
    imgLoader.src = `${imageURL}`;
  }, []);

  return (
    <DiagramImageWrapper className={`diagram-image-wrapper ${id}`}>
      {texts && ready && (
        <TextsWrapper>
          {Object.keys(texts).map((key, index) => {
            const pos = getPercentPosition(key);

            return (
              <TextItem
                className={key}
                key={`text-${index}`}
                left={pos.left}
                top={pos.top}
                dangerouslySetInnerHTML={{
                  __html: texts[key][i18n.language],
                }}
              />
            );
          })}
        </TextsWrapper>
      )}
      {textsPercent && ready && (
        <TextsWrapper>
          {Object.keys(textsPercent).map((key, index) => {
            const pos = getPercentPosition(key);

            return (
              <TextItem
                className={key}
                key={`text-pct-${index}`}
                left={pos.left}
                top={pos.top}
                dangerouslySetInnerHTML={{
                  __html: `${textsPercent[key]}%`,
                }}
              />
            );
          })}
        </TextsWrapper>
      )}
      {centeredText && ready && (
        <CenteredTextWrapper
          dangerouslySetInnerHTML={{
            __html: `${centeredText[i18n.language]}`,
          }}
        />
      )}
      {imageURL && <img src={imageURL} alt="" />}
    </DiagramImageWrapper>
  );
};

export default MySummaryDiagramImage;
