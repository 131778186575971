import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const FoundTrainersWrapper = styled.div`
  margin-top: ${prem(75)};

  .title {
    color: var(--ion-color-orange);
    margin-bottom: ${prem(20)};
  }

  .found-trainers-list {
    display: flex;
    flex-direction: column;
    gap: ${prem(20)};
  }

  .single-trainer {
    max-width: ${prem(900)};
    padding: ${prem(15)};

    /* stylelint-disable */
    background-color: rgb(var(--ion-color-accent-rgb), 0.2);
    /* stylelint-enable */

    .primary-informations {
      display: flex;
      gap: ${prem(10)};

      .photo {
        border-radius: 50%;
        position: relative;
        width: ${prem(60)};
        height: ${prem(60)};
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        img {
          width: ${prem(70)};
          height: ${prem(70)};
          position: absolute;
          right: 0;
          top: 0;
          border-radius: ${prem(50)};
        }

        svg {
          color: var(--ion-color-accent);
          width: ${prem(30)};
        }
      }

      .name-distance {
        display: flex;
        flex-direction: column;
        gap: ${prem(5)};
        flex: 1;

        .name {
          font-weight: bolder;
        }
      }
    }

    .phone-and-email {
      display: flex;
      justify-content: end;
      gap: ${prem(40)};

      svg {
        width: ${prem(30)};
        color: var(--ion-color-orange);
      }

      .email {
        width: max-content;
      }

      .icon {
        border: 1px solid var(--ion-color-orange);
        padding: ${prem(3)} ${prem(6)};
        border-radius: ${prem(40)};
      }
    }
  }
`;

export { FoundTrainersWrapper };
