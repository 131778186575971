import React, { useState, useEffect } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropperWrapper from 'pages/profile/has-profile/color-profile/image-cropper/ImageCropper.styled';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import Button from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';

interface onCropEvent {
  base64Value: string;
  blobValue: Blob;
  fileValue: File;
}

const ImageCropper: React.FC<{
  imageSrc: Blob | MediaSource;
  onCrop?: (e: onCropEvent) => void;
}> = ({ imageSrc, onCrop }) => {
  const [src, setSrc] = useState<Blob | MediaSource>();
  const [result, setResult] = useState<string>();
  const [imageRef, setImageRef] = useState<HTMLImageElement>();
  const [resultBlob, setResultBlob] = useState<Blob>();
  const [resultFile, setResultFile] = useState<File>();
  const [crop, setCrop] = useState<Crop>({
    height: 235,
    width: 235,
    unit: 'px',
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (imageSrc) {
      setSrc(imageSrc);
    }
  }, [imageSrc]);

  const cropImageNow = async () => {
    if (!imageRef) return;
    const canvas = document.createElement('canvas');
    const scaleX = imageRef?.naturalWidth / imageRef?.width;
    const scaleY = imageRef?.naturalHeight / imageRef?.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    if (!ctx) return;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const base64Image = canvas.toDataURL('image/png');
    const base64Response = await fetch(base64Image);
    const blob = await base64Response.blob();
    const blobFile = new File([blob], 'upload.png', {
      type: 'image/png',
    });
    setResult(base64Image);
    setResultBlob(blob);
    setResultFile(blobFile);
  };

  const onValidateCrop = (base64Image: string, blob: Blob, blobFile: File) => {
    onCrop &&
      onCrop({
        base64Value: base64Image,
        blobValue: blob,
        fileValue: blobFile,
      });
  };

  const { t } = useTranslation('translation');

  return (
    <ImageCropperWrapper>
      <AppModalTitle>{t('profile.colorprofile.cropprofilepicture')}</AppModalTitle>
      {src && (
        <ReactCrop
          className="react-crop"
          crop={crop}
          aspect={1 / 1}
          onChange={setCrop}
          minHeight={235}
          minWidth={235}
          maxHeight={235}
          maxWidth={235}
          onComplete={cropImageNow}
          ruleOfThirds
        >
          <img
            src={src as any}
            onLoad={(e: any) => {
              setImageRef(e.target);
            }}
          />
        </ReactCrop>
      )}
      <Button
        fill="outline"
        color="white"
        onClick={() => {
          result && resultBlob && resultFile && onValidateCrop(result, resultBlob, resultFile);
        }}
      >
        {t('common.validate')}
      </Button>
    </ImageCropperWrapper>
  );
};

export default ImageCropper;
