import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RelationPix from 'assets/images/relations-pix.png';
import PageLayout from 'layout/page/Page.layout';
import { Container } from 'layout/page/Page.layout.styled';
import {
  IntroInstruction,
  PixWrapper,
  ProfileVisibilityTitle,
  ProfileVisibilityWrapper,
  RelationsGroupsIntro,
  RelationsWrapper,
} from 'pages/relations/home/RelationsHome.styled';
import RelationGroups from 'components/relations/group/RelationGroups';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import useAPIRelations, { InvitationType } from 'hooks/api/useAPIRelations';
import { useIonViewWillEnter } from '@ionic/react';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';
import { NotificationsContext, NotificationsContextProps } from 'contexts/NotificationsContext';
import { IAdvice } from 'types/app';
import RelationInvitationsList from 'components/relations/invitations/relation-invitations-list/RelationInvitationsList';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import Toggle from 'components/common/form/toggle/Toggle';
import GuestScreen from 'components/guest-screen/GuestScreen';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import AuthentificationModal from 'components/modal/authentification/AuthentificationModal';

const RelationsHome: React.FC = () => {
  const { relationsGroups } = useContext(RelationsContext) as RelationsContextProps;
  const { user, isGuest } = useContext(UserContext) as UserContextProps;
  const { t } = useTranslation('translation');
  const { getRelations, requestGroups } = useAPIRelations();
  const hasRelations = relationsGroups?.some((group) => {
    return !!group.relations?.length;
  });

  useIonViewWillEnter(() => {
    if (user) {
      requestGroups(user);
      getRelations();
    }
  });

  const { settings, changeProfileVisibility } = useContext(SettingsContext) as SettingsContextProps;
  const { profileVisibility } = settings;
  const { notifications } = useContext(NotificationsContext) as NotificationsContextProps;
  const [invitations, setInvitations] = useState<Array<IAdvice>>();
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  useEffect(() => {
    const invitationsFromNotification = notifications.filter(
      (notification) => notification.type === InvitationType.NEW_INVITATION,
    );
    setInvitations(invitationsFromNotification);
  }, [notifications]);

  return (
    <>
      <PageLayout headerProps={{ title: t('common.relations'), hideNotification: isGuest }}>
        {!isGuest ? (
          <RelationsWrapper className="app-page">
            <Container>
              {!hasRelations && (
                <RelationsGroupsIntro>
                  <PixWrapper>
                    <img src={RelationPix} />
                  </PixWrapper>
                  <IntroInstruction dangerouslySetInnerHTML={{ __html: t('relations.intro') }} />
                </RelationsGroupsIntro>
              )}
              <RelationGroups groups={relationsGroups} />
            </Container>
            {invitations && (
              <Container>
                <RelationInvitationsList invitations={invitations} />
              </Container>
            )}
            <Container>
              <ProfileVisibilityWrapper>
                <Toggle
                  checked={!!profileVisibility}
                  onChecked={() => changeProfileVisibility(profileVisibility ? 0 : 1)}
                  enabled={true}
                />
                <ProfileVisibilityTitle>{t('settings.profilevisibility')}</ProfileVisibilityTitle>
              </ProfileVisibilityWrapper>
            </Container>
          </RelationsWrapper>
        ) : (
          <GuestScreen
            title={t('relations.guest.title') as string}
            description={t('relations.guest.description') as string}
            cta={{
              label: t('relations.guest.cta.title') as string,
              onClick: () => {
                updateModalConf({
                  component: <AuthentificationModal />,
                });
              },
            }}
          />
        )}
      </PageLayout>
    </>
  );
};

export default RelationsHome;
