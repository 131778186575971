import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// Import all translation files
import translationEnglish from 'translation/en/common.json';
import translationFrench from 'translation/fr/common.json';

i18next
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEnglish,
      },
      fr: {
        translation: translationFrench,
      },
    }
  });


export default i18next;
