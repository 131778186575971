import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BookItemWrapper } from 'components/resources/book/BookItem.styled';
import { ResourcesWrapper } from 'pages/resources/Resources.styled';

const BooksWrapper = styled.div`
  ${ResourcesWrapper};

  ${BookItemWrapper} {
    margin-bottom: ${prem(42)};
  }
`;

export { BooksWrapper };
