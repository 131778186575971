import { AxiosRequestConfig } from 'axios';
import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { replaceStrings } from 'helpers/utils';
import { ITests } from 'types/app';
import { getTestName } from 'helpers/tests';

export interface IUseAPITest {
  getTestInfos: (userId: number, token?: string) => Promise<ITests | undefined>;
  initModuleTest: (
    userId: number,
    token: string,
    moduleId: number,
    relationId: number,
  ) => Promise<boolean | undefined>;
}

function useAPITest(): IUseAPITest {
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  const formatTestInfos = (testInfos: Array<any>): ITests => {
    return testInfos.map((info) => {
      return {
        themeId: info.theme_id,
        themeName: getTestName(info.theme_id),
        relationId: info.relation_id,
        usedDay: info.used_day,
        daysLeft: info.rest,
        startedAt: info.started_at,
        createdAt: info.created_at,
        expiredAt: info.expired_at || undefined,
      };
    });
  };

  const getTestInfos = async (userId: number, token?: string) => {
    try {
      showLoading();
      const options: AxiosRequestConfig = {
        url: `${replaceStrings(API.ROUTES.TEST.INFOS, {
          userId: `${userId}`,
        })}`,
        headers: {
          Authorization: token,
        },
      };

      const result = await getRequest(options);
      const tests = result.data.data.tests;
      if (tests) {
        const formatedTestInfos = formatTestInfos(result.data.data.tests);
        return formatedTestInfos;
      } else {
        console.log('Error getting test infos');
      }
    } catch (e) {
      console.log('Error getting test infos: ', e);
    } finally {
      hideLoading();
    }

    return undefined;
  };

  const initModuleTest = async (
    userId: number,
    token: string,
    moduleId: number,
    relationId: number,
  ) => {
    try {
      showLoading();
      const options: AxiosRequestConfig = {
        url: API.ROUTES.TEST.INIT,
        method: 'POST',
        data: {
          user_id: userId,
          theme_id: moduleId,
          relation_id: relationId,
        },
        headers: {
          Authorization: token,
        },
      };

      const result = await getRequest(options);
      return !!result.data.data?.test;
    } catch (e) {
      console.log('Error test init of module ', e);
    } finally {
      hideLoading();
    }
    return undefined;
  };

  return {
    getTestInfos,
    initModuleTest,
  };
}

export default useAPITest;
