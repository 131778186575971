import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { TutoComputerWrapper } from 'components/install-app-tuto/tuto-computer/TutoComputer.styled';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { ROUTES } from 'constants/routes';
import InstallationInstructionFr from 'assets/images/installation-instructions-fr.png';
import InstallationInstructionEn from 'assets/images/installation-instructions-en.png';
import { getLocaleValue } from 'helpers/utils';

const TutoComputer: React.FC = () => {
  const { i18n, t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { getUserAutoLoginToken } = useAPIAuth();
  const [autoLoginToken, setAutoLoginToken] = useState<string>('');

  const loadUserAutoLoginToken = useCallback(async () => {
    setAutoLoginToken(
      ((await getUserAutoLoginToken(
        user?.id as number,
        user?.token as {
          value: string;
          type: string;
        },
      )) as string) || '',
    );
  }, []);

  useEffect(() => {
    loadUserAutoLoginToken();
  }, []);

  const INSTALLATION_IMAGES = {
    fr: InstallationInstructionFr,
    en: InstallationInstructionEn,
  };

  return (
    <TutoComputerWrapper>
      <div
        className='description'
        dangerouslySetInnerHTML={{
          __html: t('settings.installation.computer.description'),
        }}
      />
      <div className="qr-code-wrapper">
        <QRCode
          className="qr-code"
          fgColor="var(--ion-text-color)"
          value={`${window.location.origin}${ROUTES.AUTOLOGIN}?_token=${autoLoginToken}`}
          viewBox={'0 0 500 500'}
        />
      </div>
      <img src={getLocaleValue(INSTALLATION_IMAGES, i18n.language)} alt="" />
    </TutoComputerWrapper>
  );
};

export default TutoComputer;
