import styled from 'styled-components';
import { IonTabs, IonLabel } from '@ionic/react';
import { prem } from 'styles/helpers/spacing';
import {
  ActiveTabMarkerWrapper,
  IconTabSvg,
} from 'components/common/icons/icon-tab/IconTab.styled';
import { IconProfileWrapper } from 'components/common/icons/icon-profile/IconProfile.styled';

const IonTabsStyled = styled(IonTabs)`
  ion-tab-bar {
    --background: var(--ion-background-color);
    --color: var(--comcolors-tab-color);
    --border: none;
    position: relative;
    min-height: ${prem(64)};
  }

  ${IconTabSvg} {
    width: ${prem(32)};
    height: ${prem(32)};

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  ion-tab-button.tab-selected {
    color: var(--comcolors-tab-accent);

    ${IconProfileWrapper} {
      path.question-mark {
        fill: var(--comcolors-tab-active-profile-question-color);
      }

      path.question-mark-circles {
        stroke: var(--comcolors-tab-active-profile-question-circles-color);
      }
    }

    ${ActiveTabMarkerWrapper} {
      display: flex;
    }
  }
`;

const IonTabButtonLabel = styled(IonLabel)`
  font-weight: normal;
  font-size: ${prem(10)};
`;

const IonTabsInnerStyled = styled(IonTabs)`
  ion-tab-bar {
    --background: var(--ion-background-color);
    --color: var(--ion-text-color-secondary);
    --border: none;
    position: relative;
    min-height: ${prem(48)};
    box-shadow: 0 4px 4px 0 rgba(0 0 0 / 0.05);
  }

  ${IconTabSvg} {
    width: ${prem(26)};
    height: ${prem(26)};
  }

  ion-tab-button:hover {
    --color: var(--ion-color-accent);
    --color-selected: var(--ion-color-accent);
  }

  ion-tab-button.tab-selected {
    color: var(--ion-color-accent);

    ${ActiveTabMarkerWrapper} {
      display: none;
    }
  }
`;

export { IonTabsStyled, IonTabsInnerStyled, IonTabButtonLabel };
