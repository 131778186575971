import React from 'react';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import { BooksWrapper } from 'pages/resources/books/Books.styled';
import BookItem from 'components/resources/book/BookItem';
import { API } from 'constants/global';
import useProductsLoader from 'hooks/useProductsLoader';

const Books: React.FC = () => {
  const { t } = useTranslation('translation');
  const { products } = useProductsLoader(API.ROUTES.RESOURCES.BOOKS);

  return (
    <BooksWrapper className="app-page">
      <Container>
        <PageInnerTitle
          dangerouslySetInnerHTML={{
            __html: t('resources.books.title'),
          }}
        />
        <div>
          {products.map((book, index) => (
            <BookItem key={`book-${index}`} {...book} />
          ))}
        </div>
      </Container>
    </BooksWrapper>
  );
};

export default Books;
