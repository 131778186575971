import useAPIRelations, { InvitationType } from 'hooks/api/useAPIRelations';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IAdvice } from 'types/app';
import {
  InvitationsList,
  RelationInvitationsListWrapper,
  RelationInvitationsTitle,
} from 'components/relations/invitations/relation-invitations-list/RelationInvitationsList.styled';
import SingleRelationInvitation from 'components/relations/invitations/single-relation-invitation/SingleRelationInvitation';
import NoInvitation from 'components/relations/invitations/no-invitation/NoInvitation';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import AcceptInvitationModal from 'components/relations/invitations/single-relation-invitation/accept-invitation-modal/AcceptInvitationModal';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface RelationInvitationsListProps {
  invitations: Array<IAdvice>;
}

const RelationInvitationsList: React.FC<RelationInvitationsListProps> = ({ invitations }) => {
  const { t } = useTranslation('translation');
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { user } = useContext(UserContext) as UserContextProps;
  const { refuseRelationInvitation } = useAPIRelations();
  const { loadNotifications } = useAPIAdvices();

  const onRefuseInvitation = async (notificationId: number) => {
    await refuseRelationInvitation(notificationId);
    updateModalConf();
    await loadNotifications(AdviceType.ALL, user?.id as number);
  };

  return (
    <RelationInvitationsListWrapper>
      <RelationInvitationsTitle>
        {t('relations.invitations.receivedinvitations')}
      </RelationInvitationsTitle>
      <InvitationsList>
        {invitations &&
          invitations.map((invitation, key) => {
            return (
              <React.Fragment key={key}>
                {invitation.type === InvitationType.NEW_INVITATION && (
                  <SingleRelationInvitation
                    onConfirm={() => {
                      updateModalConf({
                        component: (
                          <AcceptInvitationModal
                            title={invitation.advice}
                            notificationId={invitation.notificationId as number}
                            onRefuseInvitation={() =>
                              onRefuseInvitation(invitation.notificationId as number)
                            }
                          />
                        ),
                      });
                    }}
                    onRefuse={() => onRefuseInvitation(invitation.notificationId as number)}
                    invitation={invitation}
                  />
                )}
              </React.Fragment>
            );
          })}
        {invitations.length <= 0 && (
          <>
            <NoInvitation />
          </>
        )}
      </InvitationsList>
    </RelationInvitationsListWrapper>
  );
};

export default RelationInvitationsList;
