import React from 'react';
import { IAdvice } from 'types/app';
import { SingleRelationInvitationWrapper } from 'components/relations/invitations/single-relation-invitation/SingleRelationInvitation.styled';
import Button from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAddRelation } from 'assets/svg/icon-people-plus.svg';

export interface SingleRelationInvitationProps {
  invitation: IAdvice;
  onConfirm?: () => void;
  onRefuse?: () => void;
}

const SingleRelationInvitation: React.FC<SingleRelationInvitationProps> = ({
  invitation,
  onConfirm,
  onRefuse,
}) => {
  const { t } = useTranslation('translation');

  return (
    <SingleRelationInvitationWrapper>
      <div className="invitation-icon">
        <IconAddRelation />
      </div>
      <div className="invitation-name">
        {invitation.name} {invitation.firstname}
      </div>
      <div className="invitation-confirmation-buttons">
        <div className="accept-invitation">
          <Button
            fill="solid"
            color="accent"
            onClick={() => {
              onConfirm && onConfirm();
            }}
          >
            {t('common.accept')}
          </Button>
        </div>
        <div className="refuse-invitation">
          <Button
            fill="outline"
            color="accent"
            onClick={() => {
              onRefuse && onRefuse();
            }}
          >
            {t('common.refuse')}
          </Button>
        </div>
      </div>
    </SingleRelationInvitationWrapper>
  );
};

export default SingleRelationInvitation;
