import ButtonBack from 'components/common/button/ButtonBack';
import PageLayout from 'layout/page/Page.layout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShopWrapper } from 'pages/shop/Shop.styled';
import { PayableToolsContext, PayableToolsContextProps } from 'contexts/PayableToolsContext';
import SingleTool from 'components/shop/single-tool/SingleTool';
import ShopSummary from 'components/shop/shop-summary/ShopSummary';
import { IPayableTool, IUser } from 'types/app';
import { BuyToolContext, BuyToolContextProps } from 'contexts/BuyToolContext';
import { useIonViewWillEnter } from '@ionic/react';
import { PAYABLE_TOOLS_CODES } from 'constants/global';
import { checkDisableBuyAllAccessPack } from 'helpers/shop';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { disableBuyModuleButton, hideBuyModuleButton } from 'helpers/modules';
import { ROUTES } from 'constants/routes';

const Shop: React.FC = () => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { payableTools, loadPayableTools } = useContext(
    PayableToolsContext,
  ) as PayableToolsContextProps;
  const { addToolToShoppingList, removeToolFromShoppingList, updateShoppingList, shoppingList } =
    useContext(BuyToolContext) as BuyToolContextProps;

  const [checkedAllAccessPack, setCheckedAllAccessPack] = useState<boolean>(false);

  const [otherToolIsSelected, setOtherToolIsSelected] = useState<boolean>(false);

  const checkToolIsSelected = (tool: IPayableTool) => {
    return !!shoppingList.find((item) => item.code === tool.code);
  };

  const onToggleSelectTool = (tool: IPayableTool) => {
    const isSelected = checkToolIsSelected(tool);
    if (isSelected) {
      removeToolFromShoppingList(tool);
    } else {
      addToolToShoppingList(tool);
    }
  };

  const onToggleSelectAllAccessPack = (tool: IPayableTool) => {
    if (tool.code === PAYABLE_TOOLS_CODES.FULL_PACKAGE) {
      setCheckedAllAccessPack(!checkedAllAccessPack);
      onToggleSelectTool(tool);
    }
  };

  const checkDisableTool = (tool: IPayableTool): boolean => {
    if (tool.code === PAYABLE_TOOLS_CODES.FULL_PACKAGE) {
      return !!checkDisableBuyAllAccessPack(user as IUser, payableTools) || otherToolIsSelected;
    } else {
      if (checkedAllAccessPack) {
        return true;
      }
    }
    return false;
  };

  useIonViewWillEnter(async () => {
    await loadPayableTools();
  });

  useEffect(() => {
    if (shoppingList.length > 0) {
      if (shoppingList[0].code !== PAYABLE_TOOLS_CODES.FULL_PACKAGE) setOtherToolIsSelected(true);
    } else {
      setOtherToolIsSelected(false);
    }
  }, [shoppingList]);

  useEffect(() => {
    if (otherToolIsSelected) {
      updateShoppingList(
        shoppingList.filter((tool) => tool.code !== PAYABLE_TOOLS_CODES.FULL_PACKAGE),
      );
    }
    if (checkedAllAccessPack) {
      updateShoppingList(
        shoppingList.filter((tool) => tool.code === PAYABLE_TOOLS_CODES.FULL_PACKAGE),
      );
    }
  }, [checkedAllAccessPack, otherToolIsSelected]);

  useEffect(() => {
    if (user) {
      if (hideBuyModuleButton(user) || disableBuyModuleButton(user)) {
        window.location.href = ROUTES.HOME;
      }
    }
  }, []);

  return (
    <PageLayout
      className="page-layout-shop"
      headerProps={{
        title: t('common.comcolorsshop'),
        hideNotification: false,
      }}
    >
      <ShopWrapper className="shop-wrapper">
        <ButtonBack
          onClick={() => {
            history.back();
          }}
        />
        <div className="payable-tools-list">
          {payableTools &&
            payableTools.map((tool, key) => {
              if (!tool.hide) {
                return (
                  <SingleTool
                    tool={tool}
                    key={key}
                    selected={checkToolIsSelected(tool)}
                    onToggle={() => {
                      if (tool.code === PAYABLE_TOOLS_CODES.FULL_PACKAGE) {
                        return onToggleSelectAllAccessPack(tool);
                      }
                      onToggleSelectTool(tool);
                    }}
                    disabled={checkDisableTool(tool) || tool.price_ttc <= 0}
                  />
                );
              }
            })}
        </div>
        <ShopSummary />
      </ShopWrapper>
    </PageLayout>
  );
};

export default Shop;
