import styled from 'styled-components';
import { centerize } from 'styles/helpers/utils';

const IconTabWrapper = styled.div`
  padding-bottom: 0;
`;

const IconTabSvg = styled.div`
  ${centerize};
`;

const ActiveTabMarkerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  ${centerize};
  display: none;
`;

export { IconTabWrapper, IconTabSvg, ActiveTabMarkerWrapper };
