import React from 'react';
import { FoundTrainersWrapper } from 'pages/find-trainer/found-trainers/FoundTrainers.styled';
import { ITrainer, ITrainers } from 'types/app';
import { ReactComponent as IconEmail } from 'assets/svg/icon-email.svg';
import { ReactComponent as IconPhone } from 'assets/svg/icon-phone.svg';
import { useTranslation } from 'react-i18next';
import { detectLanguage } from 'helpers/utils';
import { LANGUAGES } from 'constants/global';
import { Container } from 'layout/page/Page.layout.styled';

export interface FoundTrainersProps {
  trainers: ITrainers;
  resultCount: number;
}

const FoundTrainers: React.FC<FoundTrainersProps> = ({ trainers, resultCount }) => {
  const { t, i18n } = useTranslation('translation');

  return (
    <FoundTrainersWrapper>
      <Container>
        <h1 className="title">
          {t('findtrainer.resultsfound', {
            count: resultCount || 0,
          })}
        </h1>
        {trainers && (
          <div className="found-trainers-list">
            {trainers.map((trainer: ITrainer, key) => {
              return (
                <div key={key} className="single-trainer">
                  <div className="primary-informations">
                    <div
                      className="photo"
                      style={{
                        backgroundImage: `url('${trainer.photo}')`,
                      }}
                    ></div>
                    <div className="name-distance">
                      <div className="name">
                        {trainer.firstname} {trainer.lastname}
                      </div>
                      <div className="distance">
                        {detectLanguage(i18n.language) == LANGUAGES.fr ? (
                          <>{Math.round(trainer.distance)} km</>
                        ) : (
                          <>{Math.round(trainer.distance * 0.621371)} miles</>
                        )}
                      </div>
                      <div className="address">{trainer.address}</div>
                    </div>
                  </div>
                  <br />
                  <div className="phone-and-email">
                    {trainer.number && (
                      <div className="phone icon">
                        <a href={`tel:${trainer.number}`}>
                          <IconPhone />
                        </a>
                      </div>
                    )}
                    {trainer.email && (
                      <div className="email icon">
                        <a href={`mailto:${trainer.email}`} rel="noreferrer" target="_blank">
                          <IconEmail />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </FoundTrainersWrapper>
  );
};

export default FoundTrainers;
