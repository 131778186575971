import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import { FormButtonSubmitter } from 'components/common/form/Form.styled';
import { ROUTES } from 'constants/routes';
import { IonRouterLink } from '@ionic/react';

const ForgottenpwdValidation: React.FC<{ withBack?: boolean; goBackTo?: () => void }> = ({
  withBack = true,
  goBackTo,
}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <Button color="accent" expand={true} type="submit">
        {t('common.validate')}
      </Button>
      <FormButtonSubmitter type="submit" />
      {withBack && (
        <IonRouterLink
          routerLink={ROUTES.LOGIN}
          routerDirection="back"
          onClick={(e) => {
            if (goBackTo) {
              e.preventDefault();
              goBackTo();
            }
          }}
        >
          {t('common.back')}
        </IonRouterLink>
      )}
    </>
  );
};

export default ForgottenpwdValidation;
