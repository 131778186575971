import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const NotificationRelationInvitationWrapper = styled.div`
  margin-bottom: ${prem(50)};

  .relation-invitation-description-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: ${prem(10)};

    .description-text {
      flex: 1;
      font-weight: bolder;
      color: var(--ion-text-color-secondary);
    }

    .icon {
      color: var(--ion-color-orange);
      cursor: pointer;
    }
  }

  .date {
    float: right;
  }
`;


export { NotificationRelationInvitationWrapper };
