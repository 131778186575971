import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const IconShopWrapper = styled.div<{ isShopPage?: boolean; selectedItemsNumber: number }>`
  svg {
    width: ${prem(25)};
    height: ${prem(25)};
  }

  &:hover {
    cursor: pointer;
  }

  ${({ isShopPage, selectedItemsNumber }) => css`
    color: ${isShopPage
      ? 'var(--ion-color-highlight)'
      : selectedItemsNumber > 0
      ? 'var(--ion-color-accent)'
      : 'var(--ion-text-color-secondary)'};

    ${selectedItemsNumber > 0 &&
    css`
      &::after {
        content: '${selectedItemsNumber}';
        border: ${prem(2)} solid
          ${isShopPage ? 'var(--ion-color-highlight)' : 'var(--ion-color-accent)'};
        padding: ${prem(1)} ${prem(4)};
        margin-left: ${prem(5)};
        border-radius: ${prem(100)};
        ${BODY.small}
      }
    `}
  `}
`;

export { IconShopWrapper };
