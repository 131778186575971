import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { IonButton, useIonRouter } from '@ionic/react';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { ButtonPropsBase } from 'components/common/button/Button';
import { ROUTES } from 'constants/routes';
import { HEADER_HPADDING } from 'styles/helpers/spacing';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import { matchPath } from 'react-router-dom';
import { Identification, IRelation } from 'types/app';
import Slider from 'components/slider/Slider';
import { SwiperSlide } from 'swiper/react';
import RelationIdentificationHome from 'pages/relations/identification/slides/relation-identification-home/RelationIdentificationHome';
import { Swiper as SwiperClass } from 'swiper/types';
import {
  IsInCurrentTestModalWrapper,
  RelationsIdentificationWrapper,
  SliderContainer,
} from 'pages/relations/identification/RelationIdentification.styled';
import RelationManualIdentification from 'pages/relations/identification/slides/relation-manual-identification/RelationManualIdentification';
import { useLocation } from 'react-router';
import useAPIRelations, { IdentificationType } from 'hooks/api/useAPIRelations';
import RelationQuizQuestion from 'pages/relations/identification/slides/relation-quiz-identification/RelationQuizQuestion';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { backPropsDefault } from 'pages/relations/Relations';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useRelationIdentificationPermissionChecker from 'hooks/useRelationIdentificationPermissionChecker';
import { ModuleTestContext, ModuleTestContextProps } from 'contexts/ModuleTestContext';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';

export interface IdentificationProps {
  gotoManualIdentification?: () => void;
  gotoTunnelingIdentification?: () => void;
  relation?: IRelation;
  question?: Identification.IQuestion;
  result?: Identification.ISequence;
  onResponse?: (response: Identification.IResponse) => void;
  onSendColors?: (
    primaryColorId?: number | null,
    secondaryColorId?: number | null,
    colorUnspecified?: boolean,
  ) => void;
  onRestart?: () => void;
  swiperInstance?: React.MutableRefObject<SwiperClass | undefined>;
}

export const enum SlideType {
  MANUAL,
  QUESTION,
  RESULT,
}

export interface ISlideProps {
  type: SlideType;
  props: IdentificationProps;
}

const RelationIdentification: React.FC = () => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRelationById, relationQuestionnaireFactory } = useContext(
    RelationsContext,
  ) as RelationsContextProps;
  const { relationAppointmentTest, relationManagementTest } = useContext(
    ModuleTestContext,
  ) as ModuleTestContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  const { loadColoursFinderQuestionnaire, updateRelation } = useAPIRelations();
  const swiperInstance = useRef<SwiperClass>();
  const ionRouter = useIonRouter();

  // Start trick
  /*
   * useParams failed sometimes
   * */
  const location = useLocation();
  const match = matchPath(location.pathname || location.location.pathname, {
    path: `${ROUTES.RELATIONS.IDENTIFY}/:idRelation`,
    exact: true,
  });
  const idRelation = (match?.params as any)?.idRelation;
  // End trick

  const {
    icons: [chevronBack],
  } = useIcons([ICON_NAMES.CHEVRON_BACK]);
  const [backLinkProps, setBackLinkProps] = useState<ButtonPropsBase>(backPropsDefault);
  const [currentRelation, setCurrentRelation] = useState<IRelation | undefined>();
  const [slides, updateSlides] = useState<Array<ISlideProps>>([]);
  const [activeIndex, updateActiveIndex] = useState<number>(0);
  const { incrementIdentificationCount } = useAPIRelations();
  const { isLimitedUser } = useRelationIdentificationPermissionChecker(user);
  const [isInCurrentTest, setIsInCurrentTest] = useState<boolean>(false);

  // Send colours
  const sendColors = async (
    primaryColorId?: number | null,
    secondaryColorId?: number | null,
    colorUnspecified = false,
    isInBackground?: boolean,
  ) => {
    if (!primaryColorId || !currentRelation) return;

    const result = await updateRelation(
      currentRelation.id,
      IdentificationType.MANUAL,
      colorUnspecified ? 1 : 0,
      primaryColorId,
      secondaryColorId,
      isInBackground,
    );

    if (result && !isInBackground) {
      setToast({
        message: t('relations.identify.colorssaved', { relationName: currentRelation?.fullName }),
        status: 'success',
      });

      ionRouter.push(ROUTES.RELATIONS.INDEX, 'back');
    }
  };

  // Increment identification usage if user has limited access to this tool
  const incrementIdentificationUsage = () => {
    if (user && isLimitedUser) {
      incrementIdentificationCount(user.id, true);
    }
  };

  // Restart tunneling
  const restartQuestionnaire = () => {
    const slidePrevTransitionEndEvent = 'slidePrevTransitionEnd';
    const onSlidePrevTransitionEndHandler = () => {
      startQuestionnaire();
      swiperInstance.current?.off(slidePrevTransitionEndEvent, onSlidePrevTransitionEndHandler);
    };
    swiperInstance.current?.on(slidePrevTransitionEndEvent, onSlidePrevTransitionEndHandler);
    swiperInstance.current?.slideTo(1);
  };

  // Questionnaire
  const startQuestionnaire = () => {
    const question = relationQuestionnaireFactory?.getNextStep();
    showQuestionOrResult(question);
  };

  const showQuestionOrResult = (
    next?: Identification.INextStep,
    currentSlides: Array<ISlideProps> = [],
  ) => {
    if (next) {
      // Question: first question or step
      if ((next as Identification.IQuestion).label || (next as Identification.IStep).index) {
        const question: Identification.IQuestion | undefined = (next as Identification.IQuestion)
          .label
          ? (next as Identification.IQuestion)
          : relationQuestionnaireFactory?.questions[(next as Identification.IStep).index];

        updateSlides([
          ...currentSlides,
          {
            type: SlideType.QUESTION,
            props: {
              question: question,
            },
          },
        ]);
      }

      // Result
      if ((next as Identification.ISequence).steps) {
        const result = next as Identification.ISequence;
        /* Checks
        const fix = relationQuestionnaireFactory?.getSequenceById(148);

        // --- end check --- ///*/
        if (result.primaryColorId) {
          console.log('currentRelation: ', currentRelation);
          sendColors(
            result.primaryColorId,
            result.secondaryColorId,
            result.unspecifiedColours,
            true,
          );
        }
        updateSlides([
          ...currentSlides,
          {
            type: SlideType.RESULT,
            props: {
              relation: currentRelation,
              result: next as Identification.ISequence,
            },
          },
        ]);

        incrementIdentificationUsage();
      }
    }
  };

  const gotoNextQuestion = (answer: Identification.IResponse) => {
    const next = relationQuestionnaireFactory?.getNextStep(answer);

    showQuestionOrResult(next, slides);
  };

  const gotoTunnelingIdentification = async () => {
    if (!relationQuestionnaireFactory) {
      await loadColoursFinderQuestionnaire();
    } else {
      startQuestionnaire();
    }
  };

  // Manual identification
  const gotoManualIdentification = () => {
    updateSlides([
      {
        type: SlideType.MANUAL,
        props: {
          relation: currentRelation,
        },
      },
    ]);
  };

  // Swiper events
  const onInitSwiper = (swiper: SwiperClass) => {
    swiperInstance.current = swiper;
  };
  const onSlideChange = (swiper: SwiperClass) => {
    updateActiveIndex(swiper.activeIndex);
  };

  useEffect(() => {
    if (relationQuestionnaireFactory) {
      startQuestionnaire();
    }
  }, [relationQuestionnaireFactory]);

  // Update relation if the relation id changed
  useEffect(() => {
    if (idRelation) {
      const relId = parseInt(idRelation, 10);
      if (!isNaN(relId)) {
        setCurrentRelation(getRelationById(relId));
      }
    }
  }, [idRelation]);

  // Each time slides changed we slide to the freshly created slide
  useEffect(() => {
    if (swiperInstance.current) {
      swiperInstance.current.slideTo(slides.length);
    }
  }, [slides]);

  // Handle back button action
  useEffect(() => {
    let backConf: ButtonPropsBase = backPropsDefault;
    if (activeIndex !== 0) {
      backConf = {
        onClick: () => {
          swiperInstance.current?.slideTo(0);
        },
      };
    }
    setBackLinkProps(backConf);
  }, [activeIndex]);

  useEffect(() => {
    if (
      currentRelation?.id === relationAppointmentTest?.relationId ||
      currentRelation?.id === relationManagementTest?.relationId
    ) {
      setIsInCurrentTest(true);
    } else {
      setIsInCurrentTest(false);
    }
  }, [currentRelation]);

  const IsInCurrentTestModal = () => {
    return (
      <IsInCurrentTestModalWrapper>
        {t('relations.identify.cannotidentifyrelationintest')}
      </IsInCurrentTestModalWrapper>
    );
  };
  return (
    <PageLayout headerProps={{ title: t('common.relations') }}>
      {currentRelation && (
        <RelationsIdentificationWrapper className="app-page">
          <Container padding={HEADER_HPADDING}>
            <IonButton {...backLinkProps} className="clear">
              <div className="icon" slot="start">
                {chevronBack.icon}
              </div>
              <span className="label">{t('common.back')}</span>
            </IonButton>
          </Container>
          <Container>
            <PageInnerTitle
              dangerouslySetInnerHTML={{
                __html: t('relations.identify.title', {
                  relationFullName: `${currentRelation.fullName}`,
                }),
              }}
            />
          </Container>
          <SliderContainer>
            <Slider onInit={onInitSwiper} allowTouchMove={false} onSlideChange={onSlideChange}>
              <SwiperSlide>
                <RelationIdentificationHome
                  gotoManualIdentification={() => {
                    isInCurrentTest
                      ? updateModalConf({
                          component: <IsInCurrentTestModal />,
                        })
                      : gotoManualIdentification();
                  }}
                  gotoTunnelingIdentification={() => {
                    isInCurrentTest
                      ? updateModalConf({
                          component: <IsInCurrentTestModal />,
                        })
                      : gotoTunnelingIdentification();
                  }}
                  relation={currentRelation}
                />
              </SwiperSlide>
              {!!slides.length &&
                slides.map((slide, index) => {
                  return (
                    <SwiperSlide key={`slide-${index}`}>
                      {slide.type === SlideType.MANUAL && (
                        <RelationManualIdentification
                          {...slide.props}
                          gotoTunnelingIdentification={gotoTunnelingIdentification}
                          onSendColors={sendColors}
                        />
                      )}

                      {slide.type === SlideType.QUESTION && (
                        <RelationQuizQuestion
                          question={slide.props.question as Identification.IQuestion}
                          onResponse={gotoNextQuestion}
                        />
                      )}
                      {slide.type === SlideType.RESULT && (
                        <RelationQuizQuestion
                          {...slide.props}
                          onRestart={restartQuestionnaire}
                          onSendColors={sendColors}
                          swiperInstance={swiperInstance}
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
            </Slider>
          </SliderContainer>
        </RelationsIdentificationWrapper>
      )}
    </PageLayout>
  );
};

export default RelationIdentification;
