import React from 'react';
import { Container, PageTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import HourGlass from 'assets/images/hourglass.png';
import { DiscoverProfileAvailableSoonWrapper } from 'pages/profile/discover-profile/DiscoverProfile.styled';

const DiscoverProfileAvailableSoon: React.FC = () => {
  const { t } = useTranslation('translation');

  return (
    <DiscoverProfileAvailableSoonWrapper>
      <Container className="container">
        <PageTitle
          dangerouslySetInnerHTML={{
            __html: t('profile.discover.profilesoon'),
          }}
        />
        <div className="content-wrapper">
          <div className="pix-wrapper">
            <img src={HourGlass} alt="Soon" />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: t('profile.discover.profilesoondesc'),
            }}
          />
        </div>
      </Container>
    </DiscoverProfileAvailableSoonWrapper>
  );
};

export default DiscoverProfileAvailableSoon;
