import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';
import { FONT_WEIGHT } from 'styles/constants/fonts';

const ProgressBarContainer = styled.div`
  height: ${prem(6)};
  position: relative;
  overflow: hidden;
  background-color: var(--ion-text-color-secondary);
  border-radius: ${prem(6)};

  .bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: var(--ion-color-accent);
    box-shadow: 4px 0 2px 0 #345e544d;
    border-radius: ${prem(6)};
  }
`;

const ProgressionLabelPath = styled.div`
  position: relative;
  margin-top: ${prem(4)};
`;

const ProgressionLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  width: ${prem(38)};
  height: ${prem(32)};
  display: flex;
  justify-content: center;
  align-items: center;

  .label-text {
    color: white;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: ${prem(14)};
    margin-top: 2px;
    text-align: center;
  }

  svg {
    width: 100%;
    height: auto;
    color: var(--ion-color-accent);
  }
`;

const ProgressBarWrapper = styled.div`
  padding: 0 ${prem(20)};

  .title {
    color: var(--ion-text-color);
    text-align: center;
    margin-bottom: ${prem(16)};
    ${BODY.small};
  }
`;

export { ProgressBarContainer, ProgressionLabel, ProgressionLabelPath, ProgressBarWrapper };
