import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const ModulesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: ${prem(16)};
`;

export { ModulesWrapper };
