import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const StepWrapper = styled.div`
  /* stylelint-disable */
  background: rgba(var(--ion-color-accentdefault-rgb), 0.15);
  /* stylelint-enable */
  font-weight: bolder;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${prem(20)};
  padding: ${prem(20)};
  border-radius: ${prem(15)};

  &.hide {
    display: none;
  }
`;

export { StepWrapper };
