import styled, { css } from 'styled-components';

const ShowHideWrapper = styled.div<{ height: string }>`
  overflow: hidden;
  ${({ height }) =>
    height == '0px'
      ? css`
          transition: all 0.3s linear -0.25s;
          max-height: ${height};
        `
      : css`
          transition: all 0.5s ease-in;
          max-height: ${height};
        `};
`;

const ShowHideInner = styled.div``;

export { ShowHideWrapper, ShowHideInner };
