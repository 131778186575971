import { useEffect, useRef } from 'react';

const useOutsideClick = (callback: () => void) => {
  const ref = useRef<any>();
  const exceptRef = useRef<any>();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current?.contains(event.target as HTMLElement)) {
        if (!exceptRef.current?.contains(event.target as HTMLElement)) {
          callback && callback();
        }
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return { ref, exceptRef };
};

export default useOutsideClick;
