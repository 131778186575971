import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { SUBTITLE } from 'styles/helpers/typography';
import { centerMessage } from 'styles/helpers/utils';

const MotivationSubTitle = styled.h3`
  ${SUBTITLE.medium};
  color: var(--ion-text-color-secondary);
  text-align: center;
  margin-bottom: ${prem(24)};
`;

const MotivationWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: ${prem(16)};

  .no-valid-profile {
    ${centerMessage};
  }
`;

const MotivationSlideWrapper = styled.div`
  height: 100%;
`;

export { MotivationSubTitle, MotivationWrapper, MotivationSlideWrapper };
