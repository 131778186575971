import React from 'react';
import {
  Chevron,
  IconChevronWrapper,
} from 'components/common/icons/icon-chevron/IconChevron.styled';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';

export interface IconChevronProps {
  opened?: boolean;
  mode?: 'rightdown' | 'downup';
}

const IconChevron: React.FC<IconChevronProps> = ({ opened, mode = 'rightdown' }) => {
  const {
    icons: [chevron],
  } = useIcons([ICON_NAMES.CHEVRON]);
  return (
    <IconChevronWrapper opened={opened} mode={mode}>
      <Chevron>{chevron.icon}</Chevron>
    </IconChevronWrapper>
  );
};

export default IconChevron;
