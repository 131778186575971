import React, { useCallback, useEffect, useState } from 'react';
import { IFaqs } from 'types/app';
import useAPIFaqs, { FaqsCategory } from 'hooks/api/useAPIFaqs';
import { DiscoverProfileWrapper } from 'pages/profile/discover-profile/DiscoverProfile.styled';
import DiscoverProfileWithFaq from 'pages/profile/discover-profile/DiscoverProfileWithFaq';
import DiscoverProfileAvailableSoon from 'pages/profile/discover-profile/DiscoverProfileAvailableSoon';

export const enum DiscoverMode {
  AVAILABLE_SOON,
  WITH_FAQS,
}

export interface DiscoverProfileProps {
  mode: DiscoverMode;
}

const DiscoverProfile: React.FC<DiscoverProfileProps> = ({ mode }) => {
  const { getFaqsByCategory } = useAPIFaqs();
  const [faqs, setFaqs] = useState<IFaqs>([]);

  const loadFaqs = useCallback(async () => {
    setFaqs(await getFaqsByCategory(FaqsCategory.DISCOVER_PROFILE));
  }, []);

  // On loaded
  useEffect(() => {
    mode === DiscoverMode.WITH_FAQS && loadFaqs();
  }, []);

  return (
    <DiscoverProfileWrapper className="app-page">
      {mode === DiscoverMode.WITH_FAQS && <DiscoverProfileWithFaq faqs={faqs} />}
      {mode === DiscoverMode.AVAILABLE_SOON && <DiscoverProfileAvailableSoon />}
    </DiscoverProfileWrapper>
  );
};

export default DiscoverProfile;
