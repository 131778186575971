import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QuestionnaireMainProgressWrapper,
  QuestionnaireMainSliderWrapper,
  QuestionnaireMainWrapper,
} from 'pages/questionnaire/slides/questionnaire/QuestionnaireMain.styled';
import ProgressBar from 'components/common/progress-bar/ProgressBar';
import { SwiperSlide, useSwiper } from 'swiper/react';
import Slider from 'components/slider/Slider';
import QuestionSlideWrapper, {
  QuestionSlideWrapperProps,
} from 'pages/questionnaire/slides/questionnaire/questionnaire-slide/QuestionSlideWrapper';
import { Swiper as SwiperClass } from 'swiper/types';
import useAPIQuestionnaire, { IResponse } from 'hooks/api/useAPIQuestionnaire';
import { Questionnaire } from 'types/app';
import { PRIMARY_QUESTIONNAIRE_NB, TOTAL_QUESTIONNAIRE } from 'constants/global';
import { useIonLoading } from '@ionic/react';

export interface QuestionnaireMainProps {
  q: Questionnaire.IQuestionnaire;
  topicNumbers: number;
  isAdditional?: boolean;
}

const QuestionnaireMain: React.FC<QuestionnaireMainProps> = ({ q, isAdditional }) => {
  const { t } = useTranslation('translation');
  const parentSwiper = useSwiper();
  const { sendResponses, sendAdditionalResponses } = useAPIQuestionnaire();
  const [progression, updateProgression] = useState(0);
  const [isAdditionalState, setIsAdditionalState] = useState<boolean>(isAdditional || false);
  const questionnaire = useRef<Questionnaire.IQuestionnaire>(q);
  const swiperInstance = useRef<SwiperClass>();
  const [slidesContents, setSlidesContents] = useState<Array<QuestionSlideWrapperProps>>([]);
  const { loadAdditionalQuestionnaire } = useAPIQuestionnaire();
  const [present, dismiss] = useIonLoading();

  const updateSlidesContents = () => {
    const slideIndex = swiperInstance.current?.realIndex;
    const theQuestionnaire = questionnaire.current;
    const nextQuestionIndex = theQuestionnaire.currentQuestionIndex + 1;
    const prevQuestionIndex = theQuestionnaire.currentQuestionIndex - 1;
    const currentQuestion = theQuestionnaire.questions[theQuestionnaire.currentQuestionIndex];
    const nextQuestion = theQuestionnaire.questions[nextQuestionIndex];
    const previousQuestion = theQuestionnaire.questions[prevQuestionIndex];
    const slideCurrent: QuestionSlideWrapperProps = {
      question: currentQuestion,
      gotoNext:
        (nextQuestion?.response && nextQuestion.id > 0) || currentQuestion?.response
          ? gotoNext
          : undefined,
      gotoPrevious: previousQuestion?.response && progression !== (PRIMARY_QUESTIONNAIRE_NB * 100 / TOTAL_QUESTIONNAIRE) ? gotoPrevious : undefined,
      onAnswer,
      disableButShowPreviousButton: currentQuestion.disableButShowPreviousButton
    };
    const slideNext: QuestionSlideWrapperProps = {
      question: nextQuestion,
      gotoNext:
        theQuestionnaire.questions[nextQuestionIndex + 1]?.response || nextQuestion?.response
          ? gotoNext
          : undefined,
      gotoPrevious,
    };
    const slidePrevious: QuestionSlideWrapperProps = {
      question: previousQuestion,
      gotoNext,
      gotoPrevious: theQuestionnaire.questions[prevQuestionIndex - 1] ? gotoNext : undefined,
    };

    /*
    console.log('===============');
    console.log('slideCurrent: ', slideCurrent);
    console.log('slideNext: ', slideNext);
    console.log('slidePrevious: ', slidePrevious);
    */

    if (slideIndex !== undefined && swiperInstance.current) {
      const totalSlides = swiperInstance.current.slides.length;
      const currentSlideIndex = slideIndex;
      const nextSlideIndex = slideIndex + 1 >= totalSlides ? 0 : slideIndex + 1;
      const prevSlideIndex = slideIndex - 1 < 0 ? totalSlides - 1 : slideIndex - 1;
      const contents: Array<QuestionSlideWrapperProps> = [];

      contents[currentSlideIndex] = slideCurrent;
      contents[nextSlideIndex] = slideNext;
      contents[prevSlideIndex] = slidePrevious;

      /*
      console.log('currentSlideIndex: ', currentSlideIndex);
      console.log('nextSlideIndex: ', nextSlideIndex);
      console.log('prevSlideIndex: ', prevSlideIndex);
      console.log('===============');
      */
      setSlidesContents(contents);
    }
  };
  const onInitSwiper = (swiper: SwiperClass) => {
    swiperInstance.current = swiper;
    onSwiperSlideChange();
  };
  const onSwiperSlideChange = () => {
    updateSlidesContents();
  };
  const gotoPrevious = () => {
    const prevIndex = questionnaire.current?.currentQuestionIndex - 1;
    questionnaire.current.currentQuestionIndex = prevIndex > 0 ? prevIndex : 0;
    swiperInstance.current?.slidePrev();
  };
  const gotoNext = () => {
    const nextIndex = questionnaire.current?.currentQuestionIndex + 1;
    questionnaire.current.currentQuestionIndex = questionnaire.current.questions[nextIndex]
      ? nextIndex
      : questionnaire.current.questions.length - 1;
    swiperInstance.current?.slideNext();
  };
  const updateQuestionnaire = (qAnswered: Questionnaire.IQuestion, isAdditional: boolean) => {
    const tmpQnnaire: Questionnaire.IQuestionnaire = { ...questionnaire.current };
    const questions = [...tmpQnnaire.questions];

    questions.forEach((q) => {
      if (q.id === qAnswered.id) {
        q.response = qAnswered.response;
      }
    });

    const nbQAnswered = questions.filter((item) => {
      return !!item.response && item.response > 0;
    }).length;
    if (!isAdditional) {
      tmpQnnaire.progression = Math.floor((nbQAnswered / TOTAL_QUESTIONNAIRE) * 100);
    } else {
      tmpQnnaire.progression = Math.floor(
        ((nbQAnswered / TOTAL_QUESTIONNAIRE) * 100) + (PRIMARY_QUESTIONNAIRE_NB * 100 / TOTAL_QUESTIONNAIRE),
      );
    }
    questionnaire.current = tmpQnnaire;
    updateProgression(tmpQnnaire.progression);
  };

  const onAnswer = async (qAnswered: Questionnaire.IQuestion) => {
    if (!isAdditionalState) {
      const isSent = await sendResponses([
        {
          questionId: qAnswered.id,
          questionnaireId: questionnaire.current.id,
          response: qAnswered.response,
          responseOrder: qAnswered.order,
        },
      ] as Array<IResponse>);
  
      if (isSent) {
        updateQuestionnaire(qAnswered, isAdditionalState);
        if (questionnaire.current.progression >= Math.floor(PRIMARY_QUESTIONNAIRE_NB * 100 / TOTAL_QUESTIONNAIRE)) {
          // show loading here
          await present({
            spinner: 'circles',
            message: t('profile.questionnaire.loading.additionalquestions')
          });
          const additionalQData = await loadAdditionalQuestionnaire();
          // show loading here
          if (additionalQData && additionalQData.questionnaire) {
            questionnaire.current = additionalQData.questionnaire;
            setIsAdditionalState(true);
            // hide loading here
          }
          await dismiss();
        } else {
          gotoNext();
        }
      }
    } else {
      const isSent = await sendAdditionalResponses([
        {
          questionId: qAnswered.id,
          questionnaireId: questionnaire.current.id,
          response: qAnswered.response,
          responseOrder: qAnswered.order,
        },
      ] as Array<IResponse>);
      if (isSent) {
        updateQuestionnaire(qAnswered, isAdditionalState);
        if (questionnaire.current.progression === 100) {
          localStorage.setItem('answered_questionnaire', 'true');
          parentSwiper.slideNext();
        } else {
          gotoNext();
        }
      }
    }
  };

  /**
   * if (isSent) {
        updateQuestionnaire(qAnswered, true);
        if (questionnaire.current.progression === 100) {
          setTimeout(() => {
          }, 4000);
          const additionalQData = await loadAdditionalQuestionnaire();
          if (additionalQData && additionalQData.questionnaire) {
            const slides: Array<React.ReactElement> = [];
            slides.push(
              <QuestionnaireMain
                q={{
                  ...additionalQData.questionnaire,
                  id: 21,
                  questions: [...additionalQData.questionnaire.questions],
                  currentQuestionIndex:
                    additionalQData.questionnaire.currentQuestionIndex + 0,
                }}
                topicNumbers={0}
                isAdditional={true}
                updateParentSlidesContents={updateParentSlidesContents}
              />
            );
            slides.push(<QuestionnaireEnd />);
            updateParentSlidesContents(slides);
            parentSwiper.slideNext();
          }
        } else {
          gotoNext();
        }
      }
   * 
   */
  // Load
  useEffect(() => {
    updateProgression(questionnaire.current.progression);
    updateSlidesContents();
  }, [isAdditionalState]);
  return (
    <QuestionnaireMainWrapper>
      <QuestionnaireMainSliderWrapper>
        <Slider
          initialSlide={1}
          onInit={onInitSwiper}
          allowTouchMove={false}
          onSlideChangeTransitionEnd={onSwiperSlideChange}
          loop
        >
          <SwiperSlide>
            <QuestionSlideWrapper
              {...slidesContents[0]}
              key={slidesContents[0]?.question ? `${slidesContents[0].question.id}` : 0}
            />
          </SwiperSlide>
          <SwiperSlide>
            <QuestionSlideWrapper
              {...slidesContents[1]}
              key={slidesContents[1]?.question ? `${slidesContents[1].question.id}` : 1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <QuestionSlideWrapper
              {...slidesContents[2]}
              key={slidesContents[2]?.question ? `${slidesContents[2].question.id}` : 2}
            />
          </SwiperSlide>
        </Slider>
      </QuestionnaireMainSliderWrapper>
      {progression < 100 && (
        <QuestionnaireMainProgressWrapper>
          <ProgressBar percent={progression} title={`${t('questionnaire.progression-title')}`} />
        </QuestionnaireMainProgressWrapper>
      )}
    </QuestionnaireMainWrapper>
  );
};

export default QuestionnaireMain;
