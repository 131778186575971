import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const LinkedinPostsWrapper = styled.div`
  margin-bottom: ${prem(25)};
`;

const LinkedinSinglePost = styled.div`
  margin-bottom: ${prem(10)};

  .title {
    ${BODY.medium}
    font-weight: bolder;
    margin-bottom: ${prem(10)};
  }

  .image-container {
    max-width: 100%;
    margin: ${prem(10)} 0;
    text-align: center;

    img {
      border-radius: ${prem(2)};
    }
  }

  .description {
    ${BODY.small}
    line-height: ${prem(18)};

    p {
      margin-bottom: ${prem(5)};
    }
  }
`;

export { LinkedinPostsWrapper, LinkedinSinglePost };
