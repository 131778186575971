import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { IFaqs } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { IHttpResponse } from 'helpers/api';

export const enum FaqsCategory {
  DISCOVER_PROFILE = 1,
  GENERAL = 2,
}

export interface IUseAPIFaqs {
  getFaqsByCategory: (categoryId: FaqsCategory) => Promise<IFaqs>;
}

type RaqRawTranslation = { locale: string; question: string; reponse: string };

function useAPIFaqs(): IUseAPIFaqs {
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  // Parse questions/responses
  const parseFaqs = (faqs: any): IFaqs => {
    if (Array.isArray(faqs)) {
      return faqs.map((faq) => {
        const translations: Array<RaqRawTranslation> = faq.translations;

        return {
          title: {
            fr: translations.find((t) => t.locale === 'fr')?.question || '',
            en: translations.find((t) => t.locale === 'en')?.question || '',
          },
          desc: {
            fr: translations.find((t) => t.locale === 'fr')?.reponse || '',
            en: translations.find((t) => t.locale === 'en')?.reponse || '',
          },
        };
      });
    }
    return [];
  };

  // Load relation advices
  const getFaqsByCategory = async (
    categoryId: FaqsCategory,
    isInBackground?: boolean,
  ): Promise<IFaqs> => {
    !isInBackground && showLoading();

    let faqs: IFaqs = [];

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.FAQS.CATEGORY}/${categoryId}`,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const getState = result.data.data?.faqs;

      if (Array.isArray(getState)) {
        faqs = parseFaqs(getState);
      } else {
        if (result.data.message) {
          !isInBackground &&
            setToast({
              message: result.data.message,
              status: 'danger',
            });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting advices : ', e);
    } finally {
      !isInBackground && hideLoading();
    }

    return faqs;
  };

  return {
    getFaqsByCategory,
  };
}

export default useAPIFaqs;
