import { IonButtonWrapper } from 'components/common/button/Button.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const SingleRelationInvitationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${prem(20)};
  margin: 0 auto ${prem(10)} auto;
  padding-bottom: ${prem(10)};
  border-bottom: ${prem(1)} solid var(--ion-color-accent);

  .invitation-icon {
    color: var(--ion-color-accent);
    width: ${prem(20)} !important;

    svg {
      width: ${prem(25)};
    }
  }

  .invitation-name {
    ${BODY.mediumM}
    word-wrap: break-word;
    hyphens: auto;
    font-weight: bolder;
  }

  .invitation-confirmation-buttons {
    display: flex;
    align-items: center;
    gap: ${prem(5)};
    flex: 1;
    justify-content: flex-end;
    color: var(--ion-color-orange);

    ${IonButtonWrapper} {
      max-width: ${prem(80)};

      ion-button {
        &::part(native) {
          ${BODY.small}
          border-radius: ${prem(20)};
        }
      }
    }
  }
`;

export { SingleRelationInvitationWrapper };
