import styled from 'styled-components';
import { IonItem, IonTextarea } from '@ionic/react';
import { prem } from 'styles/helpers/spacing';
import COLORS from 'styles/constants/colors';

const IonInputWrapper = styled.div`
  ion-input {
    --ripple-color: transparent;
    --box-shadow: unset;
    text-transform: unset;
    height: unset;
  }

  ion-textarea {
    border: ${prem(1)} solid var(--ion-color-accent);
    padding: ${prem(10)} ${prem(13)};
  }
`;

const IonItemWrapper = styled(IonItem)`
  --padding-start: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --min-height: ${prem(20)};
  --ripple-color: transparent;

  ion-label {
    transform: translate(4%, 200%) scale(1) !important;
  }

  &.item-has-value,
  &.item-has-focus {
    ion-label {
      opacity: 0;
    }
  }

  ion-note[slot='error'] {
    --highlight-color-invalid: ${COLORS.FORM.REQUIRED.DEFAULT};
  }
`;

const IonTextAreaStyled = styled(IonTextarea)``;

export { IonInputWrapper, IonItemWrapper, IonTextAreaStyled };
