import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY, SUBTITLE } from 'styles/helpers/typography';
import { SvgWrapper, transparentEl } from 'styles/helpers/utils';

const ColoursNames = styled.p`
  margin: 0 0 ${prem(4)};
  color: var(--ion-text-color-secondary);
  ${SUBTITLE.small};
  text-align: center;
`;
const RelationInteractionDesc = styled.p`
  color: var(--ion-text-color-secondary);
  margin: ${prem(16)} 0;
  white-space: pre-wrap;
`;
const RelationInteractionWrapper = styled.div`
  margin-bottom: ${prem(30)};

  .note-redirect-to {
    color: var(--ion-text-color-secondary);
    ${BODY.small};
    text-align: center;
  }

  .no-current-test {
    .ctas {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      gap: ${prem(15)};
    }
  }

  .buy-module {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: ${prem(20)};

    .cannot-test-anymore {
      margin-top: ${prem(20)};
      text-align: center;
    }
  }
`;
const RelationInteractionButtonSubscribe = styled.button`
  ${transparentEl};
  display: inline-flex;
  align-items: center;
  gap: ${prem(10)};
  color: var(--ion-text-color-secondary);

  &.subscribed {
    color: var(--ion-color-accent);

    ${SvgWrapper} {
      color: var(--ion-color-highlight);
    }
  }
`;
const RelationInteractionButtonSubscribeWrapper = styled.div`
  text-align: right;
`;

export {
  RelationInteractionWrapper,
  ColoursNames,
  RelationInteractionDesc,
  RelationInteractionButtonSubscribe,
  RelationInteractionButtonSubscribeWrapper,
};
