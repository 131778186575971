import { PageTitle } from 'layout/page/Page.layout.styled';
import styled, { keyframes } from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(5deg);
  }

  60% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

const PaymentSuccessWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  h1 {
    text-align: center;
  }

  svg {
    color: var(--ion-color-green);
    width: 100%;
    max-width: ${prem(250)};
    animation: ${rotateAnimation} 2s linear;
  }

  ${PageTitle} {
    display: flex;
    gap: ${prem(10)};
  }
`;

export { PaymentSuccessWrapper };
