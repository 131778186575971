import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import COLORS from 'styles/constants/colors';
import { HEADLINE, SUBTITLE } from 'styles/helpers/typography';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { BREAKPOINTS, MQ_MAX_HEIGHT } from 'styles/constants/devices';

const IntroContainer = styled.div`
  margin-top: 0;
  height: 100%;
`;

const IntroPageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${MQ_MAX_HEIGHT}) {
    align-items: unset;
  }

  .intro-texts-container {
    margin-bottom: ${prem(60)};

    @media (${MQ_MAX_HEIGHT}) {
      margin-bottom: ${prem(20)};
    }
  }

  p {
    ${SUBTITLE.small};
    font-weight: ${FONT_WEIGHT.LIGHT};
    margin: 0 auto ${prem(16)};
    color: ${COLORS.BASE.GREEN.DEFAULT};
    text-align: center;

    strong {
      font-weight: ${FONT_WEIGHT.LIGHT};
    }
  }
`;

const IntroHeading = styled.h1`
  color: var(--ion-color-primary);
  ${HEADLINE.medium};
  margin-bottom: ${prem(16)};
`;

const IntroPreLoginWrapper = styled.div`
  text-align: center;
`;

const IntroPreLoginButtonWrapper = styled.div`
  width: ${prem(212)};
  margin: 0 ${prem(82)} ${prem(25)};

  @media (${BREAKPOINTS.M}) {
    width: unset;
  }
`;

export {
  IntroPageWrapper,
  IntroContainer,
  IntroHeading,
  IntroPreLoginWrapper,
  IntroPreLoginButtonWrapper,
};
