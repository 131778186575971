import {
  ButtonCloseWrapper,
  ButtonFullScreenWrapper,
  RegularButton,
} from 'components/common/button/Button.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const ElearningWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 300;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
    pointer-events: auto;
  }

  &.close {
    display: none;
    pointer-events: none;
  }

  ${RegularButton} {
    transition: 0.2s linear;
  }
  
  ${ButtonCloseWrapper} {
    position: absolute;
    right: ${prem(30)};
    top: ${prem(50)};

    ${RegularButton} {
      color: white;
      background-color: var(--ion-text-color-secondary);
      border-radius: ${prem(50)};

      &:hover {
        /* stylelint-disable */
        background: rgba(var(--ion-color-red-rgb), 0.7);
        /* stylelint-enable */
      }
    }
  }

  ${ButtonFullScreenWrapper} {
    position: absolute;
    right: ${prem(70)};
    top: ${prem(50)};

    ${RegularButton} {
      color: white;
      background-color: var(--ion-text-color-secondary);
      border-radius: ${prem(50)};

      &:hover {
        /* stylelint-disable */
        background: rgba(var(--ion-color-green-rgb), 0.7);
        /* stylelint-enable */
      }

      svg {
        padding: 8px;
      }
    }
  }
`;

export { ElearningWrapper };
