import React from 'react';
import { BuyProfileWrapper } from 'pages/profile/buy-profile/BuyProfile.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';
import QuestionnaireButton from 'components/profile/QuestionnaireButton';

export interface BuyProfileProps {
  message: string;
  btnLabel?: string;
  onClick?: () => void;
}

const BuyProfile: React.FC<BuyProfileProps> = ({ message, btnLabel, onClick }) => {
  return (
    <BuyProfileWrapper className="app-page">
      <Container flexCentered={true}>
        <p className="message">{message}</p>
        <GenericDiv width="25%" minWidth={prem(240)} center={true}>
          <QuestionnaireButton onClick={onClick} label={btnLabel} />
        </GenericDiv>
      </Container>
    </BuyProfileWrapper>
  );
};

export default BuyProfile;
