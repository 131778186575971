import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from 'layout/auth/Auth.layout';
import { useIonViewDidEnter } from '@ionic/react';
import { ForgottenpwdTitle, ForgottenpwdWrapper } from 'pages/forgottenpwd/Forgottenpwd.styled';
import ForgottenpwdEmail from 'pages/forgottenpwd/Forgottenpwd-email';
import ForgottenpwdNewPwd from 'pages/forgottenpwd/Forgottenpwd-newpwd';
import useUpdateChecker from 'hooks/useUpdateChecker';
import { ROUTES } from 'constants/routes';

export interface ForgottenPwdProps {
  onSubmitted?: (email?: string) => void;
  onBack?: () => void;
  email?: string;
}

const Forgottenpwd: React.FC = () => {
  useUpdateChecker(ROUTES.FORGOTTEN_PWD.INDEX);
  const { t } = useTranslation('translation');
  const [email, setEmail] = useState<string>();
  const [step, setStep] = useState(1);

  const onEmailSubmitted = (e?: string) => {
    if (e) {
      setEmail(e);
      setStep(2);
    }
  };

  useIonViewDidEnter(() => {
    setStep(1);
  });

  return (
    <AuthLayout>
      <ForgottenpwdWrapper className={`step-${step}`}>
        <ForgottenpwdTitle>{t('forgottenpwd.title')}</ForgottenpwdTitle>
        {step === 1 && <ForgottenpwdEmail onSubmitted={onEmailSubmitted} />}
        {step === 2 && <ForgottenpwdNewPwd email={email} onBack={() => setStep(step - 1)} />}
      </ForgottenpwdWrapper>
    </AuthLayout>
  );
};

export default Forgottenpwd;
