import styled from 'styled-components';
import { IonLabel } from '@ionic/react';
import COLORS from 'styles/constants/colors';
import { prem } from 'styles/helpers/spacing';

const IonLabelWrapper = styled(IonLabel)`
  &.label-floating.sc-ion-label-md-h,
  &.label-floating.sc-ion-label-ios-h {
    transform: translateY(120%);
  }
`;
const IonLabelAsterisk = styled.span`
  color: ${COLORS.FORM.REQUIRED.DEFAULT};
  margin-left: ${prem(8)};
  display: inline-block;
`;

export { IonLabelWrapper, IonLabelAsterisk };
