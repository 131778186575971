import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { ColorPickerContainer } from 'components/colours-picker/ColoursPicker.styled';

const MotivationColorSelectionWrapper = styled.div`
  .link-back {
    text-align: center;
  }
`;
const ColoursSelectorWrapper = styled.div`
  padding: ${prem(36)} 0;
  margin-bottom: ${prem(24)};

  ${ColorPickerContainer} {
    justify-content: center;
  }
`;
const DescColourMotivation = styled.div`
  margin-bottom: ${prem(36)};
  color: var(--ion-text-color-secondary);
`;

export { ColoursSelectorWrapper, DescColourMotivation, MotivationColorSelectionWrapper };
