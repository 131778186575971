import React, { useState } from 'react';
import {
  QuestionnaireResponsesWrapper,
  ResponsesWrapper,
} from 'components/questionnaire/QuestionnaireResponses.styled';
import Checkbox from 'components/common/form/checkbox/Checkbox';

export interface IQResponse {
  label: string;
  value: number;
}

export interface QuestionnaireResponsesProps {
  choices: Array<IQResponse>;
  onSelect?: (rep?: IQResponse) => void;
  selected?: IQResponse;
  limitsLabels?: {
    min: string;
    max: string;
  };
  disabled?: boolean
}

const QuestionnaireResponses: React.FC<QuestionnaireResponsesProps> = ({
  choices = [],
  onSelect,
  selected,
  limitsLabels,
}) => {
  const [currentSelected, setCurrent] = useState(selected);
  const onClickItem = (rep?: IQResponse) => {
    onSelect && onSelect(rep);
    setCurrent(rep);
  };

  return (
    <QuestionnaireResponsesWrapper>
      <ResponsesWrapper>
        {choices.map((item, index) => {
          return (
            <Checkbox
              key={`qr-${index}`}
              label={item.label}
              onChange={(checked) => {
                onClickItem(checked ? item : undefined);
              }}
              value={currentSelected && item.value === currentSelected.value}
              // disabled={currentSelected && item.value === currentSelected.value}
            />
          );
        })}
      </ResponsesWrapper>
      {limitsLabels && (
        <div className="limits-container">
          <div className="min" dangerouslySetInnerHTML={{ __html: limitsLabels.min }} />
          <div className="max" dangerouslySetInnerHTML={{ __html: limitsLabels.max }} />
        </div>
      )}
    </QuestionnaireResponsesWrapper>
  );
};

export default QuestionnaireResponses;
