import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { ContactLink, ContactWrapper } from 'pages/contact/Contact.styled';
import Button from 'components/common/button/Button';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import useAPIContact from 'hooks/api/useAPIContact';
import { IContactDetails } from 'types/app';
import { UserContext, UserContextProps } from 'contexts/UserContext';

const Contact: React.FC = () => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const {
    icons: [iconPhone, iconSend],
  } = useIcons([ICON_NAMES.PHONE, ICON_NAMES.SEND]);
  const { getContactDetails } = useAPIContact();
  const [contactDetailsState, setContactDetailsState] = useState<IContactDetails>();
  const loadContactDetails = useCallback(async () => {
    if (user) {
      setContactDetailsState(await getContactDetails());
    } else {
      setContactDetailsState({
        email: 'contact@comcolors.com',
        isComcolorsContact: true,
        phoneNumber: '+33 (0)1 69 23 39 19',
      });
    }
  }, []);

  useEffect(() => {
    loadContactDetails();
  }, []);

  return (
    <PageLayout headerProps={{ title: t('contact.title') }}>
      <Container className="app-page">
        <ContactWrapper>
          {contactDetailsState && (
            <>
              <PageInnerTitle>
                {contactDetailsState.isComcolorsContact
                  ? t('contact.subtitle_without_trainer')
                  : t('contact.subtitle_with_trainer')}
              </PageInnerTitle>
              <div className="contact-container">
                <div className="contact-title">
                  {contactDetailsState.isComcolorsContact
                    ? t('contact.contact_comcolors')
                    : t('contact.contact_trainer')}
                </div>
                {contactDetailsState.phoneNumber && (
                  <Button color="white" small>
                    <ContactLink href={`tel:${contactDetailsState.phoneNumber}`}>
                      <div className="icon">{iconPhone.icon}</div>
                      <label>{contactDetailsState.phoneNumber}</label>
                    </ContactLink>
                  </Button>
                )}
                <Button color="white" fill="outline" small>
                  <ContactLink href={`mailto:${contactDetailsState.email}`}>
                    <div className="icon">{iconSend.icon}</div>
                    <label>{contactDetailsState.email}</label>
                  </ContactLink>
                </Button>
              </div>
            </>
          )}
        </ContactWrapper>
      </Container>
    </PageLayout>
  );
};

export default Contact;
