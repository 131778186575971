import React from 'react';
import { IComponentWithChildren } from 'types/app';
import ShowHideComp from 'components/common/showhide/ShowHideComp';
import {
  CollapseButton,
  CollapseLabel,
  CollapseWrapper,
} from 'components/common/showhide/Collapse.styled';
import IconChevron from 'components/common/icons/icon-chevron/IconChevron';

export interface CollapseProps extends IComponentWithChildren {
  label: string | React.ReactElement;
  id?: string;
  opened?: boolean;
  onOpened?: (open: boolean, id?: string) => void;
  className?: string;
  disabled?: boolean;
}

const Collapse: React.FC<CollapseProps> = ({
  label,
  id,
  children,
  opened,
  onOpened,
  className,
  disabled,
}) => {
  const onOpenedHandler = () => {
    onOpened && onOpened(!opened, id);
  };

  return (
    <CollapseWrapper className={className}>
      <CollapseButton
        className={`${opened ? 'opened' : ''}`}
        onClick={onOpenedHandler}
        disabled={disabled}
      >
        <CollapseLabel>{label}</CollapseLabel>
        <IconChevron mode="downup" opened={opened} />
      </CollapseButton>
      <ShowHideComp opened={opened}>{children}</ShowHideComp>
    </CollapseWrapper>
  );
};

export default Collapse;
