import { IColor } from 'types/app';
import { CC_COLORS } from 'constants/global';

export function isColor(color = ''): boolean {
  return !!color.match(/^#[0-9a-f]{3,6}$/i);
}
export function getCCColor(value?: string): IColor | undefined {
  return CC_COLORS.find((c) => c.name.fr.toLowerCase() === value);
}

// Colors
export function getColorByName(name?: string): IColor | undefined {
  if (name) {
    for (const color of CC_COLORS) {
      for (const item of Object.entries(color.name)) {
        if (item[1].toLowerCase() == name.toLowerCase()) {
          return color;
        }
      }
    }
  }

  return undefined;
}

export function getColorById(id?: number | null): IColor | undefined {
  return CC_COLORS.find((c) => c.id === id);
}

export function getColorCodeById(id?: number | null, defaultColor?: string): string | null {
  const color = getColorById(id);

  if (color) {
    return color.color;
  }

  return defaultColor || null;
}

export function getColorNameById(id: number, locale: string): string | null {
  const color = getColorById(id);

  if (color) {
    for (const [key, value] of Object.entries(color.name)) {
      if (key === locale) {
        return value;
      }
    }
  }

  return null;
}

export function getRandomColor(force?: boolean): IColor | null {
  const rand = Math.floor(Math.random() * (CC_COLORS.length + (force ? 0 : 4)));

  try {
    return getColorById(CC_COLORS[rand].id) as IColor;
  } catch (e) {
    return null;
  }
}
