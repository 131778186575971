import React, { useContext } from 'react';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import {
  AppMenuWrapper,
  IconWrapper,
  LoginMenuContainer,
  LogoutContainer,
  UserNameContainer,
} from 'components/menu/AppMenu.styled';
import useIcons, { IIcon } from 'hooks/useIcons';
import Button from 'components/common/button/Button';
import { ButtonInnerWrapper } from 'components/common/button/Button.styled';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import AuthentificationModal from 'components/modal/authentification/AuthentificationModal';
import { useTranslation } from 'react-i18next';

export interface AppMenuProps {
  lines?: 'full' | 'inset' | 'none';
  inset?: boolean;
  entries?: Array<IIcon>;
  onSelectItem?: () => void;
}

const AppMenu: React.FC<AppMenuProps> = ({
  onSelectItem,
  inset = false,
  lines = 'none',
  entries = [],
}: AppMenuProps) => {
  const { logout } = useAPIAuth();
  const { user, updateUser, isGuest } = useContext(UserContext) as UserContextProps;
  const { t } = useTranslation('translation');
  const {
    icons: [logoutIcon],
  } = useIcons(['logout']);
  const doLogout = async () => {
    await logout();
    updateUser();
    window.location.reload();
  };

  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  return (
    <AppMenuWrapper>
      <IonList lines={lines} inset={inset}>
        {entries.map(({ icon, label, name, route }, index) => (
          <IonItem
            key={`${name}-${index}`}
            onClick={onSelectItem}
            routerLink={route}
            detail={false}
          >
            <IconWrapper>{icon}</IconWrapper>
            <IonLabel>{label}</IonLabel>
          </IonItem>
        ))}
      </IonList>
      {!isGuest ? (
        <>
          <UserNameContainer>
            <span>
              {user?.firstname} {user?.lastname}
            </span>
            <br />
            <span>{`(${user?.email})`}</span>
          </UserNameContainer>
          <LogoutContainer>
            <Button strong={false} color="accent" expand={true} onClick={doLogout}>
              <ButtonInnerWrapper>
                <IconWrapper>{logoutIcon.icon}</IconWrapper>
                {logoutIcon.label}
              </ButtonInnerWrapper>
            </Button>
          </LogoutContainer>
        </>
      ) : (
        <>
          <UserNameContainer>
            <span>{t('guest.guestaccount')}</span>
            <br />
            <span>{`(${t('guest.connectformorefeature')})`}</span>
          </UserNameContainer>
          <LoginMenuContainer>
            <Button
              strong={false}
              color="accent"
              expand={true}
              onClick={() => {
                updateModalConf({
                  component: <AuthentificationModal />,
                });
              }}
            >
              <ButtonInnerWrapper>{t('guest.connect')}</ButtonInnerWrapper>
            </Button>
          </LoginMenuContainer>
        </>
      )}
    </AppMenuWrapper>
  );
};

export default AppMenu;
