import React from 'react';
import { ButtonProps } from 'components/common/button/Button';
import { ButtonTOCWrapper } from 'components/common/button/Button.styled';

const ButtonTOC: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <ButtonTOCWrapper className={props.className || ''} onClick={() => props.onClick && props.onClick()}>
      <svg viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="sphere" d="M0.5 18C0.5 8.32165 8.12434 0.5 17.5 0.5C26.8757 0.5 34.5 8.32165 34.5 18C34.5 27.6784 26.8757 35.5 17.5 35.5C8.12434 35.5 0.5 27.6784 0.5 18Z" fill="#FD6E41" stroke="white" />
        <path d="M10.9821 11C10.4321 11 10 11.4321 10 11.9821C10 12.5321 10.4321 12.9643 10.9821 12.9643C11.5321 12.9643 11.9643 12.5321 11.9643 11.9821C11.9643 11.4321 11.5321 11 10.9821 11ZM13.9286 11V12.9643H25.7143V11H13.9286ZM10.9821 14.9286C10.4321 14.9286 10 15.3607 10 15.9107C10 16.4607 10.4321 16.8929 10.9821 16.8929C11.5321 16.8929 11.9643 16.4607 11.9643 15.9107C11.9643 15.3607 11.5321 14.9286 10.9821 14.9286ZM13.9286 14.9286V16.8929H25.7143V14.9286H13.9286ZM10.9821 18.8571C10.4321 18.8571 10 19.2893 10 19.8393C10 20.3893 10.4321 20.8214 10.9821 20.8214C11.5321 20.8214 11.9643 20.3893 11.9643 19.8393C11.9643 19.2893 11.5321 18.8571 10.9821 18.8571ZM13.9286 18.8571V20.8214H25.7143V18.8571H13.9286ZM10.9821 22.7857C10.4321 22.7857 10 23.2179 10 23.7679C10 24.3179 10.4321 24.75 10.9821 24.75C11.5321 24.75 11.9643 24.3179 11.9643 23.7679C11.9643 23.2179 11.5321 22.7857 10.9821 22.7857ZM13.9286 22.7857V24.75H25.7143V22.7857H13.9286Z" fill="white" />
      </svg>
    </ButtonTOCWrapper>
  );
};

export default ButtonTOC;
