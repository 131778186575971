import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from 'layout/auth/Auth.layout';
import { LoginContainer } from 'pages/login/Login.styled';
import { IntroPageWrapper } from 'pages/intro/Intro.styled';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import { Link } from 'react-router-dom';
import Button from 'components/common/button/Button';
import { FormButtonSubmitter, FormErrorWrapper } from 'components/common/form/Form.styled';
import { ROUTES } from 'constants/routes';
import useFormHandler from 'hooks/useFormHandler';
import { FORM } from 'constants/form';
import { TextFieldTypes } from '@ionic/core';
import useAPIAuth from 'hooks/api/useAPIAuth';
import useUpdateChecker from 'hooks/useUpdateChecker';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';
import useCheckInstallation from 'hooks/useCheckInstallation';
import { IonRouterLink } from '@ionic/react';

const Login: React.FC = () => {
  useCheckInstallation();
  useUpdateChecker(ROUTES.LOGIN);
  const rollbar = useRollbar();
  const { t } = useTranslation('translation');
  const { doLogin } = useAPIAuth();
  const [authFailed, setAuthFailed] = useState<boolean>(false);
  const { fields, isFormValid, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'email',
      value: '',
      required: true,
      type: FORM.TYPES.EMAIL as TextFieldTypes,
    },
    {
      name: 'password',
      value: '',
      required: true,
      type: FORM.TYPES.PASSWORD as TextFieldTypes,
    },
  ]);

  // Remove user
  /*
  useIonViewDidEnter(() => {
    updateUser();
  });
  */

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormValid()) {
      const email = getFieldByName('email').value as string;
      const password = getFieldByName('password').value as string;

      try {
        const isLoggedIn = await doLogin(email.trim(), password.trim());
        if (isLoggedIn) {
          setAuthFailed(false);
          window.location.href = ROUTES.INDEX;
        } else {
          setAuthFailed(true);
        }
      } catch (e) {
        setAuthFailed(true);
        console.log('Login > onSubmit: error : ', e);
        rollbar.error(e as LogArgument);
      }
    }
  };

  return (
    <AuthLayout>
      <IntroPageWrapper className="app-page">
        <LoginContainer>
          <form noValidate onSubmit={onSubmit} autoComplete="off">
            <Textfield
              {...({
                ...getFieldByName('email', fields),
                label: t('login.form.email') || '',
                inputmode: 'email',
                onChange: (value?) => updateFieldValue('email', value),
              } as TextfieldProps)}
            />
            <Textfield
              {...({
                ...getFieldByName('password', fields),
                label: t('login.form.password') || '',
                onChange: (value?) => updateFieldValue('password', value),
              } as TextfieldProps)}
            />
            {authFailed && (
              <FormErrorWrapper>
                <span dangerouslySetInnerHTML={{ __html: t('message.error.login') }} />
              </FormErrorWrapper>
            )}
            <Link to={`${ROUTES.FORGOTTEN_PWD.INDEX}`}>{t('login.forgottenpwd')}</Link>
            <Button color="accent" expand={true} type="submit">
              {t('login.iconnect')}
            </Button>
            <IonRouterLink routerLink={ROUTES.REGISTER}>{t('intro.login.noaccount')}</IonRouterLink>
            {/*
                Note: Trick to handle form submission on enter
                'Cause button submit in th e shadow dom don't responds
                to submit by Enter key
              */}
            <FormButtonSubmitter type="submit" />
            {/* EndNote */}
            {/* <Link to='/register'>{t('login.noaccount')}</Link> */}
          </form>
        </LoginContainer>
      </IntroPageWrapper>
    </AuthLayout>
  );
};

export default Login;
