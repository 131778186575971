import LoginForm from 'components/forms/login-form/LoginForm';
import RegisterForm from 'components/forms/register-form/RegisterForm';
import Slider from 'components/slider/Slider';
import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { AuthentificationModalWrapper } from 'components/modal/authentification/AuthentificationModal.styled';

const AuthentificationModal: React.FC = () => {
  return (
    <AuthentificationModalWrapper className="authentification-modal">
      <Slider allowTouchMove={false}>
        <SwiperSlide>
          <LoginForm />
        </SwiperSlide>
        <SwiperSlide>
          <RegisterForm />
        </SwiperSlide>
      </Slider>
    </AuthentificationModalWrapper>
  );
};

export default AuthentificationModal;
