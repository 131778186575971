import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const FranckJulienWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: ${prem(16)};

  img {
    width: 60%;
    max-width: ${prem(200)};
    margin: 0 auto ${prem(25)};
    display: block;
  }

  p {
    margin-bottom: ${prem(20)};
  }

  .description {
    color: var(--ion-text-color-secondary);
    text-align: justify;
    max-width: ${prem(600)};
    display: block;
    margin: 0 auto;
  }

  .socials-title {
    color: var(--ion-text-color-secondary);
    font-weight: bolder;
    font-size: ${prem(19)};
    max-width: ${prem(300)};
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: ${prem(30)};
  }

  .socials-icons {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: ${prem(20)};
  }
  
  .social-icon {
    margin: 0 ${prem(10)};
    color: var(--ion-color-accent);
  }
`;

export { FranckJulienWrapper };
