import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { transparentEl } from 'styles/helpers/utils';
import LanguageSelectorImage from 'assets/images/language-on-off.png';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const LanguageSelectorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${prem(36)};

  @media (${MQ_MAX_HEIGHT}) {
    margin-bottom: 0;
  }
`;

const LanguageSelectorInner = styled.div<{ lng?: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${LanguageSelectorImage});
  background-repeat: no-repeat;
  background-size: 200%;
  ${({ lng }) =>
    lng === 'en' &&
    css`
      background-position: -40px 0;
    `};
`;

const LanguageSelectorButton = styled.button`
  ${transparentEl};
  display: block;
  width: ${prem(40)};
  height: ${prem(33)};
`;

export { LanguageSelectorWrapper, LanguageSelectorInner, LanguageSelectorButton };
