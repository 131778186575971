import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const IconProfileWrapper = styled.div<{
  primaryColor?: string | null;
  secondaryColor?: string | null;
}>`
  position: relative;
  width: 100%;
  height: 100%;

  .badge {
    position: absolute;
    bottom: ${prem(-3)};
    right: 1px;

    width: ${prem(16)};
    height: ${prem(16)};

    svg {
      .badge-bg {
        fill: var(--ion-color-accent);
      }
    }
  }

  .profiled,
  .unspecified {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;

    ${({ primaryColor, secondaryColor }) =>
      primaryColor &&
      css`
        display: block;

        .primary {
          fill: ${primaryColor};
        }
        .secondary {
          fill: ${secondaryColor};
        }
      `}
  }

  .unknown {
    svg {
      .question-mark {
        fill: var(--comcolors-profile-unknown-question-color);
      }

      .question-mark-circles {
        stroke: var(--comcolors-profile-unknown-question-circles-color);
      }
    }
    ${({ primaryColor }) =>
      primaryColor &&
      css`
        display: none;
      `}
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export { IconProfileWrapper };
