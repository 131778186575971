import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { SUBTITLE } from 'styles/helpers/typography';

const ContactWrapper = styled.div`
  display: block;
  padding-bottom: ${prem(100)};

  .contact-container {
    background-color: var(--comcolors-contact-background);
    border-radius: ${prem(20)};
    padding: ${prem(40)} ${prem(20)} ${prem(25)};
    max-width: ${prem(500)};
    color: white;
    display: block;
    margin: 0 auto;
  }

  .contact-title {
    ${SUBTITLE.medium};
    text-align: center;
    margin-bottom: ${prem(24)};
  }

  .contact-info {
    text-align: center;
    border-radius: ${prem(30)};
    margin: ${prem(20)} auto;
    max-width: ${prem(400)};
    display: block;
    background-color: #ffffff30;

    a {
      max-width: 90%;
      padding-right: ${prem(5)};
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ${IonButtonWrapper} {
    margin-bottom: ${prem(20)};
  }
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  gap: ${prem(8)};
  align-items: center;
  width: 100%;

  .icon {
    width: ${prem(30)};
    height: auto;
  }

  label {
    flex: 1;
    word-break: break-all;
  }
`;

export { ContactWrapper, ContactLink };
