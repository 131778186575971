import { useEffect, useState } from 'react';
import { IProducts } from 'types/app';
import useAPIResources from 'hooks/api/useAPIResources';

export interface IUseProductsLoader {
  isLoading: boolean;
  products: IProducts;
}

const useProductsLoader = (productsApiUrl: string, field?: string) => {
  const { getProducts } = useAPIResources();
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState([] as IProducts);
  const loadProducts = async () => {
    try {
      setProducts(await getProducts(productsApiUrl, field || 'livres'));
    } catch (e: unknown) {
      console.log('useProductsLoader - error: ', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return { products, isLoading };
};

export default useProductsLoader;
