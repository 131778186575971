import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';
import { transparentEl } from 'styles/helpers/utils';
import { BREAKPOINTS } from 'styles/constants/devices';

const AdviceDesc = styled.p`
  color: var(--ion-text-color-secondary);
  padding: 0 ${prem(48)} ${prem(16)} 0;
`;

const AdviceTime = styled.span`
  font-style: italic;
  color: var(--ion-text-color-secondary);
  ${BODY.small};
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const FavButton = styled.button`
  ${transparentEl};
  width: ${prem(16)};
  height: ${prem(16)};
  color: var(--ion-text-color-secondary);

  @media (${BREAKPOINTS.M}) {
    width: ${prem(24)};
    height: ${prem(24)};
  }

  &.liked {
    color: var(--ion-color-accent);
  }
`;

const AdviceTimeWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: ${prem(8)};
`;

const AdviceWrapper = styled.div`
  margin: ${prem(16)} 0;
`;

export { AdviceDesc, AdviceTime, FavButton, AdviceTimeWrapper, AdviceWrapper };
