import React, { useRef } from 'react';
import { ReactComponent as PixChecked } from 'assets/svg/questionnaire-checked.svg';
import { useTranslation } from 'react-i18next';
import { EndScreenWrapper } from 'pages/questionnaire/slides/end/QuestionnaireEnd.styled';
import { Container } from 'layout/page/Page.layout.styled';
import Button from 'components/common/button/Button';
import { useDeviceSelectors } from 'react-device-detect';
import QuestionnaireInstallApp from 'pages/questionnaire/slides/questionnaire-install-app/QuestionnaireInstallApp';

const QuestionnaireEnd: React.FC = () => {
  const { t } = useTranslation('translation');
  const ref = useRef<any>(null);
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const { isDesktop, isAndroid, isIOS } = selectors;

  return (
    <EndScreenWrapper>
      <Container flexCentered className="main">
        <div className="pix-wrapper">
          <PixChecked />
        </div>
        <h3>{t('questionnaire.responses-saved')}</h3>
        <p>{t('questionnaire.final-message')}</p>
        <Button
          color="accent"
          expand={true}
          fill="outline"
          onClick={() => {
            handleScroll();
          }}
        >
          {t('questionnaire.install-app')}
        </Button>
      </Container>
      <Container ref={ref} className="install-app">
        <QuestionnaireInstallApp isDesktop={isDesktop} isAndroid={isAndroid} isIOS={isIOS} />
      </Container>
    </EndScreenWrapper>
  );
};

export default QuestionnaireEnd;
