import { IColor } from 'types/app';

const API = {
  BASIC_TOKEN: `Basic ${process.env.REACT_APP_API_BASIC_TOKEN}`,
  BASEPATH: process.env.REACT_APP_API_URL,
  TRAINING_PATH: process.env.REACT_APP_DIGITAL_AUTH,
  ELEARNING_PATH: process.env.REACT_APP_ELEARNING,
  BOA_KEY: process.env.REACT_APP_BOA_KEY,
  ROUTES: {
    AUTH: {
      REGISTER: '/register/new-user',
      LOGIN: '/connexion',
      LOGOUT: '/logout',
      FORGOTTEN_PWD: {
        FORGOTTEN_PWD: '/forgot_password',
        FORGOTTEN_PWD_CODE: '/valid_code',
        FORGOTTEN_PWD_NEW_PASSWORD: '/update_password',
      },
      AUTO_LOGIN: '/autologin/token',
      GET_AUTO_LOGIN_TOKEN: '/user_token/[userId]',
    },
    SETTINGS: {
      SETTINGS: '/reglages',
    },
    HOME: {
      INDEX: '/pages/[locale]/home',
      SPOTIFY_RSS: '/spotify-rss/[locale]/home',
    },
    RELATIONS: {
      RELATIONS: '/relations',
      GROUP: {
        INDEX: '/groupes',
        ADD_RELATION: '/groupes/add-relation',
      },
      QUESTIONNAIRE: '/questionnaire_personnalite',
      DETAIL: '/relations/detail',
      IDENTIFICATION: '/identification',
      APPOINTMENT: {
        DETAIL: '/rendez-vous/detail',
        SUBSCRIPTION: '/rendez-vous/abonnement',
      },
    },
    ADVICE: {
      ADVICE: '/conseils',
      ADVICE_LIKE: '/conseils/like',
    },
    NOTIFICATIONS: {
      NOTIFICATIONS: '/notifications',
      INVITATION: '/notifications/invitation',
      PROFILE_VISIBILITY: '/notifications/visibilite-profil',
    },
    USER: {
      DETAIL: '/user_infos',
      MOTIVATION: '/motivations',
      SUMMARY: '/bilan_infos'
    },
    COLOURS: {
      COLOURS: '/couleurs',
    },
    SUBSCRIPTION: '/abonnements',
    FAQS: {
      PROFILE: '/faqs/profil',
      CATEGORY: '/faqs/category',
    },
    RESOURCES: {
      BOOKS: '/livres',
      MODULES: '/modules',
      TRAININGS: '/formations',
    },
    PAYMENT: {
      TOOLS: '/outils',
      INIT_PAYMENT: '/payment-init',
      CONFIRM_PAYMENT: '/payment-confirm',
    },
    CONTACT: {
      CONTACT: '/contact/detail',
      FIND_TRAINER: '/search/formateurs',
      FIND_POSITIONS: 'https://maps.googleapis.com/maps/api/geocode/json',
    },
    MODELS: {
      COMCOLORS: '/texte_rubriques/code/rubrique_comcolors',
      FRANCK_JULLIEN: '/texte_rubriques/code/rubrique_franck_jullien',
      TEMOIGNAGE: '/temoignages',
    },
    QUESTIONNAIRE: {
      INTRO: '/texte_rubriques/code/questionnaire_txt_preliminaire',
      ACTIVITIES: '/categories_socioprofessionnelle',
      UPDATE_PROFILE: '/update_profile/prequestionnaire',
      QUESTIONS: '/questions/questionnaire',
      ANSWER: '/answers/questionnaire',
      USER_ANSWERS: '/answers/user',
      ADDITIONAL: {
        GET: '/questions_additionnelles/questionnaire',
        ANSWER: '/answers_additionnelles/questionnaire',
        ANSWERS_LIST: '/answers_additionnelles/user'
      } 
    },
    REF_DATA: {
      ACTIVITIES: '/categories_socioprofessionnelle',
    },
    PROFILE: {
      MY_PROFILE: '/profile_infos',
      SHARED_PROFILE: '/getshared_profile/token',
      VIDEOS: '/videos',
      UPLOAD_PROFILE_PICTURE: 'sharednetwork/upload/[userId]',
      CHOOSE_PROFILE_PICTURE_FILTER: 'sharednetwork/filter/[userId]',
    },
    TEST: {
      INFOS: '/test-infos/[userId]',
      INIT: '/test-init',
    },
    TUTO: {
      INFOS: '/videos/tutoriels/[lang]'
    }
  },
};

const LS_KEYS = {
  USER: 'user',
  SKIP_INTRO: 'skipIntro',
  SETTINGS: 'settings',
  LANG: 'i18nextLng',
  RELATION_QUESTIONNAIRE: 'relationQuestionnaire',
  COLOUR_DETAIL: 'colourDetail',
  SHOW_TUTO: 'showTuto',
};
const COLORS_CODES = {
  RED: 'red',
  PURPLE: 'purple',
  BLUE: 'blue',
  GREEN: 'green',
  YELLOW: 'yellow',
  ORANGE: 'orange',
};

const CC_COLORS: Array<IColor> = [
  {
    id: 2,
    order: 0,
    name: {
      fr: 'Rouge',
      en: 'Red',
    },
    color: '#cc0001',
    code: COLORS_CODES.RED,
  },
  {
    id: 4,
    order: 1,
    name: {
      fr: 'Violet',
      en: 'Purple',
    },
    color: '#704A97',
    code: COLORS_CODES.PURPLE,
  },
  {
    id: 5,
    order: 2,
    name: {
      fr: 'Bleu',
      en: 'Blue',
    },
    color: '#006496',
    code: COLORS_CODES.BLUE,
  },
  {
    id: 1,
    order: 3,
    name: {
      fr: 'Vert',
      en: 'Green',
    },
    color: '#669934',
    code: COLORS_CODES.GREEN,
  },
  {
    id: 3,
    order: 4,
    name: {
      fr: 'Jaune',
      en: 'Yellow',
    },
    color: '#F1A81E',
    code: COLORS_CODES.YELLOW,
  },
  {
    id: 6,
    order: 5,
    name: {
      fr: 'Orange',
      en: 'Orange',
    },
    color: '#F0611B',
    code: COLORS_CODES.ORANGE,
  },
];

const NOTIFICATIONS = {
  MOTIVATION: 'notification_motivation',
  MANAGEMENT: 'notification_management',
  SALE: 'notification_vente',
  TEAM_COHESION: 'notification_cohesion_equipe',
  PUBLIC_KEY:
    'BMXut-YTSkVR7li30HW05mqoeeiRAvb97oahUa2HOJBwkQRTTcViqL1G5bxlzGYgUr-cUyCdQYDCiaA6tSQNytU',
};

const PAYABLE_TOOLS_CODES = {
  COLOR_PROFILE: 'ProfilCouleur',
  MOTIVATION: NOTIFICATIONS.MOTIVATION,
  APPOINTMENT: NOTIFICATIONS.SALE,
  MANAGEMENT: NOTIFICATIONS.MANAGEMENT,
  ELEARNING_BEHAVIOR_STRESS: 'stressfr',
  ELEARNING_WORKING_TOGETHER: 'ensemblefr',
  TALENT_QUESTIONNAIRE: 'questionnairetalent',
  GROUP_CARTOGRAPHY: 'CartographieGroupe',
  FULL_PACKAGE: 'PackageAppComcolors',
};

const MAX_RELATION_IDENTIFICATION = ((): number => {
  const max = 4;
  try {
    return process.env.REACT_APP_MAX_RELATION_IDENTIFICATION
      ? parseInt(process.env.REACT_APP_MAX_RELATION_IDENTIFICATION, 10)
      : max;
  } catch (e) {
    console.log('MAX_RELATION_IDENTIFICATION error: ', e);
  }

  return max;
})();

const enum BADGE {
  VALID = 'valid',
  WARNING = 'exclamation',
  QUESTION = 'interrogation',
  NONE = 'none',
}

const MODULES_CODES = {
  PROFILE: 'profil_couleur',
  MOTIVATION: 'motivation',
  RELATION: 'relation',
  APPOINTMENT: 'rendez_vous',
  MAP: 'cartographie_groupe',
  ELEARNING: 'elearning',
  TALENTS: 'talents',
};

const PRICE_UNITS = {
  eur: '€',
};

const LANGUAGES = {
  fr: 'fr',
  en: 'en',
};

const MODULE_TEST_NAMES = {
  RELATION_APPOINTMENT: {
    fr: 'Préparer un rendez-vous',
    en: 'Prepare a meeting',
  },
  RELATION_MANAGEMENT: {
    fr: 'Gérer la relation',
    en: 'Managing relationships',
  },
  UNKNOWN: {
    fr: 'Inconnu',
    en: 'Unknown',
  },
};

const MODULES_TEST_IDS = {
  RELATION_APPOINTMENT: 4,
  RELATION_MANAGEMENT: 6,
};

const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_T1LVt1r53yjclxgvp9MWrMIq';

const GEOLOC_KEY = process.env.REACT_APP_GEOLOC_KEY || 'AIzaSyDagvY-pYrA0mlooACpCBVp5OVBErFtdOs';

const EUROPE_COUNTRY_CODES = ['FR'];

const PRIMARY_QUESTIONNAIRE_NB = 98;
const ADDITIONAL_QUESTIONNNAIRE_NB = 20;
const TOTAL_QUESTIONNAIRE = PRIMARY_QUESTIONNAIRE_NB + ADDITIONAL_QUESTIONNNAIRE_NB;

export {
  API,
  LS_KEYS,
  COLORS_CODES,
  CC_COLORS,
  NOTIFICATIONS,
  MAX_RELATION_IDENTIFICATION,
  BADGE,
  PAYABLE_TOOLS_CODES,
  MODULES_CODES,
  PRICE_UNITS,
  LANGUAGES,
  STRIPE_PUBLISHABLE_KEY,
  MODULE_TEST_NAMES,
  MODULES_TEST_IDS,
  GEOLOC_KEY,
  EUROPE_COUNTRY_CODES,
  TOTAL_QUESTIONNAIRE,
  PRIMARY_QUESTIONNAIRE_NB,
  ADDITIONAL_QUESTIONNNAIRE_NB
};
