import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const RelationInvitationsTitle = styled.h3`
  margin-bottom: ${prem(30)};
`;

const RelationInvitationsListWrapper = styled.div`
  padding-left: ${prem(20)};
  min-height: ${prem(165)};
`;

const InvitationsList = styled.div`
  color: var(--ion-text-color-secondary);
`;

export { RelationInvitationsTitle, RelationInvitationsListWrapper, InvitationsList };
