import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { IUserMotivation } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import useAPINotificationSubscription from 'hooks/api/useAPINotificationSubscription';
import { IHttpResponse } from 'helpers/api';

export interface IUseAPIMotivations {
  getMotivationDetail: (
    userId: number,
    isInBackground?: boolean,
  ) => Promise<IUserMotivation | undefined>;
}

function useAPIMotivations(): IUseAPIMotivations {
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { loadSubscriptionDetail } = useAPINotificationSubscription();

  // Parse advice
  const parseMotivation = (raw: any): IUserMotivation | undefined => {
    if (raw.couleur_abonne_id) {
      return {
        colourId: raw.couleur_abonne_id,
        motivationText: raw.texte_motivation,
        subscribed: !!raw.has_abonnement,
        subscriptionId: raw.id_abonnement || undefined,
      } as IUserMotivation;
    }

    return undefined;
  };

  //

  // Load user motivation
  const getMotivationDetail = async (
    userId: number,
    isInBackground?: boolean,
  ): Promise<IUserMotivation | undefined> => {
    !!isInBackground && showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.USER.MOTIVATION}/${userId}/detail`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const getState = result.data.data?.motivation;

      if (getState) {
        const motivation = parseMotivation(getState);
        // If subscribed to a color
        // We get the subscription detail
        if (motivation) {
          if (motivation?.subscriptionId) {
            motivation.subscription = await loadSubscriptionDetail(motivation.subscriptionId, true);
          } else {
            motivation.subscription = undefined;
          }
        }

        return motivation;
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while getting motivation: ', e);
      if (!isInBackground) {
        setToast({
          message: (e as Error).message || '',
          status: 'danger',
        });
      }
    } finally {
      !!isInBackground && hideLoading();
    }
    return undefined;
  };

  return {
    getMotivationDetail,
  };
}

export default useAPIMotivations;
