import React, { createContext, FC, useState } from 'react';
import {
  IComponentWithChildren,
  Identification,
  IRelation,
  IRelationGroup,
  IRelationGroupBase,
} from 'types/app';

export interface RelationsContextProps {
  updateRelationsGroups: (g: Array<IRelationGroup>) => void;
  addRelations: (relation: IRelation, groupId: number) => void;
  removeRelations: (relationId: number, groupId: number) => void;
  relationsGroups?: Array<IRelationGroup>;
  openGroup?: IRelationGroup;
  updateOpenGroup: (group?: IRelationGroup) => void;
  getRelationById: (relationId: number) => IRelation | undefined;
  relationQuestionnaireFactory?: Identification.IFactory;
  setRelationQuestionnaireFactory: (f?: Identification.IFactory) => void;
  identificationCount: number;
  updateIdentificationCount: (count: number) => void;
  groupsList: Array<IRelationGroupBase>;
  updateGroupsList: (newList: Array<IRelationGroupBase>) => void;
}

export interface RelationsProviderProps extends IComponentWithChildren {
  groups?: Array<IRelationGroup>;
}

const RelationsContext = createContext<RelationsContextProps | null>(null);

const RelationsProvider: FC<RelationsProviderProps> = ({ groups, children }) => {
  const [relationsGroups, setRelationsGroups] = useState(groups);
  const [groupsList, setGroupsList] = useState<Array<IRelationGroupBase>>([]);
  const [openGroup, setOpenedGroup] = useState<IRelationGroup | undefined>();
  const [relationQuestionnaireFactory, setRelationQuestionnaireFactory] =
    useState<Identification.IFactory>();
  const [identificationCount, setIdentificationCount] = useState(0);
  const updateIdentificationCount = (count: number) => {
    setIdentificationCount(count);
  };
  const updateRelationsGroups = (g: Array<IRelationGroup>) => {
    setRelationsGroups(g);
  };
  const updateGroupsList = (newList: Array<IRelationGroupBase>) => {
    setGroupsList(newList);
  };
  const addRelations = (relation: IRelation) => {
    console.log('relation: ', relation);
  };
  const removeRelations = (relationId: number) => {
    console.log('relation: ', relationId);
  };
  const updateOpenGroup = (group?: IRelationGroup) => {
    setOpenedGroup(group);
  };
  const getRelationById = (relationId: number) => {
    let result;

    if (relationsGroups && relationId) {
      for (const g of relationsGroups) {
        if (g.relations) {
          const rel = g.relations.find((r) => r.id === relationId);

          if (rel) {
            result = rel;
            break;
          }
        }
      }
    }

    return result;
  };

  return (
    <RelationsContext.Provider
      value={{
        relationsGroups,
        updateRelationsGroups,
        addRelations,
        removeRelations,
        updateOpenGroup,
        openGroup,
        getRelationById,
        relationQuestionnaireFactory,
        setRelationQuestionnaireFactory,
        identificationCount,
        updateIdentificationCount,
        groupsList,
        updateGroupsList,
      }}
    >
      {children}
    </RelationsContext.Provider>
  );
};

export { RelationsContext, RelationsProvider };
