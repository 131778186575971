import styled from 'styled-components';
import { transparentEl } from 'styles/helpers/utils';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { prem } from 'styles/helpers/spacing';
import { BUTTON } from 'styles/helpers/typography';
import {
  Chevron,
  IconChevronWrapper,
} from 'components/common/icons/icon-chevron/IconChevron.styled';

const CollapseLabel = styled.span`
  &::first-letter {
    text-transform: uppercase;
  }

  text-align: left;
`;

const CollapseWrapper = styled.div``;

const CollapseButton = styled.button`
  ${transparentEl};
  ${BUTTON.large};
  padding: ${prem(25)};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  color: var(--ion-text-color);

  ${CollapseLabel} {
    flex: 1;
  }

  ${IconChevronWrapper} {
    flex-basis: ${prem(30)};
  }

  &.opened {
    ${CollapseLabel} {
      color: var(--ion-color-accent);
    }

    ${Chevron} {
      color: var(--ion-color-accent);
    }
  }

  &.center-label {
    ${CollapseLabel} {
      text-align: center;
    }
  }
`;

export { CollapseWrapper, CollapseButton, CollapseLabel };
