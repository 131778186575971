import React, { useContext, useEffect, useState } from 'react';
import { AppModalContainer, AppModalWrapper } from 'components/modal/AppModal.styled';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import ButtonClose from 'components/common/button/ButtonClose';

const AppModal: React.FC = () => {
  const { modalConf } = useContext(ModalContext) as ModalContextProps;
  const [open, setOpen] = useState(false);
  const [init, setInit] = useState(false);

  useEffect(() => {
    setOpen(!!modalConf);
    modalConf && setInit(true);
  }, [modalConf]);

  function remove_hash_from_url() {
    const uri = window.location.toString();

    if (uri.indexOf('#') > 0) {
      const clean_uri = uri.substring(0, uri.indexOf('#'));

      window.history.replaceState({}, document.title, clean_uri);
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (open) {
      window.history.pushState(null, '', `${currentPath}#openmodal`);
    } else {
      remove_hash_from_url();
    }
  }, [open]);

  return init ? (
    <AppModalWrapper
      style={{
        display: modalConf?.hide ? 'none' : 'block',
      }}
      className={open ? 'open' : 'close'}
    >
      <AppModalContainer className={open ? 'open' : 'close'}>
        <ButtonClose onClick={() => setOpen(false)} />
        {modalConf?.component}
      </AppModalContainer>
    </AppModalWrapper>
  ) : (
    <></>
  );
};

export default AppModal;
