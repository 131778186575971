import { IonButtonWrapper } from 'components/common/button/Button.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const RelationIconExplicationsWrapper = styled.div`
  width: auto;
  margin: ${prem(20)} ${prem(32)};
  color: var(--ion-text-color-secondary);
  /* stylelint-disable */
  background-color: rgba(var(--ion-color-accent-rgb), 0.2);
  /* stylelint-enable */
  padding: ${prem(10)};
  font-style: italic;

  .title {
    display: block;
    padding-top: ${prem(5)};
    padding-left: ${prem(38)};
    width: 100%;
    ${BODY.small}
  }
`;

const IconExplicationWrapper = styled.div`
  display: flex;
  gap: ${prem(12)};
  align-items: start;
  margin: ${prem(5)} 0;

  .icon {
    width: ${prem(25)} !important;
    max-width: ${prem(25)} !important;
    color: var(--ion-color-accent);
  }

  .description {
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 80%;
    padding-top: ${prem(2)};

    ${BODY.mediumM}
  }
`;

const IconBadgeWarning = styled.div`
  ${IonButtonWrapper} {
    max-width: ${prem(225)};
    margin: ${prem(20)} auto 0;
    border-radius: 0;

    ion-button {
      &::part(native) {
        border-radius: ${prem(0)};
        ${BODY.small};
      }
    }
  }
`;

const IconSubscribed = styled.div`
  color: var(--ion-color-orange);
  text-align: center;
`;

export {
  RelationIconExplicationsWrapper,
  IconExplicationWrapper,
  IconBadgeWarning,
  IconSubscribed,
};
