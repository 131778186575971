import React from 'react';
import { IconShopWrapper } from 'components/common/icons/icon-shop/IconShop.styled';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';

export interface IconShopProps {
  selectedItemsNumber?: number;
  isShopPage?: boolean;
  onClick: () => void;
}

const IconShop: React.FC<IconShopProps> = ({ selectedItemsNumber, isShopPage, onClick }) => {
  const {
    icons: [iconShop],
  } = useIcons([ICON_NAMES.SHOP]);

  return (
    <IconShopWrapper
      isShopPage={isShopPage}
      onClick={onClick}
      selectedItemsNumber={selectedItemsNumber || 0}
    >
      {iconShop.icon}
    </IconShopWrapper>
  );
};

export default IconShop;
