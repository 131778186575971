import React from 'react';
import { CC_COLORS } from 'constants/global';
import { ColorItem, ColorPickerContainer } from 'components/colours-picker/ColoursPicker.styled';
import { IColor } from 'types/app';

export interface ColoursPickerProps {
  onSelectColor?: (colourId: number) => void;
  exceptedColour?: number;
  colors?: Array<IColor>;
  selectedColorId?: number | null;
}

const ColoursPicker: React.FC<ColoursPickerProps> = ({
  onSelectColor,
  exceptedColour,
  selectedColorId,
  colors = CC_COLORS,
}) => {
  return (
    <ColorPickerContainer>
      {colors
        .filter((c) => c.id !== exceptedColour)
        .map((c, index) => (
          <ColorItem
            className={selectedColorId === c.id ? 'selected' : ''}
            key={`c-${index}`}
            onClick={() => {
              onSelectColor && onSelectColor(c.id);
            }}
            bgColor={c.color}
          />
        ))}
    </ColorPickerContainer>
  );
};

export default ColoursPicker;
