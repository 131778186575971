import React, { useContext } from 'react';
import NotificationPix from 'assets/images/notificatons-pix.png';
import { Container } from 'layout/page/Page.layout.styled';
import { ROUTES } from 'constants/routes';
import {
  NoNotificationsNote,
  NoNotificationsPix,
  NoNotificationsWrapper,
} from 'pages/notifications/Notifications.styled';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { useTranslation } from 'react-i18next';

const NoNotifications: React.FC = () => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;

  return (
    <NoNotificationsWrapper>
      <Container>
        <div>
          <NoNotificationsPix>
            <img src={NotificationPix} />
          </NoNotificationsPix>
          <NoNotificationsNote>{t('notifications.nonotifs')}</NoNotificationsNote>
        </div>
        {!!user?.motivation && (
          <div>
            <p className="subscribe-text">{t('notifications.subscribe')}</p>
            <GenericDiv width="25%" minWidth={prem(240)} center={true}>
              <Button color="accent" expand={true} routerLink={ROUTES.PROFILE.MY_MOTIVATION}>
                {t('notifications.receiveadvices')}
              </Button>
            </GenericDiv>
          </div>
        )}
      </Container>
    </NoNotificationsWrapper>
  );
};

export default NoNotifications;
