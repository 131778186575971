import React from 'react';
import { IAdvice } from 'types/app';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import {
  NotificationAdviceTitle,
  NotificationAdviceWrapper,
} from 'components/advice/NotificationAdvice.styled';
import { AdviceType } from 'hooks/api/useAPIAdvices';
import Advice from 'components/advice/Advice';

export interface NotificationAdviceProps {
  advice: IAdvice;
  onLikeAdvice?: (state: boolean) => void;
}

const NotificationAdvice: React.FC<NotificationAdviceProps> = ({ advice, onLikeAdvice }) => {
  const { t, i18n } = useTranslation('translation');

  return (
    <NotificationAdviceWrapper>
      <NotificationAdviceTitle>
        {advice.type === AdviceType.RELATION &&
          t('notifications.advicetitlerelation', {
            colourName: getLocaleValue(advice.colour?.name, i18n.language),
          })}
        {advice.type === AdviceType.MOTIVATION &&
          t('notifications.advicetitlemotivation', {
            colourName: getLocaleValue(advice.colour?.name, i18n.language),
          })}
      </NotificationAdviceTitle>
      <Advice advice={advice} onLike={onLikeAdvice} />
    </NotificationAdviceWrapper>
  );
};

export default NotificationAdvice;
