import { IUser } from 'types/app';

export const hideBuyModuleButton = (user: IUser) => {
  if (user) {
    if (!user.profileInfo?.colors?.primary) {
      return true;
    }
    if (!user.profileInfo?.isMemberTypeIndividualGroup) {
      if (user.profileInfo?.isDirectClientGroup) {
        if (!user.profileInfo.hasValidatedProfile) {
          return true;
        }
      } else {
        if (!user.profileInfo?.hasAuthorizedSales || !user.profileInfo.hasValidatedProfile) {
          return true;
        }
      }
    }
  }
  return false;
};

export const disableBuyModuleButton = (user: IUser) => {
  if (user) {
    if (
      (user.profileInfo?.isMemberTypeIndividualGroup ||
        user.profileInfo?.isMemberTypeSiteMemberGroup) &&
      !user.profileInfo.hasValidatedProfile
    ) {
      return true;
    }
  }

  return false;
};

export const showBuyElearningButton = (user: IUser) => {
  let show = !hideBuyModuleButton(user) && !disableBuyModuleButton(user);
  if (user) {
    if (
      (user.profileInfo?.isMemberTypeIndividualGroup ||
        user.profileInfo?.isMemberTypeSiteMemberGroup) &&
      user.profileInfo.colors?.primary
    ) {
      show = true;
    }
  }

  return show;
};

// export const disableBuyElearninButton = (user: IUser) => {};
