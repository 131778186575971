import React, { useContext, useMemo } from 'react';
import {
  SingleColorWrapper,
  SingleColorLabelWrapper,
} from 'components/models/sixcolors/singlecolor/SingleColor.styled';
import { IModelColor } from 'types/app';
import Collapse from 'components/common/showhide/Collapse';
import { Container } from 'layout/page/Page.layout.styled';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import Button from 'components/common/button/Button';
import { VideoPlayerContext, VideoPlayerContextProps } from 'contexts/VideoPlayerContext';
import Chip from 'components/common/chip/Chip';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

export interface ColorsListProps {
  color: IModelColor;
  opened?: boolean;
  onOpened?: (open: boolean) => void;
  className?: string;
}

const SingleColor: React.FC<ColorsListProps> = ({ color, opened, onOpened, className }) => {
  const {
    icons: [video],
  } = useIcons([ICON_NAMES.VIDEO]);
  const { setVideoUrl } = useContext(VideoPlayerContext) as VideoPlayerContextProps;
  const { i18n } = useTranslation('translation');
  const onPlayVideo = (videoUrl: string): void => {
    setVideoUrl(videoUrl);
  };
  const formatQualities = (qualities: string): string => {
    return qualities.replaceAll(', ', ' - ');
  };

  const SingleColorLabel: React.FC = useMemo(() => {
    const Component: React.FC = () => {
      return (
        <SingleColorLabelWrapper color={color.color}>
          <div className="icon">
            <Chip color={color.color} />
          </div>
          <div className="label">{getLocaleValue(color.name, i18n.language)}</div>
        </SingleColorLabelWrapper>
      );
    };
    return Component;
  }, []);

  return (
    <SingleColorWrapper backgroundColor={color.color} className={className}>
      <Collapse label={<SingleColorLabel />} opened={opened} onOpened={onOpened}>
        <Container className="single-color-collapse-content">
          <p className="single-color-qualities">
            {formatQualities(getLocaleValue(color.qualities, i18n.language))}
          </p>
          <p className="single-color-description">
            {getLocaleValue(color.description, i18n.language)}
          </p>
          {color.videoUrl && (
            <Button
              small
              expand
              fill="clear"
              className="single-video-button"
              onClick={() => {
                onPlayVideo(getLocaleValue(color.videoUrl, i18n.language));
              }}
            >
              <span className="single-video-icon">{video.icon}</span>
            </Button>
          )}
        </Container>
      </Collapse>
    </SingleColorWrapper>
  );
};

export default SingleColor;
