import React, { useEffect } from 'react';
import AuthLayout from 'layout/auth/Auth.layout';
import { IntroContainer } from 'pages/intro/Intro.styled';
import IntroPageLogin from 'pages/intro/Intro-page-login';
import { LS_KEYS } from 'constants/global';
import { saveToStorage } from 'helpers/storage';
import useCheckInstallation from 'hooks/useCheckInstallation';

const Intro: React.FC = () => {
  useCheckInstallation();

  useEffect(() => {
    saveToStorage(LS_KEYS.SKIP_INTRO, '1');
  }, []);

  return (
    <AuthLayout>
      <IntroContainer className="intro-container app-page">
        <IntroPageLogin />
      </IntroContainer>
    </AuthLayout>
  );
};

export default Intro;
