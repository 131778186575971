import React from 'react';
import { ITestimonial } from 'types/app';
import { SingleTestimonialWrapper } from 'components/models/testimonials/singletestimonial/SingleTestimonial.styled';
import { removeAttributes } from 'helpers/utils';

export interface SingleTestimonialProps {
  testimonial: ITestimonial;
  className?: string;
}

const SingleTestimonial: React.FC<SingleTestimonialProps> = ({ testimonial, className }) => {
  return (
    <SingleTestimonialWrapper className={className}>
      <div
        className="testimonial-author-testimonial"
        dangerouslySetInnerHTML={{
          __html: `<span><strong>${removeAttributes(
            testimonial.authorName,
          )} : </strong> "${removeAttributes(testimonial.testimonial)}"</span>`,
        }}
      ></div>
      {testimonial.logo && <img className="testimonial-logo" src={testimonial.logo} alt="" />}
    </SingleTestimonialWrapper>
  );
};

export default SingleTestimonial;
