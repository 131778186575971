import React, { useEffect } from 'react';
import { IModules } from 'types/app';
import {
  LabelWrapper,
  ModulesListWrapper,
} from 'components/resources/module/moduleslist/ModulesList.styled';
import useCollapse from 'hooks/useCollapse';
import { Container } from 'layout/page/Page.layout.styled';
import Collapse from 'components/common/showhide/Collapse';
import SingleModule from 'components/resources/module/single-module/SingleModule';

export interface ModulesListProps {
  modules: IModules;
  className?: string;
  defaultOpenedId?: number | string;
}

const ModuleLabel: React.FC<{ icon: React.ReactElement; title: string }> = ({ icon, title }) => (
  <LabelWrapper>
    <div className="label-icon">{icon}</div>
    <div className="label-string">{title}</div>
  </LabelWrapper>
);

const ModulesList: React.FC<ModulesListProps> = ({ modules, className, defaultOpenedId }) => {
  const { getOpenState, updateStates } = useCollapse();

  useEffect(() => {
    const collapsesIds = modules.map((key, i) => ({
      id: `${i}`,
      opened: defaultOpenedId !== undefined ? i === defaultOpenedId : false,
    }));
    updateStates('', false, collapsesIds);
  }, [modules]);

  return (
    <ModulesListWrapper className={className}>
      {modules &&
        modules.map((module, key) => {
          const id = `${key}`;
          return (
            <Collapse
              key={`module-${id}`}
              label={<ModuleLabel icon={module.icon.icon} title={module.title} />}
              opened={getOpenState(id)}
              onOpened={(o) => updateStates(id, o)}
              className={`${module.hasAccess ? 'has-access' : ''}`}
            >
              <Container>
                <SingleModule module={module} />
              </Container>
            </Collapse>
          );
        })}
    </ModulesListWrapper>
  );
};

export default ModulesList;
