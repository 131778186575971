import React, { useCallback, useEffect, useState } from 'react';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import { TrainingWrapper } from 'pages/resources/training/Training.styled';
import TrainingList from 'components/resources/training/traininglist/TrainingList';
import useAPIResources from 'hooks/api/useAPIResources';
import { IResourceTrainings } from 'types/app';
import { getLocaleValue, removeAttributes } from 'helpers/utils';

const Training: React.FC = () => {
  const { i18n, t } = useTranslation('translation');
  const { getTrainings } = useAPIResources();

  const [trainings, setTrainings] = useState<IResourceTrainings>();

  const loadDatas = useCallback(async () => {
    setTrainings(await getTrainings());
  }, []);

  useEffect(() => {
    loadDatas();
  }, []);

  return (
    <TrainingWrapper>
      <Container className="app-page">
        <PageInnerTitle
          dangerouslySetInnerHTML={{
            __html: t('resources.training-courses.title'),
          }}
        />
        <div
          className="trainings-description"
          dangerouslySetInnerHTML={{
            __html: removeAttributes(getLocaleValue(trainings?.introduction, i18n.language)) || '',
          }}
        />
      </Container>
      {trainings && <TrainingList trainings={trainings} />}
    </TrainingWrapper>
  );
};

export default Training;
