import React from 'react';
import {
  RelationsIdentificationSlideWrapper,
  SlideContentWrapper,
  SlideIntroWrapper,
} from 'pages/relations/identification/RelationIdentification.styled';
import { useTranslation } from 'react-i18next';
import {
  QuestionLabel,
  RelationQuizIdentificationWrapper,
  ResponseLabel,
} from 'pages/relations/identification/slides/relation-quiz-identification/RelationQuizIdentification.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { Identification } from 'types/app';
import IconProfile from 'components/common/icons/icon-profile/IconProfile';
import { getColorCodeById, getColorNameById } from 'helpers/colors';
import { IdentificationProps } from 'pages/relations/identification/RelationIdentification';
import { ROUTES } from 'constants/routes';
import { ColorLabel } from 'components/colours-picker/ColoursPicker.styled';

const RelationQuizQuestion: React.FC<IdentificationProps> = ({
  relation,
  question,
  result,
  onResponse,
  onRestart,
  swiperInstance,
}) => {
  const { t, i18n } = useTranslation('translation');
  const onSelectResponse = (response: Identification.IResponse) => {
    onResponse && onResponse(response);
  };
  const primaryColorLabel: string = result?.primaryColorId
    ? `${getColorNameById(result.primaryColorId, i18n.language)}`
    : '';
  const primaryColorCode: string = result?.primaryColorId
    ? `${getColorCodeById(result.primaryColorId)}`
    : '';
  const secondaryColorLabel: string = result?.secondaryColorId
    ? `${getColorNameById(result.secondaryColorId, i18n.language)}`
    : '';
  const secondaryColorCode: string = result?.secondaryColorId
    ? `${getColorCodeById(result.secondaryColorId)}`
    : '';

  return (
    <RelationsIdentificationSlideWrapper>
      <RelationQuizIdentificationWrapper className="quiz-identification">
        <Container>
          <SlideIntroWrapper>
            <QuestionLabel className={result && result.primaryColorId ? 'result' : ''}>
              {question && question.label[i18n.language]}
              {result &&
                (result.coloursNotFound
                  ? t('relations.identify.noresult')
                  : t('relations.identify.result'))}
            </QuestionLabel>
          </SlideIntroWrapper>
          <SlideContentWrapper className={result ? 'result' : ''}>
            {question && (
              <GenericDiv width="50%" minWidth={prem(230)}>
                {question.responses.map((response, index) => {
                  return (
                    <Button
                      color="accent"
                      expand={true}
                      onClick={() => onSelectResponse(response)}
                      key={`r-${index}`}
                    >
                      <ResponseLabel>{response.label[i18n.language]}</ResponseLabel>
                    </Button>
                  );
                })}
              </GenericDiv>
            )}
            {result && !result.coloursNotFound && (
              <>
                <GenericDiv width="50%" minWidth={prem(230)} className="colors">
                  <div className="icon-profile-wrapper">
                    <IconProfile
                      unspecifiedColor={result.unspecifiedColours}
                      primaryColorId={result.primaryColorId}
                      secondaryColorId={result.secondaryColorId || result.primaryColorId}
                    />
                  </div>
                </GenericDiv>
                <GenericDiv width="50%" minWidth={prem(230)} className="colors-names">
                  {!result.unspecifiedColours && (
                    <p>
                      {primaryColorLabel && (
                        <>
                          <span className="color-name">{t('common.primarycolor')} : </span>
                          <ColorLabel color={primaryColorCode}>{primaryColorLabel}</ColorLabel>
                          <br />
                        </>
                      )}
                      {secondaryColorLabel && (
                        <>
                          <span className="color-name">{t('common.secondarycolor')} : </span>
                          <ColorLabel color={secondaryColorCode}>{secondaryColorLabel}</ColorLabel>
                          <br />
                        </>
                      )}
                    </p>
                  )}
                  {result.unspecifiedColours && (
                    <>
                      <p>
                        {primaryColorLabel && (
                          <>
                            <span className="color-name">
                              {t('common.colour', { count: secondaryColorLabel ? 2 : 1 })} :{' '}
                            </span>
                            <ColorLabel color={primaryColorCode}>{primaryColorLabel}</ColorLabel>
                            {secondaryColorLabel && (
                              <>
                                {' '}
                                &{' '}
                                <ColorLabel color={secondaryColorCode}>
                                  {secondaryColorLabel}
                                </ColorLabel>
                              </>
                            )}
                          </>
                        )}
                      </p>
                      <p>{t('common.undefined_colors')}</p>
                    </>
                  )}
                </GenericDiv>
                <GenericDiv width="50%" minWidth={prem(230)}>
                  <Button
                    color="accent"
                    fill="outline"
                    expand={true}
                    routerLink={`${ROUTES.RELATIONS.MANAGE}/${relation?.id}`}
                    onClick={() => {
                      if (swiperInstance) {
                        swiperInstance.current?.slideTo(0);
                      }
                    }}
                  >
                    {t('relations.manage')}
                  </Button>
                </GenericDiv>
              </>
            )}
            {result && result.coloursNotFound && (
              <GenericDiv width="50%" minWidth={prem(230)}>
                <Button
                  color="accent"
                  expand={true}
                  onClick={() => {
                    onRestart && onRestart();
                  }}
                >
                  {t('relations.identify.restart')}
                </Button>
              </GenericDiv>
            )}
          </SlideContentWrapper>
        </Container>
      </RelationQuizIdentificationWrapper>
    </RelationsIdentificationSlideWrapper>
  );
};

export default RelationQuizQuestion;
