import styled from 'styled-components';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import COLORS from 'styles/constants/colors';
import { MySummaryReadMoreWrapper } from 'pages/profile/has-profile/my-summary/MySummaryReadMore.styled';

const MySummaryPopupWrapper = styled.div<{ open?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
`;

const MySummaryPopupInner = styled.div<{ open?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 94%;
  max-height: 95%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 13px;
  box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 0.25);
  padding: 30px 20px;
  opacity: ${({open}) => open ? '1' : '0'};
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
`;

const MySummaryPopupContent = styled.div`
  font-size: 14px;

  .color-title-block {
    color: var(--color-title);
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
    align-items: center;
  }

  .to-remember-title-wrapper {
    margin-bottom: 25px;
    text-align: center;

    .to-remember-title {
      width: 80%;
      margin: 0 auto;
      color: ${COLORS.BASE.BROWN.DEFAULT};
    }

    strong.remember-label {
      color: var(--remember-color);
      font-size: 16px;
    }
  }

  .to-remember-title-arrow {
    margin-bottom: 18px;
    display: none;

    svg {
      display: none;
    }

    .arrow.arrow-rouge {
      color: #E2001A;
    }

    .arrow.arrow-violette {
      color: #A664A1;
    }

    .arrow.arrow-orange {
      color: ${COLORS.BASE.ORANGE.DEFAULT};
    }

    .arrow.arrow-bleue {
      color: #006AB3;
    }

    .arrow.arrow-jaune {
      color: #FCC521;
    }

    .arrow.arrow-verte {
      color: #2D8F2E;
    }

    .label {
      font-size: 16px;
      font-weight: ${FONT_WEIGHT.SEMIBOLD};
      color: inherit;
    }
  }

  .to-remember-content {
    color: ${COLORS.BASE.BROWN.DEFAULT};

    p {
      margin-bottom: 14px;
    }
  }

  ${MySummaryReadMoreWrapper} {
    display: none;
  }
`;

const MySummaryPopupContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 20px;
`;

const CloseButton = styled.button`
  border: none;
  background: transparent;
  width: 18px;
  height: 18px;
  position: absolute;
  padding: 2px;
  right: 12px;
  top: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const CloseButtonWrapper = styled.div`
  height: 22px;
  text-align: right;
`;

export {
  MySummaryPopupWrapper,
  MySummaryPopupInner,
  MySummaryPopupContent,
  MySummaryPopupContentWrapper,
  CloseButton,
  CloseButtonWrapper
};
