import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const ChipWrapper = styled.div<{ color?: string }>`
  width: ${prem(60)};
  height: ${prem(60)};
  background-color: var(--ion-color-medium-shade);
  border-radius: 50%;

  ${({ color }) => color && css`
    background-color: ${color};
  `};
`;

export { ChipWrapper };
