import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  CheckboxContainer,
  CheckboxLabel,
  CheckboxWrapper,
  CheckWrapper,
} from 'components/common/form/checkbox/Checkbox.styled';
import { FormErrorWrapper } from 'components/common/form/Form.styled';
import { getId } from 'helpers/utils';

export interface CheckboxProps {
  label?: string;
  className?: string;
  value?: boolean;
  required?: boolean;
  onClick?: () => void;
  onChange?: (value: boolean) => void;
  errorMessage?: string | null;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  className,
  value,
  onClick,
  onChange,
  errorMessage,
  disabled,
}) => {
  const refField = useRef<string>(`check-${getId()}`);
  const [valueState, setValueState] = useState<boolean>(!!value);
  const handleInputChange = (e: ChangeEvent) => {
    const value = (e.target as HTMLInputElement).checked;

    setValueState(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    setValueState(!!value);
  }, [value]);

  return (
    <CheckboxWrapper className={className}>
      <CheckboxContainer
        onClick={() => {
          !disabled && onClick && onClick();
        }}
      >
        <input
          type="checkbox"
          id={refField.current}
          name={refField.current}
          checked={valueState}
          onChange={handleInputChange}
          disabled={!!disabled}
        />
        <CheckWrapper disabled={!!disabled} />
        {label && (
          <CheckboxLabel htmlFor={refField.current} dangerouslySetInnerHTML={{ __html: label }} />
        )}
      </CheckboxContainer>
      {!!errorMessage && <FormErrorWrapper>{errorMessage}</FormErrorWrapper>}
    </CheckboxWrapper>
  );
};

export default Checkbox;
