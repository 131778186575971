import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { SixColorsWrapper } from 'pages/models/sixcolors/SixColors.styled';
import { IModelColor } from 'types/app';
import ColorsList from 'components/models/sixcolors/colorslist/ColorsList';
import useAPIModels from 'hooks/api/useAPIModels';

const SixColors: React.FC = () => {
  const { t } = useTranslation('translation');
  const { getColors } = useAPIModels();
  const [colors, setColors] = useState<Array<IModelColor>>([]);

  const loadColors = useCallback(async () => {
    setColors(await getColors());
  }, []);

  useEffect(() => {
    loadColors();
  }, []);
  
  return (
    <SixColorsWrapper>
      <Container className="app-page">
        <PageInnerTitle
          dangerouslySetInnerHTML={{
            __html: t('model.six_colors.title'),
          }}
        />
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: t('model.six_colors.description'),
          }}
        />
      </Container>
      <ColorsList colors={colors} />
    </SixColorsWrapper>
  );
};

export default SixColors;