import { AppModalTitle } from 'components/modal/AppModal.styled';
import React from 'react';
import { ToolDescriptionWrapper } from 'components/shop/single-tool/tool-description/ToolDescription.styled';

export interface ToolDescriptionProps {
  title: string;
  description: string;
}

const ToolDescription: React.FC<ToolDescriptionProps> = ({ title, description }) => {
  return (
    <ToolDescriptionWrapper>
      <AppModalTitle>{title}</AppModalTitle>
      <div
        className="tool-description"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </ToolDescriptionWrapper>
  );
};

export default ToolDescription;
