import React, { createContext, FC, useState } from 'react';
import { IComponentWithChildren } from 'types/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';

export interface ElearningContextProps {
  openElearning: (newFrameUrl: string) => void;
  closeElearning: () => void;
  elearningFrameUrl: string;
  goFullScreen: () => void;
  exitFullScreen: () => void;
}

const ElearningContext = createContext<ElearningContextProps | null>(null);

const ElearningProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [currentFrameUrl, setFrameUrl] = useState<string>('');
  // t('message.error.cannotrotatescreen.description')
  const doNothing = () => {
    ('');
    return undefined;
  };

  const requestFullscreen = async (element: any) => {
    if (element.requestFullscreen) {
      await element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      await element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      await element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      await element.msRequestFullscreen();
    }
  };

  const setFullScreenAndRotate = async () => {
    try {
      const element = document.documentElement;
      await requestFullscreen(element);
      ScreenOrientation.lock({
        orientation: 'landscape',
      }).catch(() => {
        doNothing();
      });
    } catch {
      doNothing();
    }
  };

  const rotateAndExitFullScreen = async () => {
    try {
      const element = document.documentElement;
      await requestFullscreen(element);
      ScreenOrientation.lock({
        orientation: 'portrait',
      })
        .catch(() => {
          doNothing();
        })
        .finally(async () => {
          typeof document.exitFullscreen && document.exitFullscreen();
        });
    } catch {
      typeof document.exitFullscreen && document.exitFullscreen();
    }
  };

  const openElearning = (newFrameUrl: string): void => {
    setFrameUrl(newFrameUrl);
    setFullScreenAndRotate();
  };

  const closeElearning = (): void => {
    setFrameUrl('');
    rotateAndExitFullScreen();
  };

  return (
    <ElearningContext.Provider
      value={{
        openElearning: openElearning,
        closeElearning: closeElearning,
        elearningFrameUrl: currentFrameUrl,
        goFullScreen: setFullScreenAndRotate,
        exitFullScreen: rotateAndExitFullScreen,
      }}
    >
      {children}
    </ElearningContext.Provider>
  );
};

export { ElearningContext, ElearningProvider };
