import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/constants/devices';

const DiagramImageWrapper = styled.div`
  position: relative;
  max-width: 594px;
  margin: 0 auto;
  font-size: 12px;

  @media (min-width: 475px) {
    font-size: 14px;
  }

  @media (min-width: 510px) {
    font-size: 16px;
  }

  @media (${BREAKPOINTS.M}) {
    font-size: 18px;
  }

  img {
    width: 100%;
  }
`;

const TextsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const TextItem = styled.div<{ left: string; top: string }>`
  position: absolute;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  color: black;
  text-align: center;
  transform: translate(-50%, -50%);
`;

const CenteredTextWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 21%;
  color: white;
  text-align: center;
  text-shadow: 0 2px 4px rgb(0 0 0 / 0.75);
`;

export { DiagramImageWrapper, TextsWrapper, TextItem, CenteredTextWrapper };
