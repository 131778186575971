import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY, HEADLINE } from 'styles/helpers/typography';
import { BREAKPOINTS } from 'styles/constants/devices';
import { IonButtonWrapper } from 'components/common/button/Button.styled';

const EndScreenWrapper = styled.div`
  height: 100%;
  text-align: center;
  min-height: 100vh;
  overflow-y: scroll;

  .main {
    height: 100vh;
  }

  .install-app {
    min-height: 120vh;
  }

  .pix-wrapper {
    margin-bottom: ${prem(42)};

    svg {
      width: 100%;
      max-width: ${prem(100)};
    }
  }

  h3 {
    ${HEADLINE.small};
    margin-bottom: ${prem(60)};
  }

  p {
    color: var(--ion-text-color-secondary);
    ${BODY.large};
    text-align: left;
    margin-bottom: ${prem(60)};

    @media (${BREAKPOINTS.M}) {
      text-align: center;
    }
  }

  ${IonButtonWrapper} {
    max-width: ${prem(700)};
    margin: 0 auto;
  }

  .get-into-app {
    ${IonButtonWrapper} {
      max-width: ${prem(400)};
    }
  }
`;

export { EndScreenWrapper };
