import React, { useContext } from 'react';
import { ShopSummaryWrapper } from 'components/shop/shop-summary/ShopSummary.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { BuyToolContext, BuyToolContextProps } from 'contexts/BuyToolContext';
import { formatPrice } from 'helpers/utils';
import { IPriceUnit } from 'types/app';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';

const ShopSummary = () => {
  const { shoppingList, updatePaymentConfs } = useContext(BuyToolContext) as BuyToolContextProps;
  const currency: IPriceUnit = 'eur';
  const { i18n, t } = useTranslation('translation');

  return (
    <ShopSummaryWrapper>
      <Container>
        <div className="selected-tools">
          <div className="label">{t('shop.selectedarticles')}:</div>
          <div className="quantity value">{shoppingList.length}</div>
        </div>
        <div className="total-to-pay">
          <div className="label">Total:</div>
          <div className="total-price value">
            {formatPrice(
              shoppingList.reduce((current, tool) => tool.price_ttc + current, 0),
              currency,
              i18n.language,
            )}
          </div>
        </div>
        <Button
          className="proceed-to-payment-button"
          fill="outline"
          color="white"
          onClick={() => {
            updatePaymentConfs({
              newTools: shoppingList,
            });
          }}
          disabled={shoppingList.length <= 0}
        >
          {t('shop.proceedtopayment')}
        </Button>
      </Container>
    </ShopSummaryWrapper>
  );
};

export default ShopSummary;
