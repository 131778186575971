import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { HEADLINE } from 'styles/helpers/typography';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const Container = styled.div<{ padding?: number; flexCentered?: boolean }>`
  ${({ padding = 25 }) => css`
    padding: 0 ${prem(padding)};
  `}

  ${({ flexCentered }) =>
    flexCentered &&
    css`
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}
`;

const Iframe = styled.iframe`
  border: none;
`;

const PageTitle = styled.h1`
  ${HEADLINE.medium};
  margin: ${prem(20)} 0 ${prem(34)};
`;

const PageInnerTitle = styled.h1`
  ${HEADLINE.small};
  text-align: center;
  margin: ${prem(24)} 0 ${prem(34)};

  @media (${MQ_MAX_HEIGHT}) {
    margin: ${prem(16)} 0;
  }
`;

export { Container, Iframe, PageTitle, PageInnerTitle };
