import styled from 'styled-components';
import { SlideContentWrapper } from 'pages/relations/identification/RelationIdentification.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const RelationIdentificationHomeWrapper = styled.div`
  height: 100%;

  ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${SlideContentWrapper} {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${IonButtonWrapper} {
      margin-bottom: ${prem(30)};

      &:last-of-type {
        margin-bottom: ${prem(16)};
      }
    }

    @media (${MQ_MAX_HEIGHT}) {
      justify-content: unset;
    }
  }
`;

const IdentificationLimitationMessage = styled.div`
  color: var(--ion-color-base);
  text-align: center;
  font-weight: ${FONT_WEIGHT.BOLD};
  ${BODY.large};
`;

export { RelationIdentificationHomeWrapper, IdentificationLimitationMessage };
