import React, { useEffect, useState } from 'react';
import {
  InstallOnAndroidWrapper,
  InstallOnIOSWrapper,
  TutoSmartphoneWrapper,
} from 'components/install-app-tuto/tuto-smartphone/TutoSmartphone.styled';
import { useTranslation } from 'react-i18next';
import { Container } from 'layout/page/Page.layout.styled';
import { ReactComponent as IconShare } from 'assets/svg/icon-share.svg';
import { ReactComponent as IconThreeDots } from 'assets/svg/icon-three-dots.svg';
import { useDeviceSelectors } from 'react-device-detect';

export interface TutoSmartphoneProps {
  isAndroid?: boolean;
  isIOS?: boolean;
}

const TutoSmartphone: React.FC<TutoSmartphoneProps> = ({ isAndroid, isIOS }) => {
  const { t } = useTranslation('translation');
  const MIN_IOS_VERSION = 16.4;

  const [data] = useDeviceSelectors(window.navigator.userAgent);

  const [iosVersionIsValid, setIosVersionIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (isIOS) setIosVersionIsValid(data.osVersion > MIN_IOS_VERSION);
  }, [isIOS]);

  useEffect(() => {
    if (isAndroid) {
      return;
    }
  }, [isAndroid]);

  const InstallOnAndroid: React.FC = () => (
    <InstallOnAndroidWrapper>
      <div className="android-title title">
        {t('settings.installation.plateform_android.title')}
      </div>
      <Container className="android-tutorial tutorial">
        <ul>
          <li className="step0">
            <span
              dangerouslySetInnerHTML={{
                __html: t('settings.installation.plateform_android.steps.step0'),
              }}
            />
            <span>
              <IconThreeDots />
            </span>
          </li>
          <li
            className="step1"
            dangerouslySetInnerHTML={{
              __html: t('settings.installation.plateform_android.steps.step1'),
            }}
          />
        </ul>
      </Container>
    </InstallOnAndroidWrapper>
  );

  const InstallOnIos: React.FC = () => (
    <InstallOnIOSWrapper>
      <div className="ios-title title">
        {t('settings.installation.plateform_ios.title', {
          iOSDevice: 'IOS',
        })}
      </div>
      <Container className="ios-tutorial tutorial">
        <ul>
          <li className="step0">
            <span
              dangerouslySetInnerHTML={{
                __html: t('settings.installation.plateform_ios.steps.step0', {
                  iconShare: '',
                }),
              }}
            />
            <span>
              <IconShare />
            </span>{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: t('common.intoprightcornerofbrowser'),
              }}
            />
            <span> {t('common.or')}</span>
            <span> {t('common.atbottomofbrowser')}</span>
          </li>
          <li
            className="step1"
            dangerouslySetInnerHTML={{
              __html: t('settings.installation.plateform_ios.steps.step1'),
            }}
          />
        </ul>
        {!iosVersionIsValid && (
          <div className="ios-warning">
            {t('settings.installation.plateform_ios.additionnal_note')}
          </div>
        )}
      </Container>
    </InstallOnIOSWrapper>
  );

  return (
    <TutoSmartphoneWrapper>
      {isAndroid && (
        <Container>
          <InstallOnAndroid />
        </Container>
      )}
      {isIOS && (
        <Container>
          <InstallOnIos />
        </Container>
      )}
    </TutoSmartphoneWrapper>
  );
};

export default TutoSmartphone;
