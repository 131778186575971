import styled from 'styled-components';
import { IonInput, IonItem } from '@ionic/react';
import { prem } from 'styles/helpers/spacing';
import COLORS from 'styles/constants/colors';

const IonInputWrapper = styled.div`
  ion-input {
    --ripple-color: transparent;
    --box-shadow: unset;
    text-transform: unset;
    height: unset;
  }
`;

const IonItemWrapper = styled(IonItem)`
  --padding-start: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --min-height: ${prem(20)};
  --ripple-color: transparent;

  &.item-has-value,
  &.item-has-focus {
    ion-label {
      color: var(--ion-text-color) !important;
      transform: translateY(0%) scale(0.75) !important;
    }
  }

  ion-note[slot='error'] {
    --highlight-color-invalid: ${COLORS.FORM.REQUIRED.DEFAULT};
  }
`;

const IonInputStyled = styled(IonInput)`
  input.native-input {
    --padding-top: 0;
    --padding-bottom: ${prem(8)};
    border-bottom: 1px solid var(--ion-color-accent);
  }
`;

export { IonInputWrapper, IonItemWrapper, IonInputStyled };
