import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  HeaderPageWrapper,
  HeaderRightSideWrapper,
  PageHeaderLogoWrapper,
  PageTitleWrapper,
} from 'components/header/Header.main.styled';
import IconNotification from 'components/common/icons/icon-notification/IconNotification';
import { NotificationsContext, NotificationsContextProps } from 'contexts/NotificationsContext';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { useIonRouter } from '@ionic/react';
import { ReactComponent as LogoComcolors } from 'assets/svg/logo-comcolors.svg';
import LanguageSelector from 'components/header/languageselector/Language.selector';
import IconShop from 'components/common/icons/icon-shop/IconShop';
import { BuyToolContext, BuyToolContextProps } from 'contexts/BuyToolContext';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface HeaderPageProps {
  title?: string | null;
  hideNotification?: boolean;
  showLangSwitcher?: boolean;
  showLogo?: boolean;
  hideShopCard?: boolean;
}

const HeaderPage: React.FC<HeaderPageProps> = ({
  title,
  hideNotification,
  showLangSwitcher,
  showLogo,
  hideShopCard,
}): React.ReactElement => {
  const { hasNewNotifications } = useContext(NotificationsContext) as NotificationsContextProps;
  const { shoppingList } = useContext(BuyToolContext) as BuyToolContextProps;
  const { user } = useContext(UserContext) as UserContextProps;
  const ionRouter = useIonRouter();
  const location = useLocation();
  const pathname = location.pathname || location.location.pathname;
  const matchNotificationPage = matchPath(pathname, {
    path: ROUTES.NOTIFICATIONS,
    exact: true,
  });
  const matchShopPage = matchPath(pathname, {
    path: ROUTES.SHOP,
    exact: true,
  });
  const onClickNotif = () => {
    ionRouter.push(ROUTES.NOTIFICATIONS);
  };
  const onClickShop = () => {
    ionRouter.push(ROUTES.SHOP);
  };

  const [shoppingListItemsNumber, setShoppingListItemsNumber] = useState(shoppingList.length);

  useEffect(() => {
    shoppingList &&
      shoppingList.length != shoppingListItemsNumber &&
      setShoppingListItemsNumber(shoppingList.length || 0);
  }, [shoppingList]);

  const LeftSide = useMemo(() => {
    const Component: React.FC = () => {
      if (title) {
        return <PageTitleWrapper>{title}</PageTitleWrapper>;
      } else {
        if (showLogo) {
          return (
            <PageHeaderLogoWrapper>
              <LogoComcolors />
            </PageHeaderLogoWrapper>
          );
        }
      }

      return <div />;
    };
    return Component;
  }, [title, showLogo]);

  const RightSide = useMemo(() => {
    const Component: React.FC = () => {
      if (!hideNotification) {
        return (
          <>
            {!hideShopCard && user && (
              <>
                <IconShop
                  onClick={onClickShop}
                  isShopPage={!!matchShopPage}
                  selectedItemsNumber={shoppingListItemsNumber}
                />
              </>
            )}
            {user && (
              <IconNotification
                hasNotification={hasNewNotifications}
                isNotificationPage={!!matchNotificationPage}
                onClick={onClickNotif}
              />
            )}
          </>
        );
      } else {
        if (showLangSwitcher) {
          return <LanguageSelector />;
        }
      }

      return <div />;
    };

    const wrappedComponent: React.FC = () => {
      return (
        <HeaderRightSideWrapper>
          <Component />
        </HeaderRightSideWrapper>
      );
    };
    return wrappedComponent;
  }, [
    hideNotification,
    hideShopCard,
    showLangSwitcher,
    shoppingListItemsNumber,
    matchNotificationPage,
    matchShopPage,
    hasNewNotifications,
  ]);

  return (
    <HeaderPageWrapper>
      <LeftSide />
      <RightSide />
    </HeaderPageWrapper>
  );
};

export default HeaderPage;
