import React, { useContext, useRef } from 'react';
import { ElearningWrapper } from 'pages/elearning/Elearning.styled';
import { Iframe } from 'layout/page/Page.layout.styled';
import { ElearningContext, ElearningContextProps } from 'contexts/ElearningContext';
import ButtonClose from 'components/common/button/ButtonClose';
import ButtonFullScreen from 'components/common/button/ButtonFullscreen';

const Elearning: React.FC = () => {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const { closeElearning, elearningFrameUrl, goFullScreen, exitFullScreen } = useContext(
    ElearningContext,
  ) as ElearningContextProps;

  const onClose = () => {
    closeElearning();
  };

  const toggleFullScreen = async () => {
    if (document.fullscreenElement) {
      exitFullScreen();
    } else {
      goFullScreen();
    }
  };

  return (
    <ElearningWrapper className={elearningFrameUrl ? 'open' : 'close'}>
      {elearningFrameUrl && (
        <>
          <Iframe
            className="iframe"
            width="100%"
            height="100%"
            src={elearningFrameUrl}
            ref={iFrameRef}
            allowFullScreen
          />
          <ButtonClose onClick={() => onClose()} />
          <ButtonFullScreen onClick={() => toggleFullScreen()} />
        </>
      )}
    </ElearningWrapper>
  );
};

export default Elearning;
