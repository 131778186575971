import styled from 'styled-components';
import {
  CollapseButton,
  CollapseLabel,
  CollapseWrapper,
} from 'components/common/showhide/Collapse.styled';
import { Chevron } from 'components/common/icons/icon-chevron/IconChevron.styled';
import { prem } from 'styles/helpers/spacing';

const ModulesListWrapper = styled.div`
  ${CollapseWrapper} {
    ${CollapseButton} {
      color: var(--ion-text-color-secondary);
      max-width: ${prem(990)};
      margin: 0 auto;

      &.opened {
        ${CollapseLabel} {
          color: unset;
        }

        ${Chevron} {
          color: unset;
        }
      }
    }

    &.has-access {
      ${CollapseButton} {
        color: var(--ion-color-accent) !important;
      }
    }

    color: var(--ion-text-color-secondary);
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  .label-icon {
    width: ${prem(25)};
    margin-right: ${prem(10)};
  }
`;

export { ModulesListWrapper, LabelWrapper };
