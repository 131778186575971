import styled, { css } from 'styled-components';

const ImageRendererWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const ImageToRender = styled.img<{ loaded?: boolean }>`
  ${({ loaded }) =>
    !loaded &&
    css`
      position: absolute;
      opacity: 0;
    `};
`;

export { ImageRendererWrapper, ImageToRender };
