import { IonButtonWrapper } from 'components/common/button/Button.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';

const SingleModuleWrapper = styled.div`
  .single-module-description {
    display: block;
    max-width: ${prem(930)};
    margin: 0 auto;
  }

  ${GenericDiv} {
    ${IonButtonWrapper} {
      margin: ${prem(20)} auto;
    }

    .video {
      margin-right: ${prem(7)};
    }
  }
`;

export { SingleModuleWrapper };
