import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import { FormButtonSubmitter, FormFieldsGroup } from 'components/common/form/Form.styled';
import Button from 'components/common/button/Button';
import useFormHandler from 'hooks/useFormHandler';
import { FORM } from 'constants/form';
import { TextFieldTypes } from '@ionic/core';
import {
  AddRelationFormWrapper,
  QuestionWrapper,
} from 'pages/relations/add-relation/AddRelation.styled';
import SelectBox, { SelectBoxProps } from 'components/common/form/selectbox/SelectBox';
import { ROUTES } from 'constants/routes';
import { IonRouterLink, useIonRouter } from '@ionic/react';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import useAPIRelations from 'hooks/api/useAPIRelations';
import { Form } from 'types/app';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';

const AddRelation: React.FC = () => {
  const { t } = useTranslation('translation');
  const { relationsGroups } = useContext(RelationsContext) as RelationsContextProps;
  const { getRelations, createRelation } = useAPIRelations();
  const { settings } = useContext(SettingsContext) as SettingsContextProps;
  const ionRouter = useIonRouter();
  const { fields, isFormValid, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'name',
      value: '',
    },
    {
      name: 'firstname',
      value: '',
      required: true,
    },
    {
      name: 'pronoun',
      required: true,
      options: [
        {
          id: 1,
          label: t('common.form.pronoun.him'),
        },
        {
          id: 2,
          label: t('common.form.pronoun.her'),
        },
      ],
    },
    {
      name: 'group',
      value: '',
      required: true,
    },
    {
      name: 'email',
      value: '',
      type: FORM.TYPES.EMAIL as TextFieldTypes,
    },
  ]);
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormValid()) {
      const name = getFieldByName('name', fields).value as string;
      const firstName = getFieldByName('firstname', fields).value as string;
      const pronoun = getFieldByName('pronoun', fields).value as Form.IOption;
      const group = getFieldByName('group', fields).value as Form.IOption;
      const email = getFieldByName('email', fields).value as string;
      const result: boolean | undefined = await createRelation(
        name,
        firstName,
        pronoun.label,
        group.id as number,
        email,
      );

      result && ionRouter.push(`${ROUTES.RELATIONS.INDEX}?open=${group.id}`, 'back');
    }
  };

  useEffect(() => {
    !relationsGroups && getRelations();
  }, [relationsGroups]);

  const [showMailQuestionNote, setShowMailQuestionNote] = useState<boolean>(false);

  const {
    icons: [badgeWarning],
  } = useIcons([ICON_NAMES.PROFILE_BADGE_WARNING]);

  return (
    <PageLayout headerProps={{ title: t('common.relations') }}>
      <Container className="app-page">
        <PageInnerTitle>{t('relations.addrelation.title')}</PageInnerTitle>
        <AddRelationFormWrapper>
          <form noValidate onSubmit={onSubmit} autoComplete="off">
            <FormFieldsGroup>
              <Textfield
                {...({
                  ...getFieldByName('firstname', fields),
                  label: t('common.firstname') || '',
                  onChange: (value?) => updateFieldValue('firstname', value),
                } as TextfieldProps)}
              />
              <Textfield
                {...({
                  ...getFieldByName('name', fields),
                  label: t('common.name') || '',
                  onChange: (value?) => updateFieldValue('name', value),
                } as TextfieldProps)}
              />
              <SelectBox
                {...({
                  ...getFieldByName('pronoun', fields),
                  label: t('common.pronoun') || '',
                  onChange: (value?) => updateFieldValue('pronoun', value),
                } as SelectBoxProps)}
              />
              <SelectBox
                {...({
                  ...getFieldByName('group', fields),
                  options: relationsGroups
                    ? relationsGroups.map((g) => ({ id: g.id, label: g.name }))
                    : [],
                  label: t('relations.addrelation.addtoagroup') || '',
                  onChange: (value?) => updateFieldValue('group', value),
                } as SelectBoxProps)}
              />
            </FormFieldsGroup>
            <QuestionWrapper>
              <div className="question-description">
                {t('relations.addrelation.sendemailquestion')}{' '}
                <span
                  className="question-icon"
                  onClick={() => {
                    setShowMailQuestionNote(!showMailQuestionNote);
                  }}
                >
                  {badgeWarning.icon}
                </span>
              </div>
              <div
                className={`question-note ${showMailQuestionNote ? 'show' : ''}`}
                dangerouslySetInnerHTML={{
                  __html: settings.profileVisibility
                    ? t('relations.addrelation.sendmailquestionnote.visibleprofile')
                    : t('relations.addrelation.sendmailquestionnote.notvisibleprofile'),
                }}
              />
            </QuestionWrapper>
            <FormFieldsGroup>
              <Textfield
                {...({
                  ...getFieldByName('email', fields),
                  label: t('common.email') || '',
                  inputmode: 'email',
                  onChange: (value?) => updateFieldValue('email', value),
                } as TextfieldProps)}
              />
            </FormFieldsGroup>
            <Button color="accent" expand={false} type="submit">
              {t('relations.addrelation.addtherelation')}
            </Button>
            <IonRouterLink routerLink={ROUTES.RELATIONS.INDEX} routerDirection="back">
              {t('common.back')}
            </IonRouterLink>
            <FormButtonSubmitter type="submit" />
          </form>
        </AddRelationFormWrapper>
      </Container>
    </PageLayout>
  );
};

export default AddRelation;
