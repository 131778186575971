import { MODULES_TEST_IDS, MODULE_TEST_NAMES } from 'constants/global';
import { ILocale } from 'types/app';

const getTestName = (id: number): ILocale => {
  if (id === MODULES_TEST_IDS.RELATION_MANAGEMENT) {
    return MODULE_TEST_NAMES.RELATION_MANAGEMENT;
  } else if (id === MODULES_TEST_IDS.RELATION_APPOINTMENT) {
    return MODULE_TEST_NAMES.RELATION_APPOINTMENT;
  } else {
    return MODULE_TEST_NAMES.UNKNOWN;
  }
};
export { getTestName };
