import React from 'react';
import { ButtonProps } from 'components/common/button/Button';
import { ReactComponent as IconFullScreen } from 'assets/svg/icon-fullscreen.svg';
import { ButtonFullScreenWrapper, RegularButton } from 'components/common/button/Button.styled';

const ButtonFullScreen: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <ButtonFullScreenWrapper>
      <RegularButton {...props}>
        <IconFullScreen />
      </RegularButton>
    </ButtonFullScreenWrapper>
  );
};

export default ButtonFullScreen;
