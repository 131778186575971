import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ColorQualities,
  SharedProfileWrapper,
} from 'pages/profile/shared-profile/SharedProfile.styled';
import { Container, PageInnerTitle, PageTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { IColor, ISharedProfile, ISharedProfileColors } from 'types/app';
import useAPIProfile from 'hooks/api/useAPIProfile';
import { useQuery } from 'hooks/useQuery';
import { GenericDiv } from 'styles/helpers/utils';
import Button from 'components/common/button/Button';
import {
  formatProfileQualitiesString,
  getLocaleValue,
  removeAttributes,
  replaceStrings,
} from 'helpers/utils';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { ReactComponent as LogoComcolors } from 'assets/svg/logo-comcolors.svg';
import { ROUTES } from 'constants/routes';
import LanguageSelector from 'components/header/languageselector/Language.selector';
import { HeaderWrapper } from 'components/header/Header.main.styled';
import { VideoPlayerContext, VideoPlayerContextProps } from 'contexts/VideoPlayerContext';
import VideoThumbnailsSlider from 'components/slider/video-thumbnails-slider/VideoThumbnailsSlider';
import useIonNavContext from 'hooks/useIonNavContext';
import ProfileDetails from 'components/profile/has-profile/profile-details/ProfileDetails';
import { getColorByName } from 'helpers/colors';

const SharedProfile: React.FC = () => {
  const { gotoUrl } = useIonNavContext();
  const { t, i18n } = useTranslation('translation');
  const [userProfile, setUserProfile] = useState<ISharedProfile>();
  const { getSharedProfileDetails } = useAPIProfile();
  const query = useQuery();
  const { setVideoUrl } = useContext(VideoPlayerContext) as VideoPlayerContextProps;
  const {
    icons: [iconLogo, iconYoutube],
  } = useIcons([ICON_NAMES.LOGO, ICON_NAMES.YOUTUBE]);

  const onPlayVideo = useCallback((videoUrl: string) => {
    setVideoUrl(videoUrl);
  }, []);

  const loadDatas = useCallback(async () => {
    setUserProfile(await getSharedProfileDetails(query.get('_token') || ''));
  }, []);

  const initialColorNames: string[] = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];

  const getColorListWithQualities = (colorNames: string[]): ISharedProfileColors[] => {
    if (!userProfile) return [];

    return colorNames
      .filter((colorName) => colorName !== userProfile.profileInfo?.colors?.primary?.code)
      .map((colorName) => ({
        ...(getColorByName(colorName) as IColor),
        qualities: userProfile.diagramColorsTexts[colorName],
      }));
  };

  const [otherDiagramColors, setOtherDiagramColors] = useState<Array<ISharedProfileColors>>();

  useEffect(() => {
    loadDatas();
  }, []);

  useEffect(() => {
    const newDiagramsList = getColorListWithQualities(initialColorNames);
    setOtherDiagramColors(newDiagramsList);
  }, [userProfile]);

  return (
    <>
      {userProfile && (
        <SharedProfileWrapper className="app-page">
          <PageLayout headerProps={{ hideNotification: true }}>
            <Container>
              <HeaderWrapper>
                <LanguageSelector />
                <div className="comcolors-logo-container">
                  <LogoComcolors />
                </div>
              </HeaderWrapper>
              <PageTitle
                dangerouslySetInnerHTML={{
                  __html: removeAttributes(
                    t('profile.sharedprofile.title', {
                      name: userProfile?.firstname,
                    }),
                  ),
                }}
              ></PageTitle>
              <Container>
                <div
                  className="text center-text"
                  dangerouslySetInnerHTML={{
                    __html: t('profile.sharedprofile.description', {
                      name: userProfile?.firstname,
                    }),
                  }}
                />
              </Container>
            </Container>
            <Container className="section-with-background how-to-read-a-profile">
              <div
                className="text description"
                dangerouslySetInnerHTML={{
                  __html: replaceStrings(
                    getLocaleValue(userProfile.diagramPercentageText, i18n.language) || '',
                    {
                      PRENOM: userProfile.firstname || '',
                    },
                  ),
                }}
              />
            </Container>
            <Container>
              <PageInnerTitle
                className="shared-profile-details-title"
                dangerouslySetInnerHTML={{
                  __html: t('profile.sharedprofile.profile.title'),
                }}
              />
              <div
                className="shared-profile-details-subtitle"
                dangerouslySetInnerHTML={{
                  __html: t('profile.sharedprofile.profile.subtitle', {
                    name: userProfile.firstname,
                  }),
                }}
              />
              <div className="shared-profile-details">
                <div className="primary-color">
                  <div className="title">
                    {getLocaleValue(userProfile.profileInfo?.colors?.primary?.name, i18n.language)}
                  </div>
                  <ColorQualities
                    color={userProfile.profileInfo?.colors?.primary?.color}
                    dangerouslySetInnerHTML={{
                      __html: formatProfileQualitiesString(
                        getLocaleValue(userProfile.primaryColorCharacters, i18n.language),
                      ),
                    }}
                  />
                </div>
                <div className="other-colors-title">
                  {t('profile.sharedprofile.othercolors', {
                    name: userProfile.firstname,
                  })}
                </div>
                <div className="other-colors">
                  <div className="first-line">
                    {otherDiagramColors &&
                      otherDiagramColors.slice(0, 3).map((color, key) => {
                        if (color) {
                          return (
                            <ColorQualities
                              key={key}
                              color={color.color}
                              dangerouslySetInnerHTML={{
                                __html: formatProfileQualitiesString(
                                  getLocaleValue(color.qualities, i18n.language),
                                ),
                              }}
                            />
                          );
                        }
                      })}
                  </div>
                  <div className="second-line">
                    {otherDiagramColors &&
                      otherDiagramColors.slice(3, 6).map((color, key) => {
                        if (color) {
                          return (
                            <ColorQualities
                              key={key}
                              color={color.color}
                              dangerouslySetInnerHTML={{
                                __html: formatProfileQualitiesString(
                                  getLocaleValue(color.qualities, i18n.language),
                                ),
                              }}
                            />
                          );
                        }
                      })}
                  </div>
                </div>
                <PageInnerTitle
                  dangerouslySetInnerHTML={{
                    __html: t('profile.sharedprofile.profile.diagramof', {
                      name: userProfile.firstname,
                    }),
                  }}
                />
                <ProfileDetails profileDetails={userProfile} pronoun="his" />
                <div
                  className="text description"
                  dangerouslySetInnerHTML={{
                    __html: removeAttributes(
                      getLocaleValue(userProfile.howToReadProfile.description, i18n.language) || '',
                    ),
                  }}
                />
              </div>
            </Container>
            <div className="section-with-background learn-more-about-comcolors-model">
              <PageInnerTitle>
                {t('profile.sharedprofile.learnmoreaboutcomcolorsmodel')}
              </PageInnerTitle>
              <div className="thumbnails-container">
                <div
                  className="youtube-channel"
                  onClick={() => {
                    gotoUrl('https://www.youtube.com/@SUPERGREENCOMCOLORS');
                  }}
                >
                  <span className="icon">{iconYoutube.icon}</span>
                  <span className="label">francksupergreen</span>
                </div>
                <VideoThumbnailsSlider
                  showBullets
                  thumbnails={userProfile.sliderVideos}
                  onSelectItem={onPlayVideo}
                />
              </div>
            </div>
            <Container>
              <Container>
                <div className="text section-title">
                  <strong>{t('profile.sharedprofile.discoveryourpersonality.title')}</strong>
                </div>
                <GenericDiv center minWidth="100px" maxWidth="250px">
                  <a href={ROUTES.HOME}>
                    <Button fill="solid" color="accent">
                      {t('profile.sharedprofile.discoveryourpersonality.ctalabel')}
                    </Button>
                  </a>
                </GenericDiv>
              </Container>
              <Container className="footer">
                <div
                  className="text center-text footer-section description"
                  dangerouslySetInnerHTML={{
                    __html: removeAttributes(t('profile.sharedprofile.footer')),
                  }}
                />
                <div className="center-text footer-section logo-comcolors-app">
                  <a href={ROUTES.HOME}>{iconLogo.icon}</a>
                </div>
                <div className=" text center-text footer-section">
                  <strong>
                    <a href="https://www.comcolors.com">www.comcolors.com</a>
                  </strong>
                </div>
              </Container>
            </Container>
          </PageLayout>
        </SharedProfileWrapper>
      )}
    </>
  );
};

export default SharedProfile;
