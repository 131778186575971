import React from 'react';
import { IonNav } from '@ionic/react';
import RelationsHome from 'pages/relations/home/RelationsHome';
import PageLayout from 'layout/page/Page.layout';
import { useTranslation } from 'react-i18next';
import { ButtonPropsBase } from 'components/common/button/Button';
import { ROUTES } from 'constants/routes';

export const backPropsDefault: ButtonPropsBase = {
  routerLink: ROUTES.RELATIONS.INDEX,
  routerDirection: 'back',
};

const Relations: React.FC = () => {
  const { t } = useTranslation('translation');

  return (
    <PageLayout headerProps={{ title: t('common.relations') }}>
      <IonNav root={() => <RelationsHome />} />
    </PageLayout>
  );
};

export default Relations;
