import React from 'react';
import { ButtonProps } from 'components/common/button/Button';
import { ReactComponent as IconClose } from 'assets/svg/icon-close.svg';
import { ButtonCloseWrapper, RegularButton } from 'components/common/button/Button.styled';

const ButtonClose: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <ButtonCloseWrapper>
      <RegularButton {...props}>
        <IconClose />
      </RegularButton>
    </ButtonCloseWrapper>
  );
};

export default ButtonClose;
