import React, { useContext, useState } from 'react';
import { StripeElementLocale } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { BuyToolsWrapper } from 'components/shop/buy-tools/BuyTools.styled';
import { IPayableTools } from 'types/app';
// import { UserContext, UserContextProps } from 'contexts/UserContext';
import { StripeContext, StripeContextProps } from 'contexts/StripeContext';
import { useTranslation } from 'react-i18next';
import PaymentSuccess from 'components/payment/success/PaymentSuccess';
// import { PayableToolsContext, PayableToolsContextProps } from 'contexts/PayableToolsContext';
// import useAPIAuth from 'hooks/api/useAPIAuth';
import CheckoutForm from 'components/payment/form/checkout-form/CheckoutForm';

export interface BuyToolsProps {
  tools: IPayableTools;
}

const BuyTools: React.FC<BuyToolsProps> = ({ tools }) => {
  const { stripePromise } = useContext(StripeContext) as StripeContextProps;
  const { i18n, t } = useTranslation('translation');
  const [successfullPayment, setSuccessfullPayment] = useState<boolean>(false);
  // const { loadPayableTools } = useContext(PayableToolsContext) as PayableToolsContextProps;
  // const { getUseDetail } = useAPIAuth();
  // const { user } = useContext(UserContext) as UserContextProps;

  const onSuccessPayment = async () => {
    setSuccessfullPayment(true);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    // await loadPayableTools();
    // if (user) {
    //   await getUseDetail(user, false, user.token);
    // }
  };

  return (
    <BuyToolsWrapper>
      <div className="payment-form">
        {stripePromise && tools && tools.length > 0 ? (
          <Elements
            stripe={stripePromise}
            options={{
              locale: i18n.language as StripeElementLocale,
            }}
          >
            {!successfullPayment ? (
              <CheckoutForm tools={tools} onSuccess={onSuccessPayment} />
            ) : (
              <PaymentSuccess />
            )}
          </Elements>
        ) : (
          <div className="error">{t('message.error.cannotdisplaypaymentform')}</div>
        )}
      </div>
    </BuyToolsWrapper>
  );
};

export default BuyTools;
