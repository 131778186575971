import React, { useContext } from 'react';
import { ModuleTestAvailabilityWrapper } from 'components/module-test/test-availability/ModuleTestAvailability.styled';
import { IModule, ITest, IUser } from 'types/app';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { Container } from 'layout/page/Page.layout.styled';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { disableBuyModuleButton, hideBuyModuleButton } from 'helpers/modules';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface ModuleTestAvailability {
  moduleTestInfos: ITest;
  onBuyModule: () => any;
  module: IModule;
}

const ModuleTestAvailability: React.FC<ModuleTestAvailability> = ({
  moduleTestInfos,
  onBuyModule,
  module,
}) => {
  const { i18n, t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;

  return (
    <ModuleTestAvailabilityWrapper>
      {module && (
        <Container flexCentered>
          <div className="test-days-left">
            <strong>
              {t('relations.management.test.availabilitytitle', {
                daysLeft: moduleTestInfos.daysLeft,
                daySuffix: moduleTestInfos.daysLeft > 1 ? 's' : '',
                moduleName: getLocaleValue(moduleTestInfos.themeName, i18n.language),
              })}
            </strong>
          </div>
          <div className="buy-module">
            {t('relations.management.test.buydescription', {
              daysLeft: moduleTestInfos.usedDay + moduleTestInfos.daysLeft,
            })}
          </div>
          {!hideBuyModuleButton(user as IUser) && !disableBuyModuleButton(user as IUser) && (
            <GenericDiv width="50%" minWidth={prem(200)} center={true}>
              <Button
                onClick={() => onBuyModule && onBuyModule()}
                color="accent"
                fill="outline"
                expand={true}
              >
                {t('common.buymodule')}
              </Button>
            </GenericDiv>
          )}
        </Container>
      )}
    </ModuleTestAvailabilityWrapper>
  );
};

export default ModuleTestAvailability;
