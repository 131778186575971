import React from 'react';
import { IonButton } from '@ionic/react';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { IComColorsColors, IComponentWithChildren } from 'types/app';

export type IIonButtonFill = 'clear' | 'outline' | 'solid';
export type IIonButtonExpand = 'block' | 'full' | undefined;
type IIonButtonMode = 'ios' | 'md';
type IIonButtonType = 'submit' | 'reset' | 'button';

export interface ButtonPropsBase extends IComponentWithChildren {
  small?: boolean;
  strong?: boolean;
  color?: IComColorsColors;
  opacity?: number;
  fill?: IIonButtonFill;
  disableRouter?: boolean;
  routerLink?: string;
  routerDirection?: 'none' | 'forward' | 'back' | 'root';
  type?: IIonButtonType;
  href?: string;
  target?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface ButtonProps extends ButtonPropsBase {
  expand?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  color,
  small,
  expand,
  routerLink,
  routerDirection,
  type,
  href,
  onClick,
  className,
  target,
  disabled = false,
  fill = 'solid',
  strong = true,
  opacity = 0.25,
}: ButtonProps) => {
  const ionButtonProps = {
    strong,
    color,
    fill,
    routerLink,
    routerDirection,
    type,
    href,
    target,
    disabled,
    mode: 'ios' as IIonButtonMode,
    ...(expand && { expand: 'block' as IIonButtonExpand }),
  };

  return (
    <IonButtonWrapper small={small} opacity={fill === 'solid' ? opacity : 0} className={className}>
      <IonButton
        {...ionButtonProps}

        onClick={() => {
          onClick && onClick();
        }}
      >
        {children}
      </IonButton>
    </IonButtonWrapper>
  );
};

export default Button;
