import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { transparentEl } from 'styles/helpers/utils';
import { BUTTON } from 'styles/helpers/typography';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { RelationMenuWrapper } from 'pages/relations/Relations.styled';
import {
  ButtonCloseWrapper,
  RegularButton,
  RegularButtonFlexed,
} from 'components/common/button/Button.styled';

const RelationItemHeaderLeft = styled.div<{ gap: number }>`
  display: flex;
  align-items: center;
  ${({ gap }) =>
    css`
      gap: ${gap}px;
    `};
`;

const RelationItemWrapper = styled.div`
  margin: ${prem(5)} 0;
  position: relative;

  ${RelationItemHeaderLeft} {
    &.relation-icon-and-name-wrapper {
      width: 90%;
    }

    &.relation-name {
      flex: 1;
    }
  }
`;

const RelationColorIcon = styled.div`
  width: ${prem(40)};
  height: ${prem(40)};

  .profile-icon {
    svg {
      width: ${prem(40)};
      height: ${prem(40)};
    }
  }
`;

const RelationMessageIcon = styled.div`
  width: ${prem(25)};
  height: ${prem(25)};
`;

const RelationItemName = styled.span`
  ${BUTTON.large};
  font-weight: ${FONT_WEIGHT.BOLD};
  color: var(--ion-text-color-secondary);
  text-align: start;
`;

const RelationSubscribedIcon = styled.div`
  color: var(--ion-color-orange);
`;

const RelationItemContextualMenuWrapper = styled(RelationMenuWrapper)`
  position: relative;
  min-width: ${prem(300)};
  width: 80%;
  margin: ${prem(16)} auto;
  padding-top: ${prem(24)};

  ${RegularButtonFlexed} {
    ${BUTTON.large};
    width: 100%;
    justify-content: left;
  }

  ${ButtonCloseWrapper} {
    ${RegularButton} {
      color: var(--ion-text-color-secondary);
    }

    position: absolute;
    right: ${prem(6)};
    top: ${prem(10)};

    svg {
      width: ${prem(20)};
      height: ${prem(20)};
    }
  }
`;

const RelationItemMenuGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${prem(15)};

  .dropdown-wrapper {
    flex: 1;
  }
`;

const RelationItemHeaderWrapper = styled.button`
  ${transparentEl};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export {
  RelationItemWrapper,
  RelationItemHeaderWrapper,
  RelationItemHeaderLeft,
  RelationColorIcon,
  RelationItemName,
  RelationSubscribedIcon,
  RelationMessageIcon,
  RelationItemContextualMenuWrapper,
  RelationItemMenuGroupWrapper,
};
