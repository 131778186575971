import { IconProfileWrapper } from 'components/common/icons/icon-profile/IconProfile.styled';
import { ProfileDetailsWrapper } from 'components/profile/has-profile/profile-details/ProfileDetails.styled';
import { VideoThumbnailWrapper } from 'components/slider/video-thumbnails-slider/VideoThumbnailsSlider.styled';
import { Container, PageInnerTitle, PageTitle } from 'layout/page/Page.layout.styled';
import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY, HEADLINE } from 'styles/helpers/typography';

const ColorQualities = styled.div<{ color?: string }>`
  position: relative;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  background-color: grey;
  color: white;
  white-space: nowrap;
  width: max-content;
  height: auto;
  padding: ${prem(30)} ${prem(27)};
  border-radius: 50%;
  /* stylelint-disable */
  box-shadow: 0px ${prem(3)} ${prem(2)} grey;
  /* stylelint-enable */
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `};

  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    /* stylelint-disable */
    box-shadow: rgba(0, 0, 0, 0.3) ${prem(0)} ${prem(2)} ${prem(2)},
      rgb(var(--ion-color-white-rgb), 0.7) ${prem(0)} ${prem(-1)} ${prem(1)};
    /* stylelint-enable */
    width: 98%;
    height: 97%;
    top: ${prem(2)};
    left: 1px;
  }
`;

const SharedProfileWrapper = styled.div`
  ${PageTitle} {
    text-align: center;
    padding-top: ${prem(40)};
  }

  ${Container} {
    margin-bottom: ${prem(50)};

    a {
      text-decoration: none;
    }
  }

  .description {
    text-align: start;
    max-width: ${prem(900)};

    p {
      margin-bottom: ${prem(20)};
    }
  }

  .how-to-read-a-profile {
    ${VideoThumbnailWrapper} {
      width: 100%;
      max-width: ${prem(500)};
      display: block;
      margin: 0 auto;
    }

    .description {
      padding-top: ${prem(40)};

      * {
        background-color: transparent !important;
        color: var(--ion-text-color-primary) !important;
      }

      p:first-child {
        color: var(--ion-text-color) !important;
      }
    }
  }

  ${PageInnerTitle} {
    &.shared-profile-details-title {
      margin-bottom: ${prem(4)};
    }
  }

  .shared-profile-details-subtitle {
    text-align: center;
    margin-bottom: ${prem(20)};
  }

  .shared-profile-details {
    .title {
      ${HEADLINE.small};
      text-align: center;
      margin-bottom: ${prem(30)};
    }

    .primary-color {
      margin-bottom: ${prem(40)};

      ${ColorQualities} {
        font-size: ${prem(18)};
        margin: 0 auto;
        padding: ${prem(43)} ${prem(40)};
      }
    }

    .other-colors-title {
      text-align: center;
      margin-bottom: ${prem(50)};
    }

    .other-colors {
      max-width: ${prem(400)};
      display: block;
      margin: 0 auto;

      .first-line {
        display: flex;
        flex-wrap: wrap;
      }

      .second-line {
        display: flex;
        flex-wrap: wrap;
        max-width: 75%;
        margin: 0 auto;
      }

      ${ColorQualities} {
        ${BODY.small}
        font-weight: 700;
        padding: ${prem(21)} ${prem(18)};
        width: ${prem(100)};
        height: ${prem(102)};
        margin: 0 auto ${prem(20)} auto;
        /* stylelint-disable */
        box-shadow: rgba(0, 0, 0, 0.7) ${prem(-2)} ${prem(2)} ${prem(4)};
        /* stylelint-enable */
      }
    }
  }

  ${ProfileDetailsWrapper} {
    .dominating-color {
      display: none;
    }

    .secondary-color {
      display: none;
    }

    .color-diagrams {
      .diagram-container {
        .section-title {
          display: none;
        }
      }
    }
  }

  .learn-more-about-comcolors-model {
    margin-bottom: ${prem(50)};

    .thumbnails-container {
      max-width: ${prem(900)};
      margin: 0 auto;

      .youtube-channel {
        ${BODY.small}
        padding-left: ${prem(24)};
        margin-bottom: ${prem(5)};
        display: flex;
        align-items: center;

        .icon {
          color: var(--ion-color-orange);
          margin-right: ${prem(5)};

          svg {
            width: ${prem(25)};
            height: max-content;
          }
        }
      }
    }

    ${VideoThumbnailWrapper} {
      width: ${prem(260)};
    }

    .swiper-slide {
      width: ${prem(260)};
    }
  }

  .comcolors-logo-container {
    width: ${prem(111)};
    margin: 0 auto;
  }

  .text {
    color: var(--ion-text-color-secondary);
    margin: 0 auto ${prem(20)} auto;
  }

  .center-text {
    text-align: center;
  }

  .section-with-background {
    ${PageInnerTitle} {
      padding: 0 ${prem(10)};
    }

    /* stylelint-disable */
    background-color: rgb(var(--ion-color-accent-rgb), 0.2);
    /* stylelint-enable */
    padding-top: ${prem(10)};
    padding-bottom: ${prem(30)};
  }

  .color-icon {
    max-width: ${prem(100)};
    display: block;
    margin: 0 auto;

    ${IconProfileWrapper} {
      .profiled {
        position: relative;
      }
    }
  }

  .color-title {
    margin-bottom: ${prem(10)};
  }

  .section-title {
    margin-bottom: ${prem(25)};
    text-align: center;
  }

  .footer {
    padding-bottom: ${prem(100)};
  }

  .footer-section {
    margin-bottom: ${prem(25)};
  }

  .logo-comcolors-app {
    svg {
      width: ${prem(50)};
      height: ${prem(50)};
    }
  }

  a {
    color: var(--ion-text-color-secondary);
  }
`;

export { SharedProfileWrapper, ColorQualities };
