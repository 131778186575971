import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { ISectionBaseTexts, IModelColor, ITestimonial, IFranckJullien } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { API } from 'constants/global';
import { IHttpResponse } from 'helpers/api';
import { useTranslation } from 'react-i18next';
import ImageFranckJullien from 'assets/images/franck-julien.png';
import Image0Fr from 'assets/images/thumbnails/model-comcolors-image-01-fr.jpg';
import Image0En from 'assets/images/thumbnails/model-comcolors-image-01-en.jpg';
import { detectLanguage, getSectionBaseTexts } from 'helpers/utils';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';

export interface IUseAPIModels {
  getFranckJullien: () => Promise<IFranckJullien | undefined>;
  getComcolors: () => Promise<ISectionBaseTexts | undefined>;
  getColors: () => Promise<Array<IModelColor>>;
  getTestimonials: () => Promise<Array<ITestimonial>> | Array<ITestimonial>;
  postTestimonial: (
    userId: number,
    firstname: string,
    company: string,
    content: string,
  ) => Promise<undefined> | Promise<boolean>;
}

function useAPIModels(): IUseAPIModels {
  const { i18n, t } = useTranslation('translation');
  const { language } = i18n;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { languages } = useContext(SettingsContext) as SettingsContextProps;

  const COMCOLORSTHUMBNAILS: { [key: string]: string } = {
    image0fr: Image0Fr,
    image0en: Image0En,
  };

  const imageComcolors = COMCOLORSTHUMBNAILS[t('model.comcolors.image0.key')] || Image0Fr;

  const formatFrankJullienInformationsFromAPI = (informations: any): IFranckJullien => {
    return {
      ...{
        ...getSectionBaseTexts(
          informations.texte.translations,
          languages,
          informations.texte.image_url || ImageFranckJullien,
        ),
      },
      socials: {
        linkedin: informations.social.linkedin,
        youtube: informations.social.youtube,
        tiktok: informations.social.tiktok,
      },
    };
  };

  const getFranckJullien = async () => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.MODELS.FRANCK_JULLIEN}`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const getState: any = result.data.data;
      if (getState) {
        return formatFrankJullienInformationsFromAPI(getState);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting informations', e);
      setToast({
        message: 'Error while getting informations',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  const getComcolors = async () => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.MODELS.COMCOLORS}`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const getState: any = result.data.data;
      if (getState) {
        const translations = getState.texte.translations;

        return getSectionBaseTexts(translations, languages, getState.image_url || imageComcolors);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting informations', e);
      setToast({
        message: 'Error while getting informations',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return {
      title: {},
      description: {},
      imageUrl: '',
    };
  };

  const formatColorsObject = (objectToFormat: Array<any>): Array<IModelColor> => {
    return objectToFormat.map((color: any) => ({
      id: color?.id,
      order: color?.ordre,
      name: color?.couleur_nom,
      color: color?.couleur_code_hex,
      qualities: color?.plus_traits_caracteres,
      language,
      description: color?.plus_environnement_favorable,
      videoUrl: color?.url_video,
    }));
  };

  const getColors = async () => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.COLOURS.COLOURS}`,
        method: 'get',
        headers: {
          Authorization: `${API.BASIC_TOKEN}`,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const getState: Array<any> = result.data.data;
      if (getState) {
        return formatColorsObject(getState);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting colors', e);
      setToast({
        message: 'Error while getting colors',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return [];
  };

  const formatTestimonialsInformationsFromAPI = (informations: any): Array<ITestimonial> => {
    const testimonials = informations.temoignages;

    return testimonials.map((testimonial: any) => {
      return {
        authorName: testimonial.fullname,
        logo: testimonial.logo_url,
        testimonial: testimonial.texte,
      };
    });
  };

  const getTestimonials = async () => {
    showLoading();
    const options: AxiosRequestConfig = {
      url: `${API.ROUTES.MODELS.TEMOIGNAGE}/${detectLanguage(i18n.language)}`,
      method: 'get',
    };
    const result: IHttpResponse = await getRequest(options);
    const getState: any = result.data.data;
    try {
      if (getState) {
        return formatTestimonialsInformationsFromAPI(getState);
      }
    } catch (e: unknown) {
      console.log('Error while getting informations', e);
      setToast({
        message: t('message.error.testimonialsnotfetched'),
        status: 'danger',
      });
    } finally {
      hideLoading();
    }
    return [];
  };

  const postTestimonial = async (
    userId: number,
    firstname: string,
    company: string,
    content: string,
  ) => {
    showLoading();
    const options: AxiosRequestConfig = {
      url: `${API.ROUTES.MODELS.TEMOIGNAGE}/${userId}`,
      method: 'post',
      data: {
        userId: userId,
        prenom: firstname,
        company: company,
        content: content,
        locale: detectLanguage(i18n.language),
      },
    };

    try {
      const result: IHttpResponse = await getRequest(options);
      const temoignage = result?.data?.data?.temoignage;

      if (temoignage) {
        setToast({
          message: t('message.senttestimonialmessage'),
          status: 'success',
        });

        return true;
      } else {
        setToast({
          message: t('message.error.notsenttestimonialmessage'),
          status: 'danger',
        });

        return false;
      }
    } catch (e: unknown) {
      console.log('Error while sending testimonial', e);
      setToast({
        message: t('message.error.notsenttestimonialmessage'),
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return false;
  };

  return {
    getFranckJullien,
    getComcolors,
    getColors,
    getTestimonials,
    postTestimonial,
  };
}

export default useAPIModels;
