import React from 'react';
import { IComponentWithChildren } from 'types/app';
import {
  ActiveTabMarkerWrapper,
  IconTabSvg,
  IconTabWrapper,
} from 'components/common/icons/icon-tab/IconTab.styled';
import { ReactComponent as ActiveTabMarkerSvg } from 'assets/svg/active-tab.svg';

const IconTab: React.FC<IComponentWithChildren> = ({ children }) => {
  return (
    <IconTabWrapper>
      <ActiveTabMarkerWrapper>
        <ActiveTabMarkerSvg />
      </ActiveTabMarkerWrapper>
      <IconTabSvg>{children}</IconTabSvg>
    </IconTabWrapper>
  );
};

export default IconTab;
