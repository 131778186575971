import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { IonHeaderStyled } from 'layout/ionic/IonHeader.styled';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const HeaderWrapper = styled(IonHeaderStyled)`
  padding: ${prem(33)} ${prem(15)};

  &::after {
    display: none;
  }

  @media (${MQ_MAX_HEIGHT}) {
    padding: ${prem(15)};
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoImageWrapper = styled.div`
  width: 60%;
  max-width: ${prem(500)};
  min-width: ${prem(250)};

  @media (${MQ_MAX_HEIGHT}) {
    width: 20%;
  }
`;

export { HeaderWrapper, LogoWrapper, LogoImageWrapper };
