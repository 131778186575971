import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Iframe } from 'layout/page/Page.layout.styled';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { TrainingWrapper } from 'pages/training/Training.styled';
import { API, LANGUAGES } from 'constants/global';
import { useIonRouter } from '@ionic/react';
import { ROUTES } from 'constants/routes';
import useFetch from 'hooks/api/useFetch';
import { getBase64User } from 'helpers/utils';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';

const Training: React.FC = () => {
  const { t } = useTranslation('translation');
  const { settings } = useContext(SettingsContext) as SettingsContextProps;
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const ionRouter = useIonRouter();
  const { showLoading, hideLoading } = useFetch();
  const { user } = useContext(UserContext) as UserContextProps;
  const [url, setUrl] = useState('');
  const iframeCurrent = iFrameRef.current;
  const onIframeLoaded = () => {
    hideLoading();
  };

  const [currentLanguage, setCurrentLanguage] = useState(settings.lang || LANGUAGES.fr);

  useEffect(() => {
    settings.lang && currentLanguage !== settings.lang && setCurrentLanguage(settings.lang);
  }, [settings.lang]);
  
  useEffect(() => {
    showLoading();
    if (user?.profileInfo?.tools.digital) {
      // Alefa utils
      const base64 = getBase64User(user.email as string);

      setUrl(`${API.TRAINING_PATH}${base64}&t=${new Date().getTime()}&l=${currentLanguage}`);
    } else {
      hideLoading();
      ionRouter.push(ROUTES.HOME, 'back');
    }
  }, [currentLanguage]);

  useEffect(() => {
    iframeCurrent?.addEventListener('load', onIframeLoaded);

    return () => {
      iframeCurrent?.removeEventListener('load', onIframeLoaded);
    };
  }, [iframeCurrent]);

  useEffect(() => {
    const handleOnline = () => {
      const base64 = getBase64User(user?.email as string);
      setUrl(`${API.TRAINING_PATH}${base64}&t=${new Date().getTime()}`);
    };
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  return (
    <PageLayout headerProps={{ title: t('menu.tools') }}>
      <TrainingWrapper className="app-page">
        <Iframe width="100%" height="100%" src={url} ref={iFrameRef} />
      </TrainingWrapper>
    </PageLayout>
  );
};

export default Training;
