import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MotivationWrapper } from 'pages/profile/has-profile/my-motivation/Motivation.styled';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import Slider from 'components/slider/Slider';
import { SwiperSlide } from 'swiper/react';
import { SliderContainer } from 'pages/relations/identification/RelationIdentification.styled';
import { Swiper as SwiperClass } from 'swiper/types';
import MotivationHome from 'pages/profile/has-profile/my-motivation/slides/motivation-home/MotivationHome';
import MotivationColorSelection from 'pages/profile/has-profile/my-motivation/slides/motivation-color-selection/MotivationColorSelection';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';
import useAPIMotivations from 'hooks/api/useAPIMotivations';
import { IAdvice, IPerson, IUserMotivation } from 'types/app';
import SubscriptionModal, {
  SubscriptionModalProps,
} from 'components/modal/subscription/SubscriptionModal';
import { SubscriptionType } from 'hooks/api/useAPINotificationSubscription';
import { getColorById } from 'helpers/colors';
import { getLocaleValue } from 'helpers/utils';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import { NOTIFICATIONS } from 'constants/global';
import TutoButtonPlayer from 'components/tuto-button-player/TutoButtonPlayer';

const Motivation: React.FC = () => {
  const { user, updateUser } = useContext(UserContext) as UserContextProps;
  const { t, i18n } = useTranslation('translation');
  const { getAdvices, setAdvicesRead } = useAPIAdvices();
  const { getMotivationDetail } = useAPIMotivations();
  const [isLoadingMotivation, setMotivationLoading] = useState(true);
  const [activeIndex, updateActiveIndex] = useState<number>(0);
  const [advices, setAdvices] = useState<Array<IAdvice>>([]);
  const swiperInstance = useRef<SwiperClass>();
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  // Swiper events
  const onInitSwiper = (swiper: SwiperClass) => {
    swiperInstance.current = swiper;
  };
  const onSlideChange = (swiper: SwiperClass) => {
    updateActiveIndex(swiper.activeIndex);
  };
  const gotoSlide = (index: number) => {
    swiperInstance.current?.slideTo(index);
  };

  // Load advices
  const loadAdvices = useCallback(async () => {
    try {
      let advices: Array<IAdvice> = [];
      if (user && user.motivation?.subscribed) {
        const person: IPerson = {
          pronoun: user.pronoun || '',
          firstName: user.firstname || '',
        };

        advices = await getAdvices(AdviceType.MOTIVATION, user.id, undefined, person);
        await setAdvicesRead(AdviceType.MOTIVATION, user.id);
      }
      setAdvices(advices);
    } catch (e) {
      console.log('loadAdvices error: ', e);
    }
  }, []);

  // Load motivations
  const loadMotivations = useCallback(async () => {
    try {
      if (user) {
        const motivation: IUserMotivation | undefined = await getMotivationDetail(
          user?.id || 0,
          true,
        );

        // If user has not motivation tool, turn out motivation
        updateUser({
          ...user,
          motivation: user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MOTIVATION)
            ? motivation
            : undefined,
        });
      }
    } catch (e) {
      console.log('loadMotivations error: ', e);
    }
    setMotivationLoading(false);
  }, [user]);

  // When button subscription is clicked
  const onSubscribe = () => {
    const theColour = getColorById(user?.motivation?.colourId);
    const colourName = getLocaleValue(theColour?.name, i18n.language);
    const subscription = user?.motivation?.subscription;
    const motivationName = t('profile.motivation.themotivation', { colourName });
    const props: SubscriptionModalProps = {
      type: SubscriptionType.MOTIVATION,
      motivation: { colorId: user?.motivation?.colourId || 0 },
      texts: {
        title: t('common.subscribeto', {
          name: `${motivationName}`,
        }),
        subscriptionNote: t('profile.motivation.youreceivenotificaionnote'),
        unsubscriptionNote: t('profile.motivation.youdonotreceivenotificaionnote', { colourName }),
      },
      subscriptionId: user?.motivation?.subscriptionId,
      deviceAuth: subscription?.deviceAuth,
      deviceEndPoint: subscription?.deviceEndPoint,
      deviceP256Dh: subscription?.deviceP256Dh,
      onValidate: () => {
        // reload motivation
        loadMotivations();
        updateModalConf();
      },
      onCancel: () => {
        updateModalConf();
      },
      ...(user?.motivation?.subscription && {
        hour: user.motivation.subscription.hour,
        day: user.motivation.subscription.day,
      }),
    };

    updateModalConf({
      component: <SubscriptionModal key={Math.random()} {...props} />,
    });
  };

  // Load advices each time we are in slide 0
  useEffect(() => {
    if (activeIndex === 0) {
      if (user?.motivation?.subscription) {
        setTimeout(() => {
          loadAdvices();
        }, 250);
      } else {
        setAdvices([]);
      }
    }
  }, [activeIndex]);

  // On loaded
  useEffect(() => {
    loadMotivations();
  }, []);

  return (
    <MotivationWrapper className="app-page">
      {!isLoadingMotivation && (
        <>
          {user?.motivation ? (
            <>
              <br />
              <TutoButtonPlayer tutoKey='add_motivation' />
              <Container>
                <PageInnerTitle
                  dangerouslySetInnerHTML={{
                    __html: t('profile.motivation.title'),
                  }}
                />
              </Container>
              <SliderContainer>
                <Slider onInit={onInitSwiper} allowTouchMove={false} onSlideChange={onSlideChange}>
                  <SwiperSlide>
                    <MotivationHome
                      motivation={user.motivation}
                      advices={advices}
                      onChangeAdvice={() => {
                        loadAdvices();
                      }}
                      onSubscribe={onSubscribe}
                      onChangeColor={() => {
                        gotoSlide(1);
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <MotivationColorSelection
                      onBack={() => {
                        gotoSlide(0);
                      }}
                    />
                  </SwiperSlide>
                </Slider>
              </SliderContainer>
            </>
          ) : (
            <Container flexCentered={true}>
              {!user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MOTIVATION) && (
                <p
                  className="no-valid-profile"
                  dangerouslySetInnerHTML={{
                    __html: t('profile.motivation.nomotivation'),
                  }}
                />
              )}
              {!user?.profileInfo?.hasValidatedProfile && (
                <p className="no-valid-profile">{t('profile.motivation.novalidprofile')}</p>
              )}
            </Container>
          )}
        </>
      )}
    </MotivationWrapper>
  );
};

export default Motivation;
