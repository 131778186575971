import styled, { css } from 'styled-components';
import { RegularButton } from 'components/common/button/Button.styled';
import { prem } from 'styles/helpers/spacing';

const MobileLayoutWrapper = styled.div``;

const MoreMenuWrapper = styled.div<{ height?: number; zIndex?: number }>`
  height: 100%;
  overflow: hidden;
  z-index: 1000;
  position: relative;
  pointer-events: none;

  ${({ height }) =>
    css`
      height: ${height}px;
    `}
`;

const MoreMenu = styled.div<{ transform?: string }>`
  pointer-events: auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  background: var(--comcolors-tab-more-menu-bg);
  transition: transform 0.2s cubic-bezier(0, 0, 0.58, 1);
  padding: ${prem(38)} ${prem(38)} ${prem(20)};

  &.open {
    visibility: visible;
    transform: translate3d(-50%, 0%, 0);
    box-shadow: 0 -4px 5px -2px var(--ion-color-accent);
  }

  &.close {
    visibility: hidden;
    transform: translate3d(-50%, 100%, 0);
  }

  ${RegularButton} {
    color: var(--ion-text-color);
    position: absolute;
    top: ${prem(17)};
    right: ${prem(17)};
  }
`;

export { MobileLayoutWrapper, MoreMenuWrapper, MoreMenu };
