import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const SpotifyAudiosWrapper = styled.div`
  padding-top: ${prem(10)};

  .single-audio {
    display: flex;
    margin-bottom: ${prem(20)};
    gap: ${prem(20)};

    .audio-img {
      width: ${prem(150)};
    }

    .audio-texts {
      width: 75%;

      .audio-title {
        word-wrap: break-word;
        hyphens: auto;
        overflow-wrap: break-word;
        font-weight: bolder;
        margin-bottom: ${prem(10)};
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .audio-description {
        ${BODY.small}
        display: block;
        max-width: ${prem(500)};
      }

      .see-more {
        font-weight: bolder;
        cursor: pointer;
      }
    }
  }
`;

export { SpotifyAudiosWrapper };
