import { IconProfileWrapper } from 'components/common/icons/icon-profile/IconProfile.styled';
import { Container } from 'layout/page/Page.layout.styled';
import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const ProfileDetailsWrapper = styled.div`
  ${Container} {
    text-align: center;
    color: var(--ion-text-color-secondary);
    margin-bottom: ${prem(25)};

    .color-icon {
      max-width: ${prem(100)};
      display: block;
      margin: 0 auto;

      ${IconProfileWrapper} {
        .profiled {
          position: relative;
        }
      }
    }

    .color-title {
      margin-bottom: ${prem(5)};
    }

    .color-qualities {
      text-transform: capitalize;
    }
  }

  .section-title {
    margin-bottom: ${prem(25)};
    text-align: center;
  }

  .description {
    text-align: start;
    max-width: ${prem(900)};
    margin: 0 auto;
  }

  .your-behavior {
    margin-bottom: ${prem(20)};
  }

  .color-diagrams {
    display: flex;
    flex-direction: column;
    align-items: center;

    .diagram-description {
      margin-bottom: ${prem(35)};
    }

    .image-container {
      position: relative;

      .diagram-secondary-text {
        position: absolute;
        width: max-content;
        color: var(--ion-white-to-black);
        top: 50%;
        left: 50%;
        ${BODY.extraSmall}
        transform: translate(-50%, -50%);
      }

      .diagram-percentage {
        position: absolute;
        ${BODY.small}
        color: var(--ion-black-to-white);
      }

      .diagram-color-text {
        position: absolute;
        ${BODY.extraSmall}
        color: var(--ion-black-to-white);
      }

      .diagram-percentage-red {
        top: 67%;
        left: 50%;
        transform: translateX(-50%);
      }

      .diagram-percentage-purple {
        top: 57%;
        left: 30%;
      }

      .diagram-percentage-blue {
        top: 39%;
        left: 30%;
      }

      .diagram-percentage-green {
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
      }

      .diagram-percentage-yellow {
        top: 39%;
        left: 63%;
      }

      .diagram-percentage-orange {
        top: 57%;
        left: 64%;
      }

      .diagram-text-red {
        top: 63%;
        left: 50%;
        transform: translateX(-50%);
      }

      .diagram-text-purple {
        top: 53%;
        left: 25%;
      }

      .diagram-text-blue {
        top: 35%;
        left: 25%;
      }

      .diagram-text-green {
        top: 26%;
        left: 50%;
        transform: translateX(-50%);
      }

      .diagram-text-yellow {
        top: 35%;
        left: 62%;
      }

      .diagram-text-orange {
        top: 53%;
        left: 61%;
      }
    }

    img {
      width: ${prem(400)};
      max-width: 100%;

      &.hide {
        display: none;
      }
    }

    .loading-image {
      opacity: 0.7;
    }
  }
`;

const DominatingColorIconWrapper = styled.div<{
  color?: string | null;
}>`
  * {
    stroke: none;
  }

  .primary {
    ${({ color }) =>
      color &&
      css`
        fill: ${color};
      `}
  }

  .secondary {
    color: transparent;
  }
`;
const SecondaryColorIconWrapper = styled.div<{
  color?: string | null;
}>`
  * {
    stroke-width: ${prem(0.2)};

    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}
  }

  .primary {
    color: transparent;
  }
`;
export { ProfileDetailsWrapper, DominatingColorIconWrapper, SecondaryColorIconWrapper };
