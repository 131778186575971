import React, { useEffect, useState } from 'react';
import Button, { ButtonPropsBase } from 'components/common/button/Button';
import { BuyModuleButtonWrapper } from 'components/resources/module/single-module/buy-module/buy-module-button/BuyModuleButton.styled';
import { useTranslation } from 'react-i18next';
import { IModule } from 'types/app';
import { MODULES_CODES } from 'constants/global';
import { detectLanguage, formatPrice } from 'helpers/utils';

export interface BuyModuleButtonProps extends ButtonPropsBase {
  module: IModule;
  hasAccessToModule?: boolean;
  disable?: boolean;
  onClick?: () => void;
}
const BuyModuleButton: React.FC<BuyModuleButtonProps> = ({
  module,
  hasAccessToModule,
  disable,
  onClick,
  fill,
  color,
}) => {
  const { i18n, t } = useTranslation('translation');

  const [disabledButton, setDisabledButton] = useState(disable);

  useEffect(() => {
    setDisabledButton(disable || hasAccessToModule || module.price_ttc <= 0);
  }, [module, disable, hasAccessToModule]);

  return (
    <BuyModuleButtonWrapper>
      {module.code !== MODULES_CODES.ELEARNING && (
        <Button
          color={color || 'accent'}
          fill={fill || 'solid'}
          type="submit"
          expand={true}
          disabled={disabledButton}
          onClick={() => {
            if (!disabledButton) {
              onClick && onClick();
            }
          }}
        >
          {`💳 ${t('resources.modules.buythemodule')} ${
            !disabledButton
              ? `${t('common.for')} ${formatPrice(
                  module.price_ttc,
                  module.currency,
                  detectLanguage(i18n.language),
                )}`
              : ''
          }`}
        </Button>
      )}
      {module.code === MODULES_CODES.ELEARNING && (
        <Button
          color="accent"
          fill="solid"
          type="submit"
          expand={true}
          disabled={disabledButton}
          onClick={() => {
            if (!disabledButton) {
              onClick && onClick();
            }
          }}
        >
          {`💳 ${t('resources.modules.buythemodule')} ${
            !disabledButton
              ? `${t('common.for')} ${formatPrice(
                  module.price_ttc,
                  module.currency,
                  detectLanguage(i18n.language),
                )}`
              : ''
          }`}
        </Button>
      )}
    </BuyModuleButtonWrapper>
  );
};

export default BuyModuleButton;
