import React, { useState } from 'react';
import { ReactComponent as IconPlay } from 'assets/svg/icon-play.svg';
import {
  PictoPlayContainer,
  VideoThumbnailWrapper,
  VideoTitle,
} from 'components/slider/video-thumbnails-slider/VideoThumbnailsSlider.styled';
import ImageRenderer from 'components/common/image/ImageRenderer';

export interface VideoThumbnailProps {
  imageUrl?: string;
  onClick?: () => void;
  title?: string;
  thumbnailLoader?: React.ReactNode;
  onThumbnailLoad?: () => void;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  imageUrl,
  onClick,
  title,
  thumbnailLoader,
  onThumbnailLoad,
}) => {
  const [imageIsLoaded, setImageIsLoaded] = useState<boolean>(false);

  const showImage = () => {
    setImageIsLoaded(true);
  };

  return (
    <div>
      <VideoThumbnailWrapper onClick={onClick}>
        {imageIsLoaded && (
          <PictoPlayContainer>
            <IconPlay />
          </PictoPlayContainer>
        )}
        <ImageRenderer
          alt=""
          src={imageUrl as string}
          onLoad={() => {
            showImage();
            onThumbnailLoad && onThumbnailLoad();
          }}
          showLoader
          imageLoader={thumbnailLoader ? thumbnailLoader : undefined}
        />
      </VideoThumbnailWrapper>
      {title && <VideoTitle>{title}</VideoTitle>}
    </div>
  );
};

export default VideoThumbnail;
