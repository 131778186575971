import styled from 'styled-components';
import { centerMessage } from 'styles/helpers/utils';

const AnswerQuestionnaireWrapper = styled.div`
  height: 100%;

  .message {
    ${centerMessage};
  }
`;

export { AnswerQuestionnaireWrapper };
