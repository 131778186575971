import React from 'react';
import {
  LinkedinPostsWrapper,
  LinkedinSinglePost,
} from 'pages/home/linkedin-posts/LinkedinPosts.styled';
import { IHome } from 'types/app';
import { useTranslation } from 'react-i18next';
import { getLocaleValue, removeAttributes } from 'helpers/utils';
import { Container } from 'layout/page/Page.layout.styled';
import ImageRenderer from 'components/common/image/ImageRenderer';

export interface LinkedinPostsProps {
  posts: IHome.ILinkedinPost[];
}
const LinkedinPosts: React.FC<LinkedinPostsProps> = ({ posts }) => {
  const { i18n } = useTranslation('translation');

  return (
    <LinkedinPostsWrapper>
      <Container>
        {posts &&
          posts.map((post, key) => {
            return (
              <LinkedinSinglePost key={key}>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: removeAttributes(getLocaleValue(post.title, i18n.language)),
                  }}
                />
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: removeAttributes(getLocaleValue(post.description, i18n.language)),
                  }}
                />
                <div className="image-container">
                  <ImageRenderer src={post.imageUrl as string} alt="" showLoader />
                </div>
              </LinkedinSinglePost>
            );
          })}
      </Container>
    </LinkedinPostsWrapper>
  );
};

export default LinkedinPosts;
