import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { NotificationsWrapper } from 'pages/notifications/Notifications.styled';
import { NotificationsContext, NotificationsContextProps } from 'contexts/NotificationsContext';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import NoNotifications from 'pages/notifications/NoNotifications';
import NotificationsList from 'pages/notifications/NotificationsList';
import { IAdvice } from 'types/app';
import { useLocation } from 'react-router';
import useAPIRelations from 'hooks/api/useAPIRelations';

const Notifications: React.FC = () => {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const pathname = location.pathname || location.location.pathname;
  const { updateAdvice } = useAPIAdvices();
  const { user } = useContext(UserContext) as UserContextProps;
  const { notifications } = useContext(NotificationsContext) as NotificationsContextProps;
  const [isLoading, setLoading] = useState(true);
  const { loadNotifications, setAdvicesRead } = useAPIAdvices();
  const { requestGroups } = useAPIRelations();
  const getNotifs = async () => {
    const result = await loadNotifications(AdviceType.ALL, user?.id || 0, false);
    await setAdvicesRead(AdviceType.ALL, user?.id || 0);

    result && setLoading(false);
  };
  const onLikeAdvice = async (advice: IAdvice, state: boolean) => {
    const result = await updateAdvice(advice, state ? 1 : 0);

    result && getNotifs();
  };

  useEffect(() => {
    if ((pathname.includes('notifications') && user) || (pathname.includes('relations') && user)) {
      getNotifs();
      requestGroups(user);
    }
  }, [pathname]);

  return (
    <PageLayout headerProps={{ title: t('notifications.title') }}>
      <NotificationsWrapper className="app-page">
        {!isLoading && (
          <>
            {notifications.length ? (
              <NotificationsList notifications={notifications} onLikeAdvice={onLikeAdvice} />
            ) : (
              <NoNotifications />
            )}
          </>
        )}
      </NotificationsWrapper>
    </PageLayout>
  );
};

export default Notifications;
