import styled from 'styled-components';

// Wrapper for the entire custom select component
const CustomSelectWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;

  .chevron-wrapper {
    position: absolute;
    bottom: 4px;
    color: var(--ion-color-accent);

    &:hover {
      cursor: pointer;
    }
  }
  
  .custom-input-container {
    position: relative;
    width: 100%;

    .placeholder {
      color: var(--ion-color-text-secondary);
      position: absolute;
      transition: all .3s linear;
      transform: translate(0, -25px);

      &.active {
        font-size: 12px;
        transform: translate(0, -45px);
      }

      .required {
        color: #eb5757;
        margin-left: 4px;
        display: inline-block;
      }
    }
  }

  .error {
    position: absolute;
    bottom: -20px;
    color: #eb5757;
    z-index: 2;
    left: 0;
    font-size: 12px;
  }
`;

// Input field styling
const CustomInput = styled.input`
  width: 100%;
  padding: 20px 0 4px;
  border: none;
  border-bottom: 1px solid var(--ion-color-accent);
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  background: transparent;
  color: var(--ion-text-color-secondary);
`;

// Dropdown list styling
const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  z-index: 3;
  box-shadow: 0 4px 8px #0000001a;
`;

// Dropdown item styling with highlighted state
const DropdownItem = styled.li<{ isSelected: boolean; isHighlighted: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ isHighlighted }) => (isHighlighted ? '#f0f0f0' : '#fff')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};

  &:hover {
    background-color: #f0f0f0;
  }
`;

export { CustomSelectWrapper, CustomInput, DropdownList, DropdownItem };
