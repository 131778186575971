import styled from 'styled-components';
import {
  slideCommonWrapper,
  SlideContentWrapper,
} from 'pages/relations/identification/RelationIdentification.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { prem } from 'styles/helpers/spacing';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const RelationManualIdentificationWrapper = styled.div`
  ${slideCommonWrapper};

  ${SlideContentWrapper} {
    .colors-selectors {
      margin-bottom: ${prem(24)};
    }

    .primary-selector {
      color: transparent;
      margin-bottom: ${prem(24)};
    }

    .secondary-selector {
      color: var(--ion-text-color-secondary);
    }

    ${IonButtonWrapper} {
      margin-bottom: ${prem(16)};
    }

    @media (${MQ_MAX_HEIGHT}) {
      justify-content: unset;
      margin-bottom: ${prem(100)}
    }
  }

  height: 100%;

  ${IonButtonWrapper} {
    margin-bottom: ${prem(30)};
  }
`;

export { RelationManualIdentificationWrapper };
