import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { PageTitle } from 'layout/page/Page.layout.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY, HEADLINE } from 'styles/helpers/typography';

const CheckoutFormWrapper = styled.form`
  max-width: ${prem(700)};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${prem(25)};

  svg {
    width: ${prem(30)};
    height: ${prem(30)};
  }

  .status {
    transition: all 0.2s linear;

    &.success {
      color: var(--ion-color-green);
    }
  }

  ${PageTitle} {
    margin: ${prem(20)} 0;
    ${HEADLINE.small}
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .space-between {
    display: flex;
    gap: ${prem(10)};
    justify-content: space-between;
    flex-basis: ${prem(30)};
  }

  .account {
    display: flex;
    flex-direction: column;
    gap: ${prem(10)};

    label {
      font-weight: bolder;
    }

    .value {
      max-width: 75%;
      text-align: end;
    }

    .account-name,
    .account-email {
      display: flex;
    }
  }

  .orders {
    .orders-content {
      font-size: ${BODY.medium};
      margin-bottom: ${prem(20)};
      display: flex;
      flex-direction: column;
      gap: ${prem(10)};

      .order {
        display: flex;

        .order-title {
          flex: 1;
        }

        .order-price {
          font-weight: bolder;
          flex: 1;
          text-align: end;
        }
      }
    }

    .orders-total {
      font-size: ${BODY.large};
      padding-top: ${prem(20)};
      border-top: 1px solid #ffffff70;
      display: flex;
      font-weight: bolder;
    }
  }

  .element {
    display: flex;
    gap: ${prem(10)};
    flex-direction: column;
    width: 100%;
    margin-bottom: ${prem(20)};

    .label-n-status {
      font-size: ${prem(16)};
      display: flex;
      align-items: center;
      gap: ${prem(10)};
      position: relative;
      width: max-content;

      label {
        display: flex;
        gap: ${prem(10)};
      }

      .status {
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
        right: ${prem(-40)};
      }
    }

    .error {
      color: var(--ion-color-red);
    }
  }

  .expiration-and-cvc {
    display: flex;
    gap: ${prem(20)};
  }

  .card-number-element,
  .card-cvc-element,
  .card-expiry-element {
    display: block;
    padding: ${prem(15)} ${prem(15)};
    width: 100%;
    border: 1px solid #ffffff70;
    border-radius: ${prem(5)};
  }

  ${IonButtonWrapper} {
    margin-top: ${prem(20)};
  }

  .error {
    color: var(--ion-color-red);
  }
`;

export { CheckoutFormWrapper };
