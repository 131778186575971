import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { SUBTITLE } from 'styles/helpers/typography';

const NotificationAdviceTitle = styled.h3`
  ${SUBTITLE.small};
  margin-bottom: ${prem(16)};
  color: var(--ion-text-color-secondary);
`;

const NotificationAdviceWrapper = styled.div`
  margin: ${prem(16)} 0;
`;

export { NotificationAdviceTitle, NotificationAdviceWrapper };
