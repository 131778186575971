import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { IonLabelWrapper } from 'components/common/form/label/FormLabel.styled';
import { IonInputStyled, IonItemWrapper } from 'components/common/form/textfield/Textfield.styled';
import { PageInnerTitle } from 'layout/page/Page.layout.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const FindTrainerWrapper = styled.div`
  ${PageInnerTitle} {
    margin-bottom: ${prem(5)};
  }

  .geolocate-me {
    max-width: ${prem(300)};
    margin: 0 auto;

    ${IonButtonWrapper} {
      display: flex;
      gap: ${prem(20)};
    }
  }
`;

const SearchByPostalCode = styled.div`
  max-width: ${prem(300)};
  width: 100%;
  margin: 0 auto 21px;
  display: flex;
  align-items: end;
  justify-content: center;
  gap: ${prem(10)};
  background: transparent;
  position: relative;

  .background-color {
    width: 100%;
    height: ${prem(50)};
    background-color: #20cc9950;
    position: absolute;
    top: ${prem(28)};
    left: 0;
    z-index: -1;
    border-radius: ${prem(50)};
  }

  .icon-search {
    width: max-content;
    display: flex;
    padding-bottom: ${prem(1)};
    cursor: pointer;
  }

  ${IonItemWrapper} {
    display: flex;

    &::part(native) {
      background: transparent;
    }

    ${IonInputStyled} {
      input.native-input {
        border-bottom: 0;
      }
    }

    ${IonLabelWrapper} {
      font-style: italic;
      padding-bottom: 8px;
    }
  }
`;

export { FindTrainerWrapper, SearchByPostalCode };
