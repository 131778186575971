import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { BODY, SUBTITLE } from 'styles/helpers/typography';

const RelationsGroupsIntro = styled.div``;
const RelationsWrapper = styled.div`
  padding: ${prem(20)} 0 ${prem(50)} 0;
`;
const PixWrapper = styled.div`
  min-width: ${prem(190)};
  max-width: ${prem(400)};
  width: 63%;
  margin: ${prem(30)} auto;
`;
const IntroInstruction = styled.div`
  text-align: center;
  color: var(--ion-text-color-secondary);
  margin-bottom: ${prem(40)};

  h3 {
    ${SUBTITLE.medium};
    font-weight: ${FONT_WEIGHT.BOLD};
    margin-bottom: ${prem(20)};

    &::first-letter {
      text-transform: uppercase;
    }
  }

  p {
    ${BODY.large};
  }
`;

const ProfileVisibilityTitle = styled.div`
  ${BODY.small}
  color: var(--ion-text-color-primary);
  margin-bottom: ${prem(10)};
  text-align: start;
`;

const ProfileVisibilityWrapper = styled.div`
  display: flex;
  gap: ${prem(10)};
  padding-left: ${prem(18)};
  align-items: start;
`;

export {
  RelationsWrapper,
  RelationsGroupsIntro,
  PixWrapper,
  IntroInstruction,
  ProfileVisibilityTitle,
  ProfileVisibilityWrapper,
};
