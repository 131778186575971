import styled, { css } from 'styled-components';
import { BODY } from 'styles/helpers/typography';
import { Container } from 'layout/page/Page.layout.styled';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';
import { prem } from 'styles/helpers/spacing';

const RelationsIdentificationWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const RelationsIdentificationSlideWrapper = styled.div`
  height: 100%;
`;
const SliderContainer = styled.div`
  flex: 1;
`;
const SlideIntroWrapper = styled.div`
  color: var(--ion-text-color-secondary);
  ${BODY.large};

  @media (${MQ_MAX_HEIGHT}) {
    margin-bottom: ${prem(16)};
  }
`;
const SlideContentWrapper = styled.div``;
const slideCommonWrapper = css`
  ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${SlideContentWrapper} {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const IsInCurrentTestModalWrapper = styled.div``;

export {
  RelationsIdentificationSlideWrapper,
  RelationsIdentificationWrapper,
  SliderContainer,
  SlideIntroWrapper,
  SlideContentWrapper,
  slideCommonWrapper,
  IsInCurrentTestModalWrapper,
};
