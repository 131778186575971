import React, { useCallback, useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { ImageToRender, ImageRendererWrapper } from 'components/common/image/ImageRenderer.styled';

export interface ImageRendererProps {
  src: string;
  alt: string;
  onLoad?: any;
  imageLoader?: React.ReactNode;
  showLoader?: boolean;
  className?: string;
  lazy?: boolean;
  onClick?: () => void;
}

const ImageRenderer: React.FC<ImageRendererProps> = ({
  src,
  alt,
  onLoad,
  imageLoader,
  showLoader,
  className,
  lazy,
  onClick,
}) => {
  const ref = useRef<HTMLImageElement>();
  const [imageIsLoaded, setImageIsLoaded] = useState<boolean>(false);
  const onLoadTriggered = useRef(false);

  const onLoadFunc = useCallback(() => {
    setImageIsLoaded(true);
    !onLoadTriggered.current && onLoad?.();
    onLoadTriggered.current = true;
  }, [onLoad]);

  useEffect(() => {
    if (ref.current) {
      ref.current.complete && onLoadFunc();
    }
  }, []);

  return (
    <ImageRendererWrapper className={className} onClick={() => onClick?.()}>
      <ImageToRender
        onLoad={onLoadFunc}
        src={src}
        alt={alt}
        loaded={showLoader ? imageIsLoaded : true}
        loading={lazy ? 'lazy' : 'eager'}
        ref={ref as any}
      />
      {!imageIsLoaded && showLoader && (
        <>
          {imageLoader ? (
            imageLoader
          ) : (
            <ContentLoader
              viewBox="0 0 260 140"
              backgroundColor={'#d3d3d3'}
              foregroundColor="#c3c3c3"
              animate={true}
            >
              <rect x="0" y="0" rx="2" ry="2" width="260" height="140" />
              {/* <rect x="0" y="150" rx="4" ry="4" width="260" height="10" />
              <rect x="0" y="165" rx="4" ry="4" width="45%" height="10" /> */}
            </ContentLoader>
          )}
        </>
      )}
    </ImageRendererWrapper>
  );
};

export default ImageRenderer;
