import React from 'react';
import { ITitleDesc } from 'types/app';
import { FaqItemDesc, FaqItemWrapper } from 'components/faqs/FaqsComponent.styled';
import { Container } from 'layout/page/Page.layout.styled';
import Collapse from 'components/common/showhide/Collapse';
import { getLocaleValue } from 'helpers/utils';

export interface FaqItemProps {
  faq: ITitleDesc;
  opened?: boolean;
  onOpened?: (open: boolean) => void;
  className?: string;
  language: string;
}

const FaqItem: React.FC<FaqItemProps> = ({ faq, opened, onOpened, className, language }) => {
  return (
    <FaqItemWrapper className="faq-item-wrapper">
      <Collapse
        label={getLocaleValue(faq.title, language)}
        opened={opened}
        onOpened={onOpened}
        className={className}
      >
        <Container padding={0} className="faq-desc-wrapper">
          <FaqItemDesc
            dangerouslySetInnerHTML={{
              __html: getLocaleValue(faq.desc, language),
            }}
          />
        </Container>
      </Collapse>
    </FaqItemWrapper>
  );
};

export default FaqItem;
