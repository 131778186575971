import { LoginFormWrapper } from 'components/forms/login-form/LoginForm.styled';
import { RegisterFormWrapper } from 'components/forms/register-form/RegisterForm.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const AuthentificationModalWrapper = styled.div`
  width: ${prem(500)};
  max-width: 85vw;
  height: ${prem(800)};
  max-height: 80vh;
  overflow: auto;

  ${LoginFormWrapper} {
    padding-top: 10%;
  }

  ${RegisterFormWrapper} {
    padding-top: 10%;
  }
`;

export { AuthentificationModalWrapper };
