import React from 'react';
import { ISummaryTOC } from 'types/app';
import {
  TOCContainer,
  TOCContent,
  TOCContentWrapper,
  TOCTitle,
} from 'pages/profile/has-profile/my-summary/MySummaryTOC.styled';
import { useTranslation } from 'react-i18next';
import ButtonTOC from 'components/common/button/ButtonTOC';

interface IMySummaryTOC {
  toc: Array<ISummaryTOC>;
  onOpen: () => void;
  isOpen?: boolean;
  onSelectEntry?: (id: string) => void;
}

const getEntries = (entries: Array<ISummaryTOC>, onClickEntry: (id: string) => void , isFirstLevel = false) => {
  if (entries.length > 0) {
    return (
      <ul>
        {
          entries.map((entry, index) => {
            return (
              <li key={`entry-${index}`} className={isFirstLevel ? 'first-level' : ''}>
                <a href={entry.anchor} onClick={(evt) => {
                  evt.preventDefault();
                  onClickEntry && onClickEntry(entry.anchor);
                }}>
                  {
                    isFirstLevel ? <strong>{ entry.label }</strong> : entry.label
                  }
                </a>
                {
                  entry.children && entry.children.length > 0 && getEntries(entry.children, onClickEntry)
                }
              </li>
            );
          })
        }
      </ul>
    );
  }

  return <></>;
};

const MySummaryTOC: React.FC<IMySummaryTOC> = (
  {
    toc,
    onOpen,
    isOpen,
    onSelectEntry
  }: IMySummaryTOC) => {
  const { t } = useTranslation('translation');
  const onEntry = (id: string) => {
    onSelectEntry && onSelectEntry(id);
    onOpen();

  };

  return (
    <TOCContainer className="toc-container" open={isOpen}>
      <TOCContentWrapper className="toc-container-wrapper" open={isOpen}>
        <ButtonTOC
          className="btn-toc"
          onClick={() => { onOpen(); }}
        />
        <TOCTitle>{t('profile.mysummary.toc')}</TOCTitle>
        <TOCContent className="toc-content">
          {
            Array.isArray(toc) && toc.length > 0 && getEntries(toc, onEntry, true)
          }
        </TOCContent>
      </TOCContentWrapper>
    </TOCContainer>
  );
};

export default MySummaryTOC;
