import React, { useContext, useEffect } from 'react';
import { MainWrapper } from 'layout/main/Main.layout.styled';
import useIcons, { ICON_NAMES, IIcon } from 'hooks/useIcons';
import MobileLayout from 'layout/main/mobile/Mobile.layout';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { useLocation } from 'react-router';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import Questionnaire from 'pages/questionnaire/Questionnaire';
import { USER_PROFILE_INFO_GROUP_TYPE } from 'hooks/api/useAPIAuth';

export interface LayoutProps {
  mainEntries: Array<IIcon>;
  moreMenu?: Array<IIcon>;
}

const MainLayout: React.FC = (): React.ReactElement => {
  const location = useLocation();
  const { user } = useContext(UserContext) as UserContextProps;
  const { updateModalConf, modalConf } = useContext(ModalContext) as ModalContextProps;
  const {
    icons: [
      home,
      profile,
      relations,
      resources,
      more,
      tools,
      faq,
      settings,
      model,
      contact,
      locate,
      // elearning
    ],
  } = useIcons([
    ICON_NAMES.HOME,
    ICON_NAMES.PROFILE,
    ICON_NAMES.RELATIONS,
    ICON_NAMES.RESOURCES,
    ICON_NAMES.MORE,
    ICON_NAMES.TOOL,
    ICON_NAMES.FAQ,
    ICON_NAMES.SETTINGS,
    ICON_NAMES.MODEL,
    ICON_NAMES.CONTACT,
    ICON_NAMES.LOCATE
  ]);
  const mainEntries = [home, profile, relations, resources, more];
  const moreMenu = [faq, model, contact, locate, settings];

  const checkGoToQuestionnaire = () => {
    return user?.profileInfo?.isToQuestionnaire && user?.profileInfo?.groupId !== 1;
  };

  const checkGoToQuestionnaireIndividualGroup = () => {
    return (
      checkGoToQuestionnaire() &&
      user?.profileInfo?.hasPayedProfile &&
      user?.profileInfo?.profilePaymentDate
    );
  };

  const checkGoToQuestionnaireNotIndividual = () => {
    return (
      checkGoToQuestionnaire() &&
      user?.profileInfo?.typeGroup !== USER_PROFILE_INFO_GROUP_TYPE.INDIVIDUAL &&
      user?.profileInfo?.hasPayedProfile &&
      user?.profileInfo?.profilePaymentDate
    );
  };

  const goToPageQuestionnaire =
    user?.profileInfo?.typeGroup === USER_PROFILE_INFO_GROUP_TYPE.INDIVIDUAL
      ? checkGoToQuestionnaireIndividualGroup()
      : checkGoToQuestionnaireNotIndividual();

  // const redirectIndividualGroupUserToPayQuestionnaire =
  //   user?.profileInfo?.isToQuestionnaire &&
  //   user?.profileInfo?.groupId !== 1 &&
  //   !user?.profileInfo.hasPayedQuestionnaire &&
  //   !user.profileInfo.questionnairePaymentDate &&
  //   user?.profileInfo?.typeGroup === USER_PROFILE_INFO_GROUP_TYPE.INDIVIDUAL;

  // Show tools if permitted for the user
  if (user?.profileInfo?.tools.digital) {
    moreMenu.splice(1, 0, tools);
  }
  // if (user?.profileInfo?.tools.lms) {
  //   moreMenu.splice(2, 0, elearning);
  // }

  // Detect location change and close modal if opened
  useEffect(() => {
    modalConf && updateModalConf();
  }, [location]);

  return (
    <MainWrapper>
      {goToPageQuestionnaire ? (
        <Questionnaire />
      ) : (
        <MobileLayout mainEntries={mainEntries} moreMenu={moreMenu} />
      )}
    </MainWrapper>
  );
};

export default MainLayout;
