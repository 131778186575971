import useAPITest from 'hooks/api/useAPITest';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, ITest, ITests, IUser } from 'types/app';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { MODULE_TEST_NAMES } from 'constants/global';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

export interface ModuleTestContextProps {
  testInfos: ITests;
  loadTestInfos: (user: IUser) => Promise<void>;
  relationManagementTest: ITest;
  relationAppointmentTest: ITest;
  hasRelationManagementTest: boolean;
  hasRelationAppointmentTest: boolean;
}

const ModuleTestContext = createContext<ModuleTestContextProps | null>(null);
const ModuleTestProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [testInfos, setTestInfos] = useState<ITests>();
  const [relationManagementTest, setRelationManagementTest] = useState<ITest>();
  const [relationAppointmentTest, setRelationAppointmentTest] = useState<ITest>();
  const [hasRelationManagementTest, setHasRelationManagementTest] = useState<boolean>(false);
  const [hasRelationAppointmentTest, setHasRelationAppointmentTest] = useState<boolean>(false);
  const { getTestInfos } = useAPITest();
  const { user } = useContext(UserContext) as UserContextProps;
  const { i18n } = useTranslation('translation');

  const loadTestInfos = async (userObj: IUser) => {
    setTestInfos(await getTestInfos(userObj?.id, `${userObj.token?.type} ${userObj.token?.value}`));
  };

  useEffect(() => {
    if (user) {
      loadTestInfos(user);
    }
  }, [user]);

  useEffect(() => {
    testInfos?.forEach((info) => {
      if (
        getLocaleValue(info.themeName, i18n.language) ===
        getLocaleValue(MODULE_TEST_NAMES.RELATION_MANAGEMENT, i18n.language)
      ) {
        setRelationManagementTest(info);
      }
      if (
        getLocaleValue(info.themeName, i18n.language) ===
        getLocaleValue(MODULE_TEST_NAMES.RELATION_APPOINTMENT, i18n.language)
      ) {
        setRelationAppointmentTest(info);
      }
    });
  }, [testInfos]);

  useEffect(() => {
    setHasRelationManagementTest(!!relationManagementTest);
    setHasRelationAppointmentTest(!!relationAppointmentTest);
  }, [relationManagementTest, relationAppointmentTest]);

  return (
    <ModuleTestContext.Provider
      value={{
        testInfos: testInfos as ITests,
        loadTestInfos: loadTestInfos,
        relationAppointmentTest: relationAppointmentTest as ITest,
        relationManagementTest: relationManagementTest as ITest,
        hasRelationAppointmentTest: hasRelationAppointmentTest,
        hasRelationManagementTest: hasRelationManagementTest,
      }}
    >
      {children}
    </ModuleTestContext.Provider>
  );
};

export { ModuleTestContext, ModuleTestProvider };
