import { Identification } from 'types/app';

export function isMale(pronoun?: string): boolean {
  if (!pronoun) {
    return false;
  }
  return pronoun.toLowerCase().includes('lui') || pronoun.toLowerCase().includes('him');
}

export function IdentificationFactory(
  _questions: any,
  _responses: any,
  _sequences: any,
): Identification.IFactory {
  let currentStep = 0;
  let currentSequences: Array<Identification.ISequence> = [];

  const itemSorter = (
    itemA: Identification.IQuestion | Identification.IResponse,
    itemB: Identification.IQuestion | Identification.IResponse,
  ) => itemA.order - itemB.order;

  // Prepare questions
  const questions = _questions.map((q: any) => {
    return {
      id: q.id,
      idLib: q.id_lib,
      type: q.type as Identification.QuestionType,
      label: q.libelle,
      order: q.ordre,
      responses: _responses
        .reduce((accu: Array<Identification.IResponse>, item: any) => {
          if (item.question_id === q.id) {
            accu.push({
              id: item.id,
              questionId: item.question_id,
              label: item.libelle,
              order: item.ordre,
            });
          }

          return accu;
        }, [] as Array<Identification.IResponse>)
        .sort(itemSorter),
    };
  });

  // Prepare sequence
  const sequences: Array<Identification.ISequence> = _sequences.map((s: any) => {
    return {
      id: s.id,
      steps: s.etapes.map((e: any) => ({
        questionId: e.question,
        responseId: e.reponse,
        index: e.index,
      })),
      primaryColorId: s.couleur_dominante,
      secondaryColorId: s.couleur_secondaire,
      unspecifiedColours: !s.couleur_determinee,
      coloursNotFound: !!s.couleur_aucune,
    };
  });

  /*
   * Get sequences according to answer:
   * Find in currentSequences all sequences which have the answer id in its steps
   * AT index currentStep
   * */
  const getSequencesByAnswer = (
    sequences: Array<Identification.ISequence>,
    answer: Identification.IResponse,
    stepIndex: number,
  ): Array<Identification.ISequence> => {
    return sequences.filter((s: Identification.ISequence) => {
      return s.steps[stepIndex].responseId === answer?.id;
    });
  };

  /*
   * Get next step
   * It will be the result OR the next step OR question
   * */
  const getNextStep = (answer?: Identification.IResponse): Identification.INextStep => {
    if (answer) {
      currentSequences = getSequencesByAnswer([...currentSequences], answer, currentStep);
      // Result
      if (currentSequences.length === 1) {
        return currentSequences[0];
      }
      // Prepare for next step
      currentStep++;
    } else {
      // Reset
      currentStep = 0;
      currentSequences = [...sequences];
      const qId = currentSequences[0].steps[0].questionId;
      const question = questions.find((q: Identification.IQuestion) => q.id === qId);

      return question || questions[0];
    }

    // If no result return the right step
    return currentSequences[0].steps[currentStep];
  };

  const getSequenceById = (id: number): Identification.ISequence => {
    return sequences.find((s) => s.id === id) || sequences[0];
  };

  return { getNextStep, questions, getSequenceById };
}
