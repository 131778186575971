import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { SUBTITLE } from 'styles/helpers/typography';
import { ColorItem } from 'components/colours-picker/ColoursPicker.styled';

const AdvicesTitle = styled.h3`
  color: var(--ion-color-highlight);
  ${SUBTITLE.small};
`;

const AdvicesWrapper = styled.div`
  margin-bottom: ${prem(40)};
`;

const MotivationDescription = styled.p`
  color: var(--ion-text-color-secondary);
  margin-bottom: ${prem(24)};
`;

const MotivationColourWrapper = styled.div`
  text-align: center;
  margin-bottom: ${prem(24)};

  ${ColorItem} {
    pointer-events: none;
    margin-bottom: ${prem(16)};
  }
`;

const MotivationHomeWrapper = styled.div`
  .subscription-button {
    text-align: center;
    margin-top: ${prem(8)};
  }
`;

export {
  AdvicesTitle,
  AdvicesWrapper,
  MotivationDescription,
  MotivationColourWrapper,
  MotivationHomeWrapper,
};
