import React from 'react';
import {
  MotivationSlideWrapper,
  MotivationSubTitle,
} from 'pages/profile/has-profile/my-motivation/Motivation.styled';
import { useTranslation } from 'react-i18next';
import {
  AdvicesTitle,
  AdvicesWrapper,
  MotivationColourWrapper,
  MotivationDescription,
  MotivationHomeWrapper,
} from 'pages/profile/has-profile/my-motivation/slides/motivation-home/MotivationHome.styled';
import { Container } from 'layout/page/Page.layout.styled';
import Button from 'components/common/button/Button';
import { getColorById } from 'helpers/colors';
import { getLocaleValue } from 'helpers/utils';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';
import { IAdvice, IUserMotivation } from 'types/app';
import Advice from 'components/advice/Advice';
import useAPIAdvices from 'hooks/api/useAPIAdvices';
import { ColorItem } from 'components/colours-picker/ColoursPicker.styled';

export interface MotivationHomeProps {
  motivation?: IUserMotivation;
  onChangeColor?: () => void;
  onSubscribe?: (state: boolean) => void;
  advices?: Array<IAdvice>;
  onChangeAdvice?: () => void;
}

const MotivationHome: React.FC<MotivationHomeProps> = ({
  motivation,
  onChangeColor,
  onSubscribe,
  advices,
  onChangeAdvice,
}) => {
  const { t, i18n } = useTranslation('translation');
  const { updateAdvice } = useAPIAdvices();
  const colour = getColorById(motivation?.colourId);
  // When an advice is liked/unliked
  const onAdviceLiked = async (advice: IAdvice, state: boolean) => {
    const result = await updateAdvice(advice, state ? 1 : 0);

    // Reload advices
    result && onChangeAdvice && onChangeAdvice();
  };

  return (
    <MotivationSlideWrapper>
      {colour && (
        <MotivationHomeWrapper>
          <Container>
            <MotivationSubTitle
              dangerouslySetInnerHTML={{
                __html: t('profile.motivation.subtitle', {
                  colourName: `${getLocaleValue(colour?.name, i18n.language)}`,
                }),
              }}
            />
            <MotivationColourWrapper>
              <ColorItem bgColor={colour?.color} />
              <GenericDiv width="60%" minWidth={prem(175)} center={true}>
                <Button color="accent" onClick={onChangeColor}>
                  {t('profile.motivation.changemotivationcolour')}
                </Button>
                <Button
                  color="highlightorange"
                  fill="outline"
                  onClick={() => {
                    onSubscribe && onSubscribe(!!motivation?.subscribed);
                  }}
                  className="subscription-button"
                >
                  {t(
                    motivation?.subscribed ? 'common.subscriber' : 'profile.motivation.isubscribe',
                  )}
                </Button>
              </GenericDiv>
            </MotivationColourWrapper>
            {motivation?.motivationText && (
              <MotivationDescription>
                {getLocaleValue(motivation.motivationText, i18n.language)}
              </MotivationDescription>
            )}
            {!!advices?.length && (
              <AdvicesWrapper>
                <AdvicesTitle>{t('profile.motivation.advicestitle')}</AdvicesTitle>
                <div>
                  {advices.map((advice, index) => {
                    return (
                      <Advice
                        key={`advice-${index}`}
                        advice={advice}
                        onLike={(state: boolean) => {
                          onAdviceLiked(advice, state);
                        }}
                      />
                    );
                  })}
                </div>
              </AdvicesWrapper>
            )}
          </Container>
        </MotivationHomeWrapper>
      )}
    </MotivationSlideWrapper>
  );
};

export default MotivationHome;
