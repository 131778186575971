import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'layout/page/Page.layout.styled';
import { RelationAppointmentProps } from 'pages/relations/appointment/RelationAppointment';
import { SlideWrapper } from 'pages/relations/appointment/RelationAppointment.styled';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { RelationAppointmentColorSelectionWrapper } from 'pages/relations/appointment/slides/relation-appointment-color-selection/RelationAppointmentColorSelection.styled';
import RelationColorSelector, {
  ColorType,
} from 'components/relations/relation/color-selector/RelationColorSelector';
import { IColor } from 'types/app';
import { getColorById } from 'helpers/colors';

const RelationAppointmentColorSelection: React.FC<RelationAppointmentProps> = ({
  onSelectColors,
  relation,
  appointment
}) => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const [userColorIdSelected, setUserColorIdSelected] = useState<number | undefined>(
    appointment?.vendorInteraction?.colourId || user?.profileInfo?.colors?.primary?.id,
  );
  const [relationColorIdSelected, setRelationColorIdSelected] = useState<number | undefined | null>(
    appointment?.buyerStyle?.colourId || relation?.primaryColorId,
  );
  const userColors = useMemo<Array<IColor>>(() => {
    if (user && user.profileInfo?.colors) {
      const colors = [user.profileInfo.colors.primary as IColor];

      if (user.profileInfo.colors.primary?.id !== user.profileInfo.colors.secondary?.id) {
        colors.push(user.profileInfo.colors.secondary as IColor);
      }

      return colors;
    }
    return [];
  }, [user]);
  const relationColors = useMemo<Array<IColor>>(() => {
    if (relation?.primaryColorId) {
      const colors = [getColorById(relation.primaryColorId) as IColor];

      if (relation?.secondaryColorId && relation?.primaryColorId !== relation?.secondaryColorId) {
        colors.push(getColorById(relation.secondaryColorId) as IColor);
      }

      return colors;
    }
    return [];
  }, [relation]);

  return (
    <SlideWrapper>
      <RelationAppointmentColorSelectionWrapper className="appointment-color-selection-wrapper">
        <Container>
          <div className="colors-selectors">
            <RelationColorSelector
              onSelect={(id) => setUserColorIdSelected(id)}
              className="primary-selector"
              colorLabel={t('relations.appointment.choose-your-color')}
              type={ColorType.PRIMARY}
              defaultColorId={userColorIdSelected}
              colors={userColors}
            />

            <RelationColorSelector
              onSelect={(id) => setRelationColorIdSelected(id)}
              className="secondary-selector"
              colorLabel={t('relations.appointment.choose-relation-color', {
                relationFullName: relation?.fullName,
              })}
              type={ColorType.SECONDARY}
              defaultColorId={relationColorIdSelected}
              colors={relationColors}
            />
          </div>
          <GenericDiv className="btn-quiz" width="25%" minWidth={prem(200)} center={true}>
            <Button
              color="accent"
              expand={true}
              onClick={() => {
                if (userColorIdSelected && relationColorIdSelected) {
                  onSelectColors && onSelectColors(userColorIdSelected, relationColorIdSelected);
                }
              }}
            >
              {t('relations.appointment.use-those-colours')}
            </Button>
          </GenericDiv>
        </Container>
      </RelationAppointmentColorSelectionWrapper>
    </SlideWrapper>
  );
};

export default RelationAppointmentColorSelection;
