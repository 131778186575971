import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { FONT_WEIGHT } from 'styles/constants/fonts';

const ToggleSlider = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${prem(6)};
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--comcolors-toggle-background);
  transition: 0.4s;
  border-radius: ${prem(20)};

  &::before {
    position: absolute;
    content: '';
    height: ${prem(11)};
    width: ${prem(11)};
    left: ${prem(6)};
    bottom: ${prem(4)};
    border: 1px solid var(--ion-text-color);
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const ToggleLabel = css`
  color: var(--ion-text-color);
  font-size: ${prem(14)};
  font-weight: ${FONT_WEIGHT.BOLD};
  transition: 0.3s;
`;

const ToggleLabelOn = styled.span`
  ${ToggleLabel};
  opacity: 0;
`;

const ToggleLabelOff = styled.span`
  ${ToggleLabel};
`;

const ToggleLabelWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: ${prem(60)};
  height: ${prem(22)};

  input {
    display: none;
  }

  input:checked + ${ToggleSlider}::before {
    transform: translateX(${prem(36)});
    background: var(--comcolors-toggle-thumb-background);
    border-color: var(--comcolors-toggle-thumb-background);
  }

  /* stylelint-disable */
  input:checked + ${ToggleSlider} ${ToggleLabelOn} {
    opacity: 1;
  }

  input:checked + ${ToggleSlider} ${ToggleLabelOff} {
    opacity: 0;
  }
  /* stylelint-enable */

  &.disable-action {
    ${ToggleLabelOn} {
      visibility: hidden;
    }
  }
`;

export { ToggleLabelWrapper, ToggleSlider, ToggleLabelOn, ToggleLabelOff };
