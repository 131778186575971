import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { IonInputWrapper } from 'components/common/form/textfield/Textfield.styled';
import { BREAKPOINTS } from 'styles/constants/devices';

const RelationGroupEditWrapper = styled.div`
  width: 100%;

  @media (${BREAKPOINTS.M}) {
    width: ${prem(500)};
  }

  ${IonInputWrapper} {
    margin-bottom: ${prem(24)};
  }

  ion-item {
    --background: transparent;
    --color: white;
  }

  ion-label {
    span {
      color: white;
    }
  }

  ion-note {
    color: white;
  }
`;

export { RelationGroupEditWrapper };
