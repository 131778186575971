import React, { useEffect } from 'react';
import { TrainingListWrapper } from 'components/resources/training/traininglist/TrainingList.styled';
import { IResourceTrainings } from 'types/app';
import SingleTraining from 'components/resources/training/singletraining/SingleTraining';
import useCollapse from 'hooks/useCollapse';

export interface ITrainingList {
  trainings: IResourceTrainings;
  defaultOpenedId?: number | string;
}

const TrainingList: React.FC<ITrainingList> = ({ trainings, defaultOpenedId }) => {
  const { getOpenState, updateStates } = useCollapse();

  useEffect(() => {
    if (trainings.trainings) {
      const collapsesIds = trainings.trainings.map((c, i) => ({
        id: `${i}`,
        opened: defaultOpenedId !== undefined ? i === defaultOpenedId : false,
      }));
      updateStates('', false, collapsesIds);
    }
  }, [trainings]);

  return (
    <TrainingListWrapper>
      {trainings &&
        trainings.trainings &&
        trainings.trainings.map((training, key) => {
          const id = `${key}`;
          return (
            <SingleTraining
              opened={getOpenState(id)}
              onOpened={(o) => updateStates(id, o)}
              training={training}
              key={`training-${key}`}
              className="single-resource-training-container"
            />
          );
        })}
    </TrainingListWrapper>
  );
};

export default TrainingList;
