import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { IGenericObjectString, IMySummaryProfileSchemaExplanation, ISummary, ISummaryTOC } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { IHttpResponse } from 'helpers/api';
import { useTranslation } from 'react-i18next';
import { getId, slugifyStr } from 'helpers/utils';
// import BilanJson from 'bilan.json';

export interface IUseAPISummary {
  getSummaryDetail: (userId: number, isInBackground?: boolean) => Promise<ISummary | undefined>;
}

const svgRole = '<svg viewBox="0 0 80.7 60" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path fill="currentColor" d="M62.1,2.5c-3.6,0.9-6.4,4.5-6.4,8c0,1.1,0.3,2.3,0.8,3.4c0.3,0.6,0.5,1,0.5,1.1c0,0.1-0.1,0.1-0.3,0.1\n' +
  '\tc-0.5,0-1.7,0.4-2.5,0.7c-2.5,1.2-4.4,3.5-5,5.9c-0.2,0.8-0.2,1.3-0.2,6.4c0,4.7,0,5.6,0.2,6.2c0.8,2.6,3.9,4.3,6.1,3.5l0.3-0.1\n' +
  '\tl0,8.1l0,8.1l0.4,0.8c0.8,1.7,2.5,2.8,4.1,2.9c1.1,0.1,2.2-0.3,3.1-0.8l0.6-0.4l0.6,0.4c1.6,1.2,3.5,1.1,5.3,0\n' +
  '\tc0.8-0.5,1.6-1.5,2-2.3l0.3-0.7l0-8l0-8l0.7,0.2c0.5,0.1,0.8,0.1,1.5,0c1.7-0.2,3.2-1.3,3.9-2.9l0.4-0.8l0-5.8c0-6.3,0-6.5-0.7-8.1\n' +
  '\tc-1.1-2.6-3.9-4.8-6.4-5.3L70.8,15l0.4-0.8c0.5-1.1,0.8-2,0.8-3.3c0.1-1.4-0.2-2.5-0.9-3.9c-1-1.9-2.9-3.7-5-4.4\n' +
  '\tC65.2,2.3,63.1,2.2,62.1,2.5z M65.9,6c2,0.8,3.2,3.1,2.8,5.5c-0.2,1.2-0.6,2-1.5,2.7c-1.7,1.5-1.6,1.4-1.6,2.3c0,0.7,0,0.9,0.3,1.2\n' +
  '\tc0.5,0.6,0.7,0.7,3.2,0.7l2.4,0.1l0.9,0.4c1.5,0.7,2.5,2,2.8,3.6c0.1,0.4,0.1,2.6,0.1,6.1l0,5.5L75,34.4c-0.3,0.3-0.4,0.3-1.3,0.3\n' +
  '\tc-0.9,0-1,0-1.3-0.3l-0.3-0.3L72,30c-0.1-4.6-0.1-4.5-0.9-5c-0.7-0.4-1.5-0.3-2.1,0.4l-0.3,0.3l0,13.9c-0.1,13.7-0.1,13.9-0.3,14.2\n' +
  '\tc-0.7,0.8-2.5,0.8-2.8,0c-0.1-0.1-0.1-3.6-0.2-7.6l-0.1-7.4l-0.4-0.4c-0.4-0.4-0.5-0.4-1.1-0.4c-0.7,0-0.7,0-1.1,0.4l-0.4,0.4\n' +
  '\tl-0.1,7.4c0,4.1-0.1,7.5-0.2,7.6c-0.4,0.8-2.6,0.8-3,0c-0.1-0.1-0.1-6.5-0.2-14.2l-0.1-14l-0.3-0.3c-0.6-0.7-1.4-0.8-2.1-0.4\n' +
  '\tc-0.8,0.5-0.8,0.4-0.9,5c-0.1,4.1-0.1,4.1-0.3,4.4c-0.2,0.2-0.5,0.3-0.9,0.4c-1.1,0.2-1.8-0.2-2-1c0.1-0.2,0.1-2.7,0.1-5.6\n' +
  '\tc0-4.4,0-5.4,0.2-5.9c0.4-1.5,1.4-2.6,2.7-3.3l0.9-0.4l2.4-0.1c2.6-0.1,2.7-0.1,3.2-0.7c0.3-0.3,0.3-0.5,0.3-1.2\n' +
  '\tc0-0.9-0.1-1.2-0.7-1.7c-2.4-1.9-3-4.1-1.9-6.6c0.3-0.7,1.2-1.7,1.9-2C62.5,5.5,64.6,5.4,65.9,6z"/>\n' +
  '<path fill="#847C6E" d="M32.5,8.9c-2.8,0.7-4.9,3.4-4.9,6.2c0,0.9,0.2,1.8,0.6,2.6c0.2,0.4,0.4,0.8,0.4,0.8c0,0.1-0.1,0.1-0.2,0.1\n' +
  '\tc-0.4,0-1.3,0.3-1.9,0.6c-1.9,1-3.4,2.7-3.8,4.5c-0.1,0.6-0.2,1-0.2,4.9c0,3.6,0,4.3,0.1,4.7c0.6,2,3,3.3,4.7,2.7l0.2-0.1l0,6.2\n' +
  '\tl0,6.2l0.3,0.6c0.6,1.3,1.9,2.2,3.1,2.2c0.8,0.1,1.7-0.2,2.4-0.6l0.4-0.3l0.4,0.3c1.3,0.9,2.7,0.9,4.1,0c0.6-0.4,1.3-1.1,1.5-1.7\n' +
  '\tl0.2-0.5l0-6.2l0-6.2l0.5,0.1c0.4,0.1,0.6,0.1,1.2,0c1.3-0.2,2.4-1,3-2.2l0.3-0.6l0-4.4c0-4.9,0-5-0.5-6.2c-0.9-2-3-3.7-4.9-4\n' +
  '\tl-0.6-0.1l0.3-0.6c0.4-0.8,0.6-1.5,0.6-2.5c0.1-1.1-0.1-1.9-0.7-3c-0.7-1.5-2.2-2.8-3.8-3.3C34.9,8.8,33.2,8.7,32.5,8.9z M35.4,11.6\n' +
  '\tc1.5,0.6,2.4,2.4,2.2,4.2c-0.1,1-0.4,1.5-1.1,2.1c-1.3,1.2-1.3,1.1-1.3,1.8c0,0.6,0,0.7,0.2,0.9c0.4,0.5,0.5,0.5,2.5,0.6l1.9,0.1\n' +
  '\tl0.7,0.3c1.1,0.6,1.9,1.5,2.1,2.7c0.1,0.3,0.1,2,0.1,4.7l0,4.2l-0.2,0.2c-0.2,0.2-0.3,0.2-1,0.2c-0.7,0-0.8,0-1-0.2l-0.3-0.2\n' +
  '\tL40.1,30c-0.1-3.5-0.1-3.5-0.7-3.8c-0.5-0.3-1.1-0.2-1.6,0.3l-0.2,0.3l0,10.6c-0.1,10.5-0.1,10.7-0.2,10.9c-0.5,0.6-1.9,0.6-2.2,0\n' +
  '\tc-0.1-0.1-0.1-2.7-0.1-5.8L35,36.7l-0.3-0.3c-0.3-0.3-0.4-0.3-0.9-0.3c-0.5,0-0.6,0-0.9,0.3l-0.3,0.3l-0.1,5.7\n' +
  '\tc0,3.1-0.1,5.8-0.1,5.8c-0.3,0.6-2,0.6-2.3,0c-0.1-0.1-0.1-5-0.1-10.9l-0.1-10.7l-0.2-0.3c-0.5-0.5-1.1-0.6-1.6-0.3\n' +
  '\tc-0.6,0.4-0.6,0.3-0.7,3.8c-0.1,3.1-0.1,3.2-0.3,3.4c-0.1,0.1-0.4,0.2-0.7,0.3c-0.9,0.1-1.4-0.1-1.5-0.8c0.1-0.2,0.1-2.1,0.1-4.3\n' +
  '\tc0-3.3,0-4.1,0.1-4.5c0.3-1.1,1-2,2.1-2.6l0.7-0.3l1.9-0.1c2-0.1,2.1-0.1,2.5-0.6c0.2-0.3,0.2-0.4,0.2-0.9c0-0.7-0.1-0.9-0.5-1.3\n' +
  '\tc-1.8-1.4-2.3-3.2-1.5-5.1c0.3-0.6,0.9-1.3,1.5-1.6C32.8,11.2,34.4,11.2,35.4,11.6z"/>\n' +
  '<path fill="#847C6E" d="M9.4,15.3c-2,0.5-3.5,2.4-3.5,4.3c0,0.6,0.2,1.2,0.5,1.8C6.4,21.7,6.6,22,6.6,22c0,0-0.1,0.1-0.2,0.1\n' +
  '\tc-0.3,0-0.9,0.2-1.4,0.4c-1.4,0.7-2.4,1.9-2.8,3.2C2.2,26,2.2,26.3,2.2,29c0,2.5,0,3,0.1,3.3c0.4,1.4,2.1,2.3,3.4,1.9l0.2,0l0,4.3\n' +
  '\tl0,4.3l0.2,0.4c0.5,0.9,1.4,1.5,2.3,1.5c0.6,0,1.2-0.1,1.7-0.4l0.3-0.2l0.3,0.2c0.9,0.6,1.9,0.6,2.9,0c0.5-0.3,0.9-0.8,1.1-1.2\n' +
  '\tl0.2-0.3l0-4.3l0-4.3l0.4,0.1c0.3,0.1,0.5,0.1,0.9,0c1-0.1,1.7-0.7,2.2-1.6l0.2-0.4l0-3.1c0-3.4,0-3.5-0.4-4.3\n' +
  '\tc-0.6-1.4-2.1-2.6-3.5-2.8L14.2,22l0.2-0.5c0.3-0.6,0.4-1.1,0.5-1.8c0.1-0.8-0.1-1.3-0.5-2.1c-0.5-1-1.6-2-2.7-2.3\n' +
  '\tC11.1,15.2,9.9,15.2,9.4,15.3z M11.5,17.2c1.1,0.4,1.7,1.6,1.5,2.9c-0.1,0.7-0.3,1.1-0.8,1.5c-1,0.8-0.9,0.7-0.9,1.2\n' +
  '\tc0,0.4,0,0.5,0.2,0.6c0.3,0.3,0.4,0.4,1.8,0.4l1.3,0l0.5,0.2c0.8,0.4,1.4,1.1,1.5,1.9c0.1,0.2,0.1,1.4,0.1,3.3l0,2.9l-0.2,0.2\n' +
  '\tc-0.2,0.1-0.2,0.2-0.7,0.2c-0.5,0-0.6,0-0.7-0.2l-0.2-0.1l0-2.2c0-2.4,0-2.4-0.5-2.7c-0.4-0.2-0.8-0.1-1.1,0.2l-0.2,0.2l0,7.4\n' +
  '\tc0,7.3,0,7.4-0.2,7.6c-0.4,0.4-1.4,0.4-1.6,0c0-0.1-0.1-1.9-0.1-4.1l0-4l-0.2-0.2c-0.2-0.2-0.3-0.2-0.6-0.2c-0.4,0-0.4,0-0.6,0.2\n' +
  '\tl-0.2,0.2l0,4c0,2.2-0.1,4-0.1,4.1c-0.2,0.4-1.4,0.4-1.6,0c0-0.1-0.1-3.5-0.1-7.6l0-7.5l-0.2-0.2c-0.3-0.4-0.8-0.4-1.1-0.2\n' +
  '\tc-0.4,0.3-0.4,0.2-0.5,2.7c0,2.2,0,2.2-0.2,2.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.6,0.1-1-0.1-1.1-0.5c0.1-0.1,0-1.5,0-3\n' +
  '\tc0-2.3,0-2.9,0.1-3.2c0.2-0.8,0.7-1.4,1.5-1.8l0.5-0.2l1.3,0c1.4,0,1.5,0,1.8-0.4c0.2-0.2,0.2-0.2,0.2-0.7c0-0.5-0.1-0.6-0.4-0.9\n' +
  '\tc-1.3-1-1.7-2.2-1.1-3.5c0.2-0.4,0.7-0.9,1-1.1C9.6,17,10.7,16.9,11.5,17.2z"/>\n' +
  '</svg>';

function useAPISummary(): IUseAPISummary {
  const { t } = useTranslation('translation');
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  // Cleaning
  const cleanHTML = (htmlString: string): string => {
    const tempEl = document.createElement('div');

    tempEl.innerHTML = htmlString;

    // Remove direct text node
    tempEl.childNodes.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.parentNode) {
          node.parentNode?.removeChild(node);
        } else {
          try {
            node.remove();
          } catch (e: unknown) {
            console.log('e: ', (e as Error).message);
          }
        }
      }
    });

    tempEl.querySelectorAll('div, p, h2, h3, h4, span').forEach(pEl => {
      const hasStyleAttr = pEl.hasAttribute('style');
      const content = pEl.innerHTML.trim();

      // Remove starting point .
      if (content.startsWith('.')) {
        pEl.innerHTML = content.substring(1);
      }

      // Remove 3.x. for h2 etc
      if (pEl.nodeName.toLowerCase().startsWith('h') && content.startsWith('3.')) {
        let titleText = content.substring(5);
        titleText = titleText.endsWith(':') ? titleText.substring(0, titleText.length - 1) : titleText;
        pEl.innerHTML = titleText;
      }

      // If element empty && has no classes
      if ((content === '' || content === '&nbsp;') && !pEl.getAttribute('class')) {
        if (pEl.parentNode) {
          pEl.parentNode?.removeChild(pEl);
        } else {
          try {
            pEl.remove();
          } catch (e: unknown) {
            console.log('e: ', (e as Error).message);
          }
        }
      }

      // Remove style with justify & padding
      // pEl.removeAttribute('style');
      pEl.removeAttribute('align');

      // if found font-size, remove
      if (hasStyleAttr) {
        const styleValues = pEl.getAttribute('style');
        if (styleValues) {
          const filteredStyles = styleValues.split(';').filter(styleStr => {
            return !styleStr.includes('font') && !styleStr.includes('padding') && !styleStr.includes('text-align');
          }).join(';').trim();

          if (filteredStyles) {
            pEl.setAttribute('style', filteredStyles);
          } else {
            pEl.removeAttribute('style');
          }
        }
      }
    });

    return tempEl.innerHTML;
  };

  // Replace all shortcodes with their values
  const changeShortCodes = (htmlString: string, shortCodes: { [key: string]: string } = {}): string => {
    let result = htmlString;

    for (const key of Object.keys(shortCodes)) {
      result = result.replaceAll(key, shortCodes[key]);
    }

    return result;
  };

  const formatPrimaryColors = (htmlString: string): string => {
    const svgColor = '' +
      '<svg viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<circle class="p-color" cx="18.5" cy="18.5" r="15.5" stroke-width="6"/>\n' +
      '<circle cx="18.5" cy="18.4999" r="6.45349" fill="#D9D9D9"/>\n' +
      '</svg>';
    const targetElementClass = 'present-color present-';
    const tempEl = document.createElement('div');
    const primaryColorsElements = [] as Array<{
      id: string;
      body: HTMLDivElement
    }>;
    const makeWrapper = (colorClass: string, elImg: HTMLDivElement, order:  number) => {
      const w = document.createElement('div');
      const colorImg = document.createElement('div');
      const colorText = document.createElement('div');
      w.classList.add('primary-color-block');
      w.classList.add(`pcb-${order}`);
      colorImg.classList.add('color-img');
      colorImg.classList.add(colorClass);
      colorText.classList.add('color-text');
      w.appendChild(colorImg);
      w.appendChild(colorText);
      colorImg.appendChild(elImg);

      return w;
    };

    let primaryIndex = 0;
    tempEl.innerHTML = htmlString;

    tempEl.querySelectorAll(':scope > *').forEach(element => {
      const elClass = element.getAttribute('class');

      // If <div className="present-color present-rouge"></div> is found
      // create wrapper and move this el into wrapper
      if (elClass?.includes(targetElementClass)) {
        const classColor = elClass.split(' ');
        // Add marker to make replaceWith after
        const marker = document.createElement('div');
        const id = `color-${primaryIndex}`;

        marker.classList.add(id);
        tempEl.insertBefore(marker, element);
        element.innerHTML = svgColor;
        primaryColorsElements.push(
          {
            id,
            body: makeWrapper(classColor[1], element as HTMLDivElement, primaryIndex)
          }
        );
        primaryIndex += 1;
      }

      //
      if (element.nodeName.toLowerCase() === 'p') {
        // Insert color description into last primaryColorsElements element
        const l = primaryColorsElements.length;
        if (l) {
          const currentPCE = primaryColorsElements[l - 1];
          const span = element.querySelector('span');

          // Find color code & add it to body
          span && span.style.color && currentPCE.body.style.setProperty('--pcolor', span.style.color);
          //
          currentPCE.body.querySelector('.color-text')?.appendChild(element);
        }
      }
    });

    // Make replace with in tempEl
    if (primaryColorsElements.length) {
      tempEl.querySelectorAll('div[class*="color-"]').forEach(element => {
        for (const wrapper of primaryColorsElements) {
          if (wrapper.id === element.getAttribute('class')) {
            element.replaceWith(wrapper.body);
          }
        }
      });
    }

    return tempEl.innerHTML;
  };

  // Wrap roles
  const formatRolesColors = (htmlString: string) => {
    const tempEl = document.createElement('div');
    const makeWrapper = (roleClass: string) => {
      const w = document.createElement('div');
      const roleImg = document.createElement('div');
      const roleText = document.createElement('div');

      w.classList.add('role-color-block');
      w.classList.add(roleClass);
      roleImg.classList.add('role-img');
      roleText.classList.add('role-text');
      roleImg.innerHTML = svgRole;
      w.appendChild(roleImg);
      w.appendChild(roleText);

      return {
        mainWrapper: w,
        textWrapper: roleText
      };
    };

    tempEl.innerHTML = htmlString;
    tempEl.querySelectorAll('div[class*="role role-"]').forEach(element => {
      const elClass = `${element.getAttribute('class')}`;
      const classColor = elClass.split(' ');
      const { mainWrapper, textWrapper,  } = makeWrapper(classColor[1]);

      while(element.nextElementSibling?.nodeName.toLowerCase() === 'p') {
        const span = element.nextElementSibling.querySelector('span');
        span?.style.color && mainWrapper.style.setProperty('--role-color', span?.style.color);
        textWrapper.appendChild(element.nextElementSibling);
      }

      element.replaceWith(mainWrapper);
    });

    return tempEl.innerHTML;
  };

  // Set diagrams value into img tag
  const getDiagramsImages = (shortCodes: IGenericObjectString): IGenericObjectString => {
    const cp = { ...shortCodes };

    for (const key of Object.keys(shortCodes)) {
      cp[key] = `<img class="diagram" data-diagram-id="${key}" src="${shortCodes[key]}" alt="" />`;
    }

    return cp;
  };

  // Insert Introduction & Result title
  const insertIntroResult = (htmlString: string): string => {
    const resultElStart = '<h2 class="toc">';
    const hasTOC = htmlString.includes(resultElStart);
    const introWrapperStart = hasTOC ? '<div class="block-introduction">' : '';
    const wrapperEnd = hasTOC ? '</div>' : '';
    const resultWrapperStart = hasTOC ? '<div class="line-separator"></div><div class="block-results">' : '';
    const introEl = `<h1>${t('profile.mysummary.bodyIntroduction')}</h1>`;
    const resultEl = `<h1>${t('profile.mysummary.bodyResults')}</h1>`;

    // Mount
    return `
      ${introWrapperStart}
      ${introEl}
      ${htmlString.replace(resultElStart, `${wrapperEnd}${resultWrapperStart}${resultEl}${resultElStart}`)}
      ${wrapperEnd}
    `;
  };

  // Replace schema table with React component
  const replaceSchemaTable = (htmlString: string) => {
    const schemaData: IMySummaryProfileSchemaExplanation = {};
    const tmpWrapper = document.createElement('div');
    tmpWrapper.innerHTML = htmlString;

    // Remove schema image
    tmpWrapper.querySelector('.schema-diagram')?.remove();
    const tableElWrapper = tmpWrapper.querySelector('.schema-table');

    if (tableElWrapper) {
      const table = tableElWrapper.querySelector('table');
      table?.querySelectorAll('td').forEach(td => {
        if (td.classList.contains('schema-table-cell-1')) schemaData.cell1 = `${td.innerHTML}`;
        if (td.classList.contains('schema-table-cell-2')) schemaData.cell2 = `${td.innerHTML}`;
        if (td.classList.contains('schema-table-cell-3')) schemaData.cell3 = `${td.innerHTML}`;
        if (td.classList.contains('schema-table-cell-4')) schemaData.cell4 = `${td.innerHTML}`;
        if (td.classList.contains('schema-table-cell-5')) schemaData.cell5 = `${td.innerHTML}`;
        if (td.classList.contains('schema-table-cell-6')) schemaData.cell6 = `${td.innerHTML}`;
      });

      tableElWrapper.innerHTML = '';
    }

    return {
      html: tmpWrapper.innerHTML,
      schemaData
    };
  };

  // Build TOC & cleaning stuff
  const getTOC = (htmlString: string): { html: string; toc: Array<ISummaryTOC> } => {
    const tmpWrapper = document.createElement('div');
    const toc: Array<ISummaryTOC> = [];
    const getTitleLevel = (position: number): string => {
      return Array.from(new Array(position).fill('I')).join('');
    };
    // let titleCount = 0;

    tmpWrapper.innerHTML = htmlString;

    // Remove TOC & TOC body <div class="tocbody">
    tmpWrapper.querySelector('h2.toc')?.remove();
    tmpWrapper.querySelector('div.tocbody')?.remove();

    // Remove numcircle
    tmpWrapper.querySelectorAll('div.numcircle').forEach(el => el.remove());

    // Select children: presumed as block-introduction & block-results
    let currentTitleLevel = '';
    let h1Count = 0;
    tmpWrapper.querySelectorAll(':scope > *').forEach(el => {
      /* let h2Count = 0;
      let h3Count = 0; */

      const tocStart = {} as ISummaryTOC;

      el.querySelectorAll(':scope > h1, :scope > h2, :scope > h3').forEach(innerEl => {
        // console.log('el.nodeName: ', innerEl.nodeName, ', the node : ', innerEl);
        /*
        * h1Count += 1;
            currentTitleLevel = getTitleLevel(h1Count);
            innerEl.innerHTML = `${currentTitleLevel}. ${innerEl.textContent}`;
        * */
        let h1Text = '';
        let idH1 = '';
        let idH2 = '';
        let idH3 = '';

        switch (innerEl.nodeName.toLowerCase()) {
          case 'h1':
            h1Count += 1;
            currentTitleLevel = getTitleLevel(h1Count);
            h1Text = `${currentTitleLevel}. ${innerEl.textContent}`;
            innerEl.innerHTML = h1Text;
            idH1 = `_${slugifyStr(h1Text)}`;
            innerEl.setAttribute('id', idH1);
            tocStart.anchor = `#${idH1}`;
            tocStart.label = h1Text;
            tocStart.children = [];
            break;
          case 'h2':
            tocStart.children = tocStart.children || [];
            idH2 = `_${slugifyStr(innerEl.textContent)}`;
            innerEl.setAttribute('id', idH2);
            tocStart.children.push({
              label: `${innerEl.textContent}`,
              anchor: `#${idH2}`,
              children: []
            });
            break;
          case 'h3':
            // Get last h2
            if (tocStart.children?.length) {
              idH3 = `_${slugifyStr(innerEl.textContent)}`;
              innerEl.setAttribute('id', idH3);
              tocStart.children[tocStart.children.length - 1].children = tocStart.children[tocStart.children.length - 1].children || [];
              tocStart.children[tocStart.children.length - 1].children?.push(
                {
                  label: `${innerEl.textContent}`,
                  anchor: `#${idH3}`,
                }
              );
            }
        }
      });

      tocStart.label && toc.push({ ...tocStart });
    });

    return {
      toc,
      html: tmpWrapper.innerHTML
    };
  };

  const getReadMoreEl = (blockId: string) => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('read-more-placeholder');
    wrapper.setAttribute('data-block-id', blockId);

    return wrapper;
  };

  // Format personality traits
  const createColorBlocks = (htmlString: string) => {
    const palette = {
      'rouge': '#E2001A',
      'violette': '#A664A1',
      'orange': '#FD6E41',
      'bleue': '#006AB3',
      'jaune': '#FCC521',
      'verte': '#2D8F2E'
    } as IGenericObjectString;
    const imgColorTitle = '<svg width="49" height="37" viewBox="0 0 49 37" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<circle cx="18.5" cy="18.5" r="15.5" stroke="currentColor" stroke-width="6"/>\n' +
      '<circle cx="18.5" cy="18.4999" r="6.45349" fill="#D9D9D9"/>\n' +
      '<line x1="36" y1="23.5" x2="49" y2="23.5" stroke="currentColor"/>\n' +
      '</svg>';
    const imgArrow = '<svg width="75" height="41" viewBox="0 0 75 41" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<rect y="11.334" width="57.5581" height="18" fill="currentColor"/>\n' +
      '<path d="M75 20.3333L48.4012 37.9425L48.4012 2.72415L75 20.3333Z" fill="currentColor"/>\n' +
      '</svg>';
    const tmpWrapper = document.createElement('div');
    tmpWrapper.innerHTML = htmlString;

    // Color titles
    const colorsElements = tmpWrapper.querySelectorAll(':scope > .primary-color, :scope > .secondary-color');

    if (colorsElements.length) {
      colorsElements.forEach(cEl => {
        const titleTextEl = cEl.nextElementSibling;
        // If title is followed by p
        // wrap
        if (titleTextEl?.nodeName.toLowerCase() === 'p') {
          const span = titleTextEl.querySelector('span');
          const titleWrapperEl = document.createElement('div');

          cEl.innerHTML= imgColorTitle;
          titleWrapperEl.classList.add('color-title-block');
          titleWrapperEl.style.setProperty('--color-title', `${span?.style.color}`);
          tmpWrapper.insertBefore(titleWrapperEl, cEl);
          titleWrapperEl.appendChild(cEl);
          titleWrapperEl.appendChild(titleTextEl);
        }
      });
    }

    // To remember
    const toRememberElements = tmpWrapper.querySelectorAll(':scope > div[class*="arrow arrow-"]');
    toRememberElements.forEach(trEl => {
      const nextEl = trEl.nextElementSibling;

      if (nextEl?.nodeName.toLowerCase() === 'p') {
        const blockId = `b-${getId().toLowerCase()}`;
        const colorCode = palette[`${trEl.getAttribute('class')?.split(' ')[1].split('-')[1]}`];
        const wrapperEl = document.createElement('div');
        const titleWrapperEl = document.createElement('div');
        const arrowEl = document.createElement('div');
        const titleEl = document.createElement('div');
        const contentEl = document.createElement('div');
        const rememberEl = document.createElement('strong');
        let currentContentP: Element | null = nextEl.nextElementSibling;

        wrapperEl.classList.add('to-remember-block');
        wrapperEl.setAttribute('id', blockId);
        titleWrapperEl.classList.add('to-remember-title-wrapper');
        titleEl.classList.add('to-remember-title');
        arrowEl.classList.add('to-remember-title-arrow');
        contentEl.classList.add('to-remember-content');
        rememberEl.classList.add('remember-label');
        rememberEl.innerHTML = `${trEl.textContent} : `;

        if (colorCode) {
          wrapperEl.style.setProperty('--remember-color', colorCode);
          rememberEl.style.setProperty('--remember-color', colorCode);
        }

        // While p is the next sibling of p
        while(currentContentP && currentContentP.nodeName.toLowerCase() === 'p') {
          contentEl.appendChild(currentContentP);
          currentContentP = nextEl.nextElementSibling;
        }
        // Read more
        contentEl.appendChild(getReadMoreEl(blockId));

        // --- Block title
        nextEl.insertBefore(rememberEl, nextEl.firstChild);
        titleEl.appendChild(nextEl);
        titleWrapperEl.appendChild(arrowEl);
        titleWrapperEl.appendChild(titleEl);
        wrapperEl.appendChild(titleWrapperEl);
        wrapperEl.appendChild(contentEl);

        // Insert
        trEl.innerHTML = `${imgArrow}<div class="label">${trEl.textContent}</div>`;
        tmpWrapper.insertBefore(wrapperEl, trEl);
        arrowEl.appendChild(trEl);
      }
    });


    return tmpWrapper.innerHTML;
  };

  // Change diagrams wrapper if <p>
  const changeDiagramsWrapper = (htmlString: string) => {
    const tempEl = document.createElement('div');
    tempEl.innerHTML = htmlString;

    tempEl.querySelectorAll('img[data-diagram-id]').forEach(imgEl => {
      const parent = imgEl.parentElement;

      if (parent?.nodeName === 'P') {
        const id = `${imgEl.getAttribute('data-diagram-id')}`;
        const src = `${imgEl.getAttribute('src')}`;

        parent.setAttribute('data-diagram-id', id);
        parent.setAttribute('data-diagram-src', src);
        parent.innerHTML = id;
      }
    });

    // console.log('changeDiagramsWrapper: ', tempEl.innerHTML);

    return tempEl.innerHTML;
  };

  // Parse
  const parseSummary = (raw: any): ISummary | undefined => {
    const fYear = new Date().getFullYear();
    const shortCodes = {
      ...raw.textes_shortcode,
      sautdepage: '',
      '#year#': fYear,
      'ComColors Digital': 'ComColors&nbsp;Digital'
    };
    const textHTML = {} as { [key: string]: string };
    let textCopyright: { [key: string]: string } = {};
    let textEditedBy: { [key: string]: string } = {};
    const schemaData: { [key: string]: IMySummaryProfileSchemaExplanation } = {};
    const resultTOC = {} as { [key: string]: Array<ISummaryTOC> };

    if (raw.textes_html) {
      for (const tKey of Object.keys(raw.textes_html)) {
        const diagrammes = raw.diagrammes ? getDiagramsImages(raw.diagrammes[tKey]) : {};
        const noShortcodes = raw.textes_html[tKey]
          .map((t: string) => changeShortCodes(t, { ...shortCodes, ...diagrammes }));
        const diagramsWrapped = noShortcodes.map((t: string) => changeDiagramsWrapper(t));
        const formattedPrimaryColors = diagramsWrapped.map((t: string) => formatPrimaryColors(t));
        const formattedRolesColors = formattedPrimaryColors.map((t: string) => formatRolesColors(t));
        const outputClean = formattedRolesColors.map((t: string) => cleanHTML(t));
        const formattedPersonalityTraits = outputClean.map((t: string) => createColorBlocks(t)).join('');
        const outputWithHeadings = insertIntroResult(formattedPersonalityTraits);
        const outputWithSchema = replaceSchemaTable(outputWithHeadings);
        const { html, toc } = getTOC(outputWithSchema.html);

        schemaData[tKey] = outputWithSchema.schemaData;
        textHTML[tKey] = html;
        resultTOC[tKey] = toc;
      }
    }

    if (raw.texte_copyright) {
      textCopyright = {} as { [key: string]: string };

      for (const tKey of Object.keys(raw.texte_copyright)) {
        textCopyright[tKey] = changeShortCodes(raw.texte_copyright[tKey], shortCodes);
      }
    }

    if (raw.texte_edited_by) {
      textEditedBy = {} as { [key: string]: string };
      for (const tKey of Object.keys(raw.texte_edited_by)) {
        textEditedBy[tKey] = changeShortCodes(raw.texte_edited_by[tKey], shortCodes);
      }
    }

    const result = {
      id: raw.id as number,
      lastname: `${raw.lastname}`,
      firstname: `${raw.firstname}`,
      email: `${raw.email}`,
      pronoun: `${raw.civilite}`,
      trainerFullName: raw.trainer_fullname && `${raw.trainer_fullname}`,
      primaryCharacterTraits: raw.primary_plus_traits_caracteres,
      secondaryCharacterTraits: raw.secondary_plus_traits_caracteres,
      diagrams: raw.diagrammes,
      diagramSecondaryText: raw.diagramme_texte_secondaire,
      diagramColors: raw.diagramme_traits_couleurs,
      diagramPercent: raw.diagramme_pourcentages,
      textEditedBy,
      textCopyright: textCopyright,
      textHTML,
      resultTOC,
      schemaData,
      pdfURL: `${raw.pdf_bilan}`,
      shortCodes
    };

    if (raw?.id) {
      return result;
    }

    return undefined;
  };


  // Load user summary
  const getSummaryDetail = async (
    userId: number,
    isInBackground?: boolean,
  ): Promise<ISummary | undefined> => {
    !!isInBackground && showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.USER.SUMMARY}/${userId}`,
        method: 'get',
      };
      const result: IHttpResponse = await getRequest(options);
      const getState = result.data.data?.user_bilan;

      // const getState = (BilanJson as any).data?.user_bilan;
      if (getState) {
        return parseSummary(getState);
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while getting summary: ', e);
      if (!isInBackground) {
        setToast({
          message: (e as Error).message || '',
          status: 'danger',
        });
      }
    } finally {
      !!isInBackground && hideLoading();
    }
    return undefined;
  };

  return {
    getSummaryDetail,
  };
}

export default useAPISummary;
