import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const SixColorsWrapper = styled.div`

  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: ${prem(16)};

  .description {
    color: var(--ion-text-color-secondary);
    max-width: ${prem(675)};
    display: block;
    margin: 0 auto ${prem(20)};
    text-align: justify;
  }

  .description::after {
    content: '';
    display: inline-block;
    width: 100%;
  }
`;

export { SixColorsWrapper };
