import React from 'react';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import useFormHandler from 'hooks/useFormHandler';
import Textfield, { TextfieldProps } from 'components/common/form/textfield/Textfield';
import { FormButtonSubmitter } from 'components/common/form/Form.styled';
import { useTranslation } from 'react-i18next';
import { IRelationGroup } from 'types/app';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { RelationGroupEditWrapper } from 'components/relations/group/relation-group-edit/RelationGroupEdit.styled';
import useAPIRelations from 'hooks/api/useAPIRelations';

export interface RelationGroupsEditProps {
  group: IRelationGroup;
  onValidate: () => void;
  title?: string | null;
  userId?: number;
}

const RelationGroupEdit: React.FC<RelationGroupsEditProps> = ({ group, title, onValidate }) => {
  const { t } = useTranslation('translation');
  const { createGroup, editGroup } = useAPIRelations();
  const { fields, isFormValid, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'groupname',
      value: group?.name || '',
      required: true,
    },
  ]);
  const doCreateRequest = async (groupName: string) => {
    const result: boolean | undefined = await createGroup(groupName);

    result && onValidate();
  };
  const doEditRequest = async (groupName: string, groupId: number) => {
    const result: boolean | undefined = await editGroup({ id: groupId, name: groupName });

    result && onValidate();
  };
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormValid()) {
      if (group.id === -1) {
        doCreateRequest(getFieldByName('groupname').value as string);
      } else {
        doEditRequest(getFieldByName('groupname').value as string, group.id);
      }
    }
  };

  return (
    <RelationGroupEditWrapper>
      {title && <AppModalTitle>{title}</AppModalTitle>}
      <form noValidate onSubmit={onSubmit} autoComplete="off">
        <Textfield
          {...({
            ...getFieldByName('groupname', fields),
            label: t('relations.groupname') || '',
            onChange: (value?) => updateFieldValue('groupname', value),
          } as TextfieldProps)}
        />
        <GenericDiv width={'50%'} minWidth={'9rem'} maxWidth={'15rem'} center>
          <Button color="white" type="submit" fill="outline" expand={true}>
            {t('common.validate')}
          </Button>
        </GenericDiv>

        <FormButtonSubmitter type="submit" />
      </form>
    </RelationGroupEditWrapper>
  );
};

export default RelationGroupEdit;
