import React from 'react';
import {
  MySummaryReadMoreButton,
  MySummaryReadMoreWrapper,
} from 'pages/profile/has-profile/my-summary/MySummaryReadMore.styled';
import { useTranslation } from 'react-i18next';

interface IMySummaryReadMore {
  blockId: string;
  onClick: (blockId: string) => void;
}

const MySummaryReadMore: React.FC<IMySummaryReadMore> = ({blockId, onClick}: IMySummaryReadMore) => {
  const { t } = useTranslation('translation');

  return (
    <MySummaryReadMoreWrapper>
      <MySummaryReadMoreButton onClick={() => onClick(blockId)}>{ t('profile.mysummary.more') }</MySummaryReadMoreButton>
    </MySummaryReadMoreWrapper>
  );
};

export default MySummaryReadMore;
