import React, { useEffect, useState } from 'react';
import {
  DominatingColorIconWrapper,
  ProfileDetailsWrapper,
  SecondaryColorIconWrapper,
} from 'components/profile/has-profile/profile-details/ProfileDetails.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import { formatProfileQualitiesString, getLocaleValue, removeAttributes } from 'helpers/utils';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { IProfile } from 'types/app';
import ImageRenderer from 'components/common/image/ImageRenderer';

export interface ProfileDetailsProps {
  profileDetails?: IProfile;
  pronoun?: 'your' | 'his' | 'her';
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ profileDetails, pronoun }) => {
  const { t, i18n } = useTranslation('translation');
  const {
    icons: [profileSvg],
  } = useIcons([ICON_NAMES.PROFILE_SVG]);
  const [loadedImages, setLoadedImages] = useState<Array<any>>([]);

  const imageIsLoaded = (key: string): boolean => {
    return loadedImages.includes(key);
  };

  const addToLoadedImages = (key: any): void => {
    if (!loadedImages.includes(key)) {
      setLoadedImages((prevImages) => [...prevImages, key]);
    }
  };

  const loadImage = (src: string): Promise<string> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        resolve(image.src);
      };
    });
  };

  useEffect(() => {
    if (profileDetails && profileDetails.diagrams && Array.isArray(profileDetails.diagrams)) {
      const allImages = profileDetails.diagrams
        .map((diagram) => {
          return Object.keys(diagram.image).map((localeKey) =>
            localeKey !== i18n.language ? diagram.image[localeKey] : '',
          );
        })
        .flat()
        .filter((imageSrc) => imageSrc !== '');
      const imageLoadPromises = allImages.map((imageSrc) => loadImage(imageSrc));

      Promise.all(imageLoadPromises).then((loadedImageSrcs) => {
        loadedImageSrcs.forEach((src: any) => addToLoadedImages(src));
      });
    }
  }, [profileDetails]);

  return (
    <ProfileDetailsWrapper>
      {profileDetails && (
        <>
          <Container className="dominating-color">
            <div className="color-title">
              {t('profile.profiledetails.dominatingcolor', {
                pronoun: t(`profile.profiledetails.pronouns.${pronoun}.feminine.singular`),
              })}{' '}
              : <br />
              <strong>
                {removeAttributes(
                  getLocaleValue(profileDetails?.profileInfo?.colors?.primary?.name, i18n.language),
                )}
              </strong>
            </div>
            <DominatingColorIconWrapper
              className="color-icon"
              color={profileDetails?.profileInfo?.colors?.primary?.color}
            >
              {profileSvg.icon}
            </DominatingColorIconWrapper>
            <div
              className="color-qualities"
              dangerouslySetInnerHTML={{
                __html: formatProfileQualitiesString(
                  removeAttributes(
                    getLocaleValue(profileDetails.primaryColorCharacters, i18n.language),
                  ),
                ),
              }}
            />
          </Container>
          <Container className="secondary-color">
            <div className="color-title">
              {t('profile.profiledetails.secondarycolor', {
                pronoun: t(`profile.profiledetails.pronouns.${pronoun}.feminine.singular`),
              })}{' '}
              : <br />
              <strong>
                {removeAttributes(
                  getLocaleValue(
                    profileDetails?.profileInfo?.colors?.secondary?.name,
                    i18n.language,
                  ),
                )}
              </strong>
            </div>
            <SecondaryColorIconWrapper
              className="color-icon"
              color={profileDetails?.profileInfo?.colors?.secondary?.color}
            >
              {profileSvg.icon}
            </SecondaryColorIconWrapper>
            <div
              className="color-qualities"
              dangerouslySetInnerHTML={{
                __html: formatProfileQualitiesString(
                  getLocaleValue(profileDetails.secondaryColorCharacters, i18n.language),
                ),
              }}
            />
          </Container>
          {Array.isArray(profileDetails.diagrams) && profileDetails.diagrams.length > 0 && (
            <>
              <Container>
                <div className="section-title">
                  <strong>
                    {t('profile.profiledetails.colors', {
                      pronoun: t(`profile.profiledetails.pronouns.${pronoun}.feminine.plural`),
                    })}
                  </strong>
                </div>
                <div className="color-diagrams">
                  {profileDetails.diagrams.map((diagram, key) => {
                    if (diagram.image && getLocaleValue(diagram.image, i18n.language) !== '') {
                      const localeImage: string = getLocaleValue(diagram.image, i18n.language);
                      return (
                        <React.Fragment key={key}>
                          <div key={key} className="diagram-container">
                            <div className="section-title">
                              <strong>
                                {key + 1} .{' '}
                                {removeAttributes(getLocaleValue(diagram.title, i18n.language))}
                              </strong>
                            </div>
                            <div
                              className={`image-container ${
                                !imageIsLoaded(localeImage) && 'loading-image'
                              }`}
                            >
                              <ImageRenderer
                                alt=""
                                src={localeImage}
                                key={key}
                                onLoad={() => addToLoadedImages(localeImage)}
                              />
                              {imageIsLoaded(localeImage) && (
                                <>
                                  {diagram.showDiagramSecondaryText && (
                                    <div
                                      className="diagram-secondary-text"
                                      dangerouslySetInnerHTML={{
                                        __html: removeAttributes(
                                          getLocaleValue(
                                            profileDetails.diagramSecondaryText,
                                            i18n.language,
                                          ),
                                        ),
                                      }}
                                    />
                                  )}
                                  {diagram.showColorsPercentages && (
                                    <>
                                      {Object.keys(profileDetails.diagramsPercentages).map(
                                        (diagramKey, key) => {
                                          return (
                                            <div
                                              className={`${`diagram-percentage-${diagramKey}`} diagram-percentage`}
                                              key={key}
                                            >
                                              {profileDetails.diagramsPercentages[diagramKey]} %
                                            </div>
                                          );
                                        },
                                      )}
                                    </>
                                  )}
                                  {diagram.showColorsTexts && (
                                    <>
                                      {Object.keys(profileDetails.diagramColorsTexts).map(
                                        (diagramKey, key) => {
                                          return (
                                            <div
                                              className={`${`diagram-text-${diagramKey}`} diagram-color-text`}
                                              key={key}
                                              dangerouslySetInnerHTML={{
                                                __html: removeAttributes(
                                                  getLocaleValue(
                                                    profileDetails.diagramColorsTexts[diagramKey],
                                                    i18n.language,
                                                  ),
                                                ),
                                              }}
                                            />
                                          );
                                        },
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    }
                  })}
                </div>
              </Container>
            </>
          )}
          <Container className="your-behavior">
            {/* <div className="section-title">
              <strong>
                {t('profile.profiledetails.behaviorsunderstress', {
                  pronoun: t(`profile.profiledetails.pronouns.${pronoun}.feminine.plural`),
                })}
              </strong>
            </div> */}
            {/* <div
              className="your-behavior-description description"
              // dangerouslySetInnerHTML={{
              //   __html: removeAttributes(
              //     getLocaleValue(profileDetails.behaviourUnderStress, i18n.language),
              //   ),
              // }}
              dangerouslySetInnerHTML={{
                __html: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore nemo praesentium ullam doloremque aliquid, explicabo atque alias blanditiis corporis voluptatum quia temporibus consequuntur, ut, ipsum dolorum. Quae magnam velit porro.'
              }}
            /> */}
          </Container>
        </>
      )}
    </ProfileDetailsWrapper>
  );
};

export default ProfileDetails;
