import React from 'react';
import { Components } from '@ionic/core/components';
import { IonLabelAsterisk, IonLabelWrapper } from 'components/common/form/label/FormLabel.styled';

export interface FormLabelProps extends Components.IonLabel {
  label: string;
  required?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({
  label,
  required,
  position = 'floating',
}: FormLabelProps) => {
  return (
    <IonLabelWrapper position={position}>
      <span>{label}</span>
      {required && <IonLabelAsterisk>*</IonLabelAsterisk>}
    </IonLabelWrapper>
  );
};

export default FormLabel;
