import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { transparentEl } from 'styles/helpers/utils';

const IconNotificationWrapper = styled.button<{ isNotificationPage: boolean }>`
  ${transparentEl};
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: 2px;
  }

  ${({ isNotificationPage }) =>
    isNotificationPage
      ? css`
          color: var(--ion-color-highlight);
        `
      : css`
          color: var(--ion-text-color-secondary);
        `}
`;

const NotificationDot = styled.div`
  background: var(--ion-color-accent);
  width: ${prem(6)};
  height: ${prem(6)};
  border-radius: 50%;
`;

export { IconNotificationWrapper, NotificationDot };
