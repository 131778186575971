import React, { useEffect, useState } from 'react';
import {
  ToggleLabelOff,
  ToggleLabelOn,
  ToggleLabelWrapper,
  ToggleSlider,
} from 'components/common/form/toggle/Toggle.styled';

export interface ToggleProps {
  checked?: boolean;
  onChecked?: (checked: boolean) => void;
  name?: string;
  enabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ checked, name, onChecked, enabled = true }) => {
  const [state, setState] = useState(checked);

  useEffect(() => {
    setState(checked);
  }, [checked]);

  return (
    <ToggleLabelWrapper className={enabled ? '' : 'disable-action'}>
      <input
        type="checkbox"
        name={name}
        checked={state ? enabled : state}
        onChange={(e) => {
          setState(e.target.checked);
          onChecked && onChecked(e.target.checked);
        }}
        disabled={!enabled}
      />
      <ToggleSlider>
        <ToggleLabelOn>On</ToggleLabelOn>
        <ToggleLabelOff>Off</ToggleLabelOff>
      </ToggleSlider>
    </ToggleLabelWrapper>
  );
};

export default Toggle;
