import styled from 'styled-components';
import COLORS from 'styles/constants/colors';

const SliderWrapper = styled.div`
  height: 100%;

  .swiper {
    height: 100%;

    .swiper-pagination-bullet {
      background: white;
      border: 1px solid ${COLORS.BASE.GREEN.DEFAULT};
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: var(--commcolors-color-accent-default, ${COLORS.ACCENT.GREEN});
      border: 1px solid var(--commcolors-color-accent-default, ${COLORS.ACCENT.GREEN});
    }
  }
`;

export { SliderWrapper };
