import React, { useContext, useEffect, useState } from 'react';
import { ModulesWrapper } from 'pages/resources/modules/Modules.styled';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
// import useAPIResources from 'hooks/api/useAPIResources';
// import { IModules } from 'types/app';
import ModulesList from 'components/resources/module/moduleslist/ModulesList';
import { PayableToolsContext, PayableToolsContextProps } from 'contexts/PayableToolsContext';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { ModuleContext, ModuleContextProps } from 'contexts/ModuleContext';

const Modules: React.FC<any> = () => {
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const [isLoaded, setIsLoaded] = useState(false);
  const { payableTools, loadPayableTools } = useContext(
    PayableToolsContext,
  ) as PayableToolsContextProps;

  const { loadModules, modules } = useContext(ModuleContext) as ModuleContextProps;

  useEffect(() => {
    if (user) {
      loadPayableTools();
    }
  }, []);

  useEffect(() => {
    if (!isLoaded && payableTools.length >= 0) {
      loadModules();
      setIsLoaded(true);
    }
    // console.log(payableTools);
  }, [payableTools]);

  // useEffect(() => {
  //   console.log(modules);
  // }, [modules]);

  return (
    <ModulesWrapper>
      <Container className="app-page">
        <PageInnerTitle
          dangerouslySetInnerHTML={{
            __html: t('resources.modules.title'),
          }}
        />
      </Container>
      {modules && <ModulesList modules={modules} />}
    </ModulesWrapper>
  );
};
export default Modules;
