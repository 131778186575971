const ROUTES = {
  INDEX: '/',
  INTRO: '/intro',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOTTEN_PWD: {
    INDEX: '/forgottenpwd',
    CODE: '/forgottenpwd/code',
    NEW_PWD: '/forgottenpwd/newpassword',
  },
  HOME: '/home',
  PROFILE: {
    INDEX: '/profile',
    MY_PROFILE: '/profile/my-profile',
    MY_SUMMARY: '/profile/my-summary',
    MY_MOTIVATION: '/profile/my-motivation',
    COLOUR_PROFILE: '/profile/colour-profile',
  },
  SHARED_PROFILE: '/sharedprofile',
  RELATIONS: {
    INDEX: '/relations',
    ADD: '/relations/add',
    IDENTIFY: '/relations/identify',
    MANAGE: '/relations/manage',
    APPOINTMENT: '/relations/appointment',
  },
  RESOURCES: {
    INDEX: '/resources',
    MODULES: '/resources/modules',
    BOOKS: '/resources/books',
    TRAINING: '/resources/resources-training',
  },
  MODEL: {
    INDEX: '/models',
    COMCOLORS: '/models/comcolors',
    SIX_COLORS: '/models/six-colors',
    TESTIMONIALS: {
      INDEX: '/models/testimonials',
      ADD: '/models/testimonials/add',
    },
    FRANCK_JULIEN: '/models/franck-julien',
  },
  CONTACT: '/contact',
  FAQ: '/faqs',
  SETTINGS: '/settings',
  TRAINING: '/training',
  NOTIFICATIONS: '/notifications',
  AUTOLOGIN: '/autologin',
  ELEARNING: '/elearning',
  SHOP: '/shop',
  FIND_TRAINER: '/find-trainer'
};

export { ROUTES };
