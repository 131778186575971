import React, { useEffect } from 'react';
import { IFaqs } from 'types/app';
import { Container } from 'layout/page/Page.layout.styled';
import { FaqsTitle, FaqsWrapper } from 'components/faqs/FaqsComponent.styled';
import FaqItem from 'components/faqs/FaqItem';
import useCollapse from 'hooks/useCollapse';
import { useTranslation } from 'react-i18next';

export interface FaqsProps {
  faqs: IFaqs;
  defaultOpenedId?: number | string;
  title?: string;
  className?: string;
}

const FaqsComponent: React.FC<FaqsProps> = ({ faqs, title, defaultOpenedId, className }) => {
  const { i18n } = useTranslation('translation');
  const { getOpenState, updateStates } = useCollapse();

  useEffect(() => {
    const collapsesIds = faqs.map((f, i) => ({
      id: `${i}`,
      opened: defaultOpenedId !== undefined ? i === defaultOpenedId : false,
    }));

    updateStates('', false, collapsesIds);
  }, [faqs]);
  return (
    <FaqsWrapper className={className}>
      <Container padding={0}>
        {title && <FaqsTitle>{title}</FaqsTitle>}
        {faqs.map((f, index) => {
          const id = `${index}`;

          return (
            <FaqItem
              key={`faq-${index}`}
              faq={f}
              opened={getOpenState(id)}
              onOpened={(o) => updateStates(id, o)}
              language={i18n.language}
            />
          );
        })}
      </Container>
    </FaqsWrapper>
  );
};

export default FaqsComponent;
