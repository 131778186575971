import { IonContent, IonPage } from '@ionic/react';
import { HeaderWrapper, LogoImageWrapper, LogoWrapper } from 'components/header/Header.auth.styled';
import { ReactComponent as LogoComcolors } from 'assets/svg/logo-comcolors.svg';
import React from 'react';
import { IComponentWithChildren } from 'types/app';
import LanguageSelector from 'components/header/languageselector/Language.selector';

const AuthLayout: React.FC<IComponentWithChildren> = ({
  children,
}: IComponentWithChildren): React.ReactElement => {
  return (
    <IonPage>
      <HeaderWrapper>
        <LanguageSelector />
        <LogoWrapper>
          <LogoImageWrapper>
            <LogoComcolors />
          </LogoImageWrapper>
        </LogoWrapper>
      </HeaderWrapper>
      <IonContent fullscreen>{children}</IonContent>
    </IonPage>
  );
};

export default AuthLayout;
