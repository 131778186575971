import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Container, PageTitle } from 'layout/page/Page.layout.styled';
import {
  AppVersionWrapper,
  ButtonInnerWrapper,
  ButtonsContainer,
  LinkTCU,
  SettingLine,
  SettingLineTile,
} from 'pages/settings/Settings.styled';
import Button from 'components/common/button/Button';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';
// eslint-disable-next-line no-restricted-imports
import dataPackage from '../../../package.json';
import { getAppBuild } from 'helpers/utils';

const Settings: React.FC = () => {
  const { t } = useTranslation('translation');
  const { settings, changeLanguage } = useContext(SettingsContext) as SettingsContextProps;
  const { lang } = settings;

  return (
    <PageLayout headerProps={{ title: t('menu.settings') }}>
      <Container className="app-page">
        <PageTitle>{t('settings.title')}</PageTitle>
        <SettingLine>
          <SettingLineTile>{t('common.language')}</SettingLineTile>
          <ButtonsContainer>
            <ButtonInnerWrapper>
              <Button
                color="accent"
                fill={lang === 'fr' ? 'solid' : 'outline'}
                expand={true}
                onClick={() => {
                  changeLanguage('fr');
                }}
              >
                {'Français'}
              </Button>
            </ButtonInnerWrapper>
            <ButtonInnerWrapper>
              <Button
                color="accent"
                fill={lang !== 'fr' ? 'solid' : 'outline'}
                expand={true}
                onClick={() => {
                  changeLanguage('en');
                }}
              >
                {'English'}
              </Button>
            </ButtonInnerWrapper>
          </ButtonsContainer>
        </SettingLine>
        <LinkTCU>
          <Link target="_blank" to={t('common.tcuURL')}>
            {t('common.tcu')}
          </Link>
        </LinkTCU>
        <AppVersionWrapper>
          <p>
            <span>{`version ${dataPackage.version}`}</span>
            <br />
            {process.env.REACT_APP_BUILD_TIMESTAMP && (
              <span>{`build: ${getAppBuild(process.env.REACT_APP_BUILD_TIMESTAMP)}`}</span>
            )}
          </p>
          <p></p>
        </AppVersionWrapper>
      </Container>
    </PageLayout>
  );
};

export default Settings;
