import { IUser } from 'types/app';
import { getColorByName } from 'helpers/colors';
import { USER_PROFILE_INFO_GROUP_TYPE } from 'hooks/api/useAPIAuth';

const MODE_TEST = false;

export function parseUser(userData: any, email: string): IUser | undefined {
  let data: IUser | undefined;
  let pronoun = 'elle';

  if (userData) {
    if (userData.civilite && `${userData.civilite}`.toLowerCase().includes('monsieur')) {
      pronoun = 'lui';
    }

    data = {
      id: userData.id,
      lastname: userData.lastname,
      firstname: userData.firstname,
      civility: userData.civilite || 'Monsieur',
      pronoun,
      email,
      token: {
        value: userData['access_token'],
        type: userData['token_type'],
      },
      authFailed: !!userData.authFailed,
    } as IUser;
  }

  return data;
}

// Merge user profile info into user data
export function parseUserInfo(currentUser: IUser, rawData?: any): IUser {
  const result = { ...currentUser }; //
  const profileInfo: any = rawData.user_info;
  if (!result.email) result.email = profileInfo.email;

  if (profileInfo.id === currentUser.id) {
    result.profileInfo = {
      isDirectClientGroup: profileInfo.is_groupe_client_direct,
      activity: profileInfo.socio_category,
      birthYear: profileInfo.year_birth ? `${profileInfo.year_birth}` : undefined,
      groupId: profileInfo.groupe_id,
      notifications: profileInfo.notifications,
      tools: {
        profile: profileInfo.has_tool_profile,
        digital: profileInfo.has_tool_digital,
        lms: profileInfo.has_tool_lms,
        comColorsTraining: profileInfo.access_comcolors_training,
        lmsWorkTogether: profileInfo.has_tool_elearningensemble,
        lmsStress: profileInfo.has_tool_elearningstress,
        talent: profileInfo.has_tool_talent,
      },
      pdfColors: profileInfo.pdf_couleurs,
      pdfSummary: profileInfo.pdf_bilan,
      hasValidatedProfile: profileInfo.has_valid_profile,
      hasVisibleProfile: profileInfo.has_visible_profile,
      questionnaireDate: profileInfo.date_questionnaire || null,
      hasPayedProfile: profileInfo.has_payed_tool || null,
      profilePaymentDate: profileInfo.payment_date || null,
      ...(profileInfo.source && { source: profileInfo.source }),
      ...(profileInfo.type_groupe && { typeGroup: profileInfo.type_groupe }),
      ...(rawData.texte_intro_no_profil && {
        textNoProfile: {
          fr: rawData.texte_intro_no_profil.nom_fr,
          en: rawData.texte_intro_no_profil.nom_en,
        },
      }),
      ...(profileInfo.primary_color && {
        colors: {
          primary: profileInfo.has_visible_profile
            ? getColorByName(profileInfo.primary_color)
            : undefined,
          secondary: getColorByName(profileInfo.secondary_color),
        },
      }),
      hasAuthorizedSales: profileInfo.autorized_sale,
      isInPrivilegedCompany: !!profileInfo.email_has_company,
      companyName: profileInfo.email_company_name,
    };
  }

  if (result.profileInfo && MODE_TEST) {
    /*
    Questionnaire cas 2
    result.profileInfo.tools.profile = true;
    result.profileInfo.questionnaireDate = null;
    result.profileInfo.groupId = 2;
    result.profileInfo.colors = undefined;
    result.profileInfo.source = USER_PROFILE_INFO_SOURCE.QUESTIONNAIRE;
    result.profileInfo.typeGroup = USER_PROFILE_INFO_GROUP_TYPE.INDIVIDUAL;
    */
    /*
     * Tests
     * Questionnaire source academy
     * */
    result.profileInfo.colors = undefined;
    result.profileInfo.tools.profile = true;
    result.profileInfo.hasVisibleProfile = false;
    result.profileInfo.hasValidatedProfile = false;
  }
  // ///// */

  // Additional flags
  if (result.profileInfo) {
    result.profileInfo.nationality = profileInfo?.nationalite || '';
    const hasQuestionnaireDone =
      Boolean(result.profileInfo.questionnaireDate) &&
      `${result.profileInfo.questionnaireDate}`.includes('/');

    result.profileInfo.isProfileAvailableSoon =
      result.profileInfo.tools.profile && !result.profileInfo.hasVisibleProfile;
      
    const answeredAdditionalQuestions = !!profileInfo.date_questionnaire_finale;
    result.profileInfo.isToQuestionnaire = result.profileInfo.tools.profile && !localStorage.getItem('answered_questionnaire') && (result.profileInfo.questionnaireDate === null || !hasQuestionnaireDone || !answeredAdditionalQuestions);
    result.profileInfo.isMemberTypeSiteMemberGroup = result.profileInfo.groupId === 1;
    result.profileInfo.isMemberTypeIndividualGroup =
      result.profileInfo.groupId !== 1 &&
      result.profileInfo.typeGroup === USER_PROFILE_INFO_GROUP_TYPE.INDIVIDUAL;

    if (result.profileInfo?.hasVisibleProfile) {
      result.profileInfo.hasUniqueColor =
        result.profileInfo.colors?.primary?.id === result.profileInfo.colors?.secondary?.id;
    }
  }

  return result;
}
