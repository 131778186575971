import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Container, PageTitle } from 'layout/page/Page.layout.styled';
import useAPIFaqs, { FaqsCategory } from 'hooks/api/useAPIFaqs';
import { IFaqs } from 'types/app';
import FaqsComponent from 'components/faqs/FaqsComponent';

const Faqs: React.FC = () => {
  const { t } = useTranslation('translation');
  const { getFaqsByCategory } = useAPIFaqs();
  const [faqs, setFaqs] = useState<IFaqs>([]);
  const loadFaqs = useCallback(async () => {
    setFaqs(await getFaqsByCategory(FaqsCategory.GENERAL));
  }, []);

  useEffect(() => {
    loadFaqs();
  }, []);

  return (
    <PageLayout headerProps={{ title: t('faqs.title') }}>
      <Container className="app-page ">
        <PageTitle>{t('faqs.howwehelp')}</PageTitle>
        <FaqsComponent faqs={faqs} />
      </Container>
    </PageLayout>
  );
};

export default Faqs;
