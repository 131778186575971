import styled from 'styled-components';
import { BODY, HEADLINE, SUBTITLE } from 'styles/helpers/typography';
import { prem } from 'styles/helpers/spacing';
import { centerize } from 'styles/helpers/utils';
import {
  VideoThumbnailWrapper,
  VideoThumbnailsSliderWrapper,
} from 'components/slider/video-thumbnails-slider/VideoThumbnailsSlider.styled';

const LogoComColorsWrapper = styled.div`
  min-width: ${prem(252)};
  max-width: ${prem(380)};
  width: 70%;
  margin: 0 auto ${prem(16)};

  svg {
    width: 100%;
    height: auto;
  }
`;

const HomeSwiperContainer = styled.div`
  max-width: 100%;
  margin-bottom: ${prem(24)};

  ${VideoThumbnailWrapper} {
    width: ${prem(260)};
  }

  .swiper-slide {
    width: ${prem(260)};
  }

  .youtube-videos {
    ${VideoThumbnailsSliderWrapper} {
      max-width: ${prem(900)};
    }
  }

  .tiktok-videos {
    ${VideoThumbnailsSliderWrapper} {
      max-width: ${prem(900)};

      .swiper-slide {
        width: ${prem(150)};
      }

      ${VideoThumbnailWrapper} {
        width: ${prem(150)};
      }
    }
  }
`;

const HomeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${prem(25)};
  padding-bottom: ${prem(150)};
  overflow-y: auto;

  .texts-container {
    padding: ${prem(20)} ${prem(25)} ${prem(16)};
    display: block;
    margin: 0 auto;
  }

  .desc {
    ${SUBTITLE.medium};
    margin-bottom: ${prem(60)};
  }

  .button-container {
    flex-grow: 1;
    ${centerize};
    display: flex;
    flex-direction: column;
    margin-bottom: ${prem(60)};
  }

  .section-channel {
    ${BODY.small}
    padding-left: ${prem(24)};
    margin-bottom: ${prem(5)};
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;

    .icon {
      color: var(--ion-color-orange);
      margin-right: ${prem(5)};

      svg {
        width: ${prem(25)};
        height: max-content;
      }

      img {
        width: ${prem(25)};
      }
    }
  }

  .youtube-videos-wrapper {
    .section-channel {
      .icon {
        color: var(--ion-color-red);
      }
    }
  }

  .audio-channels {
    display: flex;
    justify-content: space-between;
    max-width: ${prem(880)};

    .other-channels {
      padding-right: ${prem(20)};
      display: flex;
      gap: ${prem(10)};

      .channel {
        cursor: pointer;
      }
    }
  }

  .tiktok-videos-wrapper {
    .icon {
      color: var(--ion-color-dark);
    }
  }

  .linkedin-posts-wrapper {
    .icon {
      color: var(--ion-color-blue);
    }
  }

  .home-main {
    margin-bottom: ${prem(20)};
  }
`;

const HomeTitle = styled.h1`
  ${HEADLINE.large};
  margin-bottom: ${prem(16)};
`;

export { HomeTitle, LogoComColorsWrapper, HomeSwiperContainer, HomeWrapper };
