import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, ISettings } from 'types/app';
import { LS_KEYS } from 'constants/global';
import { useTranslation } from 'react-i18next';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPISettings from 'hooks/api/useAPISettings';
import { saveToStorage } from 'helpers/storage';

export interface SettingsContextProps {
  settings: ISettings;
  updateSettings: (settings: ISettings) => void;
  changeProfileVisibility: (value: number) => void;
  changeLanguage: (value: string) => void;
  showRefresh: boolean;
  setShowRefresh: (state: boolean) => void;
  languages: Array<string>;
}

export interface SettingsProviderProps extends IComponentWithChildren {
  settings: ISettings;
  showRefreshButton?: boolean;
}

const SettingsContext = createContext<SettingsContextProps | null>(null);
const SettingsProvider: FC<SettingsProviderProps> = ({ settings, children }) => {
  const { user } = useContext(UserContext) as UserContextProps;
  const { registerSettings } = useAPISettings();
  // const setTimeoutId = useRef<number>();
  const { i18n } = useTranslation();
  const [currentSettings, setSettings] = useState(settings);
  const [showRefresh, setShowRefresh] = useState<boolean>(false);
  const updateSettings = useCallback(
    (settings: ISettings) => {
      const tmpSettings: ISettings = {
        ...settings,
        ...(user && { idUser: user.id }),
        lang: settings.lang,
      };
      setSettings(tmpSettings);
      saveToStorage(LS_KEYS.SETTINGS, JSON.stringify(tmpSettings));
      saveToStorage(LS_KEYS.LANG, tmpSettings?.lang);

      // Apply
      // Language
      // console.log(tmpSettings);
      i18n.changeLanguage(tmpSettings.lang);
    },
    [user],
  );

  useEffect(() => {
    settings && updateSettings(settings);
  }, [settings]);
  
  const changeProfileVisibility = (value: number) => {
    updateSettings({
      ...currentSettings,
      profileVisibility: value,
    });
  };
  const changeLanguage = (value: string) => {
    updateSettings({
      ...currentSettings,
      lang: value,
    });
  };

  const languages = Object.keys(i18n.options.resources as any);

  useEffect(() => {
    // setTimeoutId.current && clearTimeout(setTimeoutId.current);
    // setTimeoutId.current = window.setTimeout(() => {

    // }, 1000);
    registerSettings(currentSettings);
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        updateSettings,
        changeProfileVisibility,
        changeLanguage,
        setShowRefresh,
        showRefresh,
        languages,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsProvider };
