import { AxiosRequestConfig } from 'axios';
import useFetch from 'hooks/api/useFetch';
import { API } from 'constants/global';
import { ITutoVideos } from 'types/app';
import { replaceStrings } from 'helpers/utils';

export interface IUseAPITutoVideos {
  getTutoInfos: (lang: string) => Promise<ITutoVideos | undefined>;
}

function useAPITutoVideos(): IUseAPITutoVideos {
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  const getTutoInfos = async (lang: string) => {
    try {
      showLoading();
      const options: AxiosRequestConfig = {
        url: replaceStrings(API.ROUTES.TUTO.INFOS, {
          lang: lang
        }),
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      };

      const result = await getRequest(options);
      const videos = result.data.data.videos;
      if (videos) {
        return videos as ITutoVideos;
      } else {
        console.log('Error getting tutorial video infos');
      }
    } catch (e) {
      console.log('Error getting tutorial video infos: ', e);
    } finally {
      hideLoading();
    }

    return undefined;
  };

  return {
    getTutoInfos,
  };
}

export default useAPITutoVideos;
