import InstallAppTuto from 'components/install-app-tuto/InstallAppTuto';
import React from 'react';

export interface QuestionnaireInstallAppProps {
  isDesktop?: boolean;
  isAndroid?: boolean;
  isIOS?: boolean;
}
const QuestionnaireInstallApp: React.FC<QuestionnaireInstallAppProps> = ({
  isDesktop,
  isAndroid,
  isIOS,
}) => {
  return <InstallAppTuto isDesktop={isDesktop} isAndroid={isAndroid} isIOS={isIOS} />;
};

export default QuestionnaireInstallApp;
