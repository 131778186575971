import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';

const QuestionnaireMainWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const QuestionnaireMainProgressWrapper = styled.div`
  height: ${prem(120)};
  padding: ${prem(10)} ${prem(8)};
`;

const QuestionnaireMainSliderWrapper = styled.div`
  flex: 1;
`;

export {
  QuestionnaireMainWrapper,
  QuestionnaireMainSliderWrapper,
  QuestionnaireMainProgressWrapper,
};
