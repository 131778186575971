import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY, SUBTITLE } from 'styles/helpers/typography';
import { greenFWContainer, SvgWrapper, transparentEl } from 'styles/helpers/utils';
import { PageInnerTitle } from 'layout/page/Page.layout.styled';
import { CollapseButton, CollapseLabel } from 'components/common/showhide/Collapse.styled';
import { Chevron } from 'components/common/icons/icon-chevron/IconChevron.styled';
import { FONT_WEIGHT } from 'styles/constants/fonts';

const RelationSubscriptionButton = styled.button`
  ${transparentEl};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

const RelationsManagementWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${PageInnerTitle} {
    margin-bottom: ${prem(20)};
  }

  .relation-profile-container {
    ${greenFWContainer};

    .icon-profile-container {
      width: ${prem(48)};
      height: ${prem(48)};
      color: transparent;
      margin: 0 auto;
    }

    .colours-content {
      color: var(--ion-text-color-secondary);

      p {
        margin: ${prem(16)} 0;
      }

      .colours-comment {
        margin-bottom: ${prem(24)};
        white-space: pre-wrap;

        p {
          margin-bottom: ${prem(16)};
        }
      }
    }

    p.colours-names {
      ${SUBTITLE.small};
      text-align: center;
    }
  }

  .subscription-marker-wrapper {
    text-align: center;

    ${RelationSubscriptionButton} {
      color: var(--ion-color-accent);
    }

    ${SvgWrapper} {
      width: ${prem(25)};
      height: ${prem(25)};
      color: var(--ion-color-highlight);
      margin: 0 auto;
    }

    span {
      ${BODY.small};
    }
  }

  .collapse-advices {
    ${CollapseButton} {
      color: var(--ion-color-highlight);

      &.opened {
        ${CollapseLabel} {
          color: var(--ion-color-highlight);
        }

        ${Chevron} {
          color: var(--ion-color-highlight);
        }
      }
    }
  }

  .no-advices {
    color: var(--ion-text-color-secondary);
    margin: 0 ${prem(25)};
  }

  .no-valid-profile-note {
    margin: ${prem(20)} 0;
    text-align: center;
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`;

const RelationAdviceWrapper = styled.div``;

export { RelationsManagementWrapper, RelationSubscriptionButton, RelationAdviceWrapper };
