import { IonButtonWrapper } from 'components/common/button/Button.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY, HEADLINE } from 'styles/helpers/typography';

const TutoSmartphoneWrapper = styled.div`
  padding-bottom: ${prem(20)};

  .title {
    text-align: center;
    margin-bottom: ${prem(20)};
    ${HEADLINE.small}
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${IonButtonWrapper} {
    width: ${prem(200)};
  }

  .tutorial {
    ${BODY.medium}
    max-width: ${prem(500)};
    margin: 0 auto;
    padding: ${prem(50)};
    color: white;
    border-radius: ${prem(50)};
    background: var(--comcolors-modal-background);

    ul {
      display: flex;
      flex-direction: column;
      gap: ${prem(20)};

      li {
        span {
          line-height: 1.4;
        }
      }
    }
  }
`;

const InstallOnAndroidWrapper = styled.div`
  padding-top: ${prem(20)};
  text-align: start;

  .android-tutorial {
    li {
      span {
        position: relative;
      }

      svg {
        width: ${prem(40)};
        position: absolute;
        top: 50%;
        left: 50%;
        stroke-width: 500px;
        transform: translate(-10%, -50%);
      }
    }
  }
`;
const InstallOnIOSWrapper = styled.div`
  padding-top: ${prem(20)};
  text-align: start;

  .ios-tutorial {
    svg {
      width: ${prem(30)};
      padding-top: ${prem(10)};
    }
  }

  .ios-warning {
    margin-top: ${prem(25)};
    font-style: italic;
    padding: ${prem(5)};
  }
`;

export { TutoSmartphoneWrapper, InstallOnAndroidWrapper, InstallOnIOSWrapper };
