import useFetch from 'hooks/api/useFetch';
import { API, COLORS_CODES, LANGUAGES } from 'constants/global';
import {
  IDiagramImage,
  IDiagramTitle,
  IProfile,
  IProfileDiagramColorText,
  IProfileDiagramPercentage,
  ISectionBaseTexts,
  ISharedProfile,
  IUserProfileInfo,
  IVideo,
  IVideos,
} from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { useContext } from 'react';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { IHttpResponse } from 'helpers/api';
import { getCCColor } from 'helpers/colors';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { useTranslation } from 'react-i18next';
import { getSectionBaseTexts, getTranslations, replaceStrings } from 'helpers/utils';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';

export interface IUseAPIProfile {
  getProfileDetails: () => Promise<IProfile | undefined>;
  getSharedProfileDetails: (token: string) => Promise<ISharedProfile | undefined>;
  uploadProfilePicture: (picture: File) => Promise<string | undefined>;
  chooseProfilePictureColor: (colorId: number) => Promise<string | undefined>;
}

function useAPIProfile(): IUseAPIProfile {
  const { i18n, t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { languages } = useContext(SettingsContext) as SettingsContextProps;

  const getDiagramImage = (diagramImages: any, key: string): IDiagramImage => {
    const diagramImage = {} as IDiagramImage;
    Object.keys(diagramImages).forEach((localeCode: any) => {
      const localObject = diagramImages[localeCode];
      Object.keys(localObject).forEach((titleDiagramKey: any) => {
        if (titleDiagramKey === key) {
          diagramImage[localeCode] = localObject[titleDiagramKey];
        }
      });
    });
    return diagramImage;
  };

  const getDiagramTitle = (diagramTitles: any, key: string): IDiagramTitle => {
    const diagramTitle = {} as IDiagramTitle;
    Object.keys(diagramTitles).forEach((localeCode: any) => {
      const localObject = diagramTitles[localeCode];
      Object.keys(localObject).forEach((titleDiagramKey: any) => {
        if (titleDiagramKey === key) {
          diagramTitle[localeCode] = localObject[titleDiagramKey];
        }
      });
    });
    return diagramTitle;
  };

  const getDiagramPercentages = (diagramPercentages: any): IProfileDiagramPercentage => {
    return {
      [COLORS_CODES.RED]: diagramPercentages.valeur_rouge,
      [COLORS_CODES.PURPLE]: diagramPercentages.valeur_violet,
      [COLORS_CODES.BLUE]: diagramPercentages.valeur_bleu,
      [COLORS_CODES.GREEN]: diagramPercentages.valeur_vert,
      [COLORS_CODES.YELLOW]: diagramPercentages.valeur_jaune,
      [COLORS_CODES.ORANGE]: diagramPercentages.valeur_orange,
    };
  };

  const getDiagramColorsTexts = (diagramTexts: any): { [x: string]: IProfileDiagramColorText } => {
    return {
      [COLORS_CODES.RED]: diagramTexts.texte_pdf_rouge,
      [COLORS_CODES.PURPLE]: diagramTexts.texte_pdf_violet,
      [COLORS_CODES.BLUE]: diagramTexts.texte_pdf_bleu,
      [COLORS_CODES.GREEN]: diagramTexts.texte_pdf_vert,
      [COLORS_CODES.YELLOW]: diagramTexts.texte_pdf_jaune,
      [COLORS_CODES.ORANGE]: diagramTexts.texte_pdf_orange,
    };
  };

  const formatVideos = (videos: Array<any>): IVideos => {
    const formattedVideos = videos.map((video) => {
      const tmpVideo = getTranslations(languages, video.translations, (input) => input.url_video);

      return {
        ...(getSectionBaseTexts(
          video.translations,
          languages,
          video.thumbnail_url,
        ) as ISectionBaseTexts),
        videoUrl:
          tmpVideo && (tmpVideo[LANGUAGES.fr] || tmpVideo[LANGUAGES.en])
            ? tmpVideo
            : {
                [LANGUAGES.fr]: video.url_video,
                [LANGUAGES.en]: video.url_video,
              },
      } as IVideo;
    });

    return formattedVideos;
  };

  const getVideos = async (): Promise<IVideos> => {
    const options = {
      method: 'get',
      url: `${API.ROUTES.PROFILE.VIDEOS}/${i18n.language}/shared`,
      headers: {
        Authorization: API.BASIC_TOKEN,
      },
    };

    const result = await getRequest(options);
    const videos = result.data.data?.videos || [];
    const formatedVideos = formatVideos(videos);
    return formatedVideos;
  };

  const formatProfileDetails = async (
    userProfileDatas: any,
    profileType: 'user_profile' | 'shared_profile' = 'user_profile',
  ): Promise<IProfile | ISharedProfile | undefined> => {
    if (!userProfileDatas) {
      return undefined;
    }

    const res: IProfile = {
      id: userProfileDatas[profileType].id,
      firstname: userProfileDatas[profileType].firstname,
      lastname: userProfileDatas[profileType].lastname,
      civility: userProfileDatas[profileType].civilite,
      diagrams: userProfileDatas[profileType].diagrammes && [
        {
          image: getDiagramImage(userProfileDatas[profileType].diagrammes, 'diagramme_1'),
          title: getDiagramTitle(
            userProfileDatas[profileType].diagramme_titres,
            'title_diagramme1',
          ),
          showColorsPercentages: true,
          showDiagramSecondaryText: true,
        },
        {
          image: getDiagramImage(userProfileDatas[profileType].diagrammes, 'diagramme_2'),
          title: getDiagramTitle(
            userProfileDatas[profileType].diagramme_titres,
            'title_diagramme2',
          ),
          showColorsTexts: true,
          showDiagramSecondaryText: true,
        },
        {
          image: getDiagramImage(userProfileDatas[profileType].diagrammes, 'diagramme_3'),
          title: getDiagramTitle(
            userProfileDatas[profileType].diagramme_titres,
            'title_diagramme3',
          ),
        },
      ],
      diagramsPercentages: getDiagramPercentages(
        userProfileDatas[profileType].diagramme_pourcentages,
      ),
      diagramColorsTexts: getDiagramColorsTexts(
        userProfileDatas[profileType].diagramme_traits_couleurs,
      ),
      diagramSecondaryText: userProfileDatas[profileType].diagramme_texte_secondaire,
      profileInfo: {
        colors: {
          primary: getCCColor(userProfileDatas[profileType].primary_color),
          secondary: getCCColor(userProfileDatas[profileType].secondary_color),
        },
      } as IUserProfileInfo,
      primaryColorCharacters: userProfileDatas[profileType].primary_plus_traits_caracteres,
      secondaryColorCharacters: userProfileDatas[profileType].secondary_plus_traits_caracteres,
      behaviourUnderStress: userProfileDatas[profileType].texte_comportement_sous_stress,
      token: {
        type: 'Bearer',
        value: userProfileDatas[profileType].token_id,
      },
    } as IProfile;

    if (profileType === 'shared_profile') {
      const videos = await getVideos();
      const sharedProfileFormated: ISharedProfile = {
        ...res,
        howToReadProfile: {
          ...getSectionBaseTexts(
            userProfileDatas.texte_comment_lire_profil.translations,
            languages,
            '',
          ),
          imageUrl: userProfileDatas.texte_comment_lire_profil.image_url,
          videoUrl: userProfileDatas.texte_comment_lire_profil.video_url,
        },
        sliderVideos: videos,
        diagramPercentageText: userProfileDatas[profileType].texte_diagramme_pourcentage,
      };
      return sharedProfileFormated as ISharedProfile;
    }

    return res as IProfile;
  };

  const getProfileDetails = async () => {
    try {
      showLoading();
      const options: AxiosRequestConfig = {
        method: 'get',
        url: user && `${API.ROUTES.PROFILE.MY_PROFILE}/${user.id}`,
      };
      options.headers = {
        Authorization: user?.token?.value,
      };
      const result: IHttpResponse = await getRequest(options);
      const userProfileDatas = result.data.data;
      if (userProfileDatas) {
        const formattedProfile = await formatProfileDetails(userProfileDatas, 'user_profile');
        return formattedProfile;
      }
    } catch (e) {
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
      console.error('Error while getting profile details: ', e);
    } finally {
      hideLoading();
    }
  };

  const getSharedProfileDetails = async (token: string) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        method: 'post',
        url: `${API.ROUTES.PROFILE.SHARED_PROFILE}`,
        data: {
          token_id: token,
        },
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const userSharedProfileDatas = result.data.data;
      if (userSharedProfileDatas) {
        const formattedProfile = (await formatProfileDetails(
          userSharedProfileDatas,
          'shared_profile',
        )) as ISharedProfile;
        return formattedProfile;
      } else {
        setToast({
          message: t('message.error.noprofiledetails'),
          status: 'danger',
        });
      }
    } catch (e) {
      console.log('Error while getting profile details: ', e);
      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }
  };

  const uploadProfilePicture = async (picture: File) => {
    showLoading();

    try {
      const bodyFormData = new FormData();
      bodyFormData.append('photo', picture);
      const options: AxiosRequestConfig = {
        method: 'post',
        url: `${replaceStrings(API.ROUTES.PROFILE.UPLOAD_PROFILE_PICTURE, {
          userId: `${user?.id}`,
        })}`,
        data: bodyFormData,
        headers: {
          Authorization: `${user?.token?.type} ${user?.token?.value}`,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      if (result?.data?.data?.shared_network?.uploaded_photo) {
        return result.data.data.shared_network.uploaded_photo;
      }
      return undefined;
    } finally {
      hideLoading();
    }
  };

  const chooseProfilePictureColor = async (colorId: number) => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        method: 'post',
        url: `${replaceStrings(API.ROUTES.PROFILE.CHOOSE_PROFILE_PICTURE_FILTER, {
          userId: `${user?.id}`,
        })}`,
        data: {
          color_id: colorId,
        },
        headers: {
          Authorization: `${user?.token?.type} ${user?.token?.value}`,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const filteredPhoto = result?.data?.data?.shared_network?.filtered_photo;
      if (filteredPhoto) {
        return filteredPhoto;
      }
      return undefined;
    } finally {
      hideLoading();
    }
  };

  return {
    getProfileDetails,
    getSharedProfileDetails,
    uploadProfilePicture,
    chooseProfilePictureColor,
  };
}

export default useAPIProfile;
