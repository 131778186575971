import React, { useEffect, useState } from 'react';
import { IonNote } from '@ionic/react';
import { Form } from 'types/app';
import { IonInputStyled, IonItemWrapper } from 'components/common/form/textfield/Textfield.styled';
import FormLabel from 'components/common/form/label/FormLabel';
import {
  SelectBoxOptionsWrapper,
  SelectBoxWrapper,
  SelectNative,
} from 'components/common/form/selectbox/SelectBox.styled';
import { RegularButtonFlexed } from 'components/common/button/Button.styled';
import IconChevron from 'components/common/icons/icon-chevron/IconChevron';
import ShowHide from 'components/common/showhide/ShowHide';
import useOutsideClick from 'hooks/useOutsideClick';
import { prem } from 'styles/helpers/spacing';

export interface SelectBoxProps extends Form.IField {
  onChange?: (value?: Form.IOption) => void;
  maxHeight?: string;
  isNative?: boolean;
}

const SelectBox: React.FC<SelectBoxProps> = ({
  label,
  required,
  value,
  errorMessage,
  onChange,
  isNative,
  maxHeight = prem(120),
  options = [] as Array<Form.IOption>,
}) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [isTouched, setIsTouched] = useState(false);
  const [open, setOpen] = useState(false);
  const selectOptions = options?.length
    ? [...(required ? [] : [{ id: -1, label: '' }]), ...options]
    : [];
  const { ref, exceptRef } = useOutsideClick(() => {
    setOpen(false);
  });
  const markTouched = () => {
    setIsTouched(true);
  };
  const onSelect = (value?: Form.IOption) => {
    setFieldValue(value);
    onChange && onChange(value);
    setOpen(false);
  };
  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = parseInt(e.target.value, 10);
    const option = selectOptions.find((o) => o.id === id);

    if (id === -1) {
      onSelect();
    } else {
      option && onSelect(option as Form.IOption);
    }
  };

  useEffect(() => {
    open && markTouched();
  }, [open]);

  return (
    <SelectBoxWrapper>
      <IonItemWrapper
        lines="none"
        className={`${errorMessage && 'ion-invalid'} ${isTouched && 'ion-touched'} ${
          fieldValue && 'item-has-value'
        }`}
      >
        {label && <FormLabel label={label} required={required} />}

        <RegularButtonFlexed
          className={isNative ? 'native' : ''}
          type="button"
          onClick={() => {
            setOpen(!open);
          }}
          ref={exceptRef}
        >
          <IconChevron mode="downup" opened={open} />
        </RegularButtonFlexed>
        {isNative ? (
          <SelectNative value={(fieldValue as Form.IOption)?.id} onChange={onChangeSelect}>
            {selectOptions?.map((opt, index) => {
              return (
                <option value={opt.id} key={`o-${index}`}>
                  {opt.label}
                </option>
              );
            })}
          </SelectNative>
        ) : (
          <IonInputStyled value={(fieldValue as Form.IOption)?.label} disabled />
        )}
      </IonItemWrapper>
      {!isNative && !!options.length && (
        <ShowHide opened={open}>
          <SelectBoxOptionsWrapper
            maxHeight={options?.length > 4 ? maxHeight : undefined}
            ref={ref}
          >
            {options.map((opt, index) => {
              return (
                <RegularButtonFlexed
                  type="button"
                  key={`opt-${index}`}
                  onClick={() => {
                    onSelect(opt);
                    if (opt.onClick) {
                      opt.onClick();
                    }
                  }}
                >
                  <span>{opt.label}</span>
                </RegularButtonFlexed>
              );
            })}
          </SelectBoxOptionsWrapper>
        </ShowHide>
      )}
      {errorMessage && <IonNote>{errorMessage}</IonNote>}
    </SelectBoxWrapper>
  );
};

export default SelectBox;
