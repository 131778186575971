import styled from 'styled-components';
import { IntroContainer } from 'pages/intro/Intro.styled';
import { prem } from 'styles/helpers/spacing';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { CheckboxLabel, CheckboxWrapper } from 'components/common/form/checkbox/Checkbox.styled';
import { BODY } from 'styles/helpers/typography';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const RegisterContainer = styled(IntroContainer)`
  padding-top: ${prem(20)};
  text-align: center;
  width: 70%;
  max-width: ${prem(500)};
  min-width: ${prem(250)};

  ${IonButtonWrapper} {
    margin: ${prem(77)} auto ${prem(24)};
    width: 50%;
    min-width: ${prem(190)};
    max-width: ${prem(250)};
  }

  .accept-cgu {
    margin-top: ${prem(40)};
  }

  ${CheckboxWrapper} {
    ${CheckboxLabel} {
      ${BODY.small};
    }
  }

  form {
    padding-bottom: ${prem(20)};

    @media (${MQ_MAX_HEIGHT}) {
      padding-bottom: ${prem(100)};
    }
  }
`;

export { RegisterContainer };
