import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { IconProfileWrapper } from 'components/common/icons/icon-profile/IconProfile.styled';

const IconProfilesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${IconProfileWrapper} {
    color: transparent;
    width: ${prem(52)};
    height: ${prem(52)};
  }

  .icon-interaction {
    width: ${prem(38)};
    color: var(--ion-text-color-secondary);

    svg {
      width: 100%;
      height: auto;
    }
  }
`;

export { IconProfilesContainer };
