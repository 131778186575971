import styled from 'styled-components';
import { SlideContentWrapper } from 'pages/relations/identification/RelationIdentification.styled';
import { Container } from 'layout/page/Page.layout.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';
import { greenFWContainer } from 'styles/helpers/utils';
import { CollapseButton } from 'components/common/showhide/Collapse.styled';
import { IconProfilesContainer } from 'components/relations/relation/interaction/interaction-icon-profiles/RelationInteractionIconProfiles.styled';

const RelationAppointmentHomeWrapper = styled.div`
  height: 100%;

  .buy-test-ctas {
    display: flex;
    justify-content: center;
    gap: ${prem(15)};
  }

  ${IconProfilesContainer} {
    margin-bottom: ${prem(16)};
  }

  ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${SlideContentWrapper} {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${IonButtonWrapper} {
      margin-bottom: ${prem(30)};

      &:last-of-type {
        margin-bottom: ${prem(16)};
      }
    }
  }

  .to-other-colours {
    margin: ${prem(16)} 0;

    p {
      margin: ${prem(16)} auto;
      width: 80%;
      ${BODY.small};
      text-align: center;
      color: var(--ion-text-color-secondary);
    }
  }

  .no-profile {
    ${greenFWContainer};
    padding: ${prem(25)};
    text-align: center;
    color: var(--ion-text-color-secondary);

    p {
      margin-bottom: ${prem(20)};
    }

    .btn-quiz {
      margin: ${prem(16)} auto;
    }
  }

  .appointment-collapses-wrapper {
    ${CollapseButton} {
      color: var(--ion-text-color-secondary);
    }
  }

  .desc-wrapper {
    color: var(--ion-text-color-secondary);
  }

  .no-appointment-module {
    .desc-wrapper {
      margin: ${prem(20)} auto ${prem(20)} auto;
    }

    .no-appointment-module-cta {
      .test-module {
        width: 50%;
        margin: 0 auto;
      }
    }
  }

  .buy-module {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: ${prem(20)};

    .cannot-test-anymore {
      margin-top: ${prem(20)};
      text-align: center;
    }
  }
`;

export { RelationAppointmentHomeWrapper };
