import React from 'react';
import { SpotifyAudiosWrapper } from 'pages/home/spotify-audios/SpotifyAudios.styled';
import { IAudios } from 'types/app';
import ImageRenderer from 'components/common/image/ImageRenderer';
import { useTranslation } from 'react-i18next';
import { getLocaleValue, removeAttributes } from 'helpers/utils';
import { Container } from 'layout/page/Page.layout.styled';

export interface SpotifyAudiosProps {
  audios: IAudios;
}

const SpotifyAudios: React.FC<SpotifyAudiosProps> = ({ audios }) => {
  const { i18n } = useTranslation('translation');

  return (
    <SpotifyAudiosWrapper>
      <Container>
        {audios &&
          audios.map((audio, key) => {
            return (
              <div className="single-audio" key={key}>
                <ImageRenderer
                  alt={getLocaleValue(audio.title, i18n.language)}
                  src={audio.imageUrl as string}
                  className="audio-img"
                  onClick={() => {
                    window.open(audio.audioPermalink, '_blank');
                  }}
                />
                <div className="audio-texts">
                  <div
                    className="audio-title"
                    onClick={() => {
                      window.open(audio.audioPermalink, '_blank');
                    }}
                  >
                    {getLocaleValue(audio.title, i18n.language)}
                  </div>
                  <span
                    className="audio-description"
                    dangerouslySetInnerHTML={{
                      __html: `${removeAttributes(
                        getLocaleValue(audio.description, i18n.language),
                      ).slice(0, 200)}...`,
                    }}
                  />
                </div>
              </div>
            );
          })}
      </Container>
    </SpotifyAudiosWrapper>
  );
};

export default SpotifyAudios;
