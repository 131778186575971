import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';
import { FaqItemWrapper } from 'components/faqs/FaqsComponent.styled';
import { CollapseButton, CollapseLabel } from 'components/common/showhide/Collapse.styled';
import { Chevron } from 'components/common/icons/icon-chevron/IconChevron.styled';
import { ShowHideWrapper } from 'components/common/showhide/ShowHide.styled';

const DiscoverProfileIntro = styled.div`
  color: var(--ion-text-color-secondary);
  margin-bottom: ${prem(48)};
`;

const DiscoverProfileWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: ${prem(16)};

  ${GenericDiv} {
    margin-bottom: ${prem(48)};
  }
`;

const DiscoverProfileWithFaqWrapper = styled.div`
  ${FaqItemWrapper} {
    ${CollapseButton} {
      color: var(--ion-text-color-secondary);

      &.opened {
        ${CollapseLabel} {
          color: var(--ion-color-highlight);
        }

        ${Chevron} {
          color: var(--ion-color-highlight);
        }
      }
    }

    /* stylelint-disable */
    ${ShowHideWrapper} {
      background: rgba(var(--ion-color-accentdefault-rgb), 0.25);
    }
    /* stylelint-enable */
  }
`;

const DiscoverProfileAvailableSoonWrapper = styled.div`
  height: 100%;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .content-wrapper {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .pix-wrapper {
    width: 50%;
    min-width: ${prem(147)};
    max-width: ${prem(250)};
    margin: 0 auto;
    margin-bottom: ${prem(30)};
  }
`;

export {
  DiscoverProfileWrapper,
  DiscoverProfileIntro,
  DiscoverProfileWithFaqWrapper,
  DiscoverProfileAvailableSoonWrapper,
};
