import React, { useState } from 'react';
import { Form } from 'types/app';
import {
  DropdownItemsWrapper,
  DropDownLabel,
  DropdownWrapper,
} from 'components/common/dropdown/Dropdown.styled';
import { RegularButtonFlexed } from 'components/common/button/Button.styled';
import IconChevron from 'components/common/icons/icon-chevron/IconChevron';
import ShowHide from 'components/common/showhide/ShowHide';

export interface DropdownProps {
  label?: string | null;
  menuItems: Array<Form.IOption>;
  onSelect?: (value: Form.IOption) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ label, menuItems, onSelect }) => {
  const [open, setOpen] = useState(false);
  const onSelectEntry = (value: Form.IOption) => {
    onSelect && onSelect(value);
  };

  return (
    <DropdownWrapper className="dropdown-wrapper">
      <RegularButtonFlexed
        type="button"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {label && <DropDownLabel>{label}</DropDownLabel>}
        <IconChevron mode="downup" opened={open} />
      </RegularButtonFlexed>
      {!!menuItems?.length && (
        <ShowHide opened={open}>
          <DropdownItemsWrapper>
            {menuItems.map((opt, index) => {
              return (
                <RegularButtonFlexed
                  className="dropdown-item"
                  type="button"
                  key={`opt-${index}`}
                  onClick={() => {
                    onSelectEntry(opt);
                  }}
                >
                  <span>{opt.label}</span>
                </RegularButtonFlexed>
              );
            })}
          </DropdownItemsWrapper>
        </ShowHide>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
