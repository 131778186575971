import styled from 'styled-components';
import { ButtonInnerWrapper, IonButtonWrapper } from 'components/common/button/Button.styled';
import { prem } from 'styles/helpers/spacing';

const AppMenuWrapper = styled.div`
  ${IonButtonWrapper} {
    margin-bottom: ${prem(16)};

    ion-button {
      --color: var(--comcolors-main-menu);
    }

    ion-button::part(native) {
      padding: 0;
    }
  }

  ion-list {
    background: transparent;
  }

  ion-item {
    --background: transparent;
    --background-activated: transparent;
    --color: var(--comcolors-main-menu);
    --min-height: ${prem(40)};

    ion-label {
      margin: 0;
    }
  }
`;
const ButtonContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: ${prem(25)};
  height: ${prem(25)};
  margin-right: ${prem(15)};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const UserNameContainer = styled.p`
  margin: ${prem(16)};
  text-align: center;
  color: var(--ion-text-color-secondary);
`;

const LogoutContainer = styled.div`
  margin: ${prem(20)} auto 0;
  width: 50%;
  max-width: ${prem(200)};

  ${ButtonInnerWrapper} {
    margin: ${prem(5)};
  }

  ${IconWrapper} {
    width: ${prem(20)};
    height: ${prem(20)};
    margin-right: ${prem(10)};
  }
`;

const LoginMenuContainer = styled.div`
  margin: ${prem(20)} auto 0;
  width: 50%;
  max-width: ${prem(200)};

  ${ButtonInnerWrapper} {
    margin: ${prem(5)};
  }

  ${IconWrapper} {
    width: ${prem(20)};
    height: ${prem(20)};
    margin-right: ${prem(10)};
  }
`;

export { AppMenuWrapper, LogoutContainer, LoginMenuContainer, ButtonContentWrapper, IconWrapper, UserNameContainer };
