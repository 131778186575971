import React, { createContext, FC, useState } from 'react';
import { IAdvice, IComponentWithChildren } from 'types/app';

export interface NotificationsContextProps {
  notifications: Array<IAdvice>;
  updateNotifications: (notifs: Array<IAdvice>) => void;
  hasNewNotifications: boolean;
}

const NotificationsContext = createContext<NotificationsContextProps | null>(null);

const NotificationsProvider: FC<IComponentWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<IAdvice>>([]);
  const [hasNotifications, setNewNotificationsFlag] = useState<boolean>(false);
  const updateNotifications = (n: Array<IAdvice>) => {
    setNewNotificationsFlag(n.some((item) => !item.read));
    setNotifications(n);
  };

  return (
    <NotificationsContext.Provider
      value={{
        updateNotifications,
        notifications,
        hasNewNotifications: hasNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
