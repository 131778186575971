import { PaymentSuccessWrapper } from 'components/payment/success/PaymentSuccess.styled';
import { ReactComponent as IconSuccess } from 'assets/svg/icon-sucess.svg';
import React, { useContext } from 'react';
import { PageTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import Confetti from 'react-confetti';
import Button from 'components/common/button/Button';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';

const PaymentSuccess = () => {
  const { t } = useTranslation('translation');
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  return (
    <PaymentSuccessWrapper>
      <PageTitle>
        <div>🎉</div>
        <div> {t('payment.paymentconfirmed')} </div>
        <div>🎉</div>
      </PageTitle>
      <Button
        color="dark"
        fill="solid"
        onClick={() => {
          updateModalConf();
        }}
      >
        {t('payment.closethismessage')}
      </Button>
      <Confetti />
      <IconSuccess />
    </PaymentSuccessWrapper>
  );
};
export default PaymentSuccess;
