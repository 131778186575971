import React, { useContext } from 'react';
import { Container, PageTitle } from 'layout/page/Page.layout.styled';
import { useTranslation } from 'react-i18next';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import {
  DiscoverProfileIntro,
  DiscoverProfileWithFaqWrapper,
} from 'pages/profile/discover-profile/DiscoverProfile.styled';
import { getLocaleValue } from 'helpers/utils';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';
import { IFaqs } from 'types/app';
import FaqsComponent from 'components/faqs/FaqsComponent';
import QuestionnaireButton from 'components/profile/QuestionnaireButton';

export interface DiscoverProfileWithFaqProps {
  faqs: IFaqs;
}

const DiscoverProfileWithFaq: React.FC<DiscoverProfileWithFaqProps> = ({ faqs }) => {
  const { user } = useContext(UserContext) as UserContextProps;
  const { t, i18n } = useTranslation('translation');

  return (
    <DiscoverProfileWithFaqWrapper>
      <Container>
        <PageTitle
          dangerouslySetInnerHTML={{
            __html: t('profile.discover.allstarts'),
          }}
        />
        <DiscoverProfileIntro
          dangerouslySetInnerHTML={{
            __html: getLocaleValue(user?.profileInfo?.textNoProfile, i18n.language),
          }}
        />
        <GenericDiv width="25%" minWidth={prem(240)} center={true}>
          <QuestionnaireButton
            label={t('profile.discover.makeappointment')}
            link={t('profile.discover.makeappointmentlink')}
          />
        </GenericDiv>
      </Container>
      <FaqsComponent
        faqs={faqs}
        title={t('profile.discover.faqtitle') || ''}
        defaultOpenedId={0}
        className="full-width"
      />
    </DiscoverProfileWithFaqWrapper>
  );
};

export default DiscoverProfileWithFaq;
