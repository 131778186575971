import React, { useContext } from 'react';
import {
  IconBadgeWarning,
  IconExplicationWrapper,
  IconSubscribed,
  RelationIconExplicationsWrapper,
} from 'components/relations/relation/icon-explications/RelationIconExplications.styled';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { BADGE } from 'constants/global';
import Button from 'components/common/button/Button';
import useAPIRelations, { IdentificationType } from 'hooks/api/useAPIRelations';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { useTranslation } from 'react-i18next';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';

export interface RelationIconEXplicationsProps {
  badge?: BADGE;
  invitationSent?: boolean;
  invitationAccepted?: boolean;
  relationId?: number;
  trueRelationPrimaryColorId?: number;
  trueRelationSecondaryColorId?: number;
  firstName?: string;
  name?: string;
  unknownProfile?: boolean;
  subscribed?: boolean;
}

const RelationIconExplications: React.FC<RelationIconEXplicationsProps> = ({
  badge,
  invitationSent,
  invitationAccepted,
  relationId,
  trueRelationPrimaryColorId,
  trueRelationSecondaryColorId,
  firstName,
  name,
  unknownProfile,
  subscribed,
}) => {
  const { updateRelation } = useAPIRelations();
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { t } = useTranslation('translation');
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  const onValidateProfileUpdate = async () => {
    if (relationId) {
      const updatedRelation = await updateRelation(
        relationId,
        IdentificationType.AUTO,
        0,
        trueRelationPrimaryColorId as number,
        trueRelationSecondaryColorId as number,
      );
      if (updatedRelation) {
        setToast({
          message: t('relations.identify.colorssaved', { relationName: `${firstName} ${name}` }),
          status: 'success',
        });
      }
    }
    updateModalConf();
  };

  const {
    icons: [
      iconBadgeChecked,
      iconBadgeWarning,
      iconBadgeQuestion,
      iconMsgSent,
      iconMsgInProgress,
      iconProfileUnknown,
      iconRelationSubscription,
    ],
  } = useIcons([
    ICON_NAMES.PROFILE_BADGE_CHECKED,
    ICON_NAMES.PROFILE_BADGE_WARNING,
    ICON_NAMES.PROFILE_BADGE_QUESTION,
    ICON_NAMES.MESSAGE_SENT,
    ICON_NAMES.MESSAGE_IN_PROGRESS,
    ICON_NAMES.UNKNOWN_PROFILE,
    ICON_NAMES.RELATION_SUBSCRIPTION_MARKER,
  ]);

  interface IIconExplication {
    icon: any;
    description: React.ReactNode;
    show: boolean;
  }

  const ICON_EXPLICATIONS: Array<IIconExplication> = [
    {
      icon: iconMsgSent.icon,
      description: <>{t('relations.iconexplications.invitationaccepted.description')}</>,
      show: invitationAccepted as boolean,
    },
    {
      icon: iconMsgInProgress.icon,
      description: <>{t('relations.iconexplications.invitationsent.description')}</>,
      show: invitationSent as boolean,
    },
    {
      icon: <IconSubscribed>{iconRelationSubscription.icon}</IconSubscribed>,
      description: <>{t('relations.iconexplications.subscribed.description')}</>,
      show: !!subscribed,
    },
    {
      icon: iconProfileUnknown.icon,
      description: <>{t('relations.iconexplications.unknownprofile.description')}</>,
      show: unknownProfile as boolean,
    },
    {
      icon: iconBadgeChecked.icon,
      description: <>{t('relations.iconexplications.badgechecked.description')}</>,
      show: badge === BADGE.VALID,
    },
    {
      icon: iconBadgeWarning.icon,
      description: (
        <IconBadgeWarning>
          {t('relations.iconexplications.badgewarning.description')}
          <Button
            fill="solid"
            color="accent"
            onClick={() => {
              onValidateProfileUpdate();
            }}
          >
            {t('relations.iconexplications.badgewarning.useprofilecolors')}
          </Button>
        </IconBadgeWarning>
      ),
      show: badge === BADGE.WARNING,
    },
    {
      icon: iconBadgeQuestion.icon,
      description: <>{t('relations.iconexplications.badgequestion.description')}.</>,
      show: badge === BADGE.QUESTION,
    },
  ];

  const showRelationIconExplications = ICON_EXPLICATIONS.find(
    (explication) => explication.show === true,
  );

  return (
    <>
      {showRelationIconExplications && (
        <RelationIconExplicationsWrapper>
          <strong className='title'>{t('common.legends')}</strong>
          {ICON_EXPLICATIONS &&
            ICON_EXPLICATIONS.map((badgeExplication, key) => {
              return (
                <React.Fragment key={key}>
                  {badgeExplication.show && (
                    <IconExplicationWrapper key={key}>
                      <div className="icon">{badgeExplication.icon}</div>
                      <div className="description">{badgeExplication.description}</div>
                    </IconExplicationWrapper>
                  )}
                </React.Fragment>
              );
            })}
        </RelationIconExplicationsWrapper>
      )}
    </>
  );
};

export default RelationIconExplications;
