import React, { useContext, useEffect } from 'react';
import { BuyToolModalWrapper } from 'components/modal/buy-tool-modal/BuyToolModal.styled';
import BuyTools from 'components/shop/buy-tools/BuyTools';
import { BuyToolContext, BuyToolContextProps } from 'contexts/BuyToolContext';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
const BuyToolModal: React.FC = () => {
  const { paymentConfs } = useContext(BuyToolContext) as BuyToolContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  useEffect(() => {
    if (paymentConfs) {
      updateModalConf({
        component: <BuyTools tools={paymentConfs.tools} />,
        onDismiss: () => {
          updateModalConf();
        },
      });
    }
  }, [paymentConfs]);

  return <BuyToolModalWrapper />;
};

export default BuyToolModal;
