import React, { useEffect, useState } from 'react';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import { useTranslation } from 'react-i18next';
import { Form, ISubscription } from 'types/app';
import Button from 'components/common/button/Button';
import {
  ActionWrapper,
  NotificationToggleWrapper,
  RelationInteractionSubscriptionWrapper,
  SelectBoxesWrapper,
  SubscriptionNote,
} from 'components/modal/subscription/SubscriptionModal.styled';
import Toggle from 'components/common/form/toggle/Toggle';
import SelectBox, { SelectBoxProps } from 'components/common/form/selectbox/SelectBox';
import useFormHandler from 'hooks/useFormHandler';
import { getHourFromUTC, getLocaleHours } from 'helpers/utils';
import NotificationService from 'helpers/notification';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import useAPINotificationSubscription, {
  IMotivationPayload,
  IRelationPayload,
  SubscriptionType,
} from 'hooks/api/useAPINotificationSubscription';

export interface SubscriptionModalProps {
  type: SubscriptionType;
  relation?: IRelationPayload;
  motivation?: IMotivationPayload;
  texts?: {
    title?: string | null;
    subscriptionNote?: string | null;
    unsubscriptionTitle?: string | null;
    unsubscriptionNote?: string | null;
  };
  subscriptionId?: number;
  onValidate: (subscription?: ISubscription) => void;
  onCancel?: () => void;
  hour?: number;
  day?: string;
  deviceEndPoint?: string;
  deviceAuth?: string;
  deviceP256Dh?: string;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  type,
  relation,
  motivation,
  subscriptionId,
  texts,
  onValidate,
  hour,
  day,
  deviceEndPoint,
}) => {
  const { t, i18n } = useTranslation('translation');
  const { subscribeToNotification, unsubscribeFromNotification } = useAPINotificationSubscription();
  const [pushManagerSubscriptionObj, setPushManagerSubscriptionObj] = useState<
    PushSubscriptionJSON | undefined | null
  >();
  const [notificationSubscription, setNotificationSubscription] = useState<boolean>(
    !!deviceEndPoint,
  );
  const [notificationError, setNotificationError] = useState<boolean>(false);
  const dayOptions = [
    {
      id: 'daily',
      label: t('common.form.notificationday.daysofweek'),
    },
    {
      id: 'weekly',
      label: t('common.form.notificationday.weekend'),
    },
  ];
  const hourOptions = getLocaleHours(i18n.language);
  const defaultDay = dayOptions.find((d) => d.id === day);
  const defaultHour = hour && hourOptions.find((h) => h.id === getHourFromUTC(hour));
  const { fields, getFieldByName, updateFieldValue } = useFormHandler([
    {
      name: 'day',
      options: dayOptions,
      value: defaultDay || dayOptions[0],
    },
    {
      name: 'hour',
      options: hourOptions,
      value: defaultHour || hourOptions[12],
    },
  ]);
  const sendSubscription = async (dayValue: string, hourValue: number) => {
    const _deviceEndPoint = pushManagerSubscriptionObj?.endpoint;
    const _deviceAuth = pushManagerSubscriptionObj?.keys?.auth;
    const _deviceP256Dh = pushManagerSubscriptionObj?.keys?.p256dh;
    const result = await subscribeToNotification(
      type,
      _deviceEndPoint || '',
      _deviceAuth || '',
      _deviceP256Dh || '',
      hourValue,
      dayValue,
      relation,
      motivation,
      subscriptionId,
    );

    result && onValidate && onValidate();
  };
  const unsubscribe = async (id: number) => {
    const result = await unsubscribeFromNotification(id);

    result && onValidate && onValidate();
  };
  const onAction = (isSubscribe: boolean): void => {
    // Unsubscribe
    if (!isSubscribe) {
      subscriptionId && unsubscribe(subscriptionId);
      return;
    }

    if (!pushManagerSubscriptionObj && notificationSubscription) {
      setNotificationError(true);
      setNotificationSubscription(false);
    } else {
      const dayValue = (getFieldByName('day').value as Form.IOption).id as string;
      const hourValue = getHourFromUTC(
        (getFieldByName('hour').value as Form.IOption).id as number,
        false,
      );

      setNotificationError(false);
      sendSubscription(dayValue, hourValue);
    }
  };
  const onNotificationToggleChanged = async (checked: boolean) => {
    setNotificationError(false);
    setNotificationSubscription(checked);

    if (checked) {
      const result = await NotificationService.getSubscription();

      setPushManagerSubscriptionObj(result);
    } else {
      setPushManagerSubscriptionObj(null);
    }
  };
  const initNotificationToggle = async () => {
    const result = await NotificationService.getSubscription();
    setNotificationSubscription(!!result && !!deviceEndPoint);
    setPushManagerSubscriptionObj(result);
  };

  useEffect(() => {
    initNotificationToggle();
  }, []);

  return (
    <RelationInteractionSubscriptionWrapper>
      {subscriptionId ? (
        <AppModalTitle>{t('relations.management.subscriptionparams')}</AppModalTitle>
      ) : (
        <AppModalTitle
          dangerouslySetInnerHTML={{
            __html: texts?.title || '',
          }}
        />
      )}
      {!subscriptionId && texts?.subscriptionNote && (
        <SubscriptionNote>{texts?.subscriptionNote}</SubscriptionNote>
      )}
      <form noValidate autoComplete="off">
        <NotificationToggleWrapper
          className={
            !NotificationService.isNotificationPushAvailable() ? 'disabled-notification' : ''
          }
        >
          <span>{t('common.notificationpush')}</span>
          <Toggle
            checked={notificationSubscription}
            onChecked={onNotificationToggleChanged}
            enabled={NotificationService.isNotificationPushAvailable()}
          />
        </NotificationToggleWrapper>
        {(notificationError || !NotificationService.isNotificationPushAvailable()) && (
          <p className="notification-error">
            {NotificationService.isNotificationPushAvailable()
              ? t('message.error.notification')
              : t('message.error.nonotificationpush')}
          </p>
        )}
        <SelectBoxesWrapper>
          <SelectBox
            {...({
              ...getFieldByName('day', fields),
              label: t('common.day') || '',
              onChange: (value?) => updateFieldValue('day', value),
              required: true,
            } as SelectBoxProps)}
          />
          <SelectBox
            {...({
              ...getFieldByName('hour', fields),
              label: t('common.hour') || '',
              onChange: (value?) => updateFieldValue('hour', value),
              isNative: true,
              required: true,
            } as SelectBoxProps)}
          />
        </SelectBoxesWrapper>
      </form>
      {subscriptionId && (
        <GenericDiv
          width="30%"
          minWidth={`${prem(150)}`}
          center={true}
          className="btn-form-submit-wrapper"
        >
          <Button
            color="white"
            expand={true}
            fill="outline"
            onClick={() => {
              onAction(true);
            }}
          >
            {t('common.save')}
          </Button>
        </GenericDiv>
      )}
      {subscriptionId && (
        <>
          <AppModalTitle
            dangerouslySetInnerHTML={{
              __html: texts?.unsubscriptionTitle || '',
            }}
          />
          {texts?.unsubscriptionNote && (
            <SubscriptionNote>{texts?.unsubscriptionNote}</SubscriptionNote>
          )}
        </>
      )}
      <ActionWrapper>
        {subscriptionId ? (
          <Button
            color="white"
            expand={true}
            fill="outline"
            onClick={() => {
              onAction(false);
            }}
          >
            {t('common.idonotsubscribe')}
          </Button>
        ) : (
          <Button
            color="white"
            expand={true}
            fill="outline"
            onClick={() => {
              onAction(true);
            }}
          >
            {t('common.isubscribe')}
          </Button>
        )}
      </ActionWrapper>
    </RelationInteractionSubscriptionWrapper>
  );
};

export default SubscriptionModal;
