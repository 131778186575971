import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const ColorLabel = styled.span<{ color?: string | null }>`
  &::first-letter {
    text-transform: uppercase;
  }

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const ColorItem = styled.button<{ bgColor: string }>`
  cursor: pointer;
  border-radius: 50%;
  width: ${prem(42)};
  height: ${prem(42)};

  &:active {
    opacity: 0.5;
  }

  ${({ bgColor }) => css`
    background: ${bgColor};
  `}

  &.selected {
    position: relative;
    border: 3px solid var(--ion-text-color);

    &::after {
      content: '';
      position: absolute;
      bottom: ${prem(-12)};
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      background-color: var(--ion-text-color);
      width: ${prem(5)};
      height: ${prem(5)};
    }
  }

  @media (${MQ_MAX_HEIGHT}) {
    width: ${prem(32)};
    height: ${prem(32)};
  }
`;

const ColorPickerContainer = styled.div`
  display: flex;
  gap: ${prem(10)};
  align-items: center;
  padding: ${prem(10)} ${prem(5)};
`;

export { ColorLabel, ColorItem, ColorPickerContainer };
