import React, { useCallback, useEffect, useState } from 'react';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { ComColorWrapper } from 'pages/models/comcolors/ComColors.styled';
import {
  FabButton,
  FabButtonIconWrapper,
  FabInnerButton,
} from 'components/common/fab-button/FabButton';
import { ReactComponent as IconMessageCircle } from 'assets/svg/icon-message-circle.svg';
import { useIonRouter } from '@ionic/react';
import { ROUTES } from 'constants/routes';
import { ISectionBaseTexts } from 'types/app';
import useAPIModels from 'hooks/api/useAPIModels';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

const ComColors: React.FC = () => {
  const ionRouter = useIonRouter();
  const { getComcolors } = useAPIModels();
  const [comcolorsInformationsState, setComcolorsInformationsState] = useState<ISectionBaseTexts>();
  const { i18n } = useTranslation('translation');

  const loadDatas = useCallback(async () => {
    const comcolorsInformations = await getComcolors();
    setComcolorsInformationsState(comcolorsInformations);
  }, []);

  useEffect(() => {
    loadDatas();
  }, []);

  useEffect(() => {
    // console.log(comcolorsInformationsState);
  }, [comcolorsInformationsState]);

  return (
    <ComColorWrapper className="app-page">
      <Container>
        {comcolorsInformationsState && (
          <>
            <PageInnerTitle
              dangerouslySetInnerHTML={{
                __html: getLocaleValue(comcolorsInformationsState.title, i18n.language),
              }}
            />
            <img src={comcolorsInformationsState.imageUrl} alt="" />
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: getLocaleValue(comcolorsInformationsState.description, i18n.language),
              }}
            />
          </>
        )}
        <FabButton
          slot="fixed"
          vertical="bottom"
          horizontal="end"
          onClick={() => {
            ionRouter.push(ROUTES.CONTACT);
          }}
        >
          <FabInnerButton color="orange">
            <FabButtonIconWrapper>
              <IconMessageCircle />
            </FabButtonIconWrapper>
          </FabInnerButton>
        </FabButton>
      </Container>
    </ComColorWrapper>
  );
};

export default ComColors;
