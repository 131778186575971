import React from 'react';
import { IonButton } from '@ionic/react';
import { ButtonProps } from 'components/common/button/Button';
import { ButtonBackWrapper } from 'components/common/button/Button.styled';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { useTranslation } from 'react-i18next';

export type ButtonBackProps = Pick<ButtonProps, 'routerLink' | 'onClick' | 'className'>;

const ButtonBack: React.FC<ButtonBackProps> = ({ routerLink, onClick, className }) => {
  const { t } = useTranslation('translation');
  const {
    icons: [chevronBack],
  } = useIcons([ICON_NAMES.CHEVRON_BACK]);
  const props = routerLink ? { routerLink } : onClick ? { onClick } : {};

  return (
    <ButtonBackWrapper className={className}>
      <IonButton {...props} routerDirection="back" className="clear">
        <div className="icon" slot="start">
          {chevronBack.icon}
        </div>
        <span className="label">{t('common.back')}</span>
      </IonButton>
    </ButtonBackWrapper>
  );
};

export default ButtonBack;
