import React from 'react';
import {
  CloseButton, CloseButtonWrapper,
  MySummaryPopupContent,
  MySummaryPopupContentWrapper,
  MySummaryPopupInner,
  MySummaryPopupWrapper,
} from 'pages/profile/has-profile/my-summary/MySummaryPopup.styled';

interface IMySummaryPopup {
  contentHTML: string;
  onClose: () => void;
  isOpen?: boolean;
}

const MySummaryPopup: React.FC<IMySummaryPopup> = ({
  isOpen,
  contentHTML,
  onClose,
}: IMySummaryPopup) => {
  const onButtonCloseClicked = () => {
    onClose();
  };

  return (
    <MySummaryPopupWrapper className="popp-container" open={isOpen}>
      <MySummaryPopupInner className="popup-inner" open={isOpen}>
        <CloseButtonWrapper>
          <CloseButton onClick={() => onButtonCloseClicked()}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.23312 0L0 3.23312L1.65096 4.88408L5.73248 9.03439L1.65096 13.1159L0 14.6981L3.23312 18L4.88408 16.349L9.03439 12.1987L13.1159 16.349L14.6981 18L18 14.6981L16.349 13.1159L12.1987 9.03439L16.349 4.88408L18 3.23312L14.6981 0L13.1159 1.65096L9.03439 5.73248L4.88408 1.65096L3.23312 0Z"
                fill="#D9D9D9"
              />
            </svg>
          </CloseButton>
        </CloseButtonWrapper>
        <MySummaryPopupContentWrapper>
          <MySummaryPopupContent
            className="my-summary-popup-content"
            dangerouslySetInnerHTML={{ __html: contentHTML }}
          />
        </MySummaryPopupContentWrapper>
      </MySummaryPopupInner>
    </MySummaryPopupWrapper>
  );
};

export default MySummaryPopup;
