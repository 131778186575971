import useAPIResources from 'hooks/api/useAPIResources';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IModules } from 'types/app';
import { PayableToolsContext, PayableToolsContextProps } from 'contexts//PayableToolsContext';

export interface ModuleContextProps {
  modules: IModules;
  loadModules: () => Promise<void>;
}
const ModuleContext = createContext<ModuleContextProps | null>(null);
const ModuleProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [modules, setModules] = useState<IModules>([]);
  const { getModules } = useAPIResources();
  const { payableTools } = useContext(PayableToolsContext) as PayableToolsContextProps;

  const loadModules = async () => {
    setModules(await getModules());
  };

  useEffect(() => {
    if (payableTools && modules.length == 0) loadModules();
  }, [payableTools]);

  return (
    <ModuleContext.Provider
      value={{
        loadModules: loadModules,
        modules: modules,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

export { ModuleContext, ModuleProvider };
