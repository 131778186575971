import styled from 'styled-components';
import { Container } from 'layout/page/Page.layout.styled';
import { prem } from 'styles/helpers/spacing';
import { IconProfileWrapper } from 'components/common/icons/icon-profile/IconProfile.styled';
import {
  RelationColorSelectorWrapper,
  SelectedColorContainer,
} from 'components/relations/relation/color-selector/RelationColorSelector.styled';
import { ColorLabel, ColorPickerContainer } from 'components/colours-picker/ColoursPicker.styled';

const RelationAppointmentColorSelectionWrapper = styled.div`
  height: 100%;

  ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${IconProfileWrapper} {
    display: none;
  }

  ${RelationColorSelectorWrapper} {
    margin-bottom: ${prem(40)};

    ${SelectedColorContainer} {
      display: block;

      h3 {
        text-align: center;
      }

      ${ColorLabel} {
        display: block;
        text-align: center;
      }
    }

    ${ColorPickerContainer} {
      justify-content: center;
    }
  }
`;

export { RelationAppointmentColorSelectionWrapper };
