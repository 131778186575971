import React, { createContext, FC, useState } from 'react';
import { IComponentWithChildren } from 'types/app';

export interface IModalConf {
  component?: React.ReactElement;
  onDismiss?: () => void;
  showModal?: boolean;
  hide?: boolean;
}

export interface ModalContextProps {
  modalConf?: IModalConf;
  updateModalConf: (conf?: IModalConf) => void;
}

export interface ModalProviderProps extends IComponentWithChildren {
  conf?: IModalConf;
}

const ModalContext = createContext<ModalContextProps | null>(null);
const ModalProvider: FC<ModalProviderProps> = ({ conf, children }) => {
  const [currentConf, setConf] = useState<IModalConf | undefined>(conf);
  const updateModalConf = (conf?: IModalConf) => {
    conf &&
      setConf({
        ...(currentConf ? currentConf : {}),
        ...conf,
      });

    !conf && setConf(undefined);
  };

  return (
    <ModalContext.Provider value={{ modalConf: currentConf, updateModalConf }}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
