import React from 'react';
import { Container } from 'layout/page/Page.layout.styled';
import { AnswerQuestionnaireWrapper } from 'pages/profile/answer-questionnaire/AnswerQuestionnaire.styled';
import { prem } from 'styles/helpers/spacing';
import { GenericDiv } from 'styles/helpers/utils';
import QuestionnaireButton from 'components/profile/QuestionnaireButton';

export interface AnswerQuestionnaireProps {
  message: string;
  btnLink?: string | null;
  btnLabel?: string | null;
}

const AnswerQuestionnaire: React.FC<AnswerQuestionnaireProps> = ({
  message,
  btnLabel,
  btnLink,
}) => {
  return (
    <AnswerQuestionnaireWrapper className="app-page">
      <Container flexCentered={true}>
        <p className="message">{message}</p>
        <GenericDiv width="25%" minWidth={prem(240)} center={true}>
          <QuestionnaireButton link={btnLink} label={btnLabel} />
        </GenericDiv>
      </Container>
    </AnswerQuestionnaireWrapper>
  );
};

export default AnswerQuestionnaire;
