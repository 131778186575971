import styled, { css } from 'styled-components';
import { SUBTITLE } from 'styles/helpers/typography';
import { prem } from 'styles/helpers/spacing';

const reset = css`
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  text-decoration: none;
  color: currentcolor;
  box-sizing: border-box;
`;

const transparentEl = css`
  background: transparent;
  border: none;
`;

const centerize = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const firstLetterUpper = css`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const GenericDiv = styled.div<{
  width?: string;
  minWidth: string;
  maxWidth?: string;
  center?: boolean;
  textCenter?: boolean;
}>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};

  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
    `};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;

const DefaultLink = styled.a`
  color: var(--ion-text-color-secondary);
  text-decoration: underline;
`;

const centerMessage = css`
  ${SUBTITLE.medium};
  margin: ${prem(24)} 0;
  text-align: center;
`;

const SvgWrapper = styled.div``;

const greenFWContainer = css`
  padding-top: ${prem(25)};
  padding-bottom: ${prem(25)};
  margin-bottom: ${prem(30)};
  /* stylelint-disable */
  background: rgba(var(--comcolors-bg-green-alt), 0.05);
  /* stylelint-enable */
`;

export {
  reset,
  transparentEl,
  centerize,
  centerMessage,
  DefaultLink,
  GenericDiv,
  firstLetterUpper,
  SvgWrapper,
  greenFWContainer
};
