import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { BODY } from 'styles/helpers/typography';

const BlogPostsWrapper = styled.div``;

const SingleBlogPost = styled.div`
  margin-bottom: ${prem(10)};

  .title {
    ${BODY.medium}
    font-weight: bolder;
    margin-bottom: ${prem(5)};
  }

  .image-container {
    max-width: 100%;
    margin: ${prem(20)} 0;
    text-align: center;

    img {
      border-radius: ${prem(2)};
    }
  }

  .description {
    ${BODY.small}

    p {
      margin-bottom: ${prem(5)};
    }
  }

  .read-next {
    display: flex;
    align-items: center;
    gap: ${prem(10)};
    color: var(--ion-color-orange);
    justify-content: end;
    margin-bottom: ${prem(10)};
    ${BODY.small};

    svg {
      cursor: pointer;
      width: ${prem(27)};

      path {
        stroke-width: ${prem(4)};
      }
    }
  }
`;

export { BlogPostsWrapper, SingleBlogPost };
