import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/constants/devices';
import { prem } from 'styles/helpers/spacing';

const ShareProfileModalWrapper = styled.div`
  width: 100%;

  @media (${BREAKPOINTS.M}) {
    width: ${prem(500)};
  }

  .link-url {
    display: block;
    margin: 0 auto ${prem(20)} auto;
    width: 70%;
    overflow-x: auto;
    white-space: nowrap;
    padding: ${prem(10)};
    border-radius: ${prem(10)};
    border: 1px solid var(--ion-white-to-black);
    color: var(--ion-text-color-secondary);
  }
`;

export { ShareProfileModalWrapper };
